/**
 * @copyright KARD
 */

import React from 'react'

import {
	// Button,
} from '@material-ui/core'

import { navigate } from "@reach/router"

import Loader from "react-js-loader";

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

// import {Loader} from '../../aui/loader'
// import Hdr from '../_common/hdr'
// import SideBar from '../_common/sidebar'
// import ChatNotif from '../_common/chat_notifications'

/**
 */
class Change extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )

        this.state = {
            isLoading: false,
            old: '',
            new: '',
            new2: '',
        }
    }   // cstr

    /**
     */
    render()
    {
        return (
            <div className="main-wrapper account-wrapper">
                <div className="account-page">
                    <div className="account-center">
                        <div className="account-box">
                            <div className="account-logo">
                                <a href="#" onClick={()=>navigate('/dashboard')}><img src="/assets/img/logo.png" alt="" /></a>
                            </div>

                            <>
                            {
                                this.state.isLoading
                            // ?   <Loader />
                            ?   <div>
                                    <Loader
                                        type="box-rectangular"
                                        bgColor={"#007bff"}
                                        title={"Loading ..."}
                                        color={"#007bff"}
                                        size={150}
                                    />
                                    {/* <div
                                        className="modal"
                                        style={{
                                        // margin: '0 auto'
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        background: "#d1cccc80",
                                        }}
                                    >
                                        <Loader
                                        type="box-rectangular"
                                        bgColor={"#007bff"}
                                        title={"Loading ..."}
                                        color={"#007bff"}
                                        size={150}
                                        />
                                    </div> */}
                                </div>

                            :   <form className="form-signin" onSubmit={ this.OnSubmit }>
                                    <div className="form-group">
                                        <label>Old Password</label>
                                        <input type="password"
                                            // autoFocus
                                            className="form-control"
                                            value={this.state.old}
                                            onChange={(e) => this.setState({old: e.target.value})}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>New Password</label>
                                        <input type="password"
                                            className="form-control"
                                            value={this.state.new}
                                            onChange={(e) => this.setState({new: e.target.value})}
                                            required
                                        />
                                    </div>

                                    {/* <div className="form-group">
                                        <label>Password</label>
                                        <input type="password"
                                            className="form-control"
                                            value={this.state.new2}
                                            onChange={(e) => this.setState({new2: e.target.value})}
                                            required
                                        />
                                    </div> */}

                                    <div className="form-group text-center">
                                        <button type="submit" value='submit' className="btn btn-primary account-btn">Login</button>
                                    </div>
                                </form>
                            }
                            </>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**
     */
    OnSubmit = ( event ) =>
    {
        event.preventDefault()

        this.Change().catch( err => {} )
    }   // OnSubmit

    /**
     */
    Change = async () =>
    {
        try
        {
            this.setState( {isLoading: true} )

            await this.props.Auth_Change( this.state.old, this.state.new )

            this.setState( {isLoading: false} )

            navigate( '/dashboard' )

            //
            return {}
        }
        catch( err )
        {
            console.warn( 'auth:Change: Change: err: ', err )

            this.setState( {isLoading: false} )

            return Promise.reject( err )
        }
    }
}   // Change

/**
 */
const mapStateToProps = ( state ) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Change )

