/**
 * @copyright KARD
 */

import React, { useEffect, useState } from "react";

import // Button,
"@material-ui/core";

// import { navigate } from "@reach/router"

import {
  LineChart,
  Line,
  // BarChart, Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

import { connect, useSelector } from "react-redux";
import * as actions from "../../rdx/actions";

// import * as Cfg from '../../_cfg'
// import {Lambda, Core} from '../../api/AWS'
import {
  Time,
  // UI,
} from "../../api/Utils";

import Page from "../_common/page";

// import Overview from './overview'
import Campaigns from "./campaigns";
import Projects from "./projects";
import Notifications from "./notifications";
import Stats from "./stats";
import RecentDonation from "./donations";
import {
  getTotalCampaign,
  getTotalDonation,
  getTotalDonationAmount,
  getTotalDonor,
} from "./dashbord_data";
// import ActivityLog from "./activity_log";
// import Introduction from "./introduction"

const useWindowDimensions = () => {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
};
/**
 */
const Home = (props) => {
  // const parentRef = React.useRef(null);
  const orgId = useSelector((state) => state.__auth.orgId);
  const [total, SetTotal] = useState({
    campaign: 0,
    donation: "0.00",
    donor: 0,
    last30DaysDonation: "0.00",
  });

  const { height, width } = useWindowDimensions();

  // console.log("height, width",height, width)

  // const [width, Width] = React.useState( 0 )
  const [isLoading, SetLoading] = React.useState(false);

  // React.useEffect(() => {
  //   // console.log("Home: props: ", props);
  //   // console.log(
  //   //   "Home: window.localStorage.getItem: ",
  //   //   window.localStorage.getItem("org_id")
  //   // );
  //   // JSON.stringify("");
  //   // if( parentRef.current )
  //   // {
  //   //     console.log( 'Home: parentRef.current.offsetWidth: ', parentRef.current.offsetWidth )
  //   //
  //   //     Width( parentRef.current.offsetWidth )
  //   // }
  // }, [parentRef]);

  useEffect(() => {
    SetLoading(true);
    init()
      .then((d) => SetLoading(false))
      .catch((e) => SetLoading(false));
  }, []);

  async function init() {
    try {
      const promise = [
        await getTotalCampaign(orgId),
        await getTotalDonor(orgId),
        await getTotalDonation(orgId),
      ];
      const result = await Promise.all(promise);
      result.map((d) => {
        if (d.totalCampaign) {
          SetTotal((prevState) => {
            return { ...prevState, campaign: d.totalCampaign };
          });
        } else if (d.totalDonor) {
          SetTotal((prevState) => {
            return { ...prevState, donor: d.totalDonor };
          });
        } else if (d.totalDonation) {
          const { totalAmount } = getTotalDonationAmount(d.Items, true);
          SetTotal((prevState) => {
            return { ...prevState, donation: totalAmount };
          });
        }
      });
      // const {totalItem} = await getTotalCampaign(orgId)
      // const {totalItem} = await getTotalDonor(orgId)
      // SetTotal(prevState => {
      //     return ({...prevState,campaign: totalItem})
      // })
      // console.log("result", result);

      return {};
    } catch (e) {
      console.warn(e);
    }
  }

  function donationLast30Days(data) {
    // console.log(data);
    const { totalAmount } = getTotalDonationAmount(data, false);
    SetTotal((prevState) => {
      return { ...prevState, last30DaysDonation: totalAmount };
    });
  }

  return (
    <Page active="dashboard">
      <div className="row">
        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div className="dash-widget">
            <span className="dash-widget-bg1">
              <i className="fa fa-cog" aria-hidden="true"></i>
            </span>
            <div className="dash-widget-info text-right">
              <div className="big-text" style={{ color: "#009efb" }}>
                {total.campaign}
              </div>
              <span className="small-text" style={{ color: "#009efb" }}>
                No of Campaign
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div className="dash-widget">
            <span className="dash-widget-bg2">
              <i className="fa fa-dollar"></i>
            </span>
            <div className="dash-widget-info text-right">
              <div className="big-text" style={{ color: "#55ce63" }}>
                £{total.donation}
              </div>
              <span className="small-text" style={{ color: "#55ce63" }}>
                Total Donation
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div className="dash-widget">
            <span className="dash-widget-bg3">
              <i className="fa fa-dollar" aria-hidden="true"></i>
            </span>
            <div className="dash-widget-info text-right">
              <div className="big-text" style={{ color: "#7a92a3" }}>
                £{total.last30DaysDonation}
              </div>
              <span className="small-text" style={{ color: "#7a92a3" }}>
                Last Month Donations
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div className="dash-widget">
            <span className="dash-widget-bg4">
              <i className="fa fa-user" aria-hidden="true"></i>
            </span>
            <div className="dash-widget-info text-right">
              <div className="big-text" style={{ color: "#ffbc35" }}>
                {total.donor}
              </div>
              <span className="small-text" style={{ color: "#ffbc35" }}>
                Total Donor
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 col-lg-8 col-xl-8">
          {/* <Last30Days Donation_Query={props.Donation_Query} /> */}
        </div>
      </div>

      <div active="dashboard">
        <div className="row">
          <div
            className="col-12 col-md-6 col-lg-10 col-xl-10"
            // ref={parentRef}
          >
            <Last30Days
              width={width}
              Donation_Query={props.Donation_Query}
              donationLast30Days={donationLast30Days}
            />
            {/* <Introduction/> */}
            {/* <Stats/> */}
            <Campaigns />
            <Projects />
          </div>

          {/* Sidebar */}
          <div className="col-12 col-md-6 col-lg-2 col-xl-2">
            {/* <Notifications /> */}
            <RecentDonation />
            {/* <ActivityLog/> */}
          </div>
        </div>
      </div>
    </Page>
  );
}; // Home

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Home);

/**
 */
const Last30Days = (props) => {
  const [isBusy, IsBusy] = React.useState(false);

  // const [data, Data] = React.useState( demo_last30days )
  const [data, Data] = React.useState([]);

  /**
   */
  React.useEffect(() => {
    Load().catch((err) => {});
  }, []);

  /**
   */
  const Load = async () => {
    try {
      IsBusy(true);

      // retrieve data
      let date_st = new Date();
      let date_end = new Date();

      date_st.setDate(date_st.getDate() - 30);
      date_st.setHours(0);
      date_st.setMinutes(0);
      date_st.setSeconds(0);

      date_end.setHours(23);
      date_end.setMinutes(59);
      date_end.setSeconds(59);

      const index = "new";
      const dt_st = Time.Epoch(date_st.getTime());
      const dt_end = Time.Epoch(date_end.getTime());

      let arr_q = [];
      let last_key = null;

      do {
        const resp_q = await props.Donation_Query({
          index,
          last_key,
          dt_st,
          dt_end,
        });

        // console.log( 'Last30Days: Load: resp_q: ', resp_q )

        const tmp_arr = resp_q.Count ? resp_q.Items : [];

        arr_q = [...arr_q, ...tmp_arr];

        last_key = resp_q.LastEvaluatedKey ? resp_q.LastEvaluatedKey : null;
      } while (last_key);

      // console.log( 'Last30Days: Load: arr_q: ', arr_q )

      if (props.donationLast30Days) {
        props.donationLast30Days(arr_q);
      }

      // cluster data
      const v_obj = arr_q.reduce((a, c) => {
        const dt_obj = new Date(c.tn * 1000);
        const dt_year = dt_obj.getFullYear().toString();
        const dt_month = (dt_obj.getMonth() + 1).toString().padStart(2, "0");
        const dt_date = dt_obj.getDate().toString().padStart(2, "0");

        const tmp_k = parseInt([dt_year, dt_month, dt_date].join(""), 10);
        const tmp_n = [dt_date].join("."); //[dt_date, dt_month].join('.')
        const tmp_o = {
          _k: tmp_k,
          _n: tmp_n,
          ...c,
        };

        if (!a[tmp_k]) {
          a[tmp_k] = [];
        }

        a[tmp_k] = [...a[tmp_k], tmp_o];

        return a;
      }, {});

      // console.log( 'Last30Days: Load: v_obj: ', v_obj )

      // const v_obj_2 =
      for (let i = 1; i <= 30; ++i) {
        const dt_obj = new Date();
        dt_obj.setDate(dt_obj.getDate() - i);
        const dt_year = dt_obj.getFullYear().toString();
        const dt_month = (dt_obj.getMonth() + 1).toString().padStart(2, "0");
        const dt_date = dt_obj.getDate().toString().padStart(2, "0");
        const tmp_k = parseInt([dt_year, dt_month, dt_date].join(""), 10);
        const tmp_n = [dt_date].join("."); //[dt_date, dt_month].join('.')

        if (!v_obj[tmp_k]) {
          v_obj[tmp_k] = [
            {
              _k: tmp_k,
              _n: tmp_n,
            },
          ];
        }
      } // for i

      //
      const v_arr = Object.keys(v_obj).reduce((a, c) => {
        try {
          return [
            ...a,
            { _k: parseInt(c, 10), _n: v_obj[c][0]._n, data: v_obj[c] },
          ];
        } catch (err) {
          return a;
        }
      }, []);

      v_arr.sort((a, b) => a._k - b._k);

      // console.log( 'Last30Days: Load: v_arr: ', v_arr )

      const f_arr = v_arr.map((x) => {
        const tmp_tot = x.data.reduce((a, c) => {
          try {
            const tot_a =
              "string" === typeof c.k.a ? parseInt(c.k.a, 10) : c.k.a;

            if ("ref" === c.s) {
              return a - tot_a;
            } else {
              return a + tot_a;
            }
          } catch (err) {
            return a;
          }
        }, 0.0);

        return {
          _k: x._k,
          _n: x._n,
          tot: tmp_tot,
          cnt: x.data.length,
        };
      });

      // console.log( 'Last30Days: Load: f_arr: ', f_arr )

      Data(f_arr);

      //
      IsBusy(false);

      return {};
    } catch (err) {
      console.warn("Last30Days: Load: err: ", err);

      IsBusy(false);

      return Promise.reject(err);
    }
  }; // Load

  // console.log("props.width", props.width);

  /**
   */
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        width={
          props.width && props.width > 800
            ? 800
            : props.width
            ? props.width - 30
            : 800
        }
        height={300}
        data={data}
      >
        <XAxis dataKey="_n" />
        {/* <YAxis/> */}
        <Tooltip formatter={(value) => [value.toFixed(2), null]} />
        {/* <Legend /> */}
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="tot" stroke="#8884d8" strokeWidth={5} />
        {/* <Line type="monotone" dataKey="pv" stroke="#82ca9d" /> */}
      </LineChart>
    </ResponsiveContainer>
  );
}; // Last30Days

// /**
//  */
// const LoadData = async () =>
// {
//     try
//     {
//         //
//     }
//     catch( err )
//     {
//         console.warn( 'LoadData: err: ', err )

//         return Promise.reject( err )
//     }
// }   // LoadData

/**
 */
const demo_last30days = [
  {
    name: "1",
    uv: 400,
    // pv: '',
    // amp: '',
  },
  {
    name: "2",
    uv: 200,
    // pv: '',
    // amp: '',
  },
  {
    name: "3",
    uv: 100,
    // pv: '',
    // amp: '',
  },
  {
    name: "4",
    uv: 100,
    // pv: '',
    // amp: '',
  },
  {
    name: "5",
    uv: 300,
    // pv: '',
    // amp: '',
  },
  {
    name: "6",
    uv: 100,
    // pv: '',
    // amp: '',
  },
  {
    name: "7",
    uv: 400,
    // pv: '',
    // amp: '',
  },
];
