import React, { useEffect, useRef, useState } from "react";
import AuiModal from "../../aui/modal";
import { useDispatch } from "react-redux";
import { Campaign_List_Project } from "../../rdx/actions";
import { CircularProgress } from "@material-ui/core";
import { Time } from "../../api/Utils";
import { Item } from "./OptionItem";

const OptionCampaign = ({
  type,
  OnSelect,
  currentCampaign,
  currentProject,
}) => {
  const dispatch = useDispatch();
  const isMounted = useRef(true);

  const [selectedCampaign, SelectCampaign] = useState(null);
  const [isShowingCampaignList, ShowCampaignList] = useState(false);
  const [isLoading, SetLoading] = useState(false);
  const [campaignList, UpdateCampaignList] = useState([]);
  const [lastKey, SetLastKey] = useState(null);
  const dtNow = Time.Epoch(Date.now());

  const LoadCampaign = async () => {
    try {
      if (!currentProject?.tn) {
        throw new Error("Select project first.");
      }
      SetLoading(true);
      const { Items, LastEvaluatedKey } = await dispatch(
        Campaign_List_Project(currentProject.tn, lastKey)
      );
      UpdateCampaignList(lastKey ? [...campaignList, ...Items] : Items);
      if (!isMounted.current) return null;

      if (LastEvaluatedKey) {
        SetLastKey(LastEvaluatedKey);
      }
      SetLoading(false);

      return { msg: "ok" };
    } catch (e) {
      console.warn("CampaignList: LoadCampaign: Error:", e);
      return Promise.reject(e);
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (currentProject) {
      SelectCampaign(null);
      LoadCampaign().then().catch();
    }
  }, [currentProject]);

  useEffect(() => {
    if (currentCampaign) {
      SelectCampaign(currentCampaign);
    }
  }, [currentCampaign]);

  const SelectCampaignHandler = (campaign) => {
    OnSelect(campaign);
    SelectCampaign(campaign);
    ShowCampaignList(false);
  };

  return (
    <>
      <button
        className="btn-block btn"
        style={{
          fontSize: 20,
          fontWeight: selectedCampaign ? 900 : null,
          margin: 5,
          padding: 16,
          color: selectedCampaign ? "#0094FF" : "#4f566a",
          backgroundColor: selectedCampaign ? "#EFF8FF" : null,
          borderColor: selectedCampaign ? "#0094FF" : "#009efb",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        onClick={(e) => {
          e.preventDefault();
          if (isLoading) return "Loading";
          if (!currentProject) {
            alert("Select a Project First");
            return "Project not found";
          }
          ShowCampaignList(true);
        }}
      >
        {isLoading ? (
          <>
            Loading...
            <CircularProgress
              color="primary"
              size={10}
              style={{ top: 10, right: 20, position: "absolute" }}
            />
          </>
        ) : (
          <>{selectedCampaign?.ci?.t || "Select Campaign"}</>
        )}
      </button>
      <AuiModal
        bOpen={isShowingCampaignList}
        OnClose={() => ShowCampaignList(false)}
        className="aui-modal"
      >
        <div className="modal-donation">
          <div style={{ overflow: isLoading ? "hidden" : "" }}>
            <div className={"d-flex justify-content-end rounded mx-3 my-4"}>
              <button
                className="btn btn-outline-danger"
                style={{
                  fontWeight: selectedCampaign ? 900 : null,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  SelectCampaignHandler(null);
                }}
              >
                <i className="fa fa-remove" style={{ fontSize: 18 }}></i> Clear
                Selection
              </button>
            </div>

            {isLoading ? (
              <CircularProgress
                color="primary"
                size={200}
                style={{ top: "30%", left: "38%", position: "absolute" }}
              />
            ) : (
              campaignList.map((value) => {
                const isExp = !!(value.te && value.te > 0 && value.te < dtNow);
                const isDisable = !(!value.s || value.s === "act");
                const isSelected = value?.tn === selectedCampaign?.tn;
                if (type === "only-active" && (isExp || isDisable)) return null;

                return (
                  <Item
                    key={value.tn}
                    item={value}
                    isExp={isExp}
                    isDisable={isDisable}
                    isSelected={isSelected}
                    OnSelect={SelectCampaignHandler}
                  />
                );
              })
            )}
            <div>
              {lastKey && (
                <button
                  className="btn btn-primary ml-3"
                  style={{
                    margin: 5,
                    padding: 5,
                  }}
                  onClick={() => LoadCampaign().catch((err) => {})}
                >
                  Show More
                </button>
              )}
            </div>
          </div>
        </div>
      </AuiModal>
    </>
  );
};
export default OptionCampaign;
