/**
 *	@copyright	KARD.
 */
import * as Cfg from '../../_cfg'
import {Lambda, Core} from '../../api/AWS'
/**
 */
export const Dashboard_Get = (  ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'dashboard',
                act: 'dashboard:get',
                c_id: gs().__auth.orgId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
            }

            console.log( 'actions/dashboard: Dashboard_Get: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/dashboard: Dashboard_Get: resp_list: ', resp_list )

            // //
            // // return resp_list
            // const mapped_data = resp_list.Items.map( x => {
            //     try
            //     {
            //         return {
            //             ...x,
            //             a: {...x.a, t: x.a.t / 100.0, r: x.a.r / 100.0 , nr: x.a.nr ? x.a.nr / 100.0 : 0},
            //             pk: x.pk.map( y => {
            //                 return {...y, a: y.a / 100.0}
            //             } ),
            //         }
            //     }
            //     catch( err )
            //     {
            //         return x
            //     }
            // } )

            //
            // return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'actions/dashboard: Dashboard_Get: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Dashboard_Get



