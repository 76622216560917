/**
 * @copyright KARD
 */

import React from 'react'

import {
	Button,
} from '@material-ui/core'

// import { navigate } from "@reach/router"

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import Hdr from '../_common/hdr'
import SideBar from '../_common/sidebar'
import ChatNotif from '../_common/chat_notifications'

/**
 */
class Lock extends React.PureComponent
{
    render()
    {
        return (
            <div className="main-wrapper error-wrapper">
                <div className="error-box">
                    <form action="http://dreamguys.co.in/preclinic/template/index.html">
                        <div className="lock-user">
                            <img className="rounded-circle" src="assets/img/user.jpg" alt="" />
                            <h6>John Doe</h6>
                        </div>
                        <div className="form-group">
                            <input className="form-control" placeholder="Enter Password" type="password" />
                        </div>
                        <div className="text-center">
                            <a href="/auth/login">Sign in as a different user?</a>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}   // Lock

/**
 */
const mapStateToProps = ( state ) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Lock )

