/**
 * @copyright KARD
 */

import React from 'react'

// import {navigate} from "@reach/router";

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

/**
 */
const CampType = ( props ) =>
{
    return (
        <select
            className="form-control"
            value={props.value}
            onChange={ e => props.OnChange ? props.OnChange( e.target.value ) : null }
            required={props.bRequired}
            disabled={props.bDisabled}
        >
            {/* <option value='std'>Standard</option> */}
            {/* <option value=''>Select one</option> */}

            <option value=''>Select one</option>
            <option value='std'>Standard</option>
            <>
            {
                props.__camp.types.map( x => (
                    <option key={x.n} value={x.n}>{x.n}</option>
                ) )
            }
            </>
        </select>
    )
}   // CampType_Drop

/**
 */
const mapStateToProps = ( state ) =>
{
    return {
        __camp: state.__camp,
    }
}   //

/**
 */
export default connect(mapStateToProps, actions)( CampType )


