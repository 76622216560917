/**
 *	@copyright	KARD.
 */

/**
 */
export const Tmp_Cred = ( cred_obj ) =>
{
    return {
            type: 'tmp:cred',
            payload: cred_obj,
        }
}   // Tmp_Cred

