/**
 * @copyright KARD
 */

import React from 'react' 
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import Page from '../_common/page'
import './donate.css'

/**
 */
const DonationPreview = ( props ) =>
{
    return (
        <Page active="donation">
            <div className="row">
                <div className="col-sm-6"> 
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Project</a></li>
                            <li className="breadcrumb-item"><a href="#">Project Preview</a></li>
                        </ol>
                    </nav>
                </div>  
            </div>

            <div className="row mt-2 mb-3">
                <div className="col-sm-12">
                    <h4 className="projectTitle m-0"><i className="fa fa-cog fa-lg" />  Project Name</h4>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-7">
                    <form>                        
                        <div className="form-group">
                            <label for="email_body" className="col-form-label projectTitle">Email Body</label>
                            <textarea class="form-control" id="email_body" rows="4"></textarea>
                        </div>
                        <div className="form-group">
                            <label for="email_body" className="col-form-label">Email Body</label>
                            <textarea class="form-control" id="email_body" rows="4"></textarea>
                        </div>

                        <button type="button" className="btn btn-primary">Send</button>
                    </form>
                </div>

                <div className="col-sm-5">
                    <div className="card bg-primary">
                        <div className="card-body">                            
                        <h3 className="text-white mb-3">Helping COVID affected people of Bangladesh</h3>
                        <p className="text-white">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, 
                        or randomised words which don't look even slightly believable. </p>

                        <div className="bg-white p-2 mb-3 rounded">
                            <div className="row">                                
                                <div className="col-sm-12">
                                    <div className="contact-info">
                                        <span className="contact-name text-ellipsis text-primary">Target Amount</span>
                                        <span className="h3 text-primary">$ 5000.00 </span>
                                    </div>
                                </div>
                                {/* <div className="col-sm-2 justify-content-center" >
                                    <i className="fa fa-dollar fa-lg text-primary" style={{margin: '15px 5px'}} />
                                </div> */}
                            </div>                            
                        </div>

                        <ul className="contact-list project-info">
                            <li>
                            <div className="row">
                                    <div className="mr-2 ml-2">
                                        <i className="fa fa-cog fa-lg text-white" style={{border: '1px solid #fff', borderRadius: '50%',padding: '15px'}} />
                                    </div>
                                    <div className="">
                                        <div className="">
                                            <span className="text-white">Project</span>
                                            <span className="contact-name text-ellipsis text-white">Funding</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="row">
                                    <div className="mr-2 ml-2">
                                        <i className="fa fa-file fa-lg text-white" style={{border: '1px solid #fff', borderRadius: '50%',padding: '15px'}} />
                                    </div>
                                    <div className="">
                                        <div className="">
                                            <span className="text-white">Type</span>
                                            <span className="contact-name text-ellipsis text-white">Standard</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="row">
                                    <div className="mr-2 ml-2">
                                        <i className="fa fa-calendar-check-o fa-lg text-white" style={{border: '1px solid #fff', borderRadius: '50%',padding: '15px'}} />
                                    </div>
                                    <div className="">
                                        <div className="">
                                            <span className="text-white">Duration</span>
                                            <span className="contact-name text-ellipsis text-white">01 Sep 2021 - 01 Jan 2022</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="row">
                                    <div className="mr-2 ml-2">
                                        <i className="fa fa-power-off fa-lg text-white" style={{border: '1px solid #fff', borderRadius: '50%',padding: '15px'}} />
                                    </div>
                                    <div className="">
                                        <div className="">
                                            <span className="text-white">Status</span>
                                            <span className="contact-name text-ellipsis text-white">Active</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    )
}   // DonationPreview

/**
 */
const mapStateToProps = ( state ) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( DonationPreview )







