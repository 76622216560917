/**
 *  @copyright KARD
 */

import React from 'react';
import { positions ,Provider as AlertProvider} from "react-alert";
import AlertTemplate from "react-alert-template-mui";

import Amplify from 'aws-amplify'

// import cfg_core from './_cfg/_core'
import * as cfg from './_cfg'
import auth_cfg from './_cfg/auth'

import './App.css'

// import logo from './logo.svg';
import './App.css';

import AppNav from './components/_router'

import {Provider} from 'react-redux'
import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {persistStore} from 'redux-persist'
import {PersistGate} from 'redux-persist/integration/react'
import reducers from './rdx/reducers'

const rdx_store = createStore( reducers, applyMiddleware(thunk) )
const rdx_persistor = persistStore( rdx_store )


/**
 */
// Amplify.configure( {Auth: amplify_config.clinic} )

// console.log("auth_cfg[cfg_core.service][cfg_core.country][cfg_core.stage]",auth_cfg[cfg_core.service][cfg_core.country][cfg_core.stage])

// const auth_obj = ...

// Amplify.configure( auth_cfg[cfg_core.service][cfg_core.country][cfg_core.stage] )
Amplify.configure( auth_cfg[cfg.stage] )

const options = {
    position: positions.MIDDLE
};

/**
 */
class App extends React.PureComponent
{
    /**
     */
    render()
    {
        return (
            <Provider store={rdx_store} >
                <PersistGate loading={null} persistor={rdx_persistor}>
                    <AlertProvider  template={AlertTemplate} {...options}>
                        <AppNav />
                    </AlertProvider>
                </PersistGate>
            </Provider>
        )
    }   // render
}   // App

/**
 */
export default App


