/**
 * @copyright KARD
 */

import React from "react";

import {navigate} from "@reach/router";

import Loader from "react-js-loader";

import {connect} from "react-redux";
import * as actions from "../../rdx/actions";
import {logo} from "../../_cfg";
import {core} from "../../_cfg/_core";

// import {Core} from '../../api/AWS.js'
// import {Loader} from "../../aui/loader";

// import Country from '../../aui/input/country'
// import cnList from '../../aconst/cnList'
// import {Core} from "../../api/aws";

/**
 */
class Login extends React.PureComponent {
    /**
     */
    constructor(props) {
        super(props);

        const isMagnolia = core.o_id === 'magnolia'

        // console.log( 'auth:Login: cstr: this.props: ', this.props )

        this.state = {
            // isOwner: 'owner' === this.props.__auth.role,
            isSignIn: false,
            orgID: isMagnolia ? '' : core.o_id,
            usrName: "",
            pass: "",
            errorMessage: false,
            // cn: this.props.__auth.cn ? this.props.__auth.cn : {val: 'Bangladesh', cn: 'bd', code: '+880'},
        };

        // console.log("auth:Login: cstr: this.state: ", this.state);
        // console.log("auth:Login: Login: this.props: ", this.props);
    }

    /**
     */
    render() {
        // console.log( 'auth:Login: render: this.props: ', this.props )

        return (
            <div className="main-wrapper account-wrapper">
                <div className="account-page">
                    <div className="account-center">
                        <div className="account-box">
                            <div className="account-logo">
                                <a href="/">
                                    <img src={logo()} alt="logo"/>
                                </a>
                            </div>

                            {/* <button type="submit" value='submit' className="btn btn-primary account-btn">Skip</button> */}

                            <>
                                {this.state.isSignIn ? (
                                    // <Loader/>
                                    <div>
                                        <Loader
                                            type="box-rectangular"
                                            bgColor={"#007bff"}
                                            title={"Authenticating ..."}
                                            color={"#007bff"}
                                            size={150}
                                        />
                                        {/* <div
                                            className="modal"
                                            style={{
                                            // margin: '0 auto'
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            background: "#d1cccc80",
                                            }}
                                        >
                                            <Loader
                                            type="box-rectangular"
                                            bgColor={"#007bff"}
                                            title={"Authenticating ..."}
                                            color={"#007bff"}
                                            size={150}
                                            />
                                        </div> */}
                                    </div>
                                ) : (
                                    <form className="form-signin" onSubmit={this.OnSubmit}>
                                        <>                      {
                                            core.o_id === 'magnolia' &&
                                            <div className="form-group">
                                                <label>Organisation ID</label>
                                                <input
                                                    type="text"
                                                    // autoFocus
                                                    className="form-control"
                                                    value={this.state.orgID}
                                                    onChange={(e) =>
                                                        this.setState({orgID: e.target.value})
                                                    }
                                                    required
                                                />
                                            </div>
                                        }
                                        </>


                                        <>
                                            {
                                                // add logic here later
                                                <div className="form-group">
                                                    <label>Username</label>
                                                    <input
                                                        type="text"
                                                        // autoFocus
                                                        className="form-control"
                                                        value={this.state.usrName}
                                                        onChange={(e) =>
                                                            this.setState({usrName: e.target.value})
                                                        }
                                                        required
                                                    />
                                                </div>
                                            }
                                        </>

                                        <div className="form-group">
                                            <label>Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                value={this.state.pass}
                                                onChange={(e) =>
                                                    this.setState({pass: e.target.value})
                                                }
                                            />
                                        </div>
                                        {this.state.errorMessage && (
                                            <div
                                                style={{
                                                    borderRadius: 8,
                                                    padding: 16,
                                                    backgroundColor: "#fdeded",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        color: "#5f2120",
                                                        fontSize: 14,
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {this.state.errorMessage}
                                                </div>
                                            </div>
                                        )}
                                        <div className="form-group text-right">
                                            <a href="/auth/forgotten">Forgot your password?</a>
                                        </div>
                                        <div className="form-group text-center">
                                            <button
                                                type="submit"
                                                value="submit"
                                                className="btn btn-primary account-btn"
                                            >
                                                Login
                                            </button>
                                        </div>
                                        {/* <div className="text-center register-link">
                                        Login as owner? <a href="/auth/guvnor">Login as Owner</a>
                                    </div> */}
                                        {/* <div className="text-center register-link">
                                        Don’t have an account? <a href="/auth/register">Register as an Owner</a>
                                    </div> */}
                                    </form>
                                )}
                            </>
                        </div>
                    </div>
                </div>
            </div>
        );
    } // render

    /**
     */
    componentDidMount() {
        //
    }

    /**
     */
    OnSubmit = (e) => {
        e.preventDefault();

        // console.log( 'auth:Login: OnSubmit: e: ', e )

        this.Login().catch(() => {
        });
    }; // OnSubmit

    /**
     */
    Login = async () => {
        try {
            this.setState({isSignIn: true});

            this.props.Auth_OrgId(this.state.orgID);

            // await this.props.Auth_Verify()

            // console.log("auth:Login: Login: this.state: ", this.state);

            const usr_obj = await this.props.Auth_Login({
                // username: this.state.usrName.length
                //     ? [this.state.orgID, this.state.usrName].join(":")
                //     : this.state.orgID,
                username: '[[root]]' === this.state.usrName
                    ? this.state.orgID
                    : [this.state.orgID, this.state.usrName].join(":"),
                pass: this.state.pass,
            });

            // console.log("auth:Login: Login: usr_obj: ", usr_obj);

            if (usr_obj.challengeName === "NEW_PASSWORD_REQUIRED") {
                // console.log( 'navigating to /auth/complete' )

                navigate("/auth/complete");
            } else {
                navigate("/dashboard");
            }

            this.setState({isSignIn: false});

            return {};
        } catch (err) {
            console.warn("auth:Login: Login: err: ", err);

            this.setState({isSignIn: false});
            this.setState({errorMessage: err.message});

            return Promise.reject(err);
        }
    }; // Login
} // Login

/**
 */
const mapStateToProps = (state) => {
    return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Login);
