/**
 * @copyright KhukuAmin R&D
 */

import React, {useRef, useState} from 'react'
// import { LinearProgress } from "@material-ui/core";
import Loader from "react-js-loader";
import {navigate} from "@reach/router";
// import { useAlert } from "react-alert";
import {useDispatch, useSelector} from "react-redux";
import {DonationNew, Donor_New, EmailSubscribe, GetCharityInfo} from "../../rdx/actions";

import Page from "../_common/page";
import PaymentMethod from "./payment_method";

/**
 */
const ProcessDonation = ({}) => {
    // const alert = useAlert();

    const {info} = useSelector((state) => state.__donation);
    const pmRef = useRef();
    const dispatch = useDispatch();
    const [int, SetInt] = useState(null);
    const [isLoading, IsLoading] = useState(false);
    const [isProcessing, IsProcessing] = useState(false);
    // const [payment, Payment] = React.useState({ m: "card", s: "not-paid" });
    const orgId = useSelector((state) => state.__auth.orgId);
    const {curProj,curCamp,curPck,curDonType,name,phone,email,addr,isGiftAid,isConsentProj,isConsentCharity} = info

    /**
     */
    React.useEffect(() => {
        if (!info)
        {
            console.log( 'ProcessDonation: navigating to: ', "/donation/int/new" )

            navigate("/donation/int/new")
        }
        else
        {
            IsLoading(true)

            dispatch(GetCharityInfo())
                .then(({ charity }) => {
                    console.log( 'ProcessDonation: after GetCharityInfo: charity: ', charity )

                    IsLoading(false)

                    SetInt(charity?.pym?.int)
                })
                .catch((err) => {
                    IsLoading(false)
                });
            // console.log(cardRef);
        }
    }, []);

    // /**
    //  */
    // React.useEffect(() => {
    //     if (!info)
    //     {
    //         console.log( 'ProcessDonation' )
    //         navigate("/donation/int/new")
    //     }
    // }, []);

    /**
     */
    const ProcessDonation = async ( method_obj ) => {
        try {
            // if (payment.m !== "card") {
            //     throw new Error("Only card donation accept.");
            // }
            console.log("ProcessDonation: ProcessDonation: method_obj: ", method_obj);

            IsProcessing( true )

            const donor_p = {
                p_no: phone.cn + phone.n,
                name: name,
                addr: addr,
                phone: phone,
                email: email,
            };

            const donation_p = {
                p_no: phone.cn + phone.n,
                type: "int",
                pr_i: curProj.i,
                cp_i: curCamp.i,
                pr_n: curProj.tn,
                cp_n: curCamp.tn,
                pck: {
                    tn: curPck.tn,
                    t: curPck.t,
                    a: parseInt(curPck.a, 10),
                    p: curPck.p,
                },
                dont_t: curDonType,
                pr_t: curProj.p.t,
                cp_t: curCamp.ci.t,
                paym: method_obj,//payment,
                name: name,
                addr: addr,
                phone: phone,
                email: email,
                c_ga: isGiftAid,
                c_ch: isConsentProj,
                c_pr: isConsentCharity,
                notes: [],
                s: "pending",
            };
            if (curPck.p === "rec" && curPck.p_i) {
                donation_p.pck["p_i"] = curPck.p_i;
            }

            const a = await Promise.all([
                NewDonor(donor_p),
                NewDonation(donation_p),
                NewSubs(),
            ]);

            console.log("ProcessDonation: ProcessDonation: a: ", a);

            // console.log(a);
            // const { donor } = a[0];
            const { donation } = a[1];

            if (!donation) {
                throw new Error("Failed to create donation");
            }

            // if (payment.m === 'card')
            if (method_obj.m === 'card')
            {
                await pmRef.current.PayWithCard(donation.d)
            }

            IsProcessing( false )

            alert( 'Donation made. Going back to donation form' )
            // alert.show("Have you received this donation ?", {
            //     // title: "Alert!!",
            //     actions: [
            //     {
            //         copy: "Yes",
            //         // onClick: () => props.CovertToReceived(props.dont),
            //         onClick: () => navigate( '/donation/int/new' )
            //     },
            //     ],
            //     // closeCopy: "No",
            // });

            // go back to donation page
            navigate( '/donation/int/new' )

            return {}
        }
        catch (err)
        {
            console.warn("ProcessDonation: ProcessDonation: err: ", err);

            IsProcessing( false )

            alert( 'Donation failed. Error: ' + err.message )

            // return Promise.reject(err);
            return { err }
        }
    }; // ProcessDonation

    /**
     */
    const NewDonation = async (donationParam) => {
        try {
            const resp_dont = await dispatch(DonationNew(donationParam));

            console.log(
                "Donations_Internal_New: NewDonation: resp_dont: ",
                resp_dont
            );

            if (!resp_dont.Item) {
                throw new Error("Filed to create donation.\nPlease try again.");
            }

            return { donation: resp_dont.Item };
        } catch (err) {
            console.warn("Donations_Internal_New: NewDonation: err: ", err);
            return Promise.reject(err);
        }
    }; // NewDonation

    /**
     */
    const NewDonor = async (donor) => {
        try {
            const resp_dont = await dispatch(Donor_New(donor));
            return { donor: resp_dont };
        } catch (err) {
            console.warn("donation/index: NewDonor: err: ", err);
            return Promise.reject(err);
        }
    }; // NewDonor
    /**
     */
    const NewSubs = async () => {
        try {
            // const p_no = [phone.cn, phone.n].join("");

            // const topc_proj = [orgId, curProj.tn].join("/");
            // const topc_org = [orgId].join("/");
            const topic_camp = [orgId, curProj.tn, curCamp.tn].join("/");

            // console.log( 'Donations_Internal_New: NewSubs: topc_proj: ', topc_proj )

            const arr_pr = [
                // isConsentProj && phone.n.length ? NewSubs_SMS( p_no, topc_proj ) : null,
                isConsentCharity && email.length
                    ? NewSubs_Email(email, topic_camp)
                    : null,
                // isConsentCharity && phone.n.length ? NewSubs_SMS( p_no, topc_org ) : null,
            ];

            const resp_pr = await Promise.all(arr_pr);

            // console.log( 'Donations_Internal_New: NewSubs: resp_pr: ', resp_pr )

            if (resp_pr) {
                //
            }

            return {};
        } catch (err) {
            console.warn("Donations_Internal_New: NewSubs: err: ", err);

            return { err };
        }
    }; // NewSubs

    /**
     */
    const NewSubs_Email = async (email, topic) => {
        try {
            const param = {
                email,
                topic,
                pr_t: curProj.p.t,
                cp_t: curCamp.ci.t,
            };
            return await dispatch(EmailSubscribe(param));
        } catch (err) {
            console.warn("Donations_Internal_New: NewSubs_Email: err: ", err);

            return { err };
        }
    }; // NewSubs_Email

    return(
        <Page active="donation">
            <>
            {
                (isLoading || isProcessing) &&
                <div>
                    <div
                        className="modal"
                        style={{
                            // margin: '0 auto'
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "#d1cccc80",
                        }}
                    >
                    <Loader
                        // type="box-rectangular"
                        type="spinner-cub"
                        bgColor={"#2680c2"}
                        title={"Processing ..."}
                        color={"#2680c2"}
                        size={150}
                    />
                    </div>
                </div>
            }
            </>
            <PaymentMethod
                Proj={info.curProj}
                Camp={info.curCamp}
                Pack={info.curPck}
                Cus={{name: info.name,phone:info.phone,addr:info.addr,email:info.email}}
                ref={pmRef}
                Int={int}
                OnPmChange={ ProcessDonation }
            />
        </Page>
    )
}
export default ProcessDonation
