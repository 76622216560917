/**
 *	@copyright	KARD.
 */

import * as Cfg from '../../_cfg'
import {Lambda, Core} from '../../api/AWS'

/**
 */
export const Settings_Get = () =>
{
    return async (d, gs) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_get = {
                actType: 'sett',
                act: 'sett:get',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    // last_key
                },
            }

            console.log( 'actions/project: Project_List: p_get: ', p_get )

            const resp_get = await aws_lambda.Invoke( p_get, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/project: Project_List: resp_get: ', resp_get )

            // d( _Settings_Obj( resp_get.Item ) )

            if( resp_get.Item && resp_get.Item.sett )
            {
                d( _Settings_Domain( resp_get.Item.sett.d ) )
            }   // resp_get.Item.sett

            //
            return resp_get
        }
        catch( err )
        {
            console.warn( 'rdx.actions: Settings_Get: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Settings_Get

/**
 */
export const Settings_Save = ( sett_obj ) =>
{
    return async (d, gs) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_save = {
                actType: 'sett',
                act: 'sett:save',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    ...sett_obj,
                },
            }

            console.log( 'actions/project: Project_List: p_save: ', p_save )

            const resp_save = await aws_lambda.Invoke( p_save, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/project: Project_List: resp_save: ', resp_save )

            //
            return resp_save
        }
        catch( err )
        {
            console.warn( 'rdx.actions: Settings_Save: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Settings_Save
/**
 */
export const Settings_Update = ( sett_obj ) =>
{
    return async (d, gs) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_save = {
                actType: 'sett',
                act: 'sett:upd',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    ...sett_obj,
                },
            }

            console.log( 'actions/settings: Settings_Update: p_save: ', p_save )

            const resp_save = await aws_lambda.Invoke( p_save, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/settings: Settings_Update: resp_save: ', resp_save )

            //
            return resp_save
        }
        catch( err )
        {
            console.warn( 'rdx.actions: Settings_Update: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Settings_Save

/**
 */
export const Settings_Stage = ( store_obj ) =>
{
    return (d, gs) =>
    {
        try
        {
            d( _Settings_Stage( store_obj ) )

            return {}
        }
        catch( err )
        {
            console.warn( 'rdx.actions: Settings_Stage: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Settings_Stage

/**
 */
// const _Settings_Obj = (sett_obj) =>
// {
//     return {
//         type: 'settings:obj',
//         payload: sett_obj,
//     }
// }   // _Settings_Obj

/**
 */
const _Settings_Domain = (domain) =>
{
    return {
        type: 'settings:domain',
        payload: domain,
    }
}   // _Settings_Domain

/**
 */
const _Settings_Stage = (stage) =>
{
    return {
        type: 'settings:stage',
        payload: stage,
    }
}   // _Settings_Stage

