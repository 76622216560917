/**
 * @copyright KARD.
 */

import React from 'react'

import {
	// Button,
} from '@material-ui/core'

// import { navigate } from "@reach/router"

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import Hdr from './hdr'
import SideBar from './sidebar'
import ChatNotif from './chat_notifications'

/**
 */
const Page = ( props ) =>
{
    if( props.bModalView )
    {
        return (
            <>
            {
                props.children
            }
            </>
        )
    }
    else
    {
        return (
            <div className="main-wrapper">
                <>
                {
                    props.bHideHdr
                ?   null

                :   <Hdr />
                }
                </>

                <>
                {
                    props.bHideSide
                ?   null

                :   <SideBar active={props.active} />
                }
                </>

                <div className="page-wrapper">
                    <div className="content">
                    {
                        props.children
                    }
                    </div>

                    <>
                    {
                        props.bHideNotif
                    ?   null

                    :   <ChatNotif />
                    }
                    </>
                </div>
            </div>
        )
    }
}   // Page

/**
 */
export default Page

/**
 */
// const mapStateToProps = ( state ) =>
// {
//     return state
// }   //

/**
 */
// export default connect(mapStateToProps, actions)( Page )






