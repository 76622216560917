import React from 'react'
import {Core, Lambda} from "../../api/AWS";
import * as Cfg from "../../_cfg";

/**
 * @param orgId
 * @returns {Promise<{totalCampaign: number, Items: []}>}
 */
export async function getTotalCampaign(orgId) {
    try {
        const aws_lambda = new Lambda( {} )

        const aws_core = new Core( {} )
        const cur_usr = await aws_core.currentuser()

        const p_list = {
            actType: 'camp',
            act: 'cam:get-campaign-list',
            c_id: orgId,
            usr: {
                ...cur_usr.attributes,
                username: cur_usr.username,
            },
            data: {},
        }

        let hasLastKey = false
        let totalCampaign = 0
        let itemList = []

        do {
            // console.log( 'home/dashboard: getTotalCampaign: p_list: ', p_list )
            const {Items,Count,LastEvaluatedKey} = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )
            // console.log( 'home/dashboard: getTotalCampaign: resp_list: ', Items,Count,LastEvaluatedKey )

            totalCampaign +=Count;
            itemList.push(...Items)
            hasLastKey = !!LastEvaluatedKey
            p_list.data['last_key'] = LastEvaluatedKey
        }while (hasLastKey)
        return {totalCampaign, Items:itemList}
    }catch (e) {
        console.warn("Error:getTotalCampaign",e)
        return Promise.reject(e)
    }
}//getTotalCampaign

/**
 * @param orgId
 * @returns {Promise<{totalDonor: number, Items: []}>}
 */
export async function getTotalDonor(orgId) {
    try {
        const aws_lambda = new Lambda( {} )

        const aws_core = new Core( {} )
        const cur_usr = await aws_core.currentuser()

        const p_list = {
            actType: 'donr',
            act: 'donr:get-list',
            c_id: orgId,
            usr: {
                ...cur_usr.attributes,
                username: cur_usr.username,
            },
            data: {},
        }

        let hasLastKey = false
        let totalDonor = 0
        let itemList = []

        do {
            console.log( 'home/dashboard: getTotalDonor: p_list: ', p_list )
            const {Items,Count,LastEvaluatedKey} = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )
            console.log( 'home/dashboard: getTotalDonor: resp_list: ', Items,Count,LastEvaluatedKey )

            totalDonor +=Count;
            itemList.push(...Items)
            hasLastKey = !!LastEvaluatedKey
            p_list.data['last_key'] = LastEvaluatedKey
        }while (hasLastKey)

        return {totalDonor, Items:itemList}
    }catch (e) {
        console.warn("Error:getTotalDonor",e)
        return Promise.reject(e)
    }
}//getTotalDonor

/**
 * @param orgId
 * @returns {Promise<{totalDonation: number, Items: []}>}
 */
export async function getTotalDonation(orgId) {
    try {
        const aws_lambda = new Lambda( {} )

        const aws_core = new Core( {} )
        const cur_usr = await aws_core.currentuser()

        const p_list = {
            actType: 'dont',
            act: 'dont:get-donation-list',
            c_id: orgId,
            usr: {
                ...cur_usr.attributes,
                username: cur_usr.username,
            },
            data: {},
        }

        let hasLastKey = false
        let totalDonation = 0
        let itemList = []

        do {
            // console.log( 'home/dashboard: getTotalDonation: p_list: ', p_list )
            const {Items,Count,LastEvaluatedKey} = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )
            // console.log( 'home/dashboard: getTotalDonation: resp_list: ', Items,Count,LastEvaluatedKey )

            totalDonation +=Count;
            itemList.push(...Items)
            hasLastKey = !!LastEvaluatedKey
            p_list.data['last_key'] = LastEvaluatedKey
        }while (hasLastKey)
        return {totalDonation, Items:itemList}
    }catch (e) {
        console.warn("Error:getTotalDonation",e)
        return Promise.reject(e)
    }
}//getTotalCampaign

export function getTotalDonationAmount(items,p) {
    if (!items.length || items.length <1 )
        return {totalAmount:'0.00'}

    let totalAmount = 0.0

    items.map(d=>{
        const a = d?.k?.a || 0
        totalAmount += a
    })
    if (p)
    {
        return {totalAmount: (totalAmount/100).toFixed(2)}
    }
    return {totalAmount: totalAmount.toFixed(2)}

}
