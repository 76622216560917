/**
 *	@copyright	KARD.
 */

import * as Cfg from '../../_cfg'
import {Lambda, Core} from '../../api/AWS'

// import {Cfg_Lambda} from './cfg'

/**
 */
export const Staffs_List = ( b_more ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'staffs',
                act: 'staffs:list',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data: {
                    last_key: b_more ? gs().__staffs.lastKey : null,
                },
            }

            console.log( 'actions/staffs: Staffs_List: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/staffs: Staffs_List: resp_list: ', resp_list )

            if( resp_list )
            {
                //
            }

            d( _Staffs_List(
                b_more ? [...gs().__staffs.list, ...resp_list.Items] : [...resp_list.Items],
                resp_list.LastEvaluatedKey ? resp_list.LastEvaluatedKey : null,
            ) )

            return resp_list
        }
        catch( err )
        {
            console.warn( 'actions/staffs: Staffs_List: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Staffs_List

/**
 */
export const Staffs_Add = ( staff_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_add = {
                actType: 'staffs',
                act: 'staffs:new',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data: {
                    u_id: staff_obj.u_id,
                    password: staff_obj.password,

                    name: staff_obj.name,
                    email: staff_obj.email,
                    phone: staff_obj.phone,
                    roles: staff_obj.roles,
                },
            }

            console.log( 'actions/staffs: Staffs_Add: p_add: ', p_add )

            const resp_add = await aws_lambda.Invoke( p_add, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/staffs: Staffs_Add: resp_add: ', resp_add )

            if( resp_add )
            {
                //
            }

            //
            return resp_add
        }
        catch( err )
        {
            console.warn( 'actions/staffs: Staffs_Add: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Staffs_Add

/**
 */
export const Staffs_Upd = ( staff_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_add = {
                actType: 'staffs',
                act: 'staffs:upd',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data: {
                    username: staff_obj.username,
                    u_nm: staff_obj.name,
                    email: staff_obj.email,
                    phone: staff_obj.phone,
                    role: staff_obj.roles,
                },
            }

            // console.log( 'actions/staffs: Staffs_Upd: p_add: ', p_add )

            const resp_add = await aws_lambda.Invoke( p_add, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/staffs: Staffs_Upd: resp_add: ', resp_add )

            if( resp_add )
            {
                //
            }

            //
            return resp_add
        }
        catch( err )
        {
            console.warn( 'actions/staffs: Staffs_Upd: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Staffs_Upd

/**
 */
const _Staffs_List = (list, last_key) =>
{
    return {
        type: 'staffs:list',
        payload: {list, last_key},
    }
}




