/**
 * @copyright KARD
 */

import React, { useEffect, useState } from "react";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from "../../rdx/actions";
import { Time } from "../../api/Utils";

import Page from "../_common/page";
import AuiModal from "../../aui/modal";
import ListProj from "../donation/list_proj";
import { ListCamp } from "../donation/list_camp";
import { Donation_Query } from "../../rdx/actions";
import MonthlyDonation from "./monthly_donation";
import OptionProject from "../_common/OptionProject";
import OptionCampaign from "../_common/OptionCampaign";

const useWindowDimensions = () => {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
};
/**
 */
const Home = (props) => {
  // const parentRef = React.useRef(null);
  const orgId = useSelector((state) => state.__auth.orgId);
  const Rdx = useDispatch();
  const [curProj, CurProj] = React.useState(null);
  const [curCamp, CurCamp] = React.useState(null);
  const [isModal_Proj, IsModal_Proj] = React.useState(false);
  const [isModal_Camp, IsModal_Camp] = React.useState(false);
  const [campaignDataLoading, CampaignDataLoading] = React.useState(false);
  const [campaignData, SetCampaignData] = React.useState([]);
  const [total, SetTotal] = useState({
    campaign: 0,
    donation: "0.00",
    donor: 0,
    last30DaysDonation: "0.00",
  });

  const { height, width } = useWindowDimensions();
  const [isLoading, SetLoading] = React.useState(false);

  useEffect(() => {
    SetLoading(true);
  }, []);

  const LoadData = async (params) => {
    try {
      let hasMore = false;
      let dataList = [];
      do {
        const data = await Rdx(Donation_Query(params));
        // console.log(data);
        hasMore = !!data.LastEvaluatedKey;
        params["last_key"] = data?.LastEvaluatedKey;
        dataList.push(...data.Items);
      } while (hasMore);

      return dataList;
    } catch (e) {
      console.warn("Filed to load specific donation data!", e);
      return Promise.reject(e);
    }
  };

  const LoadCampaignData = async () => {
    try {
      const params = {
        last_key: null,
        dt_st: 0,
        dt_end: 0,
        nm_f: null,
        nm_l: null,
        pr_n: curProj?.tn,
        cp_n: curCamp?.tn,
        paym_m: null,
        paym_s: null,
        a_st: 0,
        a_end: 0,
      };
      return await LoadData(params);
    } catch (e) {
      console.warn("Filed to load specific campaign data!", e);
      return Promise.reject(e);
    }
  };

  useEffect(() => {
    if (curCamp && curProj) {
      CampaignDataLoading(true);
      LoadCampaignData()
        .then((data) => {
          // console.log(data)
          SetCampaignData(data);
          CampaignDataLoading(false);
        })
        .catch((_) => CampaignDataLoading(false));
    }
  }, [curCamp]);

  const ProjectSelectHandler = (project) => {
    CurProj(project);
    CurCamp(null);
  }

  const CampaignSelectHandler = (campaign) => {
    CurCamp(campaign);
  }

  return (
    <Page active="analytics">
      <div className="row mt-2 mb-3">
        <div className="col">
          <h4 className="projectTitle m-0">
            <img
              style={{ height: 20, width: 20, marginRight: 8 }}
              src={require("../../components/assets/icons/sideBar/active/analyticsActive.png")}
              alt=''
            />
            Analytics
          </h4>
        </div>
      </div>

      <div active="dashboard">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h2 className="projectTitle">Campaign Specific Data.</h2>
            <form className="mb-3">
              <div className="row">
                <div className="col">
                  <OptionProject type={'all'} OnSelect={ProjectSelectHandler} currentProject={curProj}/>
                </div>
                <div className="col">
                  <OptionCampaign type={'only-active'} OnSelect={CampaignSelectHandler} currentProject={curProj} currentCampaign={curCamp}/>
                </div>
              </div>
            </form>
            <SpecificCampaignData data={campaignData} camp={curCamp} />
            {/*<Last30Days*/}
            {/*    width={width}*/}
            {/*    Donation_Query={props.Donation_Query}*/}
            {/*    //   donationLast30Days={donationLast30Days}*/}
            {/*/>*/}
          </div>
        </div>
        <div className="row">
          <MonthlyDonation />
        </div>
      </div>
    </Page>
  );
}; // Home

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Home);

const SpecificCampaignData = ({ data, camp }) => {
  const [chartData, SetChartData] = React.useState([]);
  const [monthList, SetMonthList] = React.useState({});

  const getMonthList = (dts, dte) => {
    let st = new Date(Time.EpochMS(dts));
    let et = new Date(Time.EpochMS(dte));
    let monthList = {};
    while (st <= et) {
      const month = st.getMonth();
      const year = st.getFullYear();
      const monthName = Time.monthsShort[Time.Print2D(month + 1)];
      const data = {
        name: [monthName, year.toString().slice(2)].join(". "),
        amount: 0,
      };
      monthList[monthName] = data;
      st = new Date(st.setMonth(st.getMonth() + 1));
    }
    return monthList;
  };

  useEffect(() => {
    if (camp) {
      const st = camp?.ts > 0 ? camp.ts : camp.tn;
      const et = camp?.te > 0 ? camp.te : parseInt(Date.now() / 1000, 10);
      const data = getMonthList(st, et);
      SetMonthList(data);
    }
  }, [camp]);

  useEffect(() => {
    if (monthList && data) {
      // let currMonth = '';
      // let monthTotal = 0;
      console.log(chartData);

      const prepData = data.reduce((a, c) => {
        const month = new Date(Time.EpochMS(c.tn)).getMonth();
        const currMonthName = Time.monthsShort[Time.Print2D(month + 1)];
        console.log(currMonthName);
        a[currMonthName] = {
          ...a[currMonthName],
          amount: a[currMonthName].amount + c?.k?.a || 0,
        };
        return a;
      }, monthList);
      console.log(prepData);
      SetChartData(Object.values(prepData));
    }
  }, [monthList, data]);
  return (
    <ResponsiveContainer height={400}>
      <BarChart
        style={{ marginLeft: "-36px" }}
        // width={500}
        height={300}
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        {/*<Tooltip />*/}
        <Bar dataKey="amount" fill="#009efb" barSize={56} />
      </BarChart>
    </ResponsiveContainer>
  );
};
