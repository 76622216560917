/**
 * @copyright KARD
 */

import React, { useEffect, useReducer, useState } from "react";

import { navigate } from "@reach/router";

import { connect, useDispatch, useSelector } from "react-redux";
// import * as actions from '../../rdx/actions'

import { Time } from "../../api/Utils";

import AuiModal from "../../aui/modal";
// import AuiStatus from '../../aui/status'

import Page from "../_common/page";
// import PayPattern from '../_common/pay_pat'
import ListProj from "../donation/list_proj";
import { ListCamp } from "../donation/list_camp";

// import CampType_V from './type_v'
import {
  News_Details,
  News_Edit,
  News_Get,
  News_Add,
  News_SaveImage,
  Project_Details,
  Campaign_Details,
  uploadFile,
} from "../../rdx/actions";

import DescComp from "../_common/desc_comp";
import { ImageInput } from "../_common/img_input";

const ctInit = {
  listDesc: [],
};

const INIT_STATE = {
  title: "",
  snippet: "",
  selectedProject: {},
  selectedCampaign: {},
  i: "",
  ni: "",
  url: null,
  url_dt: null,
  status: "publ", // draft / published
  featured: "norm", // norm / feat
  profileImg: {
    //
  },
  ld: [],
  projectList: [],
  descList: [],
  isBusy: false,
  showProjectList: false,
  showCampaignList: false,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "project-list":
      return { ...state, projectList: action.payload };

    case "article":
      const { payload } = action;
      return {
        ...state,
        title: payload.t,
        i: payload.i,
        ni: payload.ni,
        snippet: payload.sn,
        url_dt: null,
        url: payload.url,
        featured: payload.f, // norm / feat
        profileImg: {
          url: payload.url,
        },
        status: payload.st,
        ld: payload?.ld || ctInit,
        selectedProject: payload.p ? { tn: payload.p, p: payload.pi } : null,
        selectedCampaign: payload.c ? { tn: payload.c, ci: payload.ci } : null,
      };
    case "busy":
      return { ...state, isBusy: action.payload };
    case "select-project":
      return { ...state, selectedProject: action.payload };
    case "select-campaign":
      return { ...state, selectedCampaign: action.payload };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 */
const NewsView = (props) => {
  // const orgId = useSelector((state) => state.__auth.orgId);
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const [newsList, NewsList] = React.useState([]);
  const rdx = useDispatch();

  async function Init(n_id) {
    try {
      console.log("INIT", props);
      dispatch({ type: "busy", payload: true });
      const { Item } = await rdx(News_Get(n_id));
      if (Item) {
        const project = {
          tn: Item.p,
          p: Item.pi,
        };
        const campaign = {
          tn: Item.c,
          p: Item.ci,
        };
        dispatch({ type: "select-project", payload: project });
        dispatch({ type: "select-campaign", payload: campaign });
        dispatch({ type: "article", payload: Item });
      }

      dispatch({ type: "busy", payload: true });
    } catch (e) {
      console.warn(e);
      dispatch({ type: "busy", payload: false });
      return e;
    }
  }

  /**
   */
  React.useEffect(() => {
    if (props.n_id) {
      Init(props.n_id).then().catch();
    }
  }, [props.n_id]);

  return (
    <Page active="news">
      {/* <div className="row">
        <div className="col-sm-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">News</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Helping COVID affected people</a>
              </li>
            </ol>
          </nav>
        </div>
      </div> */}

      <div className="row">
        <div className="col mb-3">
          <h4
            className="projectTitle m-0"
            style={{ textTransform: "capitalize" }}
          >
            <img
              style={{ height: 20, width: 20, marginRight: 16 }}
              src={require("../../components/assets/icons/sideBar/active/news.png")}
            />
            {state.title}
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card border-0">
            {/* <h3 className="mb-3">{state.title}</h3> */}

            {state?.ld.map((val) => {
              if (val.t === "txt") {
                return (
                  <p className="my-3" style={{ fontSize: 16 }}>
                    {val.d}
                  </p>
                );
              } else {
                return (
                  <img
                    alt="news-image"
                    // style={{ width: 500 }}
                    className="img img-responsive mx-auto"
                    src={val.d.url}
                  />
                );
              }
            })}
            <a
              href=""
              className="btn btn-primary btn-sm mr-2"
              style={{ width: 200 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate(`/news/edit/${props.n_id}`);
              }}
            >
              Edit
            </a>
          </div>
        </div>

        {/* <div className="col-sm-3">
          <div className="card bg-primary">
            <div className="card-body">
              <h3 className="text-white mb-3">Recent News</h3>

              <ul className="contact-list project-info">
                <li className="p-0 mb-2">
                  <div className="text-white">
                    <i className="fa fa-file fa-lg pr-2" /> Pendemic Cautions
                  </div>
                </li>
                <li className="p-0 mb-2">
                  <div className="text-white">
                    <i className="fa fa-file fa-lg pr-2" /> Vacccine for COVID
                    new varient
                  </div>
                </li>
                <li className="p-0 mb-2">
                  <div className="text-white">
                    <i className="fa fa-file fa-lg pr-2" /> Pendemic Cautions
                  </div>
                </li>
                <li className="p-0 mb-2">
                  <div className="text-white">
                    <i className="fa fa-file fa-lg pr-2" /> Pendemic Cautions
                  </div>
                </li>
                <li className="p-0 mb-2">
                  <div className="text-white">
                    <i className="fa fa-file fa-lg pr-2" /> Vacccine for COVID
                    new varient
                  </div>
                </li>
                <li className="p-0 mb-2">
                  <div className="text-white">
                    <i className="fa fa-file fa-lg pr-2" /> Pendemic Cautions
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </Page>
  );
}; // NewsView

/**
 */
export default NewsView;
