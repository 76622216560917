/**
 * @copyright KARD
 */

import React from 'react'

/**
 */
const Select = ( props ) =>
{
    // const v_list = props.hdr ? [{val: props.hdr}, ...props.list] : props.list

    // console.log("props", props)

    const OnChange = ( e ) =>
    {
        if( !props.OnChange )
            return {}
        //
        // if( props.hdr.val === e.target.value )
        //     return {}

        props.OnChange( e.target.value )

    }   // OnChange

    return (
        <div className={props.cCont ? props.cCont : "form-group"}>
            <>
            {
                props.labelFn
            ?   props.labelFn()

            :   <label>
                {
                    props.label
                ?   props.label

                :   'Select'
                }
                </label>
            }
            </>

            <select
                className={props.cSel ? props.cSel : "form-control select"}
                value={props.val}
                onChange={ OnChange }
                required={props.isReq}
            >
            {
                props.list.map( x => (
                    <option key={x.val}
                            value={x.val}
                    >
                        {x.val}
                    </option>
                ) )
            }
            </select>
        </div>
    )
}   // Select

/**
 */
export default Select



