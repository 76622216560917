/**
 * @copyright    KARD.
 */

import AWS from 'aws-sdk';

import {Auth} from 'aws-amplify';

import {AStorage} from './Utils';

AWS.config.update({dynamoDbCrc32: false});

/**
 */
class Core
{
    /**
     *    {
     *  }
     */
    constructor(p)
    {
        if( !p ) p = {};
        // this.cfg = cfg_core

        // this._region = Region(p.cn?p.cn:this.cfg.country);
        // this._cn_def = p.cn ? p.cn : elml_config.cn_code;
        // this._cn_def = p.cn
        // this._cn = this._cn_def;

        this._cred = p.credentials ? p.credentials : null;
    }

    /**
     */
    Configure()
    {
        //
    }

    /**
     */
    async PassChange( usr, old_pw, new_pw )
    {
        return await Auth.changePassword( usr, old_pw, new_pw,
                // attributes: {
                //     email: p.email,
                //     phone_number: p.phone_number
                // }
            )
    }

    /**
     */
    async PassComplete( usr, new_pw )
    {
        return await Auth.completeNewPassword( usr, new_pw )
    }

    /**
     */
    async SignUp(p)
    {
        try
        {
            return await Auth.signUp({
                    username: p.username
                ,   password: p.password
                ,   attributes: {
                        email: p.email,
                        phone_number: p.phone_number
                    }
                });
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     */
    async SignUp_Confirm(p)
    {
        try
        {
            return await Auth.confirmSignUp(p.username, p.auth_code);
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     */
    async SignUp_Resend( p )
    {
        try
        {
            return await Auth.resendSignUp(p.username);
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     *    {
     *  ,   username: ''        // mandatory
     *  ,   password: ''        // mandatory
     *  }
     */
    async SignIn(p)
    {
        try
        {
            // console.log( 'AWS.Core: SignIn: p: ', p )

            const user = await Auth.signIn(p.username, p.password);

            //
            return user;
        }
        catch (err)
        {
            return Promise.reject( err )
        }
    }

    async SignOut()
    {
        try
        {
            return await Auth.signOut() ;
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    // /**
    //  */
    // _UsrName(p) {
    //     return 'usr_' + this.cfg.cnCode + p.phone.replace(/\s+/g, '').replace(/^(0{1,})/, '');
    // }

    /**
     *    {
     *      b_retrieve: false           // optional
     *  }
     */
    async Credentials()
    {
        try
        {
            const cur_cred = await Auth.currentCredentials()

            // console.log( 'Credentials: cur_cred: ', cur_cred )

            this._cred = await Auth.essentialCredentials( cur_cred );

            // console.log( 'Credentials: this._cred: ', this._cred )

            return this._cred;
        }
        catch( err )
        {
            console.warn( 'Credentials: err: ', err )

            return Promise.reject( err );
        }
    }

    /**
     */
    async Credentials_Current()
    {
        try
        {
            this._cred = await Auth.currentCredentials();

            return this._cred;
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     */
    async currentuser()
    {
        try
        {
            return await Auth.currentAuthenticatedUser();
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     */
    async Id()
    {
        try
        {
            this._cred = await Auth.essentialCredentials( await Auth.currentCredentials() );

            return this._cred ? this._cred.identityId : '';
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     */
    async Name()
    {
        try
        {
            const user = await Auth.currentAuthenticatedUser();
            const attr = user.attributes;

            return {t: attr['custom:title'], f: attr.given_name, l: attr.family_name};
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     */
    async Phone()
    {
        try
        {
            const user = await Auth.currentAuthenticatedUser();
            const attr = user.attributes;

            return {number: attr.phone_number, bVerified: attr.phone_number_verified};
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     */
    async Email()
    {
        try
        {
            const user = await Auth.currentAuthenticatedUser();
            const attr = user.attributes;

            return {addr: attr.email, bVerified: attr.email_verified};
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    async SetName( name_obj )
    {
        try
        {
            let user = await Auth.currentAuthenticatedUser();

            await Auth.updateUserAttributes( user, {'custom:title': name_obj.title, given_name: name_obj.first, family_name: name_obj.last} );

            return {msg: 'OK'};
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    async EmailVerification()
    {
        try
        {
            return await Auth.verifyCurrentUserAttribute( 'email' )
        }
        catch( err )
        {
            return Promise.reject( err )
        }
    }

    async EmailVerificationSubmit( veri_code )
    {
        try
        {
            return await Auth.verifyCurrentUserAttributeSubmit( 'email', veri_code )
        }
        catch( err )
        {
            return Promise.reject( err )
        }
    }
    async SetEmail( email_addr )
    {
        try
        {

            let user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes( user, {'email': email_addr} );
            return {msg: 'OK'};
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }


    /**
     */
    async StorageGet(key)
    {
        try
        {
            // console.log(await this.Id());
            return await AStorage.Get(await this.Id() + ':' + key);
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     */
    async StorageSet(key, val)
    {
        try
        {
            // console.log(this.Id());
            return await AStorage.Set(await this.Id() + ':' + key, val);
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }
    /**
     */
    async StorageClear(key)
    {
        try
        {
            // console.log(this.Id());
            return await AStorage.RemoveItem(await this.Id() + ':' +key);
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     * @param p
     * @returns {Promise.<*>}
     */
    async Request_Forgot_Password(p)
    {
        // console.log("asd",p);
        return await Auth.forgotPassword('usr_'+p.phone_no);
    }

    /**
     * @param p
     * @param code
     * @param password
     * @returns {Promise.<*>}
     */
    async Submit_Forgot_Password(p){
        return await Auth.forgotPasswordSubmit(p.username, p.auth_code, p.password);
    }

    //
}   // class AWS

/**
 */
// class DDB extends Core   {
class DDB
{
    constructor(p)
    {
        this._core = p.core ? p.core : new Core( {} );
        // this._region = "eu-west-2" ;
        // this._endPoint = 'https://dynamodb.' + this._region + '.amazonaws.com';
        this._bInit = false;
    }

    async Init(p)
    {
        if (this._bInit && !p.b_init)
            return {msg: 'OK'};

        // this._endPoint = 'https://dynamodb.' + this._region + '.amazonaws.com';

        return {msg: 'OK'};
    }

    Client = () => {
        return new Promise( async ( sol, rej ) => {
            try{
                // const aws_cred = await this._core.Credentials()
                const aws_cred = await this._core.Credentials_Current()

                // console.log( 'aws_cred: ', aws_cred )

                const client = new AWS.DynamoDB.DocumentClient({
                    region: aws_cred._clientConfig.region,//this._region,
                    // endpoint: this._endPoint,
                    credentials: aws_cred
                });

                return sol( client );
            }
            catch( err ){
                return rej(err);
            }
        } );
    }

    Set = (p) => {
        return new Promise( async ( sol, rej ) => {
            try{
                const client = await this.Client();

                client.put(p, (err, data) => {
                    if (err)
                        return rej(err);

                    return sol( data );
                });
            }
            catch( err ){
                return rej(err);
            }
        } );
    }

    Get = (p) => {
        return new Promise( async ( sol, rej ) => {
            try{
                const client = await this.Client();

                client.get(p, (err, data) => {
                    if (err)
                        return rej(err);

                    return sol( data );
                });
            }
            catch( err ){
                return rej(err);
            }
        } );
    }

    /**
     *  \todo Need to add number of attempts.
     *
     *  var params = {tables: [ {name: '', keys: [{}]}, proj_exp: '', attr_names: {}, attr_vals: {} ], ret_con_cap: ''}
     */
    BatchGet = (p) => {
        return new Promise( async ( sol, rej ) => {
            try{
                const client = await this.Client();

                /** Request Syntax
                    {
                       "RequestItems": {
                          "string" : {
                             "AttributesToGet": [ "string" ],
                             "ConsistentRead": boolean,
                             "ExpressionAttributeNames": {
                                "string" : "string"
                             },
                             "Keys": [
                                {
                                   "string" : {
                                      "B": blob,
                                      "BOOL": boolean,
                                      "BS": [ blob ],
                                      "L": [
                                         "AttributeValue"
                                      ],
                                      "M": {
                                         "string" : "AttributeValue"
                                      },
                                      "N": "string",
                                      "NS": [ "string" ],
                                      "NULL": boolean,
                                      "S": "string",
                                      "SS": [ "string" ]
                                   }
                                }
                             ],
                             "ProjectionExpression": "string"
                          }
                       },
                       "ReturnConsumedCapacity": "string"
                    }
                 */
                let p_get = { RequestItems: {} };

                p.tables.forEach( tbl => {
                    if( !p_get.RequestItems[tbl.name] )
                        p_get.RequestItems[tbl.name] = { Keys: [] }

                    tbl.keys.forEach( key => {
                        p_get.RequestItems[tbl.name].Keys.push( key );
                    } );

                    if( tbl.ConsistentRead )
                        p_get.RequestItems[tbl.name].ConsistentRead = tbl.ConsistentRead;

                    if( tbl.proj_exp )
                        p_get.RequestItems[tbl.name].ProjectionExpression = tbl.proj_exp;

                    if( tbl.attr_names )
                        p_get.RequestItems[tbl.name].ExpressionAttributeNames = tbl.attr_names;

                    if( tbl.attr_vals )
                        p_get.RequestItems[tbl.name].ExpressionAttributeNames = tbl.attr_vals;

                    if( tbl.ReturnConsumedCapacity )
                        p_get.RequestItems[tbl.name].ReturnConsumedCapacity = tbl.ReturnConsumedCapacity;
                } );

                // console.log( 'AWS: DDB: BatchGet: p_get: ', p_get );

                client.batchGet(p_get, (err, data) => {
                    if (err)
                        return rej(err);

                    return sol( data );
                });
            }
            catch( err ){
                return rej(err);
            }
        } );
    }

    Upd = (p) => {
        return new Promise( async ( sol, rej ) => {
            try{
                const client = await this.Client();

                client.update(p, (err, data) => {
                    if (err)
                        return rej( err);

                    return sol( data );
                });
            }
            catch( err ){
                return rej(err);
            }
        } );
    }

    Query = (p) => {
        return new Promise( async ( sol, rej ) => {
            try{
                const client = await this.Client();

                client.query(p, (err, data) => {
                    if (err)
                        return rej(err);

                    return sol( data );
                });
            }
            catch( err ){
                return rej(err);
            }
        } );
    }

    Scan = (p) => {
        return new Promise( async ( sol, rej ) => {
            try{
                const client = await this.Client();

                client.scan(p, (err, data) => {
                    if (err)
                        return rej(err);

                    return sol( data );
                });
            }
            catch( err ){
                return rej(err);
            }
        } );
    }

    Del = (p) => {
        return new Promise( async ( sol, rej ) => {
            try{
                const client = await this.Client();

                client.delete(p, (err, data) => {
                    if (err)
                        return rej(err);

                    return sol( data );
                });
            }
            catch( err ){
                return rej(err);
            }
        } );
    }

}   // class DDB

/**
 */
class Lambda extends Core  {

    constructor(p) {
        super(p);

        // this._endPoint = elml_config._lambda_endPoint;

    }

    async Init(p) {
        if( !p ) p = {};

        if (this._bInit && !p.b_init)
            return {msg: 'OK'};
        return {msg: 'Initialized!!'};
    }

    Invoke(params,func_name)
    {
        return new Promise( async (sol, rej) => {
            try
            {
                // const auth_cred = await this.Credentials()
                const auth_cred = await this.Credentials_Current()

                // console.log( 'AWS: Lambda: Invoke: auth_cred: ', auth_cred );

                // await this.Init( {b_init: true} );
                const lambda = new AWS.Lambda({
                        apiVersion: '2015-03-31',
                        region: auth_cred._clientConfig.region,
                        credentials: auth_cred,
                    });

                const params_lambda = {
                    FunctionName: func_name, /* required */
                    InvocationType: "RequestResponse",
                    LogType: "Tail",
                    Payload: JSON.stringify(params)
                };

                // console.log( 'AWS: Lambda: Invoke: params_lambda: ', params_lambda );

                lambda.invoke(params_lambda, (err, data) => {
                    if (err)
                        return rej( err );

                    try
                    {
                        data = JSON.parse(data.Payload);

                        // console.log( 'AWS: Lambda: Invoke: data: ', data );

                        if( data.errorMessage )
                        {
                            // console.log( 'AWS: Lambda: Invoke: err: ', data );

                            return rej( data.errorMessage )
                        }

                        return sol( data );
                    }
                    catch (err)
                    {
                        return rej( new Error( 'Payload has to be json parsable.' ) )
                    }
                });
            }
            catch( err ){
                return rej( err );
            }
        });

    }
}   // class Lambda


/**
 */
class S3
{
    /**
     */
    static async Upload( p )
    {
        return new Promise( async (res, rej) => {
            const auth_cred = await this.Credentials_Current()

            const aws_core = new Core( {} );

            const s3_obj = new AWS.S3({
                    apiVersion: '2015-03-31',
                    region: auth_cred._clientConfig.region,
                    credentials: auth_cred,
                });

            const p_s3 = {
                    Bucket: p.bucket,
                    Key: p.key,
                    Body: p.data,
                    ContentType: p.contentType,//'image/jpeg'
                };

            // console.log( 'S3: Upload: p_s3: ', p_s3 );

            s3_obj.upload( p_s3, (err, data) => {
                if( err )
                    return rej( err );

                return res( data );
            } );
        });
    }   // Upload

    /**
     */
    static async Put( p )
    {
        return new Promise( async (res, rej) => {
            const aws_core = new Core( {} );

            const auth_cred =  await aws_core.Credentials_Current();
            console.log(auth_cred)

            const s3_obj = new AWS.S3({
                apiVersion: '2015-03-31',
                region: auth_cred._clientConfig.region,
                credentials: auth_cred,
            });
            const p_s3 = {
                Bucket: p.bucket
                ,   Key: p.key
                ,   Body: p.data
                ,   ContentType: p.contentType ? p.contentType : 'image/jpeg'
            };

            s3_obj.putObject( p_s3, (err, data) => {
                if( err )
                    return rej( err );

                return res( data );
            } );
        } );
    }   // Put

    /**
     */
    static async SignedUrl( p )
    {
        return new Promise( async (res, rej) => {
            const aws_core = new Core( {} );

            const auth_cred =  await aws_core.Credentials_Current();
            console.log(auth_cred)

            const s3_obj = new AWS.S3({
                apiVersion: '2015-03-31',
                region: auth_cred._clientConfig.region,
                credentials: auth_cred,
            });
            const p_s3 = {
                Bucket: p.bucket
                ,   Key: p.key
                ,   Expires: /*p.duration ? p.duration :*/ (365 * 24 * 60 * 60)
            };

            // const p_s3 = {
            //     Bucket: p.bucket
            //     ,   Key: p.key
            //     ,   Expires: /*p.duration ? p.duration :*/ (365 * 24 * 60 * 60) // 1 year
            // };

            s3_obj.getSignedUrl( 'getObject', p_s3, (err, data) => {
                if( err )
                    return rej( err );

                return res( data );
            } );
        } );
    }

    /**
     */
    static async SignedUrl_Put( p )
    {
        return new Promise( async (res, rej) => {
            const aws_core = new Core( {} );

            const s3_obj = new AWS.S3({
                apiVersion: '2015-03-31',
                // region: "eu-west-2",
                credentials: await aws_core.Credentials(),
            });

            const p_s3 = {
                Bucket: p.bucket
                ,   Key: p.key
                ,   ContentType: p.content_type ? p.content_type : 'image/jpeg'
                // ,   Expires: (1 * 60) // 1 min
                // default expiry is 15 min
            };

            s3_obj.getSignedUrl( 'putObject', p_s3, (err, data) => {
                if( err )
                    return rej( err );

                return res( data );
            } );
        });
    }
}   // S3

/**
 */
export { DDB, Core, Lambda, S3};

