/**
 * @copyright KARD
 */

import React from "react";

import { navigate } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";
import Page from "../_common/page";

import "./email.css";

/**
 */
const EmailLog = (props) => {
  const [isLoading, IsLoading] = React.useState(false);

  const [list, List] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);

  //
  React.useEffect(() => {
    Load(null).catch((err) => {});
  }, []);

  //
  const Load = async (b_more) => {
    try {
      IsLoading(true);

      const resp_list = await props.Comm_Log("email", b_more ? lastKey : null);

      console.log("EmailLog: Load: resp_list: ", resp_list);

      List(b_more ? [...list, ...resp_list.Items] : [...resp_list.Items]);
      LastKey(resp_list.LastEvaluatedKey);

      IsLoading(false);

      return {};
    } catch (err) {
      console.warn("EmailLog: Load: err: ", err);

      IsLoading(false);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // Load

  //
  return (
    <Page active="email">
      <div className="row">
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
            </ol>
          </nav>
        </div>
        <div className="col text-right">
          <p>Monday, 10 Augest 2021</p>
        </div>
      </div>

      <div className="row mt-2 mb-3">
        <div className="col">
          <h4 className="projectTitle m-0">
            <i className="fa fa-envelope fa-lg" /> Email Log
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="row mt-3 ml-1 mr-1">
              <div className="col">
                <div className="form-group has-search">
                  <span className="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Subject</th>
                    <th>Staff</th>
                    <th>Type</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {list.map((x) => (
                    <ListView key={[x.a, x.l].join(":")} log={x} />
                  ))}
                </tbody>

                <tfoot></tfoot>
              </table>
            </div>

            <div className="row">
              <div className="col-sm-12 ml-3 mr-3">
                <div className="pull-left">
                  <div className="dataTables_length">
                    <label className="entries">
                      Show
                      <select size="1" className="form-control show-entries">
                        <option value="10" selected="selected">
                          10
                        </option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div>

                <div className="pull-right mr-5">
                  <ul className="pagination">
                    <li className="">
                      <a
                        href="#"
                        tabindex="0"
                        className="paginate_button previous"
                        id="DataTables_Table_0_previous"
                      >
                        Previous
                      </a>
                    </li>
                    <li className="active">
                      <a tabindex="0">1</a>
                    </li>
                    <li>
                      <a tabindex="0">2</a>
                    </li>
                    <li>
                      <a tabindex="0">3</a>
                    </li>
                    <li>
                      <a
                        href="#"
                        tabindex="0"
                        className="paginate_button next"
                        id="DataTables_Table_0_next"
                      >
                        Next
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}; // EmailLog

/**
 */
const ListView = (props) => {
  const [strDate, StrDate] = React.useState("");

  React.useEffect(() => {
    const dt_obj = new Date(props.log.tn);

    StrDate(
      [
        dt_obj.getDate().toString().padStart(2, "0"),
        (dt_obj.getMonth() + 1).toString().padStart(2, "0"),
        dt_obj.getFullYear().toString(),
      ].join(".")
    );
  }, []);

  return (
    <tr>
      <td>{strDate}</td>
      <td>{props.log.f}</td>
      <td>{props.log.to}</td>
      <td>{props.log.su}</td>
      <td>{props.log.s}</td>
      <td>{props.log.tc}</td>

      {/* Status */}
      <td>{""}</td>
    </tr>
  );
}; // ListView

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(EmailLog);
