/**
 * @copyright KARD
 */

import React, { useState } from "react";

import { navigate, Link } from "@reach/router";
import AuiModal from "../../aui/modal";
import { connect, useDispatch } from "react-redux";
import * as actions from "../../rdx/actions";

import * as Cfg from "../../_cfg";
import ListProj from "../donation/list_proj";

import Page from "../_common/page";
import { LinearProgress } from "@material-ui/core";
import {
  Campaign_List,
  Campaign_List_Project,
  CampaignQuery,
} from "../../rdx/actions";
import { Time } from "../../api/Utils";
import { useAlert } from "react-alert";

/**
 */
const CampaignList = (props) => {
  const [isModal_Filter, IsModal_Filter] = React.useState(false);
  const dispatch = useDispatch();
  const [isLoading, IsLoading] = React.useState(false);
  const [error, SetError] = useState(null);
  const [listCamp, ListCamp] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);
  const [hasMore, HasMore] = useState(false);

  const [isFilter, IsFilter] = React.useState(false);
  const [filterObj, FilterObj] = React.useState({
    //
  });
  const [dt, SetDt] = React.useState("");

  //
  React.useEffect(() => {
    const bMore = !!lastKey;

    if (isFilter) {
      IsLoading(true);
      const fo = {
        dtStart: filterObj.dateSt
          ? new Date(filterObj.dateSt).setHours(0, 0, 0, 0)
          : 0,
        dtEnd: filterObj.dateEnd
          ? new Date(filterObj.dateEnd).setHours(0, 0, 0, 0)
          : 0,
        amountStart: filterObj.amountSt
          ? parseInt(parseFloat(filterObj.amountSt) * 100, 10)
          : 0,
        amountEnd: filterObj.amountEnd
          ? parseInt(parseFloat(filterObj.amountEnd) * 100, 10)
          : 0,
        pr_n: filterObj.curProj?.tn,
      };
      dispatch(CampaignQuery(fo, bMore ? lastKey : null))
        .then(({ Items, LastEvaluatedKey }) => {
          ListCamp(bMore ? [...listCamp, ...Items] : [...Items]);
          LastKey(LastEvaluatedKey);
          IsLoading(false);
        })
        .catch((err) => IsLoading(false));
    } else {
      Load(bMore).catch((err) => {});
    }
  }, [filterObj]);

  //
  const Load = async () => {
    try {
      IsLoading(true);
      SetError(null);
      const resp_list = await dispatch(
        Campaign_List(hasMore ? lastKey : null, 10)
      );
      console.log("resp_list", resp_list);
      HasMore(!!resp_list.LastEvaluatedKey);
      if (resp_list.LastEvaluatedKey) {
        LastKey(resp_list.LastEvaluatedKey);
      }

      IsLoading(false);

      if (!hasMore && !resp_list.Items.length) {
        // alert('No campaigns found')
        SetError("No campaigns found");
        return { success: false, campaign: [] };
      }
      ListCamp([...listCamp, ...resp_list.Items]);
      SetDt(resp_list.dt_now);

      return { success: true, campaign: resp_list.Items };
    } catch (err) {
      console.warn("ProjectList: Load: err: ", err);

      IsLoading(false);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // Load

  //
  return (
    <Page active="campaign">
      {/* <div className="row">
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Campaign</a>
              </li>
            </ol>
          </nav>
        </div>
        <div className="col text-right">
          <p>Monday, 10 Augest 2021</p>
        </div>
      </div> */}

      <div className="row mt-2 mb-3">
        <div className="col">
          <h4 className="projectTitle m-0">
            <img
              style={{ height: 20, width: 20, marginRight: 8 }}
              src={require("../../components/assets/icons/sideBar/active/campaign.png")}
            />
            All Campaigns
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="row my-3 ml-1 mr-1">
              <div className="col-sm-4 col">
                {/* <div className="form-group has-search">
                  <span className="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                </div> */}
                <button
                  className="btn btn btn-outline-primary mr-1 px-4"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    // navigate("/donation/int/new");
                    IsModal_Filter(true);
                  }}
                >
                  <i className="fa fa-filter" />{" "}
                  <a href="" className="btn-filter">
                    {isFilter ? "Filter: In Use" : "Filter: None Selected"}
                  </a>
                </button>
                <>
                  {isModal_Filter && (
                    <AuiModal
                      bOpen={isModal_Filter}
                      OnClose={() => IsModal_Filter(false)}
                    >
                      <FilterView
                        filter={filterObj}
                        OnFilter={(val) => {
                          FilterObj({ ...val });
                          IsFilter(Object.keys(val).length ? true : false);
                          // IsModal_Filter(false)
                        }}
                        OnClose={() => IsModal_Filter(false)}
                      />
                    </AuiModal>
                  )}
                </>
              </div>
              <div className="col-sm-8 col text-right">
                <button
                  className="btn btn btn-primary mr-1 px-4 font-weight-bold"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    navigate("/campaign/new");
                  }}
                >
                  <i className="fa fa-plus" />{" "}
                  <a href="" style={{ color: "#fff" }}>
                    New Campaign
                  </a>
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Project</th>
                    <th>Campaign</th>
                    <th>Target</th>
                    <th>Raised</th>
                    <th>Not Received</th>
                    <th>Start</th>
                    <th>End</th>
                    <th style={{ minWidth: 130 }}>Action</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    <LinearProgress color="primary" />
                    <LinearProgress color="success" />
                    <LinearProgress color="inherit" />
                  </div>
                ) : null}

                <tbody>
                  {listCamp.map((x) => (
                    <ListView
                      key={[x.i, x.tn].join(":")}
                      Settings_Get={props.Settings_Get}
                      camp={x}
                      __auth={props.__auth}
                      __sett={props.__sett}
                      dt={dt}
                    />
                  ))}
                </tbody>

                {/* <tfoot>
                {lastKey ? (
                  <button
                    className="btn btn-info"
                    onClick={() => {
                      Load(lastKey).catch((err) => {});
                    }}
                    disabled={isLoading}
                  >
                    Show More
                  </button>
                ) : null}
              </tfoot> */}
              </table>
            </div>
            <div className="row">
              <div className="col-sm-12 mx-3 my-3 mt-3">
                <div className="pull-left">
                  <div className="dataTables_length">
                    <tfoot>
                      {lastKey ? (
                        <button
                          className="btn btn-info"
                          onClick={() => {
                            Load(lastKey).catch((err) => {});
                          }}
                          disabled={isLoading}
                        >
                          Show More
                        </button>
                      ) : null}
                    </tfoot>
                  </div>
                </div>

                <div className="pull-right mr-5">
                  {/* <label className="entries">
                    Show
                    <select size="1" className="form-control show-entries">
                      <option value="10" selected="selected">
                        10
                      </option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    entries
                  </label> */}
                </div>

                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}; // CampaignList

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(CampaignList);

/**
 */
const ListView = (props) => {
  const Alert = useAlert();

  const [target, Target] = React.useState("0.00");
  const [raised, Raised] = React.useState("0.00");
  const [notReceived, NotReceived] = React.useState("0.00");
  const [st, St] = React.useState(Time.Epoch2Date(props.camp.ts));
  const [te, Te] = React.useState(Time.Epoch2Date(props.camp.te));

  React.useEffect(() => {
    try {
      Target(props.camp.a.t ? props.camp.a.t.toFixed(2) : "0.00");
    } catch (err) {
      //
    }

    try {
      Raised(props.camp.a.r ? props.camp.a.r.toFixed(2) : "0.00");
    } catch (err) {
      //
    }

    try {
      NotReceived(props.camp.a.nr ? props.camp.a.nr.toFixed(2) : "0.00");
    } catch (err) {
      //
    }
  }, []);

  /**
   */
  const OnShare = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    console.log("props.__sett", props.__sett);
    const resp_sett = await props.Settings_Get();
    console.log("resp_sett", resp_sett);
    if (Cfg.stage === "live" && !resp_sett.Item.d) {
      Alert.show(
        "No Domain was found, please enter a domain from the settings page ?",
        {
          title: "Alert!!",
          actions: [
            {
              copy: "Ok",
              onClick: () => {},
            },
          ],
          closeCopy: "No",
        }
      );

      return {};
    }
    const url_share = [
      "test" === Cfg.stage ? "localhost:3000" : resp_sett.Item.d,
      // 'test' === Cfg.stage ? '' : props.__sett.domain,
      // props.__auth.orgId,
      "donation/ext",
      props.camp.p,
      props.camp.tn,
    ].join("/");

    Alert.show(url_share, {
      title: "Share link",
      actions: [
        {
          copy: "Copy Link",
          onClick: () => {
            navigator.clipboard.writeText(url_share).catch();
          },
        },
      ],
      closeCopy: "Cancel",
    });

    console.log("ListView: OnShare: url_share: ", url_share);

    // const new_window = window.open(url_share, "_blank", "noopener,noreferrer");
    // // const new_window = window.open( url_share, )
    //
    // if (new_window) {
    //   new_window.opener = null;
    // }
  }; // OnShare

  /**
   */
  const OnEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(`/campaign/list/${props.camp.p}/${props.camp.tn}`);
  }; // OnEdit

  /**
   */
  const OnDel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    //
  }; // OnDel

  console.log("props.camp", props.camp);
  console.log("props.dt", props.dt);
  /**
   */
  return (
    <tr>
      {/* campaign id */}
      <td>
        <Link to={`/campaign/view/${props.camp.p}/${props.camp.tn}`}>
          {props.camp.tn}
        </Link>
      </td>

      {/* project name */}
      <td>{props.camp.pi.t}</td>

      {/* campaign name */}
      <td>{props.camp.ci.t}</td>

      {/* campaign target */}
      <td>{"£" + target}</td>

      {/* amount raised */}
      <td>{"£" + raised}</td>

      {/* not received */}
      <td>{"£" + notReceived}</td>

      {/* start / end date - 12.3.2034*/}
      <td>
        {props.camp.ts && props.dt < props.camp.ts ? (
          <span className="badge badge-primary">{st}</span>
        ) : props.camp.ts && props.dt >= props.camp.ts ? (
          <span className="badge badge-success">{st}</span>
        ) : (
          ""
        )}
      </td>

      {/* start / end date - 12.3.2034*/}
      <td>
        {props.camp.te && props.dt > props.camp.te ? (
          <span className="badge badge-danger">{te}</span>
        ) : props.camp.te ? (
          <span className="badge badge-success">{te}</span>
        ) : (
          ""
        )}
      </td>

      {/*  */}
      <td className="btn-group">
        <a
          href=""
          className="btn btn-secondary btn-sm rounded mr-1"
          onClick={OnShare}
        >
          <i className="fa fa-share text-white"></i>
        </a>
        <a
          href=""
          className="btn btn-primary btn-sm rounded mr-1"
          onClick={OnEdit}
        >
          <i className="fa fa-edit text-white"></i>
        </a>
        <a href="" className="btn btn-danger btn-sm rounded" onClick={OnDel}>
          <i className="fa fa-trash text-white"></i>
        </a>
      </td>

      {/*  */}
      <td>
        <div className="switch_box box_1">
          <input
            type="checkbox"
            className="switch_1"
            checked={"act" === props.camp.s}
            disabled
          />
        </div>
      </td>
    </tr>
  );
};

/**
 */
const FilterView = (props) => {
  const [isModal_Proj, IsModal_Proj] = React.useState(false);
  const [curProj, CurProj] = React.useState(props?.filter?.curProj);

  const [isLoadingProj, IsLoadingProj] = React.useState(false);
  const [isLoadingCamp, IsLoadingCamp] = React.useState(false);

  const [dateSt, DateSt] = React.useState(props?.filter?.dateSt);
  const [dateEnd, DateEnd] = React.useState(props?.filter?.dateEnd);

  const [projNo, ProjNo] = React.useState(props?.filter?.projNo);
  const [campNo, CampNo] = React.useState(props?.filter?.campNo);

  const [amountSt, AmountSt] = React.useState(props?.filter?.amountSt);
  const [amountEnd, AmountEnd] = React.useState(props?.filter?.amountEnd);

  React.useEffect(() => {
    LoadProjList().catch((err) => {});
  }, []);

  /**
   */
  const LoadProjList = async () => {
    try {
      IsLoadingProj(true);

      IsLoadingProj(false);
      return {};
    } catch (err) {
      IsLoadingProj(false);

      return Promise.reject(err);
    }
  }; // LoadProjList

  /**
   */
  const LoadCampList = async () => {
    try {
      IsLoadingCamp(true);

      IsLoadingCamp(false);
      return {};
    } catch (err) {
      IsLoadingCamp(false);

      return Promise.reject(err);
    }
  }; // LoadCampList

  /**
   */
  const OnClose = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.OnClose();
  }; // OnClose

  /**
   */
  const OnClearAll = (e) => {
    e.preventDefault();
    e.stopPropagation();

    DateSt(null);
    DateEnd(null);

    AmountSt(null);
    AmountEnd(null);
    CurProj(null);

    /// @note need to call save
  }; // OnClearAll

  /**
   */
  const OnSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // const name_f = nameF ? nameF.trim() : "";
    // const name_l = nameL ? nameL.trim() : "";
    // const addr_city = addrCity ? addrCity.trim() : "";
    // const addr_cn = addrCn ? addrCn.trim() : ''

    props.OnFilter({
      dateSt,
      curProj,
      campNo,
      amountSt,
      amountEnd,
    });
    props.OnClose();
  }; // OnSave

  /**
   */
  return (
    <div className="container">
      <h3 className="projectTitle ">Date</h3>
      <div className="row">
        <div className="col-sm-6">
          From
          <input
            className="form-control"
            type="date"
            value={dateSt}
            onChange={(e) => {
              DateSt(e.target.value);
            }}
          />
        </div>
        <div className="col-sm-6">
          To
          <input
            className="form-control"
            type="date"
            value={dateEnd}
            onChange={(e) => {
              DateEnd(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <h3 className="projectTitle ">Project</h3>
          <>
            <button
              className="btn-block btn"
              style={{
                fontSize: 16,
                margin: "5px 0px",
                padding: 16,
                color: curProj ? "#0094FF" : "#4f566a",
                backgroundColor: curProj ? "#EFF8FF" : null,
                borderColor: curProj ? "#0094FF" : "#dee2e6",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                IsModal_Proj(true);
              }}
            >
              {isLoadingProj
                ? "Loading..."
                : curProj
                ? curProj.p.t
                : "Select Project"}
            </button>
          </>
        </div>
      </div>

      <h3 className="projectTitle ">Amount</h3>
      <div className="row">
        <div className="col-sm-6">
          From
          <input
            className="form-control"
            value={amountSt}
            onChange={(e) => AmountSt(e.target.value)}
          />
        </div>
        <div className="col-sm-6">
          To
          <input
            className="form-control"
            value={amountEnd}
            onChange={(e) => AmountEnd(e.target.value)}
          />
        </div>
      </div>

      <div className="col-sm-12 btn-group mt-4 p-0">
        <button className="btn btn-info mr-3" onClick={OnClose}>
          Close
        </button>

        <button className="btn btn-info mr-3" onClick={OnClearAll}>
          Clear All
        </button>

        <button className="btn btn-info" onClick={OnSave}>
          Save
        </button>
      </div>
      <AuiModal
        bOpen={isModal_Proj}
        OnClose={() => IsModal_Proj(false)}
        className="aui-modal"
      >
        <div className="modal-donation">
          <ListProj
            pr={curProj}
            OnSelect={(proj) => {
              CurProj(proj);
            }}
            OnClose={() => IsModal_Proj(false)}
          />
        </div>
      </AuiModal>
    </div>
  );
}; // FilterView
