/**
 * @copyright KARD
 */

import React, {useEffect, useState} from "react";
import Loader from "react-js-loader";
// import {navigate} from "@reach/router";

import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from "../../rdx/actions";

// import Page from "../_common/page";
import { Time } from "../../api/Utils";
import {SavePyamentService} from "../../rdx/actions/paym_stripe";
import {SavePaymentOption} from "../../rdx/actions";

/**
 */
const Settings_Payment = ({pym}) => {
  const [isSaving, IsSaving] = React.useState(false);

  /**
   */
  const defPaymOpts = [
    { i: "bk", t: "Bank"},
    { i: "crd", t: "Card"},
    { i: "cs", t: "Cash"},
    { i: "cq", t: "Cheque"},
    {i: 'ppl', t: 'PayPal'},
  ];
  const dispatch = useDispatch();
  const [paymOpts, PaymOpts] = React.useState([]);
  const [paymInts, PaymInts] = React.useState([]);
  const [isNewInts, IsNewInts] = React.useState(false);
  const [int,SetInt] = useState({
    bk:{
      act:false,
      srvc:null
    },
    crd:{
      act:false,
      srvc:null
    },
    cs:{
      act:false,
    },
    cq:{
      act:false,
    },
  });
  const [ext,SetExt] = useState({
    bk:{
      act:false,
      srvc:null
    },
    crd:{
      act:false,
      srvc:null
    },
    cs:{
      act:false,
    },
    cq:{
      act:false,
    },
  });
  const [crowd,SetCrowd] = useState({
    bk:{
      act:false,
      srvc:null
    },
    crd:{
      act:false,
      srvc:null
    },
    cs:{
      act:false,
    },
    cq:{
      act:false,
    },
  });

  useEffect(()=>{
    if (pym?.int)
    {
      SetInt(prevState => pym.int)
    }
    if (pym?.ext)
    {
      SetExt(prevState => pym.ext)
    }
    if (pym?.crowd)
    {
      SetCrowd(prevState => pym.crowd)
    }
  },[pym])


  /**
   */
  React.useEffect(() => {
    // get payment parameters
    // do something here...

    // update payment parameters
    PaymOpts(defPaymOpts);
  }, []);

  // console.log(int,pym)

  /**
   */
  const OnSavePayment = async (e) => {
    e.preventDefault();
    e.stopPropagation()

    SavePayment().catch( err => {} )
  };

  /**
   */
  const SavePayment = async () =>
  {
    try
    {
      IsSaving( true )

      const params = {
        int,ext,crowd
      }

      console.log( 'Settings_Payment: SavePayment: params: ', params )

      const resp = await dispatch(SavePaymentOption(params))

      console.log( 'Settings_Payment: SavePayment: resp: ', resp )

      IsSaving( false )

      alert( 'Payment Settings Saved' )

      return {}
    }
    catch( err )
    {
      console.warn( 'Settings_Payment: SavePayment: err: ', err )

      IsSaving( false )

      alert( 'Failed to save Payment Settings' )

      return Promise.reject( err )
    }
  } // SavePayment

  /**
   */
  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <div>
              <h3 className="projectTitle">Payment Options</h3>
            </div>

            <div>
              <div className="card">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Options</th>
                        <th>Internal</th>
                        <th>External</th>
                        <th>Crowd</th>
                        <th>Paypall</th>
                      </tr>
                    </thead>

                    {paymOpts.map((x, i) => (
                      <tr key={x.tn ? x.tn : x.i}>
                        <td>
                          <a>{x.t}</a>
                        </td>
                        <td>
                          <div className="mt-2">
                            <input
                                type="checkbox"
                                className="switch_1"
                                checked={int?.[x.i]?.act === true}
                                // checked={pym?.srvc?.length && int?.[x.i]?.act === true}
                                onChange={event => SetInt({...int,[x.i]: {...int[x.i],act:event.target.checked}})}
                            />

                          </div>
                          <>
                            {
                              (x.i === "crd"||x.i === "bk"||x.i === "ppl") &&
                                <>
                                  <SelectOption srvc={pym?.srvc} OnChange={val=>SetInt({...int,[x.i]:{...int[x.i],srvc:val}})} selected={int[x.i]?.srvc}/>
                                </>
                            }
                          </>
                        </td>
                        <td>
                          <div className="mt-2">
                            <input
                                type="checkbox"
                                className="switch_1"
                                checked={ext?.[x.i]?.act === true}
                                // checked={pym?.srvc?.length && ext?.[x.i]?.act === true}
                                onChange={event => SetExt({...ext,[x.i]: {...ext[x.i],act:event.target.checked}})}
                            />

                          </div>
                          <>
                            {
                              (x.i === "crd"||x.i === "bk"||x.i === "ppl") &&
                                <>
                                  <SelectOption srvc={pym?.srvc} OnChange={val=>SetExt({...ext,[x.i]:{...ext[x.i],srvc:val}})} selected={ext[x.i]?.srvc}/>
                                </>
                            }
                          </>
                        </td>
                        <td>
                          <div className="mt-2">
                            <input
                                type="checkbox"
                                className="switch_1"
                                checked={crowd?.[x.i]?.act === true}
                                checked={pym?.srvc?.length && crowd?.[x.i]?.act === true}
                                onChange={event => SetCrowd({...crowd,[x.i]: {...crowd[x.i],act:event.target.checked}})}
                            />

                          </div>
                          <>
                            {
                              (x.i === "crd"||x.i === "bk"||x.i === "ppl") &&
                                <>
                                  <SelectOption srvc={pym?.srvc} OnChange={val=>SetCrowd({...crowd,[x.i]:{...crowd[x.i],srvc:val}})} selected={crowd[x.i]?.srvc}/>
                                </>
                            }
                          </>
                        </td>
                      </tr>
                    ))}
                  </table>
                  <button
                      className="btn btn-lg btn-primary mt-3 px-5"
                      onClick={OnSavePayment}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div>
              <h3 className="projectTitle">Payment Integrations</h3>
            </div>

            <div>
              {paymInts.map((x) => (
                <div>
                  <h3>{x.n}</h3>
                  <h5>
                    {"str:ind" === x.t
                      ? "Stripe (Individual)"
                      : "str:pf" === x.t
                      ? "Stirpe (Platform)"
                      : "gcl" === x.t
                      ? "GoCardless"
                      : "ppl" === x.t
                      ? "PayPal"
                      : "vvw" === x.t
                      ? "Viva Wallet"
                      : ""}
                  </h5>
                </div>
              ))}
            </div>

            <div>
              <button
                className="btn btn-info"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  IsNewInts(true);
                }}
              >
                Add New Integration
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            {isNewInts && <Settings_Payment_Ints_New />}
          </div>
        </div>
      </div>

      <>
      {
        (isSaving) &&
        <div>
          <div
              className="modal"
              style={{
                // margin: '0 auto'
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#d1cccc80",
              }}
          >
          <Loader
            // type="box-rectangular"
            type="spinner-cub"
            bgColor={"#2680c2"}
            title={"Saving ..."}
            color={"#2680c2"}
            size={150}
          />
          </div>
        </div>
      }
      </>
    </div>
  );
}; // Settings_Payment

/**
 */
export default Settings_Payment;

/**
 */
const Settings_Payment_Ints_New = (props) => {
  /**
   */
  const [type, SetType] = React.useState('');

  /**
   */
  const OnSave_StripeInd = async () => {
    try {
      props.OnSave({
        tn: Time.Epoch(Date.now()),
      });

      return {};
    } catch (err) {
      console.log("Settings_Payment_Ints_New: OnSave_StripeInd: err: ", err);

      return Promise.reject(err);
    }
  }; // OnSave_StripeInd

  /**
   */
  return (
    <div>
      <div>
        <h3 className="projectTitle">New Integration</h3>
      </div>
      <div>
        <button
          className="btn btn-sm btn-info mr-1"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            SetType("str:ind");
          }}
        >
          Stripe (Independent)
        </button>
        <button
          className="btn btn-sm btn-info mr-1"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            SetType("str:pf");
          }}
        >
          Stripe (Platform)
        </button>
        <button
          className="btn btn-sm btn-info mr-1"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            SetType("gc");
          }}
        >
          GoCardless
        </button>
        <button
          className="btn btn-sm btn-info mr-1"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            SetType("ppl");
          }}
        >
          Paypal
        </button>
        <button className="btn btn-sm btn-info mr-1">Viva Wallet</button>
      </div>
      {
        type === "str:ind" &&
          <StripeIntegration/>
      }
      {
        type === "ppl" &&
          <PaypalIntegration/>
      }
    </div>
  );
};

const StripeIntegration = ({}) => {
  const dispatch = useDispatch();
  const [name, SetName] = useState('');
  const [pk, SetPK] = useState('');
  const [sk, SetSK] = useState('');
  const [aci, SetACI] = useState('');

  const SaveStripeIntegration = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      const params = {
        n: name,
        sk: sk?.length > 0 ? sk : null,
        pk: pk?.length > 0 ? pk : null,
        aci: aci?.length > 0 ? aci : null,
        s: 'act',
        type:'stripe'
      }
      const resp = await dispatch(SavePyamentService(params));
      console.log(resp);

    } catch (e) {
      console.warn("settings/payment: StripeIntegration:error:", e);
      return Promise.reject(e);
    }
  }
  return (
      <div className="row">
        <div className="col-12">
          <form onSubmit={SaveStripeIntegration}>
            <div className="row">
              <div className="col-12">
                <input
                    className="form-control"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => SetName(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <input
                    className="form-control"
                    placeholder="Public Key"
                    value={pk}
                    onChange={(e) => SetPK(e.target.value.trim())}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <input
                    className="form-control"
                    placeholder="Secret Key"
                    value={sk}
                    onChange={(e) => SetSK(e.target.value.trim())}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <input
                    className="form-control"
                    placeholder="Account Id"
                    value={aci}
                    onChange={(e) => SetACI(e.target.value.trim())}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <button
                    className="btn btn-info mt-3"
                    type={"submit"} >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
  )
}

const PaypalIntegration = ({}) => {
  const dispatch = useDispatch();
  const [name, SetName] = useState('');
  const [pk, SetPK] = useState('');
  const [sk, SetSK] = useState('');
  const [aci, SetACI] = useState('');

  const SavePaypalIntegration = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      const params = {
        n: name,
        sk: sk?.length > 0 ? sk : null,
        pk: pk?.length > 0 ? pk : null,
        s: 'act',
        type:'paypal'
      }
      const resp = await dispatch(SavePyamentService(params));
      console.log(resp);

    } catch (e) {
      console.warn("settings/payment: StripeIntegration:error:", e);
      return Promise.reject(e);
    }
  }
  return (
      <div className="row">
        <div className="col-12">
          <form onSubmit={SavePaypalIntegration}>
            <div className="row">
              <div className="col-12">
                <input
                    className="form-control"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => SetName(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <input
                    className="form-control"
                    placeholder="Public Key"
                    value={pk}
                    onChange={(e) => SetPK(e.target.value.trim())}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <input
                    className="form-control"
                    placeholder="Secret Key"
                    value={sk}
                    onChange={(e) => SetSK(e.target.value.trim())}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <button
                    className="btn btn-info mt-3"
                    type={"submit"} >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
  )
}

const SelectOption = ({srvc, OnChange, selected}) =>
{
  //

  return(
    <>
    {
      srvc?.length
    ? <select
          className="form-control m-0"
          value={selected?.tn?.toString()}
          onChange={(e) => OnChange(srvc[srvc.findIndex(v=>v.tn === parseInt(e.target.value))])}
          disabled={!srvc?.length}
          // disabled
      >
        <option value={"select"}>
          {/* {srvc?.length ? 'Select' : 'Add integration'} */}
          Select
        </option>
        {
          srvc?.length && srvc.map((val,idx)=>
              <option value={val.tn}>{val?.n?.length>0?val.n:'Option -'+(idx+1)}</option>
          )
        }
      </select>

    : 'Add integration'
    }
    </>
  )
}
