/**
 * @copyright KARD.
 */

import React from 'react'
import {Table} from 'react-bootstrap'
import {
	Button,
} from '@material-ui/core'

// import { navigate } from "@reach/router"

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import './home.css'
/**
 */
class Stats extends React.PureComponent
{
    render()
    {
        return (
            <div>
                <h2 className="projectTitle">Statistical Overview</h2>
                <div className="card">
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>No</th>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Type</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>1</td>
                            <td>Zakat 2021</td>
                            <td>5000</td>
                            <td>02-02-2021</td>
                            <td>02-02-2022</td>
                            <td>Charity</td>
                            <td>Active</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Zakat 2021</td>
                            <td>5000</td>
                            <td>02-02-2021</td>
                            <td>02-02-2022</td>
                            <td>Charity</td>
                            <td>Active</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Zakat 2021</td>
                            <td>5000</td>
                            <td>02-02-2021</td>
                            <td>02-02-2022</td>
                            <td>Charity</td>
                            <td>Active</td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            </div>

        )
    }   // render
}   // Projects


/**
 */
const mapStateToProps = ( state ) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Stats )





