/**
 * @copyright KARD
 */

import React from 'react'

import {
	// Button,
} from '@material-ui/core'

import cnList from '../../aconst/cnList'

import Select from './select'

/**
 */
const Country = ( props ) =>
{
    const [isInit, IsInit] = React.useState( false )

    const cn_list = props.list
        ? props.list
        : props.filter
        
        ? props.list.filter( x => cnList.includes( y => y.key === x ) )
        : cnList

    // componentDidMount
    React.useEffect( () => {
        // console.log( 'input.Title: props: ', props )

        if( !isInit )
        {
            IsInit( true )

            // console.log( 'input.Title: def_list: ', def_list )

            if( props.OnChange && props.cn && !props.cn.cn )
            {
                props.OnChange( cn_list[0].val )
            }
        }
    }, [] )

    return (
        <div className={props.cCont ? props.cCont : 'form-group'}>
            <Select
                label='Country'
                list={cn_list}
                val={props.cn}
                OnChange={props.OnChange}
            />
        </div>
    )
}   // Cn

/**
 */
export default Country



