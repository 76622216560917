/**
 *	@copyright	KARD..
 */

import * as Cfg from '../../_cfg'
import * as AWS from '../../api/AWS'
import { Time } from '../../api/Utils'

// import {Core} from '../../api/AWS'
// import * as Time from '../../api/utils/time'

import {Tmp_Cred} from './tmp'

/**
 */
export const Auth_Verify = () =>
{
    return async ( d, gs ) =>
    {
        try
        {
            // if( gs().__auth.orgId )
            // {
            //     throw new Error( 'No organisation provided' )
            // }

            const aws_core = new AWS.Core({})

            console.log( 'rdx.Auth_Verify: aws_core: ', aws_core )

            const current_cred = await aws_core.Credentials()

            // console.log( 'rdx.Auth_Verify: current_cred: ', current_cred )

            if(current_cred.authenticated)
            {
                d( _Auth_Status( 'loggedin' ) )

                return true
            }
            else
            {
                // d( _AuthStatus('auth:req') )
                // return 'auth:req'

                d( _Auth_Status( 'unauth' ) )

                return false
            }

            // return {}
        }
        catch( err )
        {
            console.warn( 'rdx.Auth_Verify: err: ', err )

            d( _Auth_Status( 'unauth' ) )

            return Promise.reject( err )
        }
    }   // ...
}   // Auth_Verify

/**
 */
export const Auth_Login = ( {username, pass} ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_core = new AWS.Core({})

            console.log( 'rdx.Auth_Login: username: ', username )
            console.log( 'rdx.Auth_Login: pass: ', pass )

            const usr_obj = await aws_core.SignIn( {
                username: username,
                password: pass,
            } )

            console.log( 'rdx.Auth_Login: usr_obj: ', usr_obj )

            if( !usr_obj )
            {
                throw new Error( 'Something went wrong. Please try again.' )
            }

            d( _Auth_Usr( usr_obj ) )

            if( usr_obj.challengeName === 'NEW_PASSWORD_REQUIRED' )
            {
                d( Tmp_Cred({username, pass}) )

                d( _Auth_Status('NEW_PASSWORD_REQUIRED') )

                return usr_obj
            }
            else if(
                usr_obj.challengeName === 'SMS_MFA' ||
                usr_obj.challengeName === 'SOFTWARE_TOKEN_MFA' ||
                usr_obj.challengeName === 'MFA_SETUP'
            )
            {
                throw new Error( 'Not supported Authentication type.' )
            }
            else if(
                usr_obj.challengeName === 'CUSTOM_CHALLENGE' &&
                usr_obj.challengeName &&
                usr_obj.challengeParam.trigger === 'true'
            )
            {
                throw new Error( 'Not supported Authentication type.' )
            }
            else
            {
                d( _Auth_Status('loggedin') )
            }

            // d( _Auth_Usr( signin_obj ) )
            // d( _Auth_IsLoggedIn( true ) )

            // if( is_owner )
            // {
            //     d( _Auth_Role( 'owner' ) )
            // }
            // else
            // {
            //     d( _Auth_Role( 'staff' ) )
            // }

            return usr_obj
        }
        catch( err )
        {
            console.warn( 'rdx.Auth_Login: err: ', err )

            d( _Auth_Status( 'unauth' ) )

            return Promise.reject( err )
        }
    }   // ...
}   // Auth_Login

/**
 */
export const Auth_Change = ( old_pass, new_pass ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_core = new AWS.Core({})

            const usr_obj = gs().__auth.usr

            // console.log( 'rdx.Auth_Change: usr_obj: ', usr_obj )

            const change_obj = await aws_core.PassChange( usr_obj, old_pass, new_pass )

            // console.log( 'rdx.Auth_Change: change_obj: ', change_obj )

            return change_obj
        }
        catch( err )
        {
            console.warn( 'rdx.Auth_Change: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Auth_Change

/**
 */
export const Auth_Complete = ( new_pass ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_core = new AWS.Core({})

            const usr_obj = gs().__auth.usr

            // console.log( 'rdx.Auth_Complete: usr_obj: ', usr_obj )

            const complete_obj = await aws_core.PassComplete( usr_obj, new_pass )

            console.log( 'rdx.Auth_Complete: complete_obj: ', complete_obj )

            d( _Auth_Status('loggedin') )

            return complete_obj
        }
        catch( err )
        {
            console.warn( 'rdx.Auth_Complete: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Auth_Complete

/**
 */
export const Auth_Register = ( {email, phone, pass} ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_core = new AWS.Core({})

            const phone_no = [gs().__auth.cn.code, phone].join('')

            // console.log( 'rdx.Auth_Register: phone_no: ', phone_no )

            const signup_data = await aws_core.SignUp( {
                username: phone_no,
                password: pass,
                phone_number: phone_no,
                email: email,
            } )

            return signup_data
        }
        catch( err )
        {
            return Promise.reject( err )
        }
    }
}   // Auth_Register

/**
 */
export const Auth_UsrInit = () =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_core = new AWS.Core( {} )

            const cur_usr = await aws_core.currentuser()

            // console.log( 'rdx.Auth_UsrInit: cur_usr: ', cur_usr )

            const aws_lambda = new AWS.Lambda( {} )

            const p_lambda = {
                actType: 'user',
                act: 'user:init',
                data: {
                    username: cur_usr.username,
                    sub: cur_usr.attributes.sub,
                    phone: cur_usr.attributes.phone_number,
                    email: cur_usr.attributes.email,
                },
            }

            // console.log( 'rdx.Auth_UsrInit: p_lambda: ', p_lambda )

            const resp_lambda = await aws_lambda.Invoke( p_lambda, Cfg.lambda[Cfg.stage].admin )

            console.log( 'rdx.Auth_UsrInit: resp_lambda: ', resp_lambda )

            if( resp_lambda )
            {
                //
            }

            _Auth_Roles( resp_lambda.roles )

            //
            return {}
        }
        catch( err )
        {
            console.warn( 'rdx.Auth_UsrInit: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Auth_UsrInit

/**
 */
export const Auth_OrgId = ( org_id ) =>
{
    window.localStorage.setItem( 'org_id', org_id )

    console.warn( 'Auth_OrgId: org_id: ', org_id )

    return {
        type: 'auth:org_id',
        payload: org_id,
    }
}   // Auth_OrgId

/**
 */
export const _Auth_Status = ( auth_status ) =>
{

    return {
        type: 'auth:status',
        payload: auth_status,
    }
}   // _Auth_Status

/**
 */
const _Auth_Roles = ( arr_roles ) =>
{
    return {
            type: 'auth:roles',
            payload: arr_roles,
        }
}   // Auth_Role

// /**
//  */
// const _Auth_Cn = ( cn_obj ) =>
// {
//     return {
//             type: 'auth:cn',
//             payload: cn_obj,
//         }
// }   // Auth_Cn

/**
 */
const _Auth_Usr = ( usr_obj ) =>
{
    return {
            type: 'auth:usr',
            payload: usr_obj,
        }
}   // Auth_Usr








