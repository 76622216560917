/**
 * @copyright KARD
 */

import React, { useEffect, useReducer, useState } from "react";
import Loader from "react-js-loader";
import { navigate } from "@reach/router";

import { connect, useDispatch, useSelector } from "react-redux";
// import * as actions from '../../rdx/actions'

// import { Time } from "../../api/Utils";

import AuiModal from "../../aui/modal";
// import AuiStatus from '../../aui/status'

import Page from "../_common/page";
// import PayPattern from '../_common/pay_pat'
import ListProj from "../donation/list_proj";
import { ListCamp } from "../donation/list_camp";

// import CampType_V from './type_v'
import {
  // News_Details,
  News_Edit,
  News_Get,
  News_Add,
  News_SaveImage,
  // Project_Details,
  // Campaign_Details,
  uploadFile,
} from "../../rdx/actions";

import DescComp from "../_common/desc_comp";
import { ImageInput } from "../_common/img_input";

const ctInit = {
  listDesc: [],
};

const INIT_STATE = {
  title: "",
  snippet: "",
  selectedProject: {},
  selectedCampaign: {},
  i: "",
  ni: "",
  url: null,
  url_dt: null,
  status: "publ", // draft / published
  featured: "norm", // norm / feat
  profileImg: {
    //
  },
  ld: [],
  projectList: [],
  descList: [],
  isBusy: false,
  showProjectList: false,
  showCampaignList: false,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "project-list":
      return { ...state, projectList: action.payload };

    case "article":
      const { payload } = action;
      return {
        ...state,
        title: payload.t,
        i: payload.i,
        ni: payload.ni,
        snippet: payload.sn,
        url_dt: null,
        url: payload.url,
        featured: payload.f, // norm / feat
        profileImg: {
          url: payload.url,
        },
        status: payload.st,
        ld: payload?.ld || ctInit,
        selectedProject: payload.p ? { tn: payload.p, p: payload.pi } : null,
        selectedCampaign: payload.c ? { tn: payload.c, ci: payload.ci } : null,
      };
    case "busy":
      return { ...state, isBusy: action.payload };
    case "select-project":
      return { ...state, selectedProject: action.payload };
    case "select-campaign":
      return { ...state, selectedCampaign: action.payload };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

/**
 */
const CampaignNew = (props) => {
  const [curProj, CurProj] = React.useState(null);
  const [curCamp, CurCamp] = React.useState(null);
  // const orgId = useSelector((state) => state.__auth.orgId);
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const rdx = useDispatch();

  const [isLoading, IsLoading] = React.useState(false);
  const [isSaving, IsSaving] = React.useState(false);

  async function Init(n_id) {
    try {
      console.log("INIT", props);
      isLoading( true )
      dispatch({ type: "busy", payload: true });

      const { Item } = await rdx(News_Get(n_id));

      if (Item) {
        const project = {
          tn: Item.p,
          p: Item.pi,
        };
        const campaign = {
          tn: Item.c,
          p: Item.ci,
        };
        dispatch({ type: "select-project", payload: project });
        dispatch({ type: "select-campaign", payload: campaign });
        dispatch({ type: "article", payload: Item });
      }

      dispatch({ type: "busy", payload: true });

      isLoading( false )

      return {}
    } catch (e) {
      console.warn(e);
      isLoading( false )
      dispatch({ type: "busy", payload: false });
      // return e;
      return Promise.reject( e )
    }
  }

  /**
   */
  React.useEffect(() => {
    if (props.n_id) {
      Init(props.n_id)
        .then( () => {} )
        .catch( () => {} );
    }
  }, [props.n_id]);

  /**
   */
  const OnSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // if( !state.title || !!state.title.length )
    // {
    //   alert( 'Please enter title of the article' )

    //   return {}
    // }

    IsSaving( true )
    dispatch({ type: "busy", payload: true });

    if (props.n_id) {
      EditNews()
        .then(() => {
          IsSaving( false )
          dispatch({ type: "busy", payload: false })
        })
        .catch((err) => {
          IsSaving( false )
          dispatch({ type: "busy", payload: false });
        });
    } else {
      SaveProcess()
        .then((data) => {
          IsSaving( false )
          dispatch({ type: "busy", payload: false });

          if (data.success) {
            alert("News saved");
            navigate("/news");
          }
          else{
            alert("Failed to save. Try again");
          }
        })
        .catch((err) => {
          IsSaving( false )
          dispatch({ type: "busy", payload: false });
          alert(err.message ? err.message : err);
        });
    }
  }; // OnSave

  /**
   */
  async function SaveProcess() {
    try {
      const { Item } = await SaveArticle();

      if (!Item) {
        throw new Error("Failed to create News!");
      }

      if (!state.profileImg.file) {
        return { success: true, Item };
      }

      const { success, url } = await saveImage(Item);

      if (success) {
        const resp = await rdx(News_SaveImage({ url, ni: Item.ni }));
        if (resp.success) {
          return { success: true, news: resp.news.data.Attributes };
        }
      }

      return { success: true };
    } catch (e) {
      console.warn("campaign/new: SaveProcess:error", e);

      return Promise.reject(e);
    }
  } // Save Process

  /**
   */
  async function saveImage(p) {
    try {
      const file = state.profileImg.file;
      const ft = file.type.split("/")[1];
      const dtNow = Date.now();
      const iid = [dtNow, p.i, p.ni, "profile", file.name.split(".")[0]].join(
        "_"
      );
      const name = iid + "." + ft;
      const params = {
        name: name,
        key: name,
        contentType: file.type,
        file: file,
        g: "profile",
        tp: "profile",
        iid,
        ft,
      };
      // console.log(params,file);
      const { s3 } = await rdx(uploadFile(params));
      console.log(s3);
      if (s3.success) {
        return { ...s3 };
      } else {
        throw new Error("Filed to save file!");
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   */
  const SaveArticle = async () => {
    try {
      const camp_obj = {
        title: state.title,
        snippet: state.snippet,
        url: state.url,
        url_dt: state.url_dt,

        status: state.status,
        feat: state.featured,

        pr_n: state.selectedProject.tn
          ? parseInt(state.selectedProject.tn, 10)
          : null,
        pr_t: state.selectedProject.tn ? state.selectedProject?.p?.t : null,
        cp_n: state.selectedCampaign.tn
          ? parseInt(state.selectedCampaign.tn, 10)
          : null,
        cp_t: state.selectedCampaign.tn ? state.selectedCampaign?.ci?.t : null,
        listDesc: state.descList,
      };

      console.log(state);

      console.log("CampaignNew: SaveArticle: camp_obj: ", camp_obj);

      const resp = await rdx(News_Add(camp_obj));

      console.log("CampaignNew: SaveArticle: resp_save: ", resp);

      return resp;
    } catch (err) {
      console.warn("CampaignNew: Load: err: ", err);
      return Promise.reject(err);
    }
  }; // SaveArticle

  /**
   */
  const EditNews = async () => {
    try {
      let newUrl = state.url;
      if (state.profileImg.file) {
        const p = {
          i: state.i,
          ni: state.ni,
        };
        const { success, url } = await saveImage(p);
        if (success) {
          newUrl = url;
        }
      }

      const camp_obj = {
        title: state.title,
        snippet: state.snippet,
        url: newUrl,
        url_dt: state.url_dt,
        ni: props.n_id,
        status: state.status,
        feat: state.featured,

        pr_n: state.selectedProject?.tn
          ? parseInt(state.selectedProject.tn, 10)
          : null,
        pr_t: state.selectedProject?.tn ? state.selectedProject?.p?.t : null,
        cp_n: state.selectedCampaign?.tn
          ? parseInt(state.selectedCampaign.tn, 10)
          : null,
        cp_t: state.selectedCampaign?.tn ? state.selectedCampaign?.ci?.t : null,
        listDesc: state.descList,
      };

      console.log("CampaignNew: EditNews: camp_obj: ", camp_obj);

      const resp_save = await rdx(News_Edit(camp_obj));

      console.log("CampaignNew: EditNews: resp_save: ", resp_save);

      if (resp_save) {
        //
      }

      // ListProj( b_more ? [...listProj, ...resp_list.Items] : [...resp_list.Items] )
      // LastKey( resp_list.LastEvaluatedKey )

      alert("Campaign changes saved");

      return {};
    } catch (err) {
      console.warn("CampaignNew: Load: err: ", err);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // EditNews

  /**
   */
  return (
    <Page active="news">
      {/* <div className="row">
                <div className="col-sm-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">News</a></li>
                            <li className="breadcrumb-item"><a href="#">{props.n_id? 'Edit': 'Add'}</a></li>
                        </ol>
                    </nav>
                </div>
            </div> */}

      <div className="row">
        <div className="col mb-3">
          <h4 className="projectTitle m-0">
            <img
              style={{ height: 20, width: 20, marginRight: 16 }}
              src={require("../../components/assets/icons/sideBar/active/news.png")}
              alt=''
            />
            {props.n_id ? "Edit" : "Create"} News Article
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <form onSubmit={OnSave}>
            <div className="form-row">
              <div className="col-sm-12">
                <label for="name" className="col-form-label">
                  News Title *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="News Title"
                  value={state.title}
                  onChange={(e) =>
                    dispatch({ type: "title", payload: e.target.value })
                  }
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label for="snippet" className="col-form-label">
                News Snippet
              </label>
              <textarea
                class="form-control"
                id="snippet"
                rows="4"
                value={state.snippet}
                placeholder={"News Snippet"}
                onChange={(e) =>
                  dispatch({ type: "snippet", payload: e.target.value })
                }
              />
            </div>

            <div className="form-row">
              <div class="col-md-6">
                <label for="s_project" className="col-form-label">
                  Select Project
                </label>

                <div
                  className="btn-block btn"
                  style={{
                    fontSize: 20,
                    fontWeight: curProj ? 900 : null,
                    margin: "5px 0px",
                    padding: 16,
                    color: curProj ? "#0094FF" : "#4f566a",
                    backgroundColor: curProj ? "#EFF8FF" : null,
                    borderColor: curProj ? "#0094FF" : "#009efb",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                  id="s_project"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    dispatch({ type: "showProjectList", payload: true });
                  }}
                >
                  <a>{state.selectedProject?.p?.t || "Select a project"}</a>
                </div>
              </div>
              <div class="col-md-6">
                <label for="s_campaign" className="col-form-label">
                  Select Campaign
                </label>

                <div
                  className="btn-block btn"
                  style={{
                    fontSize: 20,
                    fontWeight: curCamp ? 900 : null,
                    margin: "5px 0px",
                    padding: 16,
                    color: curCamp ? "#0094FF" : "#4f566a",
                    backgroundColor: curCamp ? "#EFF8FF" : null,
                    borderColor: curCamp ? "#0094FF" : "#009efb",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                  id="s_campaign"
                  // disabled={(state.selectedProject && state.selectedProject.tn)}
                  // disabled={true}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (!state.selectedProject.tn) {
                      alert("Please select a project first");
                    } else {
                      dispatch({ type: "showCampaignList", payload: true });
                    }
                  }}
                >
                  <a>{state.selectedCampaign?.ci?.t || "Select a campaign"}</a>
                </div>
              </div>
            </div>

            <div className="form-row">
              <div class="col-md-6">
                <label for="default" className="col-sm-1 col-form-label">
                  Status
                </label>
                <div className="col-sm-2 mt-1">
                  <input
                    type="checkbox"
                    className="switch_1"
                    checked={"publ" === state.status}
                    // onChange={ e => {
                    //     console.log( 'campStatus: e.target.checked: ', e.target.checked )
                    // } }
                    onChange={(e) =>
                      dispatch({
                        type: "status",
                        payload: e.target.checked ? "publ" : "drft",
                      })
                    }
                  />
                </div>
              </div>
              <div class="col-md-6">
                <label for="default" className="col-sm-1 col-form-label">
                  Featured
                </label>
                <div className="col-sm-2 mt-1">
                  <input
                    type="checkbox"
                    className="switch_1"
                    checked={"feat" === state.featured}
                    // onChange={ e => {
                    //     console.log( 'campStatus: e.target.checked: ', e.target.checked )
                    // } }
                    onChange={(e) =>
                      dispatch({
                        type: "featured",
                        payload: e.target.checked ? "feat" : "norm",
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="form-group col-sm-12 col-lg-12 col-md-12 p-0">
              <label for="s_article" className="col-form-label">
                Article Image
              </label>

              <div className="form-group">
                <ImageInput
                  onChange={(d) => {
                    // console.log(d)
                    dispatch({ type: "profileImg", payload: d });
                  }}
                  label={"Select an image"}
                  value={state.profileImg}
                />
              </div>
            </div>

            <DescComp
              listDesc={state.ld}
              onUpdate={(l) => dispatch({ type: "descList", payload: [...l] })}
            />

            <button type="submit" className="btn btn-primary btn-lg px-5">
              {props.n_id ? "Update" : "Save"}
            </button>
          </form>

          <AuiModal
            bOpen={state.showProjectList}
            OnClose={() =>
              dispatch({ type: "showProjectList", payload: false })
            }
          >
            {/* <div style={{maxHeight: '90%'}}> */}
            <ListProj
              pr={state.selectedProject}
              OnSelect={(proj) => {
                dispatch({ type: "selectedProject", payload: proj });
              }}
              OnClose={() =>
                dispatch({ type: "showProjectList", payload: false })
              }
            />
            {/* </div> */}
          </AuiModal>

          <AuiModal
            bOpen={state.showCampaignList}
            OnClose={() =>
              dispatch({ type: "showCampaignList", payload: false })
            }
          >
            {/* <div style={{maxHeight: '90%'}}> */}
            <ListCamp
              pr={state.selectedProject}
              cp={state.selectedCampaign}
              OnSelect={(camp) => {
                dispatch({ type: "selectedCampaign", payload: camp });
              }}
              OnClose={() =>
                dispatch({ type: "showCampaignList", payload: false })
              }
            />
            {/* </div> */}
          </AuiModal>
        </div>
      </div>

      <>
      {
        (isLoading || isSaving) &&
        <div>
          <div
              className="modal"
              style={{
                // margin: '0 auto'
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#d1cccc80",
              }}
          >
          <Loader
            // type="box-rectangular"
            type="spinner-cub"
            bgColor={"#2680c2"}
            title={isLoading ? 'Loading...' : "Saving ..."}
            color={"#2680c2"}
            size={150}
          />
          </div>
        </div>
      }
      </>
    </Page>
  );
}; // CampaignNew

/**
 */
export default CampaignNew;
