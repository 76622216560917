/**
 * @copyright KARD
 */

import React from 'react'

// import {navigate} from "@reach/router";

// import {
// 	Grid,
// } from '@material-ui/core'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import * as Cfg from '../../_cfg'

// import {Time, UI} from "../../api/Utils";

// import {
//     // Core,
//     DDB,
// } from '../../api/AWS'

// import Addr from '../../aui/addr'

/**
 */
const ListDonationType = ( props ) =>
{
    const [listType, ListType] = React.useState( [
        {t: 'Zakat', d: '', dt_n: 1 },
        {t: 'Fitra', d: '', dt_n: 2, },
        {t: 'Sadaqah', d: '', dt_n: 3, },
        {t: 'Lillah', d: '', dt_n: 4, },
        {t: 'Interest', d: '', dt_n: 5, },
        {t: 'General Donation', d: '', dt_n: 6, },
        {t: 'Qurbani', d: '', dt_n: 7, },
        {t: 'Kaffarah', d: '', dt_n: 8, },
    ] )

    return (
        // <div style={{overflow: 'auto'}} >
            <>
            {/* {
                listType.map( (x, i) => (
                    // <div key={i}>
                        <button
                            key={i}
                            className="form-control"
                            style={{
                                margin: 5, padding: 5,
                                borderColor: 'black', borderWidth: 0.5, borderRadius: 5,
                                backgroundColor: props.dt && props.dt.dt_n === x.dt_n ? 'lightgreen' : null,
                            }}
                            onClick={ e => {
                                e.preventDefault()
                                e.stopPropagation()

                                props.OnSelect( x )

                                if( props.OnClose )
                                {
                                    props.OnClose()
                                }
                            } }
                        >
                            
                            <a>{x.t}</a>
                        </button>
                    // </div>
                ) )
            } */
            <select
                className="form-control"                
                // required
            >
                <option value=''>Select</option>
                <option value='zakat'>ZAKAT</option>
                <option value='sadaqah'>SADAQAH</option>
            </select>
            
            }
            
            </>
        // </div>
    )
}   // ListDonationType

/**
 */
export default ListDonationType



