/**
 *  @copyright KARD
 */

import React from "react";

import { navigate, Link } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

import { Core } from "../../api/AWS";

const Hdr = (props) => {
  /**
   */
  return (
    <div className="header">
      <div className="header-left">
        <a href="/dashboard" className="logo">
          {/* <img src="/assets/img/KA.png" width={35} height={35} alt="" /> */}
          <span>Charity Manager</span>
        </a>
      </div>
      <a id="toggle_btn" href="javascript:void(0);">
        <i className="fa fa-bars" />
      </a>
      <a id="mobile_btn" className="mobile_btn float-left" href="#sidebar">
        <i className="fa fa-bars" />
      </a>

      <ul className="nav user-menu float-right">
        <li className="nav-item dropdown d-none d-sm-block">
          <a href="/dashboard" className="hasnotifications nav-link">
            <img
              style={{ height: 20, width: 20, marginRight: 16 }}
              src={require("../../components/assets/icons/sideBar/active/home.png")}
            />
            <span>Home</span>
          </a>
        </li>
        <li className="nav-item dropdown d-none d-sm-block">
          <a
            href="#"
            onClick={() => navigate("/donation/int/new")}
            className="hasnotifications nav-link"
          >
            <img
              style={{ height: 20, width: 20, marginRight: 16 }}
              src={require("../../components/assets/icons/sideBar/active/donation.png")}
            />
            <span>Donation</span>
            {/* <span className="badge badge-pill bg-danger float-right">8</span> */}
          </a>
        </li>

        {/*<li className="nav-item dropdown d-none d-sm-block">*/}
        {/*    <Notifications />*/}
        {/*</li>*/}

        {/*<li className="nav-item dropdown d-none d-sm-block">*/}
        {/*    <a href="javascript:void(0);" id="open_msg_box" className="hasnotifications nav-link"><i className="fa fa-comment-o" /> <span className="badge badge-pill bg-danger float-right">8</span></a>*/}
        {/*</li>*/}

        <li className="nav-item dropdown has-arrow">
          <a
            href="#"
            className="dropdown-toggle nav-link user-link"
            data-toggle="dropdown"
          >
            <span className="user-img">
              <img
                className="rounded-circle"
                src="/assets/img/user.jpg"
                width={24}
                alt="Admin"
              />
              <span className="status online" />
            </span>
            {/* <span>Admin</span> */}
          </a>
          {/* <div className="dropdown-menu">
                        <Link to="/profile/home">
                            <a className="dropdown-item">Profile</a>
                        </Link>
                        <a className="dropdown-item"
                            // href="login.html"
                            onClick={ OnSignOut }
                        >
                            Logout
                        </a>
                    </div> */}
          <ProfileMenu Auth_Verify={props.Auth_Verify} />
        </li>
      </ul>

      <div className="dropdown mobile-user-menu float-right">
        <a
          href="#"
          className="dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v" />
        </a>
        {/* <div className="dropdown-menu dropdown-menu-right">
                    <Link to="/profile/home">
                        <a className="dropdown-item">Profile</a>
                    </Link>
                    <a className="dropdown-item"
                        // href="login.html"
                        onClick={ OnSignOut }
                    >
                        Logout
                    </a>
                </div> */}
        <ProfileMenu Auth_Verify={props.Auth_Verify} />
      </div>
    </div>
  );
}; // Hdr

/**
 */
const ProfileMenu = (props) => {
  /**
   */
  const OnSignOut = async () => {
    try {
      const aws_core = new Core({});

      const so = await aws_core.SignOut();

      const resp = await props.Auth_Verify();
      return navigate("/");

      // return {}
    } catch (err) {
      return { err };
    }
  }; // OnSignOut

  return (
    <div className="dropdown-menu dropdown-menu-right">
      {/* <Link to="/profile">
        <a className="dropdown-item">
          <i className="fa fa-user mr-2" />
          Profile
        </a>
      </Link> */}

      <a
        className="dropdown-item"
        // href="login.html"
        onClick={OnSignOut}
      >
        <i className="fa fa-sign-out mr-2" /> Logout
      </a>
    </div>
  );
}; // ProfileMenu

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Hdr);

// /**
//  */
// const Notifications = () =>
// {
//     return (
//         <>
//             <a href="#" className="dropdown-toggle nav-link" data-toggle="dropdown"><i className="fa fa-bell-o" /> <span className="badge badge-pill bg-danger float-right">3</span></a>
//             <div className="dropdown-menu notifications">
//                 <div className="topnav-dropdown-header">
//                 <span>Notifications</span>
//                 </div>
//                 <div className="drop-scroll">
//                 <ul className="notification-list">
//                     <li className="notification-message">
//                     <a href="activities.html">
//                         <div className="media">
//                         <span className="avatar">
//                             <img alt="John Doe" src="/assets/img/user.jpg" className="img-fluid" />
//                         </span>
//                         <div className="media-body">
//                             <p className="noti-details"><span className="noti-title">John Doe</span> added new task <span className="noti-title">Patient appointment booking</span></p>
//                             <p className="noti-time"><span className="notification-time">4 mins ago</span></p>
//                         </div>
//                         </div>
//                     </a>
//                     </li>
//                     <li className="notification-message">
//                     <a href="activities.html">
//                         <div className="media">
//                         <span className="avatar">V</span>
//                         <div className="media-body">
//                             <p className="noti-details"><span className="noti-title">Tarah Shropshire</span> changed the task name <span className="noti-title">Appointment booking with payment gateway</span></p>
//                             <p className="noti-time"><span className="notification-time">6 mins ago</span></p>
//                         </div>
//                         </div>
//                     </a>
//                     </li>
//                     <li className="notification-message">
//                     <a href="activities.html">
//                         <div className="media">
//                         <span className="avatar">L</span>
//                         <div className="media-body">
//                             <p className="noti-details"><span className="noti-title">Misty Tison</span> added <span className="noti-title">Domenic Houston</span> and <span className="noti-title">Claire Mapes</span> to project <span className="noti-title">Doctor available module</span></p>
//                             <p className="noti-time"><span className="notification-time">8 mins ago</span></p>
//                         </div>
//                         </div>
//                     </a>
//                     </li>
//                     <li className="notification-message">
//                     <a href="activities.html">
//                         <div className="media">
//                         <span className="avatar">G</span>
//                         <div className="media-body">
//                             <p className="noti-details"><span className="noti-title">Rolland Webber</span> completed task <span className="noti-title">Patient and Doctor video conferencing</span></p>
//                             <p className="noti-time"><span className="notification-time">12 mins ago</span></p>
//                         </div>
//                         </div>
//                     </a>
//                     </li>
//                     <li className="notification-message">
//                     <a href="activities.html">
//                         <div className="media">
//                         <span className="avatar">V</span>
//                         <div className="media-body">
//                             <p className="noti-details"><span className="noti-title">Bernardo Galaviz</span> added new task <span className="noti-title">Private chat module</span></p>
//                             <p className="noti-time"><span className="notification-time">2 days ago</span></p>
//                         </div>
//                         </div>
//                     </a>
//                     </li>
//                 </ul>
//                 </div>
//                 <div className="topnav-dropdown-footer">
//                 <a href="activities.html">View all Notifications</a>
//                 </div>
//             </div>
//         </>
//     )
// }   // Notifications
