/**
 * @copyright KARD
 */

import React from "react";
import { useAlert } from "react-alert";

import AuiModal from "../../aui/modal";

/**
 */
const DonationEditView = (props) => {
    const alert = useAlert();
    // const [action, setAction] = React.useState("");

    const [dontDate, DontDate] = React.useState("");
    const [dontName, DontName] = React.useState("");
    const [projName, ProjName] = React.useState("");
    const [campName, CampName] = React.useState("");
    const [donrCity, DonrCity] = React.useState("");
    const [amount, Amount] = React.useState("0.00");
    // const [donationStatus, DonationStatus] = React.useState("0.00");

    React.useEffect(() => {
        try {
        const dt_new = new Date(props.dont.tn * 1000);
        DontDate(
            [dt_new.getDate(), dt_new.getMonth() + 1, dt_new.getFullYear()].join(
            "."
            )
        );
        } catch (err) {
        //
        }

        try {
        DontName(
            [props.dont.di.n.t, props.dont.di.n.f, props.dont.di.n.l].join(" ")
        );
        } catch (err) {
        //
        }

        try {
        ProjName(props.dont.pi && props.dont.pi.t ? props.dont.pi.t : "N/A");
        } catch (err) {
        //
        }

        try {
        CampName(props.dont.ci && props.dont.ci.t ? props.dont.ci.t : "N/A");
        } catch (err) {
        //
        }

        try {
        DonrCity(
            props.dont.di && props.dont.di.a && props.dont.di.a.c
            ? props.dont.di.a.c
            : "N/A"
        );
        } catch (err) {
        //
        }

        try {
        Amount(
            props.dont.k && props.dont.k.a ? props.dont.k.a.toFixed(2) : "0.00"
        );
        } catch (err) {
        //
        }
    }, []);

    // console.log( 'donation.list.ListView: props: ', props )

    /**
     */
    // const OnEdit = ( e ) =>
    // {
    //     e.preventDefault()
    //     e.stopPropagation()

    //     navigate( `/donation/int/new/${props.camp.tn}/${props.camp.p}` )
    // }   // OnEdit

    /**
     */
    // const OnDel = ( e ) =>
    // {
    //     e.preventDefault()
    //     e.stopPropagation()

    //     //
    // }   // OnDel
    //

    // const DonationDetails = async (b_more) => {
    //   try {
    //     console.log("donation details", props.dont);
    //   } catch (err) {
    //     return Promise.reject(err);
    //   }
    // }; // DonationDetails
    /**
     */
    return (
        <AuiModal bOpen={props.bOpen} OnClose={props.OnClose}>
        <div className="row">
            <div className="col-12">
            {/* <div className="col-sm-6 col-lg-6 col-md-6">
                <div className="form-group">
                    <label> Name: {dontName}</label>
                </div>
                </div>
                <div className="col-sm-6 col-lg-6 col-md-6">
                <div className="form-group">
                    <label>Project: {projName}</label>
                </div>
                </div>
                <div className="col-sm-6 col-lg-6 col-md-6">
                <div className="form-group">
                    <label>Campaign: {campName}</label>
                </div>
                </div>

                <div className="col-sm-6 col-lg-6 col-md-6">
                <div className="form-group">
                    <label>Donation Amount: {"£ " + amount}</label>
                </div>
                </div> */}
            <div className="card border-top-0 border-bottom-0 mb-3 mt-3">
                <div className="table-responsive">
                <table className="table">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Project</th>
                        <th>Campaign</th>
                        <th>Donation Amount</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td> {dontName}</td>
                        <td> {projName}</td>
                        <td> {campName}</td>
                        <td>{"£" + amount}</td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>

            <div className="btn-group">
                {props.dont.pm &&
                props.dont.pm.m === "cash" &&
                props.dont.s === "new" && (
                    <div className="">
                    {/*  */}
                    </div>
                )}

                <div className="">
                    <>
                    {
                        (props.dont.pm &&
                        props.dont.pm.m === "cash" &&
                        props.dont.s === "new") &&
                        <button
                            // type="submit"
                            className="btn btn-primary"
                            style={{ margin: 5, padding: 5 }}
                            onClick={(e) => {
                                alert.show("Have you received this donation ?", {
                                    // title: "Alert!!",
                                    actions: [
                                    {
                                        copy: "Yes",
                                        onClick: () => props.CovertToReceived(props.dont),
                                    },
                                    ],
                                    closeCopy: "No",
                                });
                            }}
                        >
                            Mark as Received
                        </button>
                    }
                    </>

                    {/* <button
                        // type="submit"
                        className="btn btn-primary px-3 mr-2"
                        onClick={(e) => {
                            alert.show("Do you want to return this donation ?", {
                                // title: "Alert!!",
                                actions: [
                                {
                                    copy: "Yes",
                                    onClick: () => props.ReturnDonation(props.dont),
                                },
                                ],
                                closeCopy: "No",
                            });

                            return false;
                        }}
                    >
                        Return
                    </button> */}

                    <button
                        // type="cancel"
                        className="btn btn-danger px-3"
                        // disabled={!isShowingSave}

                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            props.OnClose();

                            return false;
                        }}
                    >
                        Close
                    </button>
                </div>

                <div className="">
                    {/*  */}
                </div>
            </div>
            </div>
        </div>
        </AuiModal>
    );
};

/**
 */
export default DonationEditView

