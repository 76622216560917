/**
 * @copyright KARD
 */

import React from "react";

import { Button, SwitchUnstyled } from "@material-ui/core";

import { navigate } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

//  import {Time} from '../../api/Utils'

import Page from "../_common/page";

/**
 */
class ProfileView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      //
    };
  }

  render() {
    return (
      <Page active="profile">
        <div className="row mb-5">
          <div className="col-sm-4 col-3">
            <h4 className="projectTitle">
              <i className="fa fa-user" /> Profile Home
            </h4>
          </div>
          <div className="col-sm-8 col-9 text-right m-b-20">
            <button
              onClick={() => navigate("/staff/add")}
              className="btn btn btn-primary btn-rounded float-right"
            >
              <i className="fa fa-plus" /> Edit Profile
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="profile-header">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center">
                    <img
                      className="rounded-circle"
                      src="assets/img/img-avatar.png"
                      alt="image"
                    />
                    <h3 className="user-name m-t-0 mb-0">Cristina Groves</h3>
                    <small className="text-muted">Administrator</small>
                    <div
                      className="text-center"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="staff-id mr-4">
                        Email: Nikjone@demoo.com
                      </div>
                      <div className="staff-id">Location: USA</div>
                      <div className="staff-id ml-4">
                        Phone: 001 2351 256 12
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row mt-5">
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                        <div className="card p-4">
                            <h4 className="projectTitle">
                                <i className="fa fa-user"/> About User
                            </h4>
                            <p>
                                Tart I Love Sugar Plum I Love Oat Cake. Sweet Roll Caramels I
                                Love Jujubes. Topping Cake Wafer.
                            </p>
                            <div className="row">
                                <div className="col-sm-6">
                                    <p>
                                        Total Fundraise :<br/> 100$
                                    </p>
                                </div>
                                <div className="col-sm-6">
                                    <p>
                                        Last Fundraise : <br/> 2nd September 1941
                                    </p>
                                </div>
                            </div>
                            <p>
                                Address :<br/> 122, Baker Street, oblulu Oxe, London, United
                                Kindom
                            </p>
                            <p>
                                Joined :<br/> Feb 15, 2021
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                        <div className="card p-4">
                            <h4 className="projectTitle">
                                <i className="fa fa-cog"/> Consent
                            </h4>

                            <div className="row border mx-1 py-3">
                                <div className="col-sm-10">
                                    <h4>Zakat Charity Fund</h4>
                                </div>
                                <div className="col-sm-2">
                                    <input type="checkbox" className="switch_1" checked/>
                                </div>
                                <p className="px-3 mt-2">
                                    I am a UK taxpayer and i wish ({"this charity"}) to reclaim
                                    tax back on all donations i have made with the last 6 years
                                    and all donations that i make hereafter
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        <div className="card p-4">
                            <h4 className="projectTitle">
                                <i className="fa fa-cog"/> Donation History
                            </h4>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Deadine</th>
                                    <th>Raised</th>
                                    <th>Status</th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr>
                                    <td>
                                        <a href="">20101</a>
                                    </td>
                                    <td>Zakat Charity Fund</td>
                                    <td>
                                        Emergency Appeal For War Affected People In Gaza And
                                        Others
                                    </td>
                                    <td>24 May 21</td>
                                    <td>£ 200.00</td>
                                    <td>
                                        <div className="switch_box box_1">
                                            <input
                                                type="checkbox"
                                                className="switch_1"
                                                disabled
                                                checked
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <a href="">20102</a>
                                    </td>
                                    <td>Zakat Charity Fund</td>
                                    <td>
                                        Emergency Appeal For War Affected People In Gaza And
                                        Others
                                    </td>
                                    <td>24 May 21</td>
                                    <td>£ 200.00</td>
                                    <td>
                                        <div className="switch_box box_1">
                                            <input
                                                type="checkbox"
                                                className="switch_1"
                                                disabled
                                                checked
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <a href="">20103</a>
                                    </td>
                                    <td>Zakat Charity Fund</td>
                                    <td>
                                        Emergency Appeal For War Affected People In Gaza And
                                        Others
                                    </td>
                                    <td>28 May 21</td>
                                    <td>£ 500.00</td>
                                    <td>
                                        <div className="switch_box box_1">
                                            <input
                                                type="checkbox"
                                                className="switch_1"
                                                disabled
                                                checked
                                            />
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                        <div className="card p-4">
                            <h4 className="projectTitle">
                                <i className="fa fa-cc-paypal"/> Card
                            </h4>

                            <div className="row">
                                <div className="col-sm-10">
                                    <h4>
                                        <i className="fa fa-cc-paypal"/> Visa{" "}
                                        <span className="pull-right">1254 9865 9875 4478</span>
                                    </h4>
                                    <h4>
                                        <i className="fa fa-cc-visa"/> Paypal{" "}
                                        <span className="pull-right">1254 9865 9875 4478</span>
                                    </h4>
                                    <h4>
                                        <i className="fa fa-credit-card"/> Mastercard{" "}
                                        <span className="pull-right">1254 9865 9875 4478</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
      </Page>
    );
  } // render

  // componentDidMount()
  // {
  //     //
  // }
} // ProfileView

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

export default connect(mapStateToProps, actions)(ProfileView);
