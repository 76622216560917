/**
 *  @copyright KARD
 */

import React from "react";

import { navigate, Link } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

/**
 */
class SideBar extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      open: false,
    };
  }
  render() {
    return (
      <div className="sidebar wrapper" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <nav id="sidebar">
            <ul className="list-unstyled components">
              <li>
                <Link to="/dashboard">
                  {this.props.active === "dashboard" ? (
                    <a className="active">
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/active/dashboard.png")}
                      />
                      <span>Dashboard</span>
                    </a>
                  ) : (
                    <a>
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/inactive/dashboardInactive.png")}
                      />
                      <span style={{ color: "#4F566A", fontSize: 16 }}>
                        Dashboard
                      </span>
                    </a>
                  )}
                </Link>
              </li>

              <li>
                <Link to="/analytics">
                  {this.props.active === "analytics" ? (
                    <a className="active">
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/active/analyticsActive.png")}
                      />

                      <span> Analytics</span>
                    </a>
                  ) : (
                    <a>
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/inactive/analyticsInactive.png")}
                      />

                      <span style={{ color: "#4F566A", fontSize: 16 }}>
                        Analytics
                      </span>
                    </a>
                  )}
                </Link>
              </li>

              <li>
                <Link to="/donation/list">
                  {this.props.active === "donation" ? (
                    <a className="active">
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/active/donation.png")}
                      />
                      <span>Donation</span>
                    </a>
                  ) : (
                    <a>
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/inactive/donationInactive.png")}
                      />
                      <span style={{ color: "#4F566A", fontSize: 16 }}>
                        Donation
                      </span>
                    </a>
                  )}
                </Link>
              </li>

              <li>
                <Link to="/project">
                  {this.props.active === "project" ? (
                    <a className="active">
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/project.png")}
                      />
                      <span>Projects</span>
                    </a>
                  ) : (
                    <a>
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/projectInactive.png")}
                      />
                      <span style={{ color: "#4F566A", fontSize: 16 }}>
                        Projects
                      </span>
                    </a>
                  )}
                </Link>
              </li>

              <li>
                <Link to="/campaign">
                  {this.props.active === "campaign" ? (
                    <a className="active">
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/active/campaign.png")}
                      />
                      <span>Campaign</span>
                    </a>
                  ) : (
                    <a>
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/inactive/campaignInactive.png")}
                      />
                      <span style={{ color: "#4F566A", fontSize: 16 }}>
                        Campaign
                      </span>
                    </a>
                  )}
                </Link>
              </li>

              <li>
                <Link to="/crowd/crowd_campaign">
                  {this.props.active === "crowd" ? (
                    <a className="active">
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/crowdActive.png")}
                      />
                      <span>Crowd Campaign</span>
                    </a>
                  ) : (
                    <a>
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/crowdInactive.png")}
                      />
                      <span style={{ color: "#4F566A", fontSize: 16 }}>
                        Crowd Campaign
                      </span>
                    </a>
                  )}
                </Link>
              </li>
              <li>
                <Link to="/address">
                  {this.props.active === "address" ? (
                    <a className="active">
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/addressBook.png")}
                      />
                      <span>Address Book</span>
                    </a>
                  ) : (
                    <a>
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/addressBookInactive.png")}
                      />
                      <span style={{ color: "#4F566A", fontSize: 16 }}>
                        Address Book
                      </span>
                    </a>
                  )}
                </Link>
              </li>

              <li>
                <Link to="/news">
                  {this.props.active === "news" ? (
                    <a className="active">
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/newsActive.png")}
                      />
                      <span>News</span>
                    </a>
                  ) : (
                    <a>
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/newsInactive.png")}
                      />
                      <span style={{ color: "#4F566A", fontSize: 16 }}>
                        News
                      </span>
                    </a>
                  )}
                </Link>
              </li>

              <li>
                <Link to="/staff/list">
                  {this.props.active === "staff" ? (
                    <a className="active">
                      <img
                        style={{ width: 23, marginRight: 13 }}
                        src={require("../../components/assets/icons/sideBar/staff.png")}
                      />
                      <span>Staff</span>
                    </a>
                  ) : (
                    <a>
                      <img
                        style={{ width: 23, marginRight: 13 }}
                        src={require("../../components/assets/icons/sideBar/staffInactive.png")}
                      />
                      <span style={{ color: "#4F566A", fontSize: 16 }}>
                        Staff
                      </span>
                    </a>
                  )}
                </Link>
              </li>

              <li>
                <Link to="/settings">
                  {this.props.active === "settings" ? (
                    <a className="active">
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/settings.png")}
                      />
                      <span>Settings</span>
                    </a>
                  ) : (
                    <a>
                      <img
                        style={{ height: 20, width: 20, marginRight: 16 }}
                        src={require("../../components/assets/icons/sideBar/settingsInactive.png")}
                      />
                      <span style={{ color: "#4F566A", fontSize: 16 }}>
                        Settings
                      </span>
                    </a>
                  )}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  } // render
} // SideBar

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(SideBar);
