/**
 * @copyright KARD
 */

import React from "react";

// import {navigate} from "@reach/router";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";
import Page from "../_common/page";

import "./sms.css";

/**
 * @test 1. "<var>test</var> and <var>test</var> and finally <var>test2</var>"
 */
const SmsTemplateEdit = (props) => {
  const [isBusy, IsBusy] = React.useState(false);

  // const [listProj, ListProj] = React.useState( [] )
  // const [lastKey, LastKey] = React.useState( null )

  const [tempName, TempName] = React.useState("");
  const [tempDesc, TempDesc] = React.useState("");
  const [emailSub, EmailSub] = React.useState("");
  const [emailBody, EmailBody] = React.useState("");

  const [varList, VarList] = React.useState([]);
  const [varListSub, VarListSub] = React.useState([]);
  const [varListBody, VarListBody] = React.useState([]);
  const [varObj, VarObj] = React.useState({});

  /**
   */
  React.useEffect(() => {
    if (props.temp_n) {
      LoadTemp().catch((err) => {});
    }
  }, []);

  /**
   */
  React.useEffect(() => {
    // ProcessVar( emailSub )
    const v_arr = GetVar(emailSub);

    // console.log( 'SmsTemplateEdit: ProcessVar: v_arr: ', v_arr )

    const v_list = [...varListBody, ...v_arr];

    const v_set = new Set(v_list);

    VarList(Array.from(v_set));
    VarListSub(v_arr);
  }, [emailSub]);

  /**
   */
  React.useEffect(() => {
    // ProcessVar( emailBody )
    const v_arr = GetVar(emailBody);

    // console.log( 'SmsTemplateEdit: ProcessVar: v_arr: ', v_arr )

    const v_list = [...varListSub, ...v_arr];

    const v_set = new Set(v_list);

    VarList(Array.from(v_set));
    VarListBody(v_arr);
  }, [emailBody]);

  /**
   */
  const OnSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (props.temp_n) {
      EditTemp().catch((err) => {});
    } else {
      SaveTemp().catch((err) => {});
    }
  };

  /**
   */
  const LoadTemp = async () => {
    try {
      IsBusy(true);

      const resp_get = await props.TempEmail_Details({
        tn: parseInt(props.temp_n, 10),
      });

      console.log("SmsTemplateEdit: LoadTemp: resp_get: ", resp_get);

      TempName(resp_get.Item.n);
      TempDesc(resp_get.Item.d);
      EmailSub(resp_get.Item.su);
      EmailBody(resp_get.Item.b);
      VarList(resp_get.Item.v);
      VarListSub(GetVar(resp_get.Item.su));
      VarListBody(GetVar(resp_get.Item.b));

      IsBusy(false);

      return {};
    } catch (err) {
      console.warn("SmsTemplateEdit: SaveTemp: err: ", err);

      IsBusy(false);

      return Promise.reject(err);
    }
  }; // LoadTemp

  /**
   */
  const EditTemp = async () => {
    try {
      IsBusy(true);

      const resp_save = await props.TempEmail_Edit({
        tn: props.temp_n,

        name: tempName,
        desc: tempDesc,
        sub: emailSub,
        body: emailBody,
        list_var: varList,
        b_cust: false,
      });

      console.log("SmsTemplateEdit: EditTemp: resp_save: ", resp_save);

      if (resp_save) {
        //
      }

      IsBusy(false);

      return {};
    } catch (err) {
      console.warn("SmsTemplateEdit: EditTemp: err: ", err);

      IsBusy(false);

      return { err };
    }
  }; // EditTemp

  /**
   */
  const SaveTemp = async () => {
    try {
      IsBusy(true);

      const resp_save = await props.TempEmail_Create({
        name: tempName,
        desc: tempDesc,
        sub: emailSub,
        body: emailBody,
        list_var: varList,
        b_cust: false,
      });

      console.log("SmsTemplateEdit: SaveTemp: resp_save: ", resp_save);

      if (resp_save) {
        //
      }

      IsBusy(false);

      return {};
    } catch (err) {
      console.warn("SmsTemplateEdit: SaveTemp: err: ", err);

      IsBusy(false);

      return { err };
    }
  }; // SaveTemp

  /**
   */
  return (
    <Page active="sms">
      <div className="row">
        <div className="col-sm-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">SMS Templates</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Edit Templates</a>
              </li>
            </ol>
          </nav>
        </div>

        <div className="col-sm-6 text-right">
          <p>Monday, 10 Augest 2021</p>
        </div>
      </div>

      <div className="row mt-2 mb-3">
        <div className="col">
          <h4 className="projectTitle m-0">
            <i className="fa fa-comment fa-lg" /> SMS Template
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <form onSubmit={OnSave}>
            <div className="form-group">
              <label for="name" className="col-form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                // placeholder="Arif Hossain"
                value={tempName}
                onChange={(e) => TempName(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label for="description" className="col-form-label">
                Description
              </label>
              <textarea
                class="form-control"
                id="description"
                rows="2"
                value={tempDesc}
                onChange={(e) => TempDesc(e.target.value)}
              />
            </div>

            {/* <div className="form-group row">
                            <label for="default" className="col-sm-1 col-form-label">Default</label>
                            <div className="col-sm-2 mt-1">
                                <input type="checkbox" className="switch_1" />
                            </div>
                        </div> */}

            <div
              class="alert alert-primary alert-dismissible fade show"
              role="alert"
            >
              Auto merge possible only when selecting camping from address book.
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="form-group">
              <label for="email_sub" className="col-form-label">
                Email Subject
              </label>
              <input
                type="text"
                className="form-control"
                id="email_sub"
                // placeholder="Arif Hossain"
                value={emailSub}
                onChange={(e) => EmailSub(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label for="email_body" className="col-form-label">
                Email Body
              </label>
              <textarea
                class="form-control"
                id="email_body"
                rows="6"
                value={emailBody}
                onChange={(e) => EmailBody(e.target.value)}
                required
              />
            </div>

            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {varList.map((x) => (
                  <tr key={x}>
                    <td className="badge badge-secondary px-3 py-2 mb-2">
                      {x}
                    </td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>

            <button type="submit" className="btn btn-primary btn-lg">
              Save changes
            </button>
          </form>
        </div>
      </div>
    </Page>
  );
}; // SmsTemplateEdit

/**
 */
const GetVar = (str) => {
  // console.log( 'GetVar: str.length: ', str.length )

  const V_ST = "<var>";
  const V_END = "</var>";

  let i_cur = 0;
  let v_arr = [];

  while (i_cur < str.length) {
    const v_st = str.indexOf(V_ST, i_cur);

    // console.log( 'GetVar: v_st: ', v_st )

    if (0 > v_st) {
      break;
    }

    i_cur = v_st + V_ST.length;

    // console.log( 'GetVar: st: i_cur: ', i_cur )

    const v_end = str.indexOf(V_END, i_cur);

    // console.log( 'GetVar: v_end: ', v_end )

    if (0 > v_end) {
      break;
    }

    i_cur = v_end + V_END.length;

    // console.log( 'GetVar: end: i_cur: ', i_cur )

    //
    const v_str = str.substring(v_st + V_ST.length, v_end);

    // console.log( 'GetVar: v_str: ', v_str )

    v_arr.push(v_str);
  } // while

  // return v_arr

  const v_set = new Set(v_arr);

  return Array.from(v_set);
}; // GetVar

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(SmsTemplateEdit);
