import React, {useState, useEffect, forwardRef, useImperativeHandle} from "react";
import { CardElement, PaymentRequestButtonElement, useStripe, useElements } from "@stripe/react-stripe-js";

import * as Cfg from '../../_cfg'
import {Lambda,} from '../../api/AWS'

import './cardForm.css'
import {useDispatch, useSelector} from "react-redux";
import * as AWS from "../AWS";
import {CreateSubscription} from "../../rdx/actions";

const CardForm = forwardRef(( props,ref )=>
{
	const orgId = useSelector(state => state.__auth.orgId)
	const dispatch = useDispatch();

	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState(null);
	const [processing, setProcessing] = useState(false);
	const [disabled, setDisabled] = useState(true);
	// const [clientSecret, setClientSecret] = useState('');

	const stripe = useStripe();
	const [paymentRequest, setPaymentRequest] = useState(null);
	const elements = useElements();

	let card = null;
	useImperativeHandle(ref, () => ({
		async PayWithCard(){
			return await handleSubmit()
		}
	}));


	// useEffect(() => {
	// 	// console.log('çardformx',props,stripe)
	// });


	const createPaymentIntent = async() =>
	{
		try
		{
			const aws_lambda = new Lambda( {} );

			const aws_core = new AWS.Core( {} )
			const cur_usr = await aws_core.currentuser()
			const p_lambda =
			{
				actType: 'stripe',
				act: "stripe:payment-intent",
				c_id:orgId,
				alpha: {
					c_id: orgId,
				},

				data: {
					amount: props.amount,
					currency: 'GBP',
					label: props.label,
					fees:0,
					pr_n:props?.project?.tn,
					cp_n:props?.campaign?.tn,
					project:props?.project?.p?.t,
					campaign:props?.campaign?.ci?.t,
					type:'int',
				},
				usr: {
					...cur_usr.attributes,
					username: cur_usr.username,
				},

				payment_type: "card",
			};

			console.log("p_lambda : ", p_lambda);
			const resp_list = await aws_lambda.Invoke( p_lambda, Cfg.lambda[Cfg.stage].admin )
			console.log( 'pay/cardForm: createPaymentIntent: resp_list: ', resp_list )

			return resp_list.client_secret;
		}
		catch( err )
		{
			console.error( "createPaymentIntent : catch : err : ", err );
			return Promise.reject(err)
		}
	}

	const cardStyle = {
		iconStyle: 'solid',
		style: {
			base: {
				iconColor: '#ddd',
				color: '#000',
				fontWeight: 400,
				fontFamily: 'Open Sans,Roboto, Segoe UI, sans-serif',
				fontSize: '16px',
				fontSmoothing: 'antialiased',
				margin:'16px',
				height:40,
				backgroundColor:"#F5F5F5",
				boxShadow:"1px 1px 1px #000",

				':-webkit-autofill': {
				color: '#0C4AAC',
				},
				'::placeholder': {
				color: '#707070',
				},
			},
			invalid: {
				iconColor: '#dd0000',
				color: '#dd0000',
			},
		}
	};

	const handleChange = async (event) =>
	{
		setDisabled(event.empty);
		setError(event.error ? event.error.message : "");
	};

	const handleSubmit = async event =>
	{
		try {
			if (event)
			{
				event.preventDefault();

			}
			setProcessing(true);

			const paymentIntent = await createPaymentIntent();

			const paymentMethod = await stripe.createPaymentMethod({
				type: 'card',
				card: elements.getElement(CardElement)
			});

			// console.log(paymentMethod)

			// if( paymentMethod.paymentMethod.card.country !== "GB" )
			// {
			// 	setError(`Payment failed. Only GB cards are accepted.`);
			// 	setProcessing(false);
			// 	return { paid_status: "not-paid", payment_success: false}
			// }
			let paymentObj = null;

			if (props.isRecurring)
			{
				const {phone,name,addr,email} = props.cus
				const cus = {
					name:[name.f,name.l].join(' '),
					email:email,
					address:{
						city:addr.c,
						country:addr.cn,
						line1:addr.l1,
						line2:addr.l2,
						postalCode:addr.pc,
						state:addr.p
					},
					phone: [phone.cn, phone.n].join('')
				}

				const p_sub = {
					phone,
					email,
					cus,
					pack:props.package,
					paymentMethod:paymentMethod.paymentMethod.id
				}

				const {resp} = await dispatch(CreateSubscription(p_sub))
				const {status,latest_invoice,id,customer,plan} = resp.sub
				// console.log('latest_invoice',JSON.stringify(resp.sub))
				// console.log('val',status,latest_invoice.payment_intent.id,latest_invoice.payment_intent.status)
				if (status === 'active')
				{
					const obj = {
						s_i:id,
						ci:customer,
						prc_i:plan.id,
						donor_id:cus?.phone?.length>2?cus.phone:cus.email,
						pi:latest_invoice.payment_intent.id,
						st:latest_invoice.payment_intent.status
					}
					paymentObj = obj
					// props.onSuccess( { paid_status: "paid", payment_success: true, paymentObj:obj} );
					return { paid_status: "paid", payment_success: true, paymentObj}
				}
				else
				{
					if (latest_invoice.payment_intent.status === 'requires_action')
					{
						const payload = await stripe.confirmCardPayment(latest_invoice.payment_intent.client_secret);
						// console.log('payload',payload)
						if (payload.error)
						{
							setError(`Payment failed. ${payload.error.message}`);
							setProcessing(false);
							return { paid_status: "not-paid", payment_success: false}
						}
						else
						{
							const obj = {
								s_i:id,
								pi:payload.paymentIntent.id,
								st:payload.paymentIntent.status,
								ci:customer,
								prc_i:plan.id,
								donor_id:cus?.phone?.length>2?cus.phone:cus.email,
							}

							setError(null);
							setSucceeded(true);
							setProcessing(false);
							paymentObj = obj
							// props.onSuccess( { paid_status: "paid", payment_success: true, paymentObj:obj} );
							return { paid_status: "paid", payment_success: true, paymentObj}

						}
					}
					else {
						setError(`Payment failed. ${'jani na'}`);
						setProcessing(false);
						return { paid_status: "not-paid", payment_success: false}
					}

				}

			}
			else {
				const payload = await stripe.confirmCardPayment(paymentIntent, {
					payment_method: paymentMethod.paymentMethod.id
				});

				if (payload.error)
				{
					setError(`Payment failed. ${payload.error.message}`);
					setProcessing(false);
					return { paid_status: "not-paid", payment_success: false}
				}
				else
				{
					setError(null);
					setSucceeded(true);
					setProcessing(false);
					const obj = {
						pi:payload.paymentIntent.id,
						st:payload.paymentIntent.status
					}
					paymentObj = obj
					// props.onSuccess( { paid_status: "paid", payment_success: true, paymentObj:obj} );
					return { paid_status: "paid", payment_success: true, paymentObj}
				}
			}

		}catch (e) {
			console.warn(e)
			return Promise.reject(e)
		}
	};


	return (
		<>
			{
				paymentRequest &&
				<div style={{width: "95%", marginRight: "auto", marginLeft: "auto", marginBottom: 16}}>
					<PaymentRequestButtonElement options={{paymentRequest}}/>
				</div>
			}

			<div>
				Card info
			</div>

			<form /* onSubmit={ handleSubmit } */>
				<div className="cardWrapper">
					<CardElement id="card-element" options={ cardStyle } onChange={ handleChange } />
				</div>
				{ error && (
					<div
						style={{color:"#c94b4b",fontSize:18,padding:8,fontWeight:600}}
						className="card-error" role="alert">
						{ error }
					</div>
				) }

			</form>

		</>

	);

});

export default CardForm;
