/**
 * @copyright KARD
 */

import React from "react";

import { navigate } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";
import Page from "../_common/page";

import "./sms.css";

/**
 */
const SmsTemplateList = (props) => {
  const [isLoading, IsLoading] = React.useState(false);

  const [listTemp, ListTemp] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);

  //
  return (
    <Page active="sms">
      <div className="row">
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Address Book</a>
              </li>
            </ol>
          </nav>
        </div>
        <div className="col text-right">
          <p>Monday, 10 Augest 2021</p>
        </div>
      </div>

      <div className="row mt-2 mb-3">
        <div className="col">
          <h4 className="projectTitle m-0">
            <i className="fa fa-comment fa-lg" /> SMS Templates
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="row mt-3 ml-1 mr-1">
              <div className="col">
                <div className="form-group has-search">
                  <span className="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="col text-right">
                <button
                  className="btn btn btn-primary mr-1"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    navigate("/sms/template/edit");
                  }}
                >
                  <i className="fa fa-plus" /> Create Template
                </button>
              </div>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  {/* <th>Campaign</th> */}
                  {/* <th>Default</th> */}
                  <th>Created</th>
                  <th>Last Modified</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {listTemp.map((x) => (
                  <ListView key={[x.a, x.tn].join(":")} temp={x} />
                ))}
              </tbody>

              {/*
                            <tbody>
                                <tr>
                                    <td>Zakat_2021</td>
                                    <td>Greetings</td>
                                    <td>Zakat Fund</td>
                                    <td>No</td>
                                    <td>
                                        <a href="" className="btn btn-sm" ><i className="fa fa-edit"></i></a>
                                        <a href="" className="btn btn-sm"><i className="fa fa-trash"></i></a>
                                    </td> 
                                </tr>
                                <tr>
                                    <td>Zakat_2021</td>
                                    <td>Greetings</td>
                                    <td>Zakat Fund</td>
                                    <td>No</td>
                                    <td>
                                        <a href="" className="btn btn-sm" ><i className="fa fa-edit"></i></a>
                                        <a href="" className="btn btn-sm"><i className="fa fa-trash"></i></a>
                                    </td> 
                                </tr>
                                <tr>
                                    <td>Zakat_2021</td>
                                    <td>Greetings</td>
                                    <td>Zakat Fund</td>
                                    <td>No</td>
                                    <td>
                                        <a href="" className="btn btn-sm" ><i className="fa fa-edit"></i></a>
                                        <a href="" className="btn btn-sm"><i className="fa fa-trash"></i></a>
                                    </td> 
                                </tr>
                            </tbody>
                            */}

              <tfoot></tfoot>
            </table>

            <div className="row">
              <div className="col-sm-12 ml-3 mr-3">
                <div className="pull-left">
                  <div className="dataTables_length">
                    <label className="entries">
                      Show
                      <select size="1" className="form-control show-entries">
                        <option value="10" selected="selected">
                          10
                        </option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div>

                <div className="pull-right mr-5">
                  <ul className="pagination">
                    <li className="">
                      <a
                        href="#"
                        tabindex="0"
                        className="paginate_button previous"
                        id="DataTables_Table_0_previous"
                      >
                        Previous
                      </a>
                    </li>
                    <li className="active">
                      <a tabindex="0">1</a>
                    </li>
                    <li>
                      <a tabindex="0">2</a>
                    </li>
                    <li>
                      <a tabindex="0">3</a>
                    </li>
                    <li>
                      <a
                        href="#"
                        tabindex="0"
                        className="paginate_button next"
                        id="DataTables_Table_0_next"
                      >
                        Next
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}; // SmsTemplateList

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(SmsTemplateList);

/**
 */
const ListView = (props) => {
  const [dtNew, DtNew] = React.useState("");
  const [dtMod, DtMod] = React.useState("");

  /**
   */
  React.useEffect(() => {
    const dt_new = new Date(props.temp.tn * 1000);
    const dt_mod = new Date(props.temp.tm * 1000);

    DtNew(
      [dt_new.getDate(), dt_new.getMonth() + 1, dt_new.getFullYear()].join(".")
    );
    DtMod(
      [dt_mod.getDate(), dt_mod.getMonth() + 1, dt_mod.getFullYear()].join(".")
    );
  }, []);

  /**
   */
  return (
    <tr>
      <td>{props.temp.n}</td>
      <td>{props.temp.d}</td>
      {/* <td>Zakat Fund</td>
            <td>No</td> */}
      <td>{dtNew}</td>
      <td>{dtMod}</td>
      <td>
        <a
          className="btn btn-sm"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            navigate(`/email/template/edit/${props.temp.tn}`);
          }}
        >
          <i className="fa fa-edit"></i>
        </a>
        <a className="btn btn-sm" onClick={() => {}}>
          <i className="fa fa-trash"></i>
        </a>
      </td>
    </tr>
  );
}; // ListView
