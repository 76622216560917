/**
 * @copyright KARD
 */

import React, { useEffect, useReducer, useState } from "react";

import { connect, useDispatch } from "react-redux";
import * as actions from "../../rdx/actions";

import Page from "../_common/page";

import "../donation/donate.css";
import {
  EmailSubscribe,
  EmailUnsubscribe,
  GetDonorCampaignList,
  GetDonorDonationList,
  GetDonorProfile,
  GetDonorRecurringDonationList,
  GetDonorSubscriptionList,
} from "../../rdx/actions";
import { Time } from "../../api/Utils";

const INITIAL_STATE = {
  isLoading: false,
  donorInfo: {
    name: "Jhon Wick",
    phone: "",
    email: "",
    address: "",
  },
  donation: {
    list: [],
    hasMore: false,
    LastEvaluatedKey: null,
  },
  campaign: {
    list: [],
    hasMore: false,
    LastEvaluatedKey: null,
  },
  subscription: {
    list: [],
    hasMore: false,
    LastEvaluatedKey: null,
  },
  recurringDonation: {
    list: [],
    hasMore: false,
    LastEvaluatedKey: null,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "setLoading":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "setDonorInfo":
      return {
        ...state,
        donorInfo: {
          ...state.donorInfo,
          name: [
            action.payload?.n?.t,
            action.payload?.n?.f,
            action.payload?.n?.l,
          ].join(" "),
          phone: [action.payload?.p?.cn, action.payload?.p?.n].join(""),
          email: action.payload?.e,
          address: [
            action.payload?.a?.l1,
            action.payload?.a?.l2,
            action.payload?.a?.c,
            action.payload?.a?.pc,
            action.payload?.a?.cn,
          ].join(", "),
          img:
            action.payload?.img?.f ||
            "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        },
      };

    case "addDonation":
      return {
        ...state,
        donation: {
          ...state.donation,
          list: [...state.donation.list, ...action.payload],
        },
      };
    case "hasMoreDonation":
      return {
        ...state,
        donation: {
          ...state.donation,
          hasMore: action.payload.hasMore,
          LastEvaluatedKey: action.payload.LastEvaluatedKey,
        },
      };
    case "addRecurringDonation":
      return {
        ...state,
        recurringDonation: {
          ...state.recurringDonation,
          list: [...state.recurringDonation.list, ...action.payload],
        },
      };
    case "hasMoreRecurringDonation":
      return {
        ...state,
        recurringDonation: {
          ...state.recurringDonation,
          hasMore: action.payload.hasMore,
          LastEvaluatedKey: action.payload.LastEvaluatedKey,
        },
      };
    case "addCampaign":
      return {
        ...state,
        campaign: {
          ...state.campaign,
          list: [...state.campaign.list, ...action.payload],
        },
      };
    case "hasMoreCampaign":
      return {
        ...state,
        campaign: {
          ...state.campaign,
          hasMore: action.payload.hasMore,
          LastEvaluatedKey: action.payload.LastEvaluatedKey,
        },
      };
    case "addSubscription":
      return {
        ...state,
        subscription: {
          ...state.subscription,
          list: [...state.subscription.list, ...action.payload],
        },
      };
    case "hasMoreSubscription":
      return {
        ...state,
        subscription: {
          ...state.subscription,
          hasMore: action.payload.hasMore,
          LastEvaluatedKey: action.payload.LastEvaluatedKey,
        },
      };
    default:
      throw new Error("Action Type Not Support.");
  }
}
/**
 */
const DonorView = (props) => {
  const rdx = useDispatch();
  const { donor_id } = props;
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  function Loading(val) {
    dispatch({ type: "setLoading", payload: val });
  }
  // console.log(state);
  async function getDonorInfo(donorId) {
    try {
      const donorInfo = await rdx(GetDonorProfile(donorId));
      // const donorCampaignList = await rdx(GetDonorCampaignList(donorId));
      console.log(donorInfo);
      if (!donorInfo) {
        throw new Error("Donor not found.");
      }
      dispatch({ type: "setDonorInfo", payload: donorInfo });
      return donorInfo;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async function getCampaignList(donorId) {
    try {
      const donorCampaignList = await rdx(GetDonorCampaignList(donorId));
      console.log(donorCampaignList);
      if (!donorCampaignList.Items) {
        throw new Error("Campaign not found.");
      }
      dispatch({ type: "addCampaign", payload: donorCampaignList.Items });
      if (donorCampaignList.LastEvaluatedKey) {
        dispatch({
          type: "hasMoreCampaign",
          payload: {
            hasMore: true,
            LastEvaluatedKey: donorCampaignList.LastEvaluatedKey,
          },
        });
      }
      return donorCampaignList;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async function getDonationList(phoneNo) {
    try {
      const donationList = await rdx(GetDonorDonationList(phoneNo));
      if (!donationList.Items) {
        throw new Error("Donation not found");
      }
      dispatch({ type: "addDonation", payload: donationList.Items });
      if (donationList.LastEvaluatedKey) {
        dispatch({
          type: "hasMoreDonation",
          payload: {
            hasMore: true,
            LastEvaluatedKey: donationList.LastEvaluatedKey,
          },
        });
      }
      return donationList;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async function getRecurringDonationList(phoneNo) {
    try {
      const donationList = await rdx(GetDonorRecurringDonationList(phoneNo));
      if (!donationList.Items) {
        throw new Error("Donation not found");
      }
      dispatch({ type: "addRecurringDonation", payload: donationList.Items });
      if (donationList.LastEvaluatedKey) {
        dispatch({
          type: "hasMoreRecurringDonation",
          payload: {
            hasMore: true,
            LastEvaluatedKey: donationList.LastEvaluatedKey,
          },
        });
      }
      return donationList;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async function getSubscriptionList(email) {
    try {
      const subscriptionList = await rdx(GetDonorSubscriptionList(email));
      if (!subscriptionList.Items) {
        throw new Error("No Subscription found.");
      }
      dispatch({ type: "addSubscription", payload: subscriptionList.Items });
      if (subscriptionList.LastEvaluatedKey) {
        dispatch({
          type: "hasMoreSubscription",
          payload: {
            hasMore: true,
            LastEvaluatedKey: subscriptionList.LastEvaluatedKey,
          },
        });
      }
      return subscriptionList;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async function Sub(p) {
    Loading(true);
    const param = {
      email: p.i,
      topic: p.tp,
      pr_t: p.pr_t,
      cp_t: p.cp_t,
    };
    await rdx(EmailSubscribe(param));
    await getSubscriptionList(p.i);
    Loading(false);
  }
  async function Unsub(p) {
    Loading(true);
    const param = {
      email: p.i,
      topic: p.tp,
    };
    await rdx(EmailUnsubscribe(param));
    await getSubscriptionList(p.i);
    Loading(false);
  }

  useEffect(() => {
    if (donor_id) {
      Loading(true);
      getDonorInfo(donor_id)
        .then((donorInfo) => {
          Loading(false);
          console.log(donorInfo);
          getCampaignList(donor_id).then().catch();
          getRecurringDonationList(donor_id).then().catch();
        })
        .catch(() => {
          Loading(false);
        });
    }
  }, [donor_id]);
  useEffect(() => {
    if (state.donorInfo.phone.length > 3) {
      getDonationList(state.donorInfo.phone).then().catch();
    }
  }, [state?.donorInfo?.phone]);
  useEffect(() => {
    if (state.donorInfo.email.length > 3) {
      // console.log(state.donorInfo.email);
      getSubscriptionList(state.donorInfo.email).then().catch();
    }
  }, [state?.donorInfo?.email]);
  console.log("state", state);

  return (
    <Page active="address">
      <div className="row">
        <div className="col-sm-6"></div>
      </div>

      <div className="row mt-2 mb-3">
        <div className="col-sm-12">
          <h4 className="projectTitle m-0">
            <img
              style={{ height: 20, width: 20, marginRight: 8 }}
              src={require("../../components/assets/icons/sideBar/addressBook.png")}
            />{" "}
            Donor Profile
          </h4>
        </div>
      </div>
      <div className="col">
        <div className="profile-header">
          <div className="col-md-12">
            <div className="text-center">
              <img
                className="rounded-circle"
                src={state.donorInfo.img}
                alt="image"
                width="150"
              />
              <h3 className="user-name mt-3 mb-0">{state.donorInfo.name}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-sm-7">
          <ul
            className="personal-info px-3 py-3"
            style={{
              boxShadow: "0px 8px 24px rgb(0 0 0 / 10%)",
              height: "100%",
            }}
          >
            <li>
              <span className="title" style={{ float: "none" }}>
                Phone:
              </span>
              <span className="text" style={{ fontSize: 18 }}>
                <a href={["tel", state.donorInfo.phone].join(":")}>
                  {state.donorInfo.phone}
                </a>
              </span>
            </li>
            <li>
              <span className="title" style={{ float: "none" }}>
                Email:
              </span>
              <span className="text" style={{ fontSize: 18 }}>
                <a href={["mailto", state.donorInfo.email].join(":")}>
                  {state.donorInfo.email}
                </a>
              </span>
            </li>
            <li>
              <span className="title" style={{ float: "none" }}>
                Address:
              </span>
              <span className="text" style={{ fontSize: 18 }}>
                {state.donorInfo.address}
              </span>
            </li>
          </ul>
        </div>
        <div className="col-sm-5">
          <div
            className="table-responsive px-1 py-1"
            style={{
              boxShadow: "0px 8px 24px rgb(0 0 0 / 10%)",
              height: "100%",
            }}
          >
            <table className="table" style={{ border: 0 }}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Project</th>
                  <th>Campaign</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {state.donation.list.map((x) => (
                  <tr key={x.tn}>
                    <td>{Time.Epoch2Date(x.tn)}</td>
                    <td>{x?.pi?.t}</td>
                    <td>{x?.ci?.t}</td>
                    <td> £{(x.k.a / 100).toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-sm-12 mt-4">
          <div
            className="table-responsive"
            style={{ boxShadow: "0px 8px 24px rgb(0 0 0 / 10%)" }}
          >
            <table className="table" style={{ border: 0 }}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Campaign Name</th>
                  <th>Project Name</th>
                  <th>Target</th>
                  <th>Raised</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {state.campaign.list.map((x) => (
                  <tr key={x.tn}>
                    <td>{Time.Epoch2Date(x.tn)}</td>
                    <td>{x?.t}</td>
                    <td>{x?.ci?.t}</td>
                    <td> £{(x.a.t / 100).toFixed(2)}</td>
                    <td> £{(x.a.r / 100).toFixed(2)}</td>
                    <th>
                      <span className="btn btn-primary mr-2">
                        <i className="fa fa-edit" />
                      </span>
                      <span className="btn btn-danger">
                        <i className="fa fa-trash text-white" />
                      </span>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-sm-12 mt-4">
          <div
            className="table-responsive"
            style={{ boxShadow: "0px 8px 24px rgb(0 0 0 / 10%)" }}
          >
            <table className="table" style={{ border: 0 }}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Project</th>
                  <th>Campaign</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {state.subscription.list.map((x) => (
                  <tr key={x.tn}>
                    <td>{Time.Epoch2Date(x.tn)}</td>
                    <td>{x?.pr_t}</td>
                    <td>{x?.cp_t}</td>
                    <td>{x?.i}</td>
                    {x?.b ? (
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => Unsub(x)}
                      >
                        UnSubscribe
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary btn-sm mr-1"
                        onClick={() => Sub(x)}
                      >
                        Subscribe
                      </button>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col">
          <div className="profile-header">
            <div className="col-md-12">
              <div className="text-center">
                <img
                  className="rounded-circle"
                  src={state.donorInfo.img}
                  alt="image"
                  width="150"
                />
                <h3 className="user-name mt-3 mb-0">{state.donorInfo.name}</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12">
          <div className="card-box">
            <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  href="#solid-rounded-justified-tab1"
                  data-toggle="tab"
                >
                  Info
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#solid-rounded-justified-tab2"
                  data-toggle="tab"
                >
                  Donate History
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#solid-rounded-justified-tab3"
                  data-toggle="tab"
                >
                  My Campaigns
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#solid-rounded-justified-tab4"
                  data-toggle="tab"
                >
                  My Subscriptions
                </a>
              </li>
            </ul>
            <div className="tab-content mt-3">
              <div
                className="tab-pane show active"
                id="solid-rounded-justified-tab1"
              >
                <ul className="personal-info">
                  <li>
                    <span className="title">Phone:</span>
                    <span className="text">
                      <a href={["tel", state.donorInfo.phone].join(":")}>
                        {state.donorInfo.phone}
                      </a>
                    </span>
                  </li>
                  <li>
                    <span className="title">Email:</span>
                    <span className="text">
                      <a href={["mailto", state.donorInfo.email].join(":")}>
                        {state.donorInfo.email}
                      </a>
                    </span>
                  </li>
                  <li>
                    <span className="title">Address:</span>
                    <span className="text">{state.donorInfo.address}</span>
                  </li>
                </ul>
                <button className="btn btn-primary btn-lg">Edit</button>
              </div>
              <div className="tab-pane" id="solid-rounded-justified-tab2">
                <div className="card">
                  <div className="table-responsive">
                    <table className="table" style={{ border: 0 }}>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Project</th>
                          <th>Campaign</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.donation.list.map((x) => (
                          <tr key={x.tn}>
                            <td>{Time.Epoch2Date(x.tn)}</td>
                            <td>{x?.pi?.t}</td>
                            <td>{x?.ci?.t}</td>
                            <td> £{(x.k.a / 100).toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="solid-rounded-justified-tab3">
                <div className="card">
                  <div className="table-responsive">
                    <table className="table" style={{ border: 0 }}>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Campaign Name</th>
                          <th>Target</th>
                          <th>Raised</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.campaign.list.map((x) => (
                          <tr key={x.tn}>
                            <td>{Time.Epoch2Date(x.tn)}</td>
                            <td>{x?.t}</td>
                            <td>{x?.ci?.t}</td>
                            <td> £{(x.a.t / 100).toFixed(2)}</td>
                            <td> £{(x.a.r / 100).toFixed(2)}</td>
                            <th>
                              <span className="btn btn-primary mr-2">
                                <i className="fa fa-edit" />
                              </span>
                              <span className="btn btn-danger">
                                <i className="fa fa-trash text-white" />
                              </span>
                            </th>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="solid-rounded-justified-tab4">
                <div className="card">
                  <div className="table-responsive">
                    <table className="table" style={{ border: 0 }}>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Project</th>
                          <th>Campaign</th>
                          <th>Email</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.subscription.list.map((x) => (
                          <tr key={x.tn}>
                            <td>{Time.Epoch2Date(x.tn)}</td>
                            <td>{x?.pr_t}</td>
                            <td>{x?.cp_t}</td>
                            <td>{x?.i}</td>
                            {x?.b ? (
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => Unsub(x)}
                              >
                                UnSubscribe
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary btn-sm mr-1"
                                onClick={() => Sub(x)}
                              >
                                Subscribe
                              </button>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Page>
  );
}; // DonorView

/**
 */
export default DonorView;
