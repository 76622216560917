/**
 *	@copyright	KARD.
 */

import {combineReducers} from 'redux'
import { persistReducer } from 'redux-persist'

import storage from 'redux-persist/lib/storage'

import Cfg from './Cfg'
import Auth from './Auth'

import Campaign from './Campaign'

import Staffs from './Staffs'
import Settings from './Settings'
import Tmp from './Tmp'
import Charity from "./Charity";
import Donation from "./Donation";

/**
 */
const persist_cfg = {
    key: 'root',
    storage,

    whitelist: [
            // '__cfg',
            '__auth',
            '__sett'
        ],
}

/**
 */
const rdx_reducers = combineReducers( {
    __cfg: Cfg,
    __auth: Auth,
    __camp: Campaign,
    __staffs: Staffs,
    __sett: Settings,
    __charity: Charity,
    __donation:Donation,
    __Tmp: Tmp,
} )

/**
 */
export default persistReducer( persist_cfg, rdx_reducers )
// export default rdx_reduers
