/**
 * @copyright KARD
 */

import React from "react";

import { navigate, Link } from "@reach/router";
import { LinearProgress } from "@material-ui/core";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

import AuiModal from "../../aui/modal";

import Page from "../_common/page";

/**
 */
const AddressList = (props) => {
  const [isModal_Filter, IsModal_Filter] = React.useState(false);
  const [isLoading, IsLoading] = React.useState(false);

  const [list, List] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);

  const [isFilter, IsFilter] = React.useState(false);
  const [filterObj, FilterObj] = React.useState({
    //
  });

  //
  React.useEffect(() => {
    Load(null).catch((err) => {});
  }, []);

  //
  const Load = async (b_more) => {
    try {
      IsLoading(true);

      const resp_list = await props.Donor_List(b_more ? lastKey : null);

      console.log("AddressList: Load: resp_list: ", resp_list);

      List(b_more ? [...list, ...resp_list.Items] : resp_list.Items);
      LastKey(resp_list.LastEvaluatedKey);

      IsLoading(false);

      return {};
    } catch (err) {
      console.warn("ProjectList: Load: err: ", err);

      IsLoading(false);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // Load

  //
  return (
    <Page active="address">
      {/* <div className="row">
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Address Book</a>
              </li>
            </ol>
          </nav>
        </div>
        <div className="col text-right">
          <p>Monday, 10 Augest 2021</p>
        </div>
      </div> */}
      <div className="row mt-2 mb-3">
        <div className="col">
          <h4 className="projectTitle m-0">
            <img
              style={{ height: 20, width: 20, marginRight: 8 }}
              src={require("../../components/assets/icons/sideBar/active/addressBook.png")}
            />{" "}
            Address Book
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="row my-3 ml-1 mr-1 mb-3">
              <div className="col-sm-4 col">
                {/* <div className="form-group has-search">
                  <span className="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                </div> */}
                <button
                  className="btn btn btn-outline-primary mr-1 px-4"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    // navigate("/donation/int/new");
                    IsModal_Filter(true);
                  }}
                >
                  <i className="fa fa-filter" />{" "}
                  <a href="" className="btn-filter">
                    {isFilter ? "Filter: In Use" : "Filter: None Selected"}
                  </a>
                </button>

                <>
                  {isModal_Filter && (
                    <AuiModal
                      bOpen={isModal_Filter}
                      OnClose={() => IsModal_Filter(false)}
                    >
                      <FilterView
                        filter={filterObj}
                        OnFilter={(val) => {
                          FilterObj({ ...val });
                          IsFilter(Object.keys(val).length ? true : false);
                          // IsModal_Filter(false)
                        }}
                        OnClose={() => IsModal_Filter(false)}
                      />
                    </AuiModal>
                  )}
                </>
              </div>
              <div className="col-sm-8 col text-right">
                {/* <button
                  className="btn btn btn-primary mr-1"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  data-whatever="@getbootstrap"
                >
                  <i className="fa fa-plus" /> Add New Person
                </button>
                <button className="btn btn btn-primary "> Upload Bulk</button> */}
              </div>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Contact No</th>
                    <th>Email</th>
                    <th style={{ minWidth: 150 }}>Address Line 1</th>
                    <th>Postcode</th>
                    <th>Source</th>
                    <th style={{ minWidth: 130 }}>Action</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    <LinearProgress color="primary" />
                    <LinearProgress color="success" />
                    <LinearProgress color="inherit" />
                  </div>
                ) : null}
                <tbody>
                  {list.map((x) => (
                    <ListView key={[x.i, x.u].join(":")} donr={x} />
                  ))}
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col-sm-12 ml-3 mr-3 mt-3">
                <div className="pull-left">
                  <tfoot>
                    {lastKey ? (
                      <button
                        className="btn btn-info"
                        onClick={() => {
                          Load(lastKey).catch((err) => {});
                        }}
                        disabled={isLoading}
                      >
                        Show More
                      </button>
                    ) : null}
                  </tfoot>
                </div>

                <div className="pull-right mr-5">
                  {/* <ul className="pagination">
                    <li className="">
                      <a
                        href="#"
                        tabindex="0"
                        className="paginate_button previous"
                        id="DataTables_Table_0_previous"
                      >
                        Previous
                      </a>
                    </li>
                    <li className="active">
                      <a tabindex="0">1</a>
                    </li>
                    <li>
                      <a tabindex="0">2</a>
                    </li>
                    <li>
                      <a tabindex="0">3</a>
                    </li>
                    <li>
                      <a
                        href="#"
                        tabindex="0"
                        className="paginate_button next"
                        id="DataTables_Table_0_next"
                      >
                        Next
                      </a>
                    </li>
                  </ul> */}
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal start */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <NewPerson />
        </div>
      </div>
      {/* End Modal */}
    </Page>
  );
}; // AddressList

/**
 */
const ListView = (props) => {
  const [name, Name] = React.useState("");
  const [phone, Phone] = React.useState("");
  const [email, Email] = React.useState("");
  const [addr, Addr] = React.useState("");
  const [postcode, Postcode] = React.useState("");
  const [source, Source] = React.useState("");

  /**
   */
  React.useEffect(() => {
    try {
      Name(
        [
          props.donr.n.t.charAt(0).toUpperCase() + props.donr.n.t.slice(1),
          props.donr.n.f,
          props.donr.n.l,
        ].join(" ")
      );
    } catch (err) {}

    try {
      Phone([props.donr.p.cn, props.donr.p.n].join(""));
    } catch (err) {}

    try {
      Email(props.donr.e);
    } catch (err) {}

    try {
      Addr([props.donr.a.l1, props.donr.a.l2, props.donr.a.c].join(" "));
    } catch (err) {}

    try {
      Postcode(props.donr.a.pc);
    } catch (err) {}

    try {
      if (!props.donr.sr || "d" === props.donr.sr) {
        Source("Donor");
      } else {
        Source("N/A");
      }
    } catch (err) {}
  }, []);

  /**
   */
  return (
    <tr>
      <td>
        <Link to={`/address/view/${props.donr.u}`}>{name}</Link>
      </td>
      <td>{phone}</td>
      <td>{email}</td>
      <td>{addr}</td>
      <td>{postcode}</td>
      <td>{source}</td>
      <td>
        <a href="" className="btn btn-primary btn-sm mr-1">
          <i className="fa fa-edit text-white"></i>
        </a>
        <a href="" className="btn btn-danger btn-sm">
          <i className="fa fa-trash text-white"></i>
        </a>
      </td>
    </tr>
  );
}; // ListView

/**
 */
const NewPerson = (props) => {
  // const OnClose = () =>
  // {
  //     //
  // }

  //
  const OnSubmit = () => {
    //
  }; // OnSubmit

  //
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title projectTitle" id="exampleModalLabel">
          Add New Donor
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <form>
          <div className="form-group">
            <label for="recipient-name" className="col-form-label">
              Name:
            </label>
            <input
              type="text"
              className="form-control"
              id="recipient-name"
              placeholder="Hola"
            />
          </div>
          <div className="form-group">
            <label for="address_line" className="col-form-label">
              Address Line 1:
            </label>
            <input
              type="text"
              className="form-control"
              id="address_line"
              placeholder="221 Beker Street"
            />
          </div>
          <div className="form-row mt-2 mb-2">
            <div className="col-md-6">
              <label for="contact" className="col-form-label">
                Contact No
              </label>
              <input
                type="text"
                className="form-control"
                id="contact"
                placeholder="01578988745"
              />
            </div>
            <div className="col-md-6">
              <label for="post" className="col-form-label">
                Post Code
              </label>
              <input
                type="text"
                className="form-control"
                id="post"
                placeholder="0X44 GP"
              />
            </div>
          </div>
          <div className="form-group">
            <label for="email" className="col-form-label">
              Email Address
            </label>
            <input
              type="text"
              className="form-control"
              id="email"
              placeholder="adam@example.com"
            />
          </div>
          <div className="form-group">
            <label for="source" className="col-form-label">
              Source
            </label>
            <input
              type="text"
              className="form-control"
              id="source"
              placeholder="Your Source"
            />
          </div>
        </form>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-outline-dark"
          data-dismiss="modal"
        >
          Close
        </button>
        <button type="button" className="btn btn-primary" onClick={OnSubmit}>
          Save
        </button>
      </div>
    </div>
  );
}; // NewPerson

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(AddressList);

/**
 */
const FilterView = (props) => {
  const [dateSt, DateSt] = React.useState(props?.filter?.dateSt);
  const [dateEnd, DateEnd] = React.useState(props?.filter?.dateEnd);
  const [nameF, NameF] = React.useState(props?.filter?.nameF);
  const [nameL, NameL] = React.useState(props?.filter?.nameL);

  const [phone, Phone] = React.useState(props?.filter?.phone);
  const [email, Email] = React.useState(props?.filter?.email);

  const [addrCity, AddrCity] = React.useState(props?.filter?.addrCity);
  // const [addrCn, AddrCn] = React.useState( props?.filter?.addrCn )

  const [source, Source] = React.useState(props?.filter?.source);

  /**
   */
  const OnClose = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.OnClose();
  }; // OnClose

  /**
   */
  const OnClearAll = (e) => {
    e.preventDefault();
    e.stopPropagation();

    DateSt(null);
    DateEnd(null);
    NameF(null);
    NameL(null);
    AddrCity(null);

    /// @note need to call save
  }; // OnClearAll

  /**
   */
  const OnSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const name_f = nameF ? nameF.trim() : "";
    const name_l = nameL ? nameL.trim() : "";
    const addr_city = addrCity ? addrCity.trim() : "";
    // const addr_cn = addrCn ? addrCn.trim() : ''

    props.OnFilter({
      dateSt,
      dateEnd,
      nameF: name_f.length ? name_f : null,
      nameL: name_l.length ? name_l : null,
      addrCity: addr_city.length ? addr_city : null, // addrCn: addr_cn.length ? addr_cn : null,
    });
    props.OnClose();
  }; // OnSave

  /**
   */
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6">
          <label className="projectTitle">First Name</label>
          <input
            className="form-control"
            value={nameF}
            onChange={(e) => NameF(e.target.value)}
          />
        </div>
        <div className="col-sm-6">
          <label className="projectTitle">Last Name</label>
          <input
            className="form-control"
            value={nameL}
            onChange={(e) => NameL(e.target.value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6">
          <label className="projectTitle">Phone</label>
          <input
            className="form-control"
            value={phone}
            onChange={(e) => Phone(e.target.value)}
          />
        </div>

        <div className="col-sm-6">
          <label className="projectTitle">Email</label>
          <input
            className="form-control"
            value={email}
            onChange={(e) => Email(e.target.value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6">
          <label className="projectTitle">City</label>
          <input
            className="form-control"
            value={addrCity}
            onChange={(e) => AddrCity(e.target.value)}
          />
        </div>

        <div className="col-sm-6">
          <label className="projectTitle">Source</label>
          <select
            className="form-control"
            value={source}
            onChange={(e) => Source(e.target.value)}
          >
            <option>Select</option>
            <option value="donor">Donor</option>
            {/* <option>Manual</option> */}
          </select>
        </div>
      </div>
      <div className="col-sm-12 btn-group mt-4 p-0">
        <button className="btn btn-info rounded mr-3 py-3" onClick={OnClose}>
          Close
        </button>

        <button className="btn btn-info rounded mr-3" onClick={OnClearAll}>
          Clear All
        </button>

        <button className="btn btn-info rounded mr-1" onClick={OnSave}>
          Save
        </button>
      </div>
    </div>
  );
}; // FilterView
