/**
 *	@copyright	KARD.
 */

/**
 */
export default ( state = {
    stage: 'test',      // live / test

    isAvail_Card: false,
    isAvail_Bank: false,
    isAvail_Cash: false,
    isAvail_Cheque: false,

    pm: {
        str: null,
        gc: null,
        pp: null,
    },

    domain: 'localhost:3000',

    _last: 0,
}, action ) =>
{
    switch( action.type )
    {
    case 'settings:stage':
        return {...state, stage: action.payload,}

    default:
        return state
    }   // switch ...
}   // export






