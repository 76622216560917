/**
 *	@copyright	KARD.
 */

import * as Cfg from '../../_cfg'
import {Core, Lambda} from '../../api/AWS'
import {ProcessContent} from "./storage";

/**
 */
export const News_List = ( {last_key, dt_st, dt_end, pr_n, cp_n} ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'news',
                act: 'news:list',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {last_key, dt_st, dt_end, pr_n, cp_n},
            }

            console.log( 'actions/project: News_List: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/project: News_List: resp_list: ', resp_list )

            //
            return resp_list
        }
        catch( err )
        {
            console.warn( 'actions/news: News_List: err: ', err )

            return Promise.reject( err )
        }
    }
}   // News_List

/**
 */
export const News_Details = ( {last_key, dt_st, dt_end, pr_n, cp_n} ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_details = {
                actType: 'news',
                act: 'news:details',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {last_key, dt_st, dt_end, pr_n, cp_n},
            }

            console.log( 'actions/project: News_Details: p_details: ', p_details )

            const resp_details = await aws_lambda.Invoke( p_details, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/project: News_Details: resp_details: ', resp_details )

            //
            return resp_details
        }
        catch( err )
        {
            console.warn( 'actions/news: News_Details: err: ', err )

            return Promise.reject( err )
        }
    }
}   // News_Details

/**
 */
export const News_Add = ( news_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_new = {
                actType: 'news',
                act: 'news:new',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    title: news_obj?.title,
                    snippet: news_obj?.snippet,
                    url_dt: news_obj?.url_dt,
                    status: news_obj?.status,
                    feat: news_obj?.feat,
                    pr_n: news_obj?.pr_n,
                    pr_t: news_obj?.pr_t,
                    cp_n: news_obj?.cp_n,
                    cp_t: news_obj?.cp_t,
                    ld:[],
                },
            }

            // console.log( 'actions/news: News_Add: p_new: ', p_new )

            const resp_new = await aws_lambda.Invoke( p_new, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/news: News_Add: resp_new: ', resp_new )

            news_obj['ni'] = resp_new.Item.ni
            news_obj['i'] = resp_new.Item.i

            const {success,news} = await d(UpdateContent(news_obj))
            if (success)
            {
                return {Item:news}
            }


            return resp_new
        }
        catch( err )
        {
            console.warn( 'actions/news: News_Add: err: ', err )

            return Promise.reject( err )
        }
    }
}   // News_Add

/**
 */
export const News_SaveImage = (params) =>
{
    return async (d,gs)=> {
        try {
            const aws_lambda = new Lambda({})
            const aws_core = new Core({})
            const cur_usr = await aws_core.currentuser()
            const p_list = {
                actType: 'news',
                act: 'news:img-update',
                c_id: gs().__auth.orgId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                   url:params.url,
                   ni:params.ni
                },
            }

            console.log( 'actions/news: News_SaveImage: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/news: News_SaveImage: resp_list: ', resp_list )

            //
            return resp_list

        } catch (e) {
            console.warn("actions/news: News_SaveImage:error: ", e)
            return Promise.reject(e);
        }
    }
}   // News_SaveImage

/**
 */
export const News_Edit = ( news_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()
            news_obj['ct']={listDesc:news_obj.listDesc}
            news_obj.ct = await d(ProcessContent(news_obj));
            console.log("news_obj",news_obj)
            const p_new = {
                actType: 'news',
                act: 'news:edit',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    title: news_obj?.title,
                    snippet: news_obj?.snippet,
                    url_dt: news_obj?.url_dt,
                    status: news_obj?.status,
                    feat: news_obj?.feat,
                    pr_n: news_obj?.pr_n,
                    pr_t: news_obj?.pr_t,
                    cp_n: news_obj?.cp_n,
                    cp_t: news_obj?.cp_t,
                    ld:news_obj.ct.listDesc,
                    url:news_obj.url,
                    ni:news_obj.ni
                },
            }

            console.log( 'actions/news: News_Edit: p_new: ', p_new )

            const resp_new = await aws_lambda.Invoke( p_new, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/news: News_Edit: resp_new: ', resp_new )

            //
            return resp_new
        }
        catch( err )
        {
            console.warn( 'actions/news: News_Edit: err: ', err )

            return Promise.reject( err )
        }
    }
}   // News_Edit

/**
 */
export const News_Delete = ( news_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_new = {
                actType: 'news',
                act: 'news:del',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    ...news_obj,
                },
            }

            // console.log( 'actions/news: News_Delete: p_new: ', p_new )

            const resp_new = await aws_lambda.Invoke( p_new, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/news: News_Delete: resp_new: ', resp_new )

            //
            return resp_new
        }
        catch( err )
        {
            console.warn( 'actions/news: News_Delete: err: ', err )

            return Promise.reject( err )
        }
    }
}   // News_Delete

/**
 */
export const News_Get = ( n_id ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_new = {
                actType: 'news',
                act: 'news:get',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    ni:n_id
                },
            }

            console.log( 'actions/news: News_Get: p_new: ', p_new )

            const resp_new = await aws_lambda.Invoke( p_new, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/news: News_Get: resp_new: ', resp_new )

            //
            return resp_new
        }
        catch( err )
        {
            console.warn( 'actions/news: News_Get: err: ', err )

            return Promise.reject( err )
        }
    }
}   // News_Get



const UpdateContent = (params) =>
{
    return async (d, gs)=>{
        try {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()
            if (!cur_usr)
            {
                throw new Error("User not found!")
            }
            params['ct']={listDesc:params.listDesc}
            // console.log("jlfglfhgfhgjkl",params)
            params.ct = await d(ProcessContent(params));
            // console.log(params)

            const upd_p = {
                actType: 'news',
                act: 'news:content-update',
                c_id: gs().__auth.orgId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data:{
                    ni:params.ni,
                    i:params.i,
                    ld:params.ct.listDesc,
                }
            }

            // console.log( 'actions/campaign: UpdateContent: p_list: ', upd_p )

            const {success, news} = await aws_lambda.Invoke( upd_p, Cfg.lambda[Cfg.stage].admin )
            console.log( 'actions/campaign: UpdateContent: resp_new: ', news )
            if (!success)
            {
                return success
            }
            return {success,news:news.data.Attributes}

        }catch (e) {
            console.warn(e);
            return Promise.reject(e);
        }
    }
}
