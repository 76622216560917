/**
 *	@copyright	KARD.
 */

import * as Cfg from '../../_cfg'
import {Core, Lambda} from '../../api/AWS'

export const Comm_Log = ( comm_type, last_key) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const p_subs = {
                actType: 'comm',
                act: 'email' === comm_type ? 'comm:email:log' : 'comm:sms:log',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                data: { last_key, },
            }

            // console.log( 'actions/comm: Comm_EmailSubscribe: p_subs: ', p_subs )

            const resp_subs = await aws_lambda.Invoke( p_subs, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/comm: Comm_EmailSubscribe: resp_subs: ', resp_subs )

            //
            return resp_subs
        }
        catch( err )
        {
            console.warn( 'actions/comm: Comm_EmailSubscribe: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Comm_Log

/**
 */
export const Comm_EmailSubscribe = ( email, topc ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const p_subs = {
                actType: 'comm',
                act: 'comm:email:subs',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                data: {
                    o_id: gs().__auth.orgId,
                    email,
                    topc,
                },
            }

            // console.log( 'actions/comm: Comm_EmailSubscribe: p_subs: ', p_subs )

            const resp_subs = await aws_lambda.Invoke( p_subs, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/comm: Comm_EmailSubscribe: resp_subs: ', resp_subs )

            //
            return resp_subs
        }
        catch( err )
        {
            console.warn( 'actions/comm: Comm_EmailSubscribe: err: ', err )
            console.warn( 'actions/comm: Comm_EmailSubscribe: email: ', email )
            console.warn( 'actions/comm: Comm_EmailSubscribe: topc: ', topc )

            return Promise.reject( err )
        }
    }
}   // Comm_EmailSubscribe

/**
 */
export const Comm_EmailUnsubscribe = ( email, topc ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const p_unsubs = {
                actType: 'comm',
                act: 'comm:email:unsubs',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                data: {
                    // last_key
                    // o_ic: gs().__auth.orgId,
                    email,
                    topc,
                },
            }

            // console.log( 'actions/comm: Comm_EmailUnsubscribe: p_unsubs: ', p_unsubs )

            const resp_unsubs = await aws_lambda.Invoke( p_unsubs, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/comm: Comm_EmailUnsubscribe: resp_unsubs: ', resp_unsubs )

            //
            return resp_unsubs
        }
        catch( err )
        {
            console.warn( 'actions/comm: Comm_EmailUnsubscribe: err: ', err )
            console.warn( 'actions/comm: Comm_EmailUnsubscribe: email: ', email )
            console.warn( 'actions/comm: Comm_EmailUnsubscribe: topc: ', topc )

            return Promise.reject( err )
        }
    }
}   // Comm_EmailUnsubscribe

/**
 */
export const Comm_SmsSubscribe = ( p_no, topc ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const p_subs = {
                actType: 'comm',
                act: 'comm:sms:subs',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                data: {
                    // last_key
                    o_id: gs().__auth.orgId,
                    p_no,
                    topc,
                },
            }

            // console.log( 'actions/comm: Comm_SmsSubscribe: p_subs: ', p_subs )

            const resp_subs = await aws_lambda.Invoke( p_subs, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/comm: Comm_SmsSubscribe: resp_subs: ', resp_subs )

            //
            return resp_subs
        }
        catch( err )
        {
            console.warn( 'actions/comm: Comm_SmsSubscribe: err: ', err )
            console.warn( 'actions/comm: Comm_SmsSubscribe: p_no: ', p_no )
            console.warn( 'actions/comm: Comm_SmsSubscribe: topc: ', topc )

            return Promise.reject( err )
        }
    }
}   // Comm_SmsSubscribe

/**
 */
export const Comm_SmsUnsubscribe = ( p_no, topc ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const p_unsubs = {
                actType: 'comm',
                act: 'comm:sms:unsubs',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                data: {
                    p_no,
                    topc,
                },
            }

            // console.log( 'actions/comm: Comm_SmsUnsubscribe: p_unsubs: ', p_unsubs )

            const resp_unsubs = await aws_lambda.Invoke( p_unsubs, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/comm: Comm_SmsUnsubscribe: resp_unsubs: ', resp_unsubs )

            //
            return resp_unsubs
        }
        catch( err )
        {
            console.warn( 'actions/comm: Comm_SmsUnsubscribe: err: ', err )
            console.warn( 'actions/comm: Comm_SmsUnsubscribe: p_no: ', p_no )
            console.warn( 'actions/comm: Comm_SmsUnsubscribe: topc: ', topc )

            return Promise.reject( err )
        }
    }
}   // Comm_SmsUnsubscribe

export const Comm_SendRowEmail = (params)=>
{
    return async (d, gs)=>{
        try {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()


            const p_email = {
                actType: 'email',
                act: 'email:raw',
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    email_to:params.to,
                    email_from:params.from,
                    msg_sub:params.sub,
                    msg_body:params.body
                },
            }

            console.log( 'actions/comm: Comm_SendRowEmail:  p_email: ', p_email )

            const resp_email = await aws_lambda.Invoke( p_email, Cfg.lambda[Cfg.stage].comm)

            console.log( 'actions/comm: Comm_SendRowEmail:  p_email: ', resp_email )
        }catch (e) {
            console.warn('actions/comm: Comm_SendRowEmail: error: ',e)
            return Promise.reject(e)
        }
    }
}


/**
 */
export const EmailSubscribe = (p ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()

            const p_subs = {
                actType: 'comm',
                act: 'comm:email-sub:subscribe',
                c_id: window.localStorage.getItem( 'org_id' ),
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data: {
                    o_id: window.localStorage.getItem( 'org_id' ),
                    ...p,
                },
            }

            console.log( 'actions/comm: Comm_EmailSubscribe: p_subs: ', p_subs )

            const resp_subs = await aws_lambda.Invoke( p_subs, Cfg.lambda[Cfg.stage].unauth)

            console.log( 'actions/comm: Comm_EmailSubscribe: resp_subs: ', resp_subs )

            //
            return resp_subs
        }
        catch( err )
        {
            console.warn( 'actions/comm: Comm_EmailSubscribe: err: ', err )
            return Promise.reject( err )
        }
    }
}   // Comm_EmailSubscribe

/**
 */
export const EmailUnsubscribe = (p ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()

            const p_subs = {
                actType: 'comm',
                act: 'comm:email-sub:unsubscribe',
                c_id: window.localStorage.getItem( 'org_id' ),
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data: {
                    o_id: window.localStorage.getItem( 'org_id' ),
                    ...p,
                },
            }

            console.log( 'actions/comm: Comm_EmailSubscribe: p_subs: ', p_subs )

            const resp_subs = await aws_lambda.Invoke( p_subs, Cfg.lambda[Cfg.stage].unauth)

            console.log( 'actions/comm: Comm_EmailSubscribe: resp_subs: ', resp_subs )

            //
            return resp_subs
        }
        catch( err )
        {
            console.warn( 'actions/comm: Comm_EmailSubscribe: err: ', err )
            return Promise.reject( err )
        }
    }
}   // Comm_EmailSubscribe

