/**
 *	@copyright	KARD.
 */

import * as Cfg from '../../_cfg'
import {Core, Lambda} from '../../api/AWS'
import {Settings_Get} from './settings'
/**
 */
export const NewsFeed_List = ( {last_key, dt_st, dt_end, pr_n, cp_n} ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'nfeed',
                act: 'nfeed:list',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {last_key, dt_st, dt_end, pr_n, cp_n},
            }

            console.log( 'actions/project: NewsFeed_List: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/project: NewsFeed_List: resp_list: ', resp_list )

            //
            return resp_list
        }
        catch( err )
        {
            console.warn( 'actions/newsfeed: NewsFeed_List: err: ', err )

            return Promise.reject( err )
        }
    }
}   // NewsFeed_List

/**
 */
export const NewsFeed_New = ( nfeed_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_new = {
                actType: 'nfeed',
                act: 'nfeed:new',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    ...nfeed_obj,
                    // pck: {
                    //     ...dont_obj.pck,
                    //     a: dont_obj.pck.a ? parseInt( dont_obj.pck.a * 100, 10 ) : 0,
                    // },
                },
            }

            console.log( 'actions/newsfeed: NewsFeed_New: p_new: ', p_new )

            const resp_new = await aws_lambda.Invoke( p_new, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/newsfeed: NewsFeed_New: resp_new: ', resp_new )

            //
            return resp_new
        }
        catch( err )
        {
            console.warn( 'actions/newsfeed: NewsFeed_New: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donation_New

/**
 */
export const NewsFeed_Edit = ( nfeed_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_new = {
                actType: 'nfeed',
                act: 'nfeed:edit',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    ...nfeed_obj,
                },
            }

            console.log( 'actions/newsfeed: NewsFeed_Edit: p_new: ', p_new )

            const resp_new = await aws_lambda.Invoke( p_new, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/newsfeed: NewsFeed_Edit: resp_new: ', resp_new )

            //
            return resp_new
        }
        catch( err )
        {
            console.warn( 'actions/newsfeed: NewsFeed_Edit: err: ', err )

            return Promise.reject( err )
        }
    }
}   // NewsFeed_Edit

/**
 */
export const NewsFeed_Delete = ( nfeed_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_new = {
                actType: 'nfeed',
                act: 'nfeed:del',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    ...nfeed_obj,
                },
            }

            console.log( 'actions/newsfeed: NewsFeed_Delete: p_new: ', p_new )

            const resp_del = await aws_lambda.Invoke( p_new, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/newsfeed: NewsFeed_Delete: resp_new: ', resp_new )

            //
            return resp_del
        }
        catch( err )
        {
            console.warn( 'actions/newsfeed: NewsFeed_Delete: err: ', err )

            return Promise.reject( err )
        }
    }
}   // NewsFeed_Delete

/**
 */
export const NewsFeed_SaveImage = (params) =>
{
    return async (d,gs)=> {
        try {
            const aws_lambda = new Lambda({})
            const aws_core = new Core({})
            const cur_usr = await aws_core.currentuser()
            const p_list = {
                actType: 'nfeed',
                act: 'nfeed:img:upd',
                c_id: gs().__auth.orgId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    url:params.url,
                    ni:params.ni
                },
            }

            console.log( 'actions/news: News_SaveImage: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/news: News_SaveImage: resp_list: ', resp_list )

            //
            return resp_list

        } catch (e) {
            console.warn("actions/news: News_SaveImage:error: ", e)
            return Promise.reject(e);
        }
    }
}   // News_SaveImage
/**
 */
export const NewsFeed_SendEmail = (params) =>
{
    return async (d,gs)=> {
        try {

            const sett = await d(Settings_Get());
            console.log("sett",sett)
            if(sett.Item && sett.Item.ve?.te)
            {
                const aws_lambda = new Lambda({})
                const aws_core = new Core({})
                const cur_usr = await aws_core.currentuser()
                const p_list = {
                    actType: 'nfeed',
                    act: 'nfeed:send:email',
                    c_id: gs().__auth.orgId,
                    usr: {
                        ...cur_usr.attributes,
                        username: cur_usr.username,
                    },
                    data: {
                        tp: [gs().__auth.orgId,params.p,params.c].join('/'),
                        email_from: sett.Item.ve.te,
                        msg_sub: params.msg_sub,
                        msg_body: params.msg_body
                    },
                }

                console.log( 'actions/news: NewsFeed_SendEmail: p_list: ', p_list )

                const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

                console.log( 'actions/news: NewsFeed_SendEmail: resp_list: ', resp_list )

                //
                return resp_list
            }
            else
                return {}



        } catch (e) {
            console.warn("actions/news: NewsFeed_SendEmail:error: ", e)
            return Promise.reject(e);
        }
    }
}   // News_SaveImage
