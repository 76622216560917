/**
 *	@copyright	KARD.
 */

import * as Cfg from '../../_cfg'
import {Core, Lambda} from '../../api/AWS'
import * as AWS from "../../api/AWS";

export const CheckCharityBankDetails = ()=>
{
    return async (d,gs)=>{
        try {
            console.log("hi")

            const aws_lambda = new Lambda( {} )
            const aws_core = new AWS.Core( {} )

            const cur_usr = await aws_core.currentuser()
            const p_list = {
                actType: 'stripe',
                act: 'stripe:has-account',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
            }

            console.log( 'actions/charity: GetCharityDetails: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/charity: GetCharityDetails: resp_list: ', resp_list )

            // d( _Campaign_Type( resp_list ) )

            return {msg: "ok",hasAccount:resp_list}
        }catch (e) {
            console.warn("action/charity: GetCharityDetails:error",e);
             return Promise.reject(e)
        }
    }
}

export const GetCharityInfo = ()=>
{
    return async (d,gs)=>{
        try {
            const aws_lambda = new Lambda( {} )
            const aws_core = new AWS.Core( {} )

            const cur_usr = await aws_core.currentuser()
            const p_list = {
                actType: 'charity',
                act: 'charity:get-private',
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
            }

            console.log( 'actions/charity: GetCharityInfo: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/charity: GetCharityInfo: resp_list: ', resp_list )

            return resp_list

        }catch (e) {
            console.warn("action/charity: GetCharityInfo:error",e);
             return Promise.reject(e)
        }
    }
}

export const CreateCharityStripeAccount = ()=>
{
    return async (d,gs)=>{
        try {
            const aws_lambda = new Lambda( {} )
            const aws_core = new AWS.Core( {} )

            const cur_usr = await aws_core.currentuser()
            const p_list = {
                actType: 'stripe',
                act: 'stripe:create-account',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                data: {
                    business_type: 'individual',
                    // email: 'elmelo.test@gmail.com',
                    country: 'GB',
                    default_currency: 'GBP',
                    metadata: {
                        charityId: gs().__auth.orgId,
                    },
                    tos_acceptance: {
                        ip: '0.0.0.0',
                    },
                    // business_profile: {
                    //     name: "Asif Test",//title
                    //     url: "www.elmelo.com" //@todo biss url
                    // },
                    // settings: {
                    //     payments: { statement_descriptor: "asifget"}, //title less then 22 char
                    //     payouts: {
                    //         debit_negative_balances: false,
                    //         statement_descriptor: 'asifget'//title less then 22 char
                    //     },
                    // },
                },
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
            }

            console.log( 'actions/charity: CreateCharityStripeAccount: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/charity: CreateCharityStripeAccount: resp_list: ', resp_list )

            // d( _Campaign_Type( resp_list ) )

            return {msg: "ok",resp:resp_list}
        }catch (e) {
            console.warn("action/charity: CreateCharityStripeAccount:error",e);
             return Promise.reject(e)
        }
    }
}

export const UpdateStripeBankInfo = (p) =>
{
    return async (d,gs)=>{
        try {
            const aws_lambda = new Lambda( {} )
            const aws_core = new AWS.Core( {} )

            const cur_usr = await aws_core.currentuser()
            const p_list = {
                actType: 'stripe',
                act: "stripe:bank-update",
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                data:p,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
            }

            console.log( 'actions/charity: UpdateStripeBankInfo: p_list: ', p_list )
            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )
            console.log( 'actions/charity: UpdateStripeBankInfo: resp_list: ', resp_list )

            return {msg: "ok",resp:resp_list}
        }catch (e) {
            console.warn("action/charity: UpdateStripeBankInfo:error",e);
            return Promise.reject(e)
        }
    }
}
export const UpdateStripeOwnerInfo = (p) =>
{
    return async (d,gs)=>{
        try {
            const aws_lambda = new Lambda( {} )
            const aws_core = new AWS.Core( {} )

            const cur_usr = await aws_core.currentuser()
            const p_list = {
                actType: 'stripe',
                act: "stripe:owner-update",
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                data:p,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
            }

            console.log( 'actions/charity: UpdateStripeOwnerInfo: p_list: ', p_list )
            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )
            console.log( 'actions/charity: UpdateStripeOwnerInfo: resp_list: ', resp_list )

            return {msg: "ok",resp:resp_list}
        }catch (e) {
            console.warn("action/charity: UpdateStripeOwnerInfo:error",e);
            return Promise.reject(e)
        }
    }
}
export const UpdateStripeIdentityInfo = (p) =>
{
    return async (d,gs)=>{
        try {
            const aws_lambda = new Lambda( {} )
            const aws_core = new AWS.Core( {} )

            const cur_usr = await aws_core.currentuser()
            const p_list = {
                actType: 'stripe',
                act: "stripe:identity-update",
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                data:p,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
            }

            console.log( 'actions/charity: UpdateStripeIdentityInfo: p_list: ', p_list )
            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )
            console.log( 'actions/charity: UpdateStripeIdentityInfo: resp_list: ', resp_list )

            return {msg: "ok",resp:resp_list}
        }catch (e) {
            console.warn("action/charity: UpdateStripeIdentityInfo:error",e);
            return Promise.reject(e)
        }
    }
}


export const SavePaymentOption = (props) => {
    return async (d, gs)=>{
        try {
            const aws_lambda = new Lambda( {} );

            const aws_core = new Core( {} );
            const cur_usr = await aws_core.currentuser();
            const params = {
                actType: 'charity',
                act: 'charity:save-payment-option',
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    int: props.int,
                    ext: props.ext,
                    crowd: props.crowd
                },
            }

            console.log( 'action/charity:SavePaymentOption: params: ', params );

            const resp_list = await aws_lambda.Invoke( params, Cfg.lambda[Cfg.stage].admin );

            console.log( 'action/charity:SavePaymentOption: ', resp_list );
        }catch (e) {
            console.warn("action/charity:SavePaymentOption: error:",e);
            return Promise.reject(e);
        }
    }
}
