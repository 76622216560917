import React, { useEffect, useState } from "react";
import AuiModal from "../../aui/modal";
import { Item, PackItem } from "./OptionItem";

const OptionPack = ({ OnSelect, currentCampaign, currentPack }) => {
  const [packList, SetPackList] = useState([
    { tn: -1, n: "Other", a: 0, p: "one" },
  ]);
  const [selectedPack, SelectPack] = useState(null);
  const [isShowingPackList, ShowPackList] = useState(false);

  useEffect(() => {
    if (currentCampaign) {
      SetPackList(
        currentCampaign.pk
          ? [...currentCampaign.pk, { tn: -1, n: "Other", a: 0, p: "one" }]
          : [{ tn: -1, n: "Other", a: 0, p: "one" }]
      );
    }
  }, [currentCampaign]);
  useEffect(() => {
    SelectPack(currentPack);
  }, [currentPack]);

  const SelectPackHandler = (pack) => {
    OnSelect(pack);
    SelectPack(pack);
    ShowPackList(false);
  };
  return (
    <>
      <button
        className="btn-block btn"
        style={{
          fontSize: 20,
          fontWeight: selectedPack ? 900 : null,
          margin: 5,
          padding: 16,
          color: selectedPack ? "#0094FF" : "#4f566a",
          backgroundColor: selectedPack ? "#EFF8FF" : null,
          borderColor: selectedPack ? "#0094FF" : "#009efb",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        onClick={(e) => {
          e.preventDefault();

          if (!currentCampaign) {
            alert("Select a Campaign First");
            return "Campaign not found";
          }
          ShowPackList(true);
        }}
      >
        <>{selectedPack?.n || "Select Package"}</>
      </button>
      <AuiModal
        bOpen={isShowingPackList}
        OnClose={() => ShowPackList(false)}
        className="aui-modal"
      >
        <div className="modal-donation">
          <div style={{ overflow: "auto" }}>
            {packList.map((value) => {
              const isSelected = value?.tn === selectedPack?.tn;
              return (
                <PackItem
                  key={value.tn}
                  item={value}
                  isSelected={isSelected}
                  OnSelect={SelectPackHandler}
                />
              );
            })}
          </div>
        </div>
      </AuiModal>
    </>
  );
};

export default OptionPack;
