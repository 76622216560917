/**
 * @copyright KARD.
 */

import React from 'react'

// import { navigate } from "@reach/router"

/**
 */
class P500 extends React.PureComponent
{
    render()
    {
        return (
            <div className="main-wrapper error-wrapper">
                <div className="error-box">
                    <h1>500</h1>
                    <h3><i className="fa fa-warning" /> Oops! Something went wrong</h3>
                    <p>The page you requested was not found.</p>
                    <a href="/dashboard" className="btn btn-primary go-home">Go to Home</a>
                </div>
            </div>
        )
    }
}   // P500

/**
 */
export default P500

