/**
 *	@copyright	KARD.
 */

/**
 */
export default ( state = {
    cred: {
        username: null,
        pass: null,
    },
}, action ) =>
{
    switch( action.type )
    {
    case 'tmp:cred':
        return {...state, cred: {username: action.payload.username, pass: action.payload.pass}}

    default:
        return state
    }   // switch ...
}   // export



