/**
 *  @copyright KARD
 */

import React from "react";

import { Router, navigate } from "@reach/router";

import Loader from "react-js-loader";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

// import { MLoader } from "../../aui/loader";

import AuthLogin from "../auth/login";
// import AuthLoginOwner from '../auth/loginowner'
import AuthLock from "../auth/lock";
import AuthChange from "../auth/pw_change";
import AuthComplete from "../auth/pw_complete";
import AuthRegister from "../auth/register";
import AuthForgotten from "../auth/forgotten";

import Home from "../home";

import Analytics from "../analytics";

// import DonorHome from '../donor'
// import DonorList from '../donor/list'
// import DonorNew from '../donor/new'
// import DonorView from '../donor/view'

// import DonationHome from '../donation'
import DonationList from "../donation/list";
// import DonationInternal from '../donation/internal'
import DonationIntNew from "../donation/internal_new";
import DonationExtNew from "../donation/external_new";
import DonationPreview from "../donation/preview";
import DonationProcess from "../donation/process_donation";

// import ProjectHome from '../project' // for later
import ProjectList from "../project/list";
import ProjectNewEdit from "../project/new_edit";
// import ProjectDetails from '../project/details'
import ProjectPreview from "../project/preview";

// import CampaignHome from '../campaign'   // for later
import CampaignList from "../campaign/list";
// import CampaignNew from '../campaign/new'
// import CampaignDetails from '../campaign/details'
import CampaignType from "../campaign/type";
import CampaignTypeNew from "../campaign/type_new";
import CampaignNew from "../campaign/new";
// import CampaignNew from '../campaign/new'
import CampaignPreview from "../campaign/preview";

// import Users from '../users'
import StaffList from "../staff/list";
import StaffAdd from "../staff/add";
import StaffRoles from "../staff/roles";

import Settings from "../settings";
// import SettingsStore from '../settings/store'

//
import P500 from "../_common/p500";
import P404 from "../_common/p404";

// import Email from '../Email'
import EmailLog from "../email/log";
import EmailSchedule from "../email/schedule";
import EmailTemplateList from "../email/template_list";
import EmailCompose from "../email/compose";
import EmailTemplateEdit from "../email/edit_template";

// import Email from '../Email'
import SmsLog from "../sms/log";
import SmsSchedule from "../sms/schedule";
import SmsCompose from "../sms/compose";
import SmsTemplateList from "../sms/template_list";
import SmsTemplateEdit from "../sms/edit_template";

// import Address from '../Address'
import AddressList from "../address/list";
import AddressView from "../address/view";

// import Task from '../Task'
import TaskList from "../todo/list";
import TaskNew from "../todo/new";

// import Profile from '../profile'
import ProfileView from "../profile/index";
import ProfileEdit from "../profile/edit";
import Payment from "../payment";

// import Crowd from '../crowd'
import CampaignView from "../crowd/crowd_campaign";
import DonationView from "../crowd/crowd_donation";

import NewsList from "../news";
import NewsView from "../news/view";
import NewsEdit from "../news/edit";

/**
 */
class AppNav extends React.PureComponent {
  /**
   */
  constructor(props) {
    super(props);

    this.state = {
      // bAuthVerify: true,
      // isLoggedIn: false,
    };
  } // cstr

  /**
   */
  render() {
    // console.log( 'AppNav: render: this.props: ', this.props )

    if (this.state.bAuthVerify) {
      // return <MLoader bModal={this.state.bAuthVerify} OnClose={() => {}} />;
      return (
        <div>
          <div
            className="modal"
            style={{
              // margin: '0 auto'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#d1cccc80",
            }}
          >
            <Loader
              type="box-rectangular"
              bgColor={"#007bff"}
              title={"Authenticating ..."}
              color={"#007bff"}
              size={150}
            />
          </div>
        </div>
      );
    }

    return (
      <Router>
        {"loggedin" === this.props.__auth.status ? (
          <>
            <Home path="/" />
            <Home path="/home" />
            <Home path="/dashboard" />

            <Analytics path="/analytics" />

            {/* <DonorHome path='/donor' /> */}
            {/* <DonorList path='/donor' />
                    <DonorList path='/donor/list' />
                    <DonorNew path='/donor/new' />
                    <DonorView path='/donor/view/:donr' /> */}

            {/* <DonationHome path='/donation' /> */}
            <DonationList path="/donation" />
            <DonationList path="/donation/list" />
            {/* <DonationInternal path='/donation/int' /> */}
            <DonationIntNew path="/donation/int/list/:d_id" />
            <DonationIntNew path="/donation/int/new" />
            <DonationIntNew path="/donation/int/new/:pr_n" />
            <DonationIntNew path="/donation/int/new/:pr_n/:cp_n" />
            <DonationIntNew path="/donation/int/new/:pr_n/:cp_n/:pk_n" />
            <DonationPreview path="/donation/int/view/:pr_n/:cp_n" />
            <DonationProcess path="/donation/process" />

            {/* only for test purposes */}
            {/* <DonationExtNew path='/donation/ext/:cp_n' /> */}
            {/* <DonationExtNew path="/:org/donation/ext/:cp_n" /> */}

            <CampaignView path="/crowd/crowd_campaign" />
            <DonationView path="/crowd/crowd_donation" />

            {/* <ProjectHome path='/project' /> */}
            <AddressList path="/address" />
            <AddressView path="/address/view/:donor_id" />

            {/* <Tasks path='/task' /> */}
            <TaskList path="/todo/list" />
            <TaskNew path="/todo/new" />

            {/* <ProjectHome path='/project' /> */}
            <ProjectList path="/project" />
            <ProjectList path="/project/list" />
            <ProjectNewEdit path="/project/list/:pr_n" />
            <ProjectNewEdit path="/project/new" />
            {/* <ProjectDetails path='/project/:proj' /> */}
            <ProjectPreview path="/project/view/:pr_n" />

            {/* <ProjectHome path='/email' /> */}
            <EmailLog path="/email/log" />
            <EmailSchedule path="/email/schedule" />
            <EmailTemplateList path="/email/template_list" />
            <EmailCompose path="/email/compose" />
            <EmailTemplateEdit path="/email/template/edit" />
            <EmailTemplateEdit path="/email/template/edit/:temp_n" />

            {/* <ProjectHome path='/sms' /> */}
            <SmsLog path="/sms/log" />
            <SmsSchedule path="/sms/schedule" />
            <SmsCompose path="/sms/compose" />
            <SmsTemplateList path="/sms/template_list" />
            <SmsTemplateEdit path="/sms/template/edit" />

            {/* <CampaignHome path='/campaign' /> */}
            <CampaignList path="/campaign" />
            <CampaignList path="/campaign/list" />
            {/* <CampaignNew path='/campaign/list/:pr_n/:cp_n' /> */}
            <CampaignNew path="/campaign/list/:pr_n/:cp_n" />
            <CampaignNew path="/campaign/new" />
            <CampaignNew path="/campaign/new/:pr_n" />
            <CampaignType path="/campaign/type" />
            <CampaignTypeNew path="/campaign/type/new" />
            {/* <CampaignNew path='/campaign/new_campaign' /> */}
            <CampaignPreview path="/campaign/view/:pr_n/:cp_n" />

            {/* <Users path='/staff' /> */}
            <StaffList path="/staff/list" />
            <StaffAdd path="/staff/add" />
            <StaffRoles path="/staff/roles" />

            <Settings path="/settings" />
            <Payment path="/payment" />
            {/* <SettingsStore path='/settings/store' /> */}

            {/* <Users path='/staff' /> */}
            <ProfileView path="/profile" />
            <ProfileEdit path="/profile/edit" />

            <NewsList path="/news" />
            {/* <NewsView path='/news/view/:n_id' /> */}
            <NewsView path="/news/view/:n_id" />
            <NewsEdit path="/news/add" />
            <NewsEdit path="/news/edit/:n_id" />

            <P500 path="/505" />
            <P404 default />
          </>
        ) : (
          <>
            <AuthLogin path="/" />
            <AuthLogin path="/auth" />
            <AuthLogin path="/auth/login" />
            <AuthLock path="/auth/lock" />
            <AuthChange path="/auth/change" />
            <AuthComplete path="/auth/complete" />
            <AuthRegister path="/auth/register" />
            <AuthForgotten path="/auth/forgotten" />

            <DonationExtNew path="/:org/donation/:cp_n" />

            <P500 path="/505" />
            <P404 default />
          </>
        )}
      </Router>
    );
  } // render

  /**
   */
  componentDidMount() {
    // console.log( 'AppNav: componentDidMount: this.props: ', this.props )

    this.VerifyAuth()
      .then(() => {
        // this.props.Campaign_TypeList().catch((err) => {});
      })
      .catch(console.warn);
    // this.props.Auth_Verify().catch( console.warn )
  } // componentDidMount

  /**
   */
  VerifyAuth = async () => {
    try {
      this.setState({ bAuthVerify: true });

      // console.log( 'AppNav: VerifyAuth: this.props: ', this.props )

      // this.props.Auth_Init()

      const bLoggedin = await this.props.Auth_Verify();

      console.log("AppNav: VerifyAuth: bLoggedin: ", bLoggedin);

      if (bLoggedin) {
        // navigate( '/dashboard/' )
        // await this.props.Auth_UsrInit()
        // console.error( 'this.props.Auth_UsrInit complete' )
      } else {
        // navigate( '/auth/login/' )
      }

      this.setState({ bAuthVerify: false });

      return {};
    } catch (err) {
      console.warn("AppNav: VerifyAuth: err: ", err);

      this.setState({ bAuthVerify: false });

      return Promise.reject(err);
    }
  }; // VerifyAuth
} // AppNav

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(AppNav);
