/**
 * @copyright KARD
 */

import React, { useEffect, useState } from "react";
import Loader from "react-js-loader";

// import {
//     Modal
// } from '@material-ui/core'

import { navigate } from "@reach/router";

import { connect, useDispatch,
  // useSelector
} from "react-redux";
import * as actions from "../../rdx/actions";

// import * as Cfg from "../../_cfg";

// import {Time, UI} from "../../api/Utils";
import AuiStatus from "../../aui/status";
import AuiModal from "../../aui/modal";

// import {
//   // Core,
//   DDB,
// } from "../../api/AWS";

// import Addr from '../../aui/addr'

import Page from "../_common/page";

import CampaignNew from "../campaign/new";
// import CampType_V from "../campaign/type_v";
import {
  Project_Edit,
  // SaveCampaignImage,
  SaveProjectImage,
  uploadFile,
} from "../../rdx/actions";
import DescComp from "../_common/desc_comp";
// import { Button } from "@material-ui/core";
import { ImageInput } from "../_common/img_input";
import { Time } from "../../api/Utils";

const ctInit = {
  listDesc: [],
  socTwit: "",
  profVid: "",
  socInsta: "",
  socYT: "",
  socFb: "",
};

/**
 */
const ProjectNew = (props) => {
  // const orgId = useSelector((state) => state.__auth.orgId);
  const dispatch = useDispatch();
  const [isLoading, IsLoading] = React.useState(false);
  const [isSaving, IsSaving] = React.useState(false);
  // const [isShowingSave, IsShowingSave] = React.useState( false )

  const [isModal, IsModal] = React.useState(false);
  const [tmpCamp, TmpCamp] = React.useState({});

  // const [origObj, OrigObj] = React.useState( null )
  // const [origListCamp, OrigListCamp] = React.useState( null )

  const [projTitle, ProjTitle] = React.useState("");
  const [projSubTitle, ProjSubTitle] = React.useState("");
  const [projDesc, ProjDesc] = React.useState("");
  const [projShortDesc, ProjShortDesc] = React.useState("");
  const [projStatus, ProjStatus] = React.useState("act");

  const [listCamp, ListCamp] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);
  const [tamount, TAmount] = React.useState(null);
  const [profileImg, ProfileImg] = useState({});
  const [descList, DescList] = useState([]);
  const [ct, SetCT] = useState(ctInit);
  const [projStart, ProjStart] = React.useState(0);
  const [projEnd, ProjEnd] = React.useState(0);

  /**
   */
  React.useEffect(() => {
    if (props.pr_n) {
      // IsShowingSave( false )

      Load().catch((err) => {});
    } else {
      // IsShowingSave( true )
    }
  }, []);

  useEffect(() => console.log("descList", descList), [descList]);

  /**
   */
  const Load = async () => {
    try {
      IsLoading(true);

      const pr_arr = [LoadProj(), LoadCampList(false)];

      const pr_resp = await Promise.all(pr_arr);

      if (pr_resp) {
        //
      }

      //
      IsLoading(false);

      return {};
    } catch (err) {
      console.warn("ProjectNew: Load: err: ", err);

      IsLoading(false);

      return Promise.reject(err);
    }
  }; // Load

  /**
   */
  const LoadCampList = async (b_more) => {
    try {
      const list_camp = await props.Campaign_List_Project(
        parseInt(props.pr_n, 10),
        b_more ? lastKey : null
      );

      // console.log( 'ProjectNew: LoadCampList: list_camp: ', list_camp )

      ListCamp(list_camp.Items);
      // OrigListCamp( list_camp )
      LastKey(list_camp.LastEvaluatedKey);

      //
      return {};
    } catch (err) {
      console.warn("ProjectNew: LoadCampList: err: ", err);

      return { err };
    }
  }; // LoadCampList

  /**
   */
  const LoadProj = async () => {
    try {
      const resp_proj = await props.Project_Details(parseInt(props.pr_n, 10));
      console.log("item", resp_proj.Item);

      ProjTitle(resp_proj.Item.p.t ? resp_proj.Item.p.t : "");
      ProjSubTitle(resp_proj.Item.p.ts ? resp_proj.Item.p.ts : "");
      ProjDesc(resp_proj.Item.p.d ? resp_proj.Item.p.d : "");
      ProjShortDesc(resp_proj.Item.p.ds ? resp_proj.Item.p.ds : "");
      ProjStatus(resp_proj.Item.s ? resp_proj.Item.s : "");
      SetCT(resp_proj.Item.ct ? resp_proj.Item.ct : ctInit);
      DescList([...resp_proj.Item?.ct?.listDesc] || []);
      ProjEnd(resp_proj.Item.te ? Time.Epoch2DateYMD(resp_proj.Item.te) : 0);
      ProjStart(resp_proj.Item.ts ? Time.Epoch2DateYMD(resp_proj.Item.ts) : 0);

      TAmount(resp_proj.Item?.a?.t || null);
      ProfileImg((prevState) => {
        if (resp_proj.Item?.img?.p?.f) {
          return { ...prevState, url: resp_proj.Item?.img?.p?.f };
        }
        return prevState;
      });

      return {};
    } catch (err) {
      console.warn("ProjectNew: Load: err: ", err);

      return { err };
    }
  }; // LoadProj

  /**
   */
  const OnSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (props.pr_n) {
      SaveProj()
        .then((data) => Load().catch((err) => {}))
        .catch((err) => {});
    } else {
      NewProcess()
        .then((data) => {
          console.log(data);
          IsSaving(false);
          navigate("/project/list");
          alert("Project created.");
        })
        .catch((err) => {
          IsSaving(false);
          alert(err?.message || err);
        });
    }

    return false;
  }; // OnSubmit

  /**
   */
  async function NewProcess() {
    try {
      IsSaving(true);
      const { project } = await NewProj();
      console.log(project);
      if (!project) {
        throw new Error("Failed to create Project!");
      }
      if (!profileImg.file) {
        return { success: true, project };
      }
      const { success, url } = await saveImage(project);
      if (success) {
        const resp = await dispatch(SaveProjectImage({ url, tn: project.tn }));
        if (resp.success) {
          return { success: true, project: resp.project.data.Attributes };
        }
      }
      return { success: false };
    } catch (e) {
      console.warn("campaign/new: SaveProcess:error", e);
      return Promise.reject(e);
    }
  }

  /*
   */
  async function saveImage(p) {
    try {
      const ft = profileImg.type.split("/")[1];
      const dtNow = Date.now();
      const iid = [
        dtNow,
        p.i,
        p.tn,
        "profile",
        profileImg.name.split(".")[0],
      ].join("_");
      const name = iid + "." + ft;
      const params_upd = {
        name: name,
        key: name,
        contentType: profileImg.file.type,
        file: profileImg.file,
        g: "profile",
        tp: "profile",
        iid,
        ft,
      };
      console.log(params_upd, profileImg);
      const { s3 } = await dispatch(uploadFile(params_upd));
      console.log(s3);
      if (s3.success) {
        return { ...s3 };
      } else {
        throw new Error("Filed to save file!");
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   */
  const SaveProj = async () => {
    try {
      IsSaving(true);

      const upd_p = {
        tn: parseInt(props.pr_n, 10),
        p: {
          t: projTitle,
          ts: projSubTitle,
          d: projDesc,
          ds: projShortDesc,
        },
        v: { g: [], p: {}, t: {} },
        a: { t: parseInt(tamount * 100, 10) },
        ts: projStart ? Time.Epoch(projStart) : 0,
        te: projEnd ? Time.Epoch(projEnd) : 0,
        s: projStatus,
        ct,
        listDesc: descList,
      };
      // Edit project
      const { resp_upd } = await dispatch(Project_Edit(upd_p));

      console.log("ProjectNew: NewProj: resp_edit: ", resp_upd);

      if (profileImg.file) {
        const p = resp_upd.data.Attributes;
        const { success, url } = await saveImage(p);
        if (success) {
          const resp = await dispatch(SaveProjectImage({ url, tn: p.tn }));
        }
      }

      // console.log( 'ProjectNew: NewProj: listCamp: ', listCamp )

      // create campaigns
      const pr_camp = listCamp.map(async (x, idx) => {
        try {
          if ("del" === x.__req) {
            const resp_tmp = await props.Campaign_Del(x);

            if (resp_tmp) {
              //
            }

            return { msg: "Deleted" };
          } // 'del' === x.__ref
          else if (!x.dt_n) {
            const resp_tmp = await props.Campaign_New({
              ...x,

              p: parseInt(props.pr_n, 10),
              pi: {
                t: projTitle,
              },

              _idx: idx,
            });

            if (resp_tmp) {
              //
            }

            return { msg: "New" };
          } else {
            return { msg: "OK" };
          }
        } catch (err) {
          return { err };
        }
      });

      const resp_camp = await Promise.all(pr_camp);

      // console.log( 'ProjectNew: NewProj: resp_camp: ', resp_camp )

      if (resp_camp) {
        //
      }

      alert("Project Updated.");

      IsSaving(false);

      return {};
    } catch (err) {
      IsSaving(false);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // SaveProj

  /**
   */
  const NewProj = async () => {
    try {
      IsSaving(true);
      const new_p = {
        p: {
          t: projTitle,
          ts: projSubTitle,
          d: projDesc,
          ds: projShortDesc,
        },
        v: { g: [], p: {}, t: {} },
        a: { t: parseInt(tamount * 100, 10), r: 0, nr: 0 },
        ts: projStart ? Time.Epoch(projStart) : 0,
        te: projEnd ? Time.Epoch(projEnd) : 0,
        s: projStatus,
        ct,
        listDesc: descList,
      };

      // create project
      const { project } = await props.Project_New(new_p);

      // console.log( 'ProjectNew: NewProj: resp_new: ', resp_new )

      // console.log( 'ProjectNew: NewProj: listCamp: ', listCamp )

      // create campaigns
      const pr_camp = listCamp.map(async (x, idx) => {
        try {
          const resp_tmp = await props.Campaign_New({
            ...x,

            p: project.dt_n,
            pi: {
              t: project.t,
            },

            _idx: idx,
          });

          return resp_tmp;
        } catch (err) {
          return { err };
        }
      });

      const resp_camp = await Promise.all(pr_camp);

      // console.log( 'ProjectNew: NewProj: resp_camp: ', resp_camp )

      if (resp_camp) {
        //
      }
      return { project, campaign: resp_camp };
    } catch (err) {
      IsSaving(false);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // NewProj
  /**
   */
  return (
    <Page active="project">
      <div className="row">
        <div className="col mb-3">
          <h4 className="projectTitle m-0">
            <img
              style={{ height: 20, width: 20, marginRight: 16 }}
              src={require("../../components/assets/icons/sideBar/active/project.png")}
              alt=""
            />
            Add Project
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <form onSubmit={OnSubmit}>
            <div className="row">
              <div className="col-sm-6 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>
                    Title <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={projTitle}
                    onChange={(e) => ProjTitle(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Subtitle</label>
                  <input
                    className="form-control"
                    type="text"
                    value={projSubTitle}
                    onChange={(e) => ProjSubTitle(e.target.value)}
                    // required
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Target Amount</label>
                  <input
                    className="form-control"
                    type="text"
                    value={tamount}
                    onChange={(e) => TAmount(e.target.value)}
                    // required
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    type="text"
                    value={projDesc}
                    onChange={(e) => ProjDesc(e.target.value)}
                    // required
                  ></textarea>
                </div>
              </div>

              <div className="col-sm-12 col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Short Description</label>
                  <textarea
                    className="form-control"
                    type="text"
                    value={projShortDesc}
                    onChange={(e) => ProjShortDesc(e.target.value)}
                    // required
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-3">
                <div
                  className="table-responsive border"
                  style={{ borderRadius: 8 }}
                >
                  <table className="table" width="100%">
                    <thead>
                      <tr>
                        <th width="20%">Title</th>
                        <th width="45%">Details</th>
                        <th width="15%">Target Amount</th>
                        <th width="10%">Status</th>
                        <th width="10%">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listCamp.map((x, i, a) => (
                        <ProjCampList
                          key={x.dt_n ? x.dt_n : i}
                          camp={x}
                          OnRemove={() => {
                            if (!x.dt_n) {
                              ListCamp(a.filter((item, idx) => idx !== i));
                            } else if ("del" === x.__req) {
                              delete a[i].__req;
                              ListCamp([...a]);
                            } else {
                              a[i].__req = "del";
                              ListCamp([...a]);
                            }
                          }}
                          OnEdit={() => {
                            console.log("edit", x);
                            navigate(
                              ["/campaign/list", props.pr_n, x.tn].join("/")
                            );
                          }}
                        />
                      ))}
                    </tbody>

                    <tfoot>
                      {
                        // <ProjCamp
                        //     OnAdd={ obj => ListCamp( [...listCamp, obj] ) }
                        //     lastKey={lastKey}
                        //     OnShowMore={ () => {
                        //         LoadCampList( lastKey ).catch( err => {} )
                        //     } }
                        // />
                      }
                      <tr>
                        <>
                          {props.lastKey && (
                            <button
                              className="form-control"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                // LoadCampList( lastKey ).catch( err => {} )
                                // props.OnShowMore()
                                LoadCampList(lastKey).catch((err) => {});

                                return false;
                              }}
                            >
                              Show More
                            </button>
                          )}
                        </>
                        <AuiModal
                          bOpen={isModal}
                          OnClose={() => IsModal(false)}
                        >
                          <div
                            style={{
                              backgroundColor: "lightgrey",
                              // position: 'absolute',
                              // top: '50%', left: '50%',
                              maxHeight: "90%",
                              margin: "auto",
                              "overflow-x": "auto",
                              "overflow-y": "auto",
                            }}
                          >
                            <CampaignNew
                              camp={tmpCamp}
                              bModalView={true}
                              // OnSubmit={ props.OnAdd }
                              OnSubmit={(obj) => ListCamp([...listCamp, obj])}
                              OnClose={() => IsModal(false)}
                            />
                          </div>
                        </AuiModal>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Start Date</label>
                  <input
                    className="form-control"
                    type="date"
                    value={projStart}
                    onChange={(e) => ProjStart(e.target.value)}
                    // required
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>End Date</label>
                  <input
                    className="form-control"
                    type="date"
                    value={projEnd}
                    //value={projSubTitle}
                    onChange={(e) => ProjEnd(e.target.value)}
                    // required
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Status</label>
                  <AuiStatus
                    className="form-control"
                    value={projStatus}
                    OnChange={(val) => ProjStatus(val)}
                    // OnChange={ e => {
                    //     console.log( 'AuiStatus: OnChange: e: ', e )
                    //     // ProjStatus(e.target.value)
                    // } }
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-12 col-md-12">
                <div>
                  <ImageInput
                    onChange={(d) => {
                      console.log(d);
                      ProfileImg(d);
                    }}
                    label={"Select an image"}
                    value={profileImg}
                  />
                </div>
              </div>
            </div>
            <DescComp
              listDesc={ct.listDesc}
              onUpdate={(l) => DescList([...l])}
            />
            <div className="row mt-4">
              <div className="col-sm-6 btn-group">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary mr-4 px-3 rounded"
                  // disabled={!isShowingSave}
                >
                  Save
                </button>

                <button
                  // type="cancel"
                  className="btn btn-lg btn-danger px-3 rounded"
                  // disabled={!isShowingSave}

                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    navigate("/project/list");

                    return false;
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <>
      {
        (isLoading || isSaving) &&
        <div>
          <div
              className="modal"
              style={{
                // margin: '0 auto'
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#d1cccc80",
              }}
          >
          <Loader
            // type="box-rectangular"
            type="spinner-cub"
            bgColor={"#2680c2"}
            title={isLoading ? 'Loading...' : "Saving ..."}
            color={"#2680c2"}
            size={150}
          />
          </div>
        </div>
      }
      </>
    </Page>
  );
}; // ProjectNew

/**
 */
const ProjCampList = (props) => {
  const [isRemoving, IsRemoving] = React.useState(false);
  console.log("ProjCampList: props: ", props);

  /**
   */
  // React.useEffect( () => {
  //     //
  // }, [props.camp] )

  /**
   */
  const OnEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    console.log("ProjCampList: OnEdit: props: ", props);

    if (props.camp.dt_n) {
      navigate(`/campaign/list/${props.camp.pr_n}/${props.camp.dt_n}`);
    } else {
      props.OnEdit();
    }
  }; // OnEdit

  /**
   */
  const OnRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();

    IsRemoving(props.camp.dt_n ? true : false);

    if (props.OnRemove) {
      props.OnRemove();
    }
  }; // OnRemove

  /**
   */
  return (
    <tr
      style={{
        backgroundColor: !props.camp.dt_n
          ? "lightyellow"
          : "del" === props.camp.__req
          ? "red"
          : null,
      }}
    >
      <td>{props.camp.ci.t}</td>
      <td>{props.camp.ci.t}</td>
      <td>{props.camp.a.t}</td>
      <td>{props.camp.s === "act" ? "Active" : "Deactivated"} </td>
      <td>
        <button className="btn btn-primary mr-1" onClick={OnEdit}>
          Edit
        </button>
        {/*<button className="btn btn-danger" onClick={OnRemove}>*/}
        {/*  {"del" === props.camp.__req ? "Cancel" : "Remove"}*/}
        {/*</button>*/}
      </td>
    </tr>
  );
}; // ProjCampList

// /**
//  */
// const ProjCamp = ( props ) =>
// {

//     /**
//      */
//     return (
//         null
//     )
// }   // ProjCamp

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(ProjectNew);
