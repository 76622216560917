/**
 * @copyright KARD
 */

import React from 'react'

import {navigate} from "@reach/router";

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

// import * as Cfg from '../../_cfg'

// import {Time, UI} from "../../api/Utils";

// import {
//     // Core,
//     DDB,
// } from '../../api/AWS'

// import Addr from '../../aui/addr'

import Page from '../_common/page'

/**
 */
const CampaignList = ( props ) =>
{
    const [isInit, IsInit] = React.useState( false )
    const [listTypes, ListTypes] = React.useState( [] )

    //
    React.useEffect( () => {
        Init().catch( err => {} )
    }, [] )

    //
    const Init = async () =>
    {
        try
        {
            IsInit( true )

            const resp_list = await props.Campaign_TypeList()

            ListTypes( resp_list )

            IsInit( false )

            return {}
        }
        catch( err )
        {
            IsInit( false )

            return Promise.reject( err )
        }
    }   // Init

    //
    return (
        <Page active="campaign">
            <div className="row">
                <div className="col-sm-4 col-3">
                    <h4 className="page-title">Campaign Types</h4>
                </div>
                <div className="col-sm-8 col-9 text-right m-b-20">
                    <button onClick={()=>navigate( "/campaign/type/new")} className="btn btn btn-primary btn-rounded float-right"><i className="fa fa-plus" /> Add Type</button>
                </div>
            </div>

            <div className="row">
                {/*  */}
            </div>

            <div className="row">
                <div className="col-12">
                    <label>Type List</label>

                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Desc</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                        {
                            listTypes.map( (x, i) => (
                                <tr key={i}>
                                    <td>{x.n}</td>
                                    <td>{x.d}</td>
                                    <td>
                                        <button>sss</button>
                                    </td>
                                </tr>
                            ) )
                        }
                        </tbody>

                        <tfoot>
                            {/* <CampPkg
                                OnAdd={ obj => ListPck( [...listPck, obj] ) }
                            /> */}
                        </tfoot>
                    </table>
                </div>
            </div>
        </Page>
    )
}   // CampaignList

/**
 */
const mapStateToProps = ( state ) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( CampaignList )



