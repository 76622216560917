import React, { useEffect, useRef } from "react";
import { Button } from "@material-ui/core";
import Image from "./image";
import PHI from "../../assets/img/placeholder.png";

function ImageInput(props) {
  const [imgDoc, ImgDoc] = React.useState({});
  const [errorMsg, SetErrorMsg] = React.useState("");
  const iIRef = useRef();
  const value = props.value;

  useEffect(() => {
    // console.log("?", props);
    if (value) {
      ImgDoc(value);
    }
  }, [value]);

  async function handleFileRead(event) {
    const { name, value } = event.target;
    const file = event.target.files[0];
    if (file.size > 300000) {
      SetErrorMsg(
        "Yor image size is more then 300KB. Please select an Image less then 300KB."
      );
      return { msg: "ok" };
    }
    SetErrorMsg("");
    const url = URL.createObjectURL(file);

    const img = {
      file: file,
      type: file.type,
      name: file.name,
      url: url,
    };
    if (!file) return { msg: "Image not found" };

    ImgDoc(img);
    // console.log(img);
    props.onChange(img);
  }

  return (
    <div>
      <Image
        src={imgDoc.url}
        alt={PHI}
        altTxt="image"
        cn="border col-sm-12 col-md-12 p-0"
        style={{
          // width: 400,
          maxHeight: 260,
          minHeight: 260,
          objectFit: "contain",
          padding: 5,
          borderRadius: 8,
        }}
      />
      {errorMsg.length > 5 ? (
        <p style={{ color: "red" }}>*{errorMsg}</p>
      ) : (
        <p>*Image size must be less then 300KB.</p>
      )}
      <input
        type="file"
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
        id="contained-button-file"
        ref={iIRef}
        onChange={handleFileRead}
      />
      <button
        onClick={(e) => {
          e.preventDefault();
          if (iIRef.current) {
            iIRef.current.click();
          }
        }}
        className={
          props?.className || "btn btn-outline-primary d-block mb-3 pull-right"
        }
      >
        {props?.label || "Change Image"}
      </button>
    </div>
  );
}

export { ImageInput };
