import React, { useEffect, useState } from "react";
import Page from "../_common/page";
import { navigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { Lambda } from "../../api/AWS";
import {
  CheckCharityBankDetails,
  CreateCharityStripeAccount,
  UpdateStripeBankInfo,
  UpdateStripeIdentityInfo,
  UpdateStripeOwnerInfo,
} from "../../rdx/actions";
import { Time, Utils } from "../../api/Utils";
import * as AWS from "../../api/AWS";
import * as Cfg from "../../_cfg";
import { RadioButton } from "../../api/custom_ui_tag";
import Autocomplete from "react-google-autocomplete";

function Payment(props) {
  const cfg = useSelector((state) => state.__cfg);
  const orgId = useSelector((state) => state.__auth.orgId);
  const aws_lambda = new Lambda({});
  const dispatch = useDispatch();
  const [isProcessing, SetProcessing] = useState(false);
  const [fName, SetFName] = useState("");
  const [lName, SetLName] = useState("");
  const [accountNo, SetAccountNo] = useState("");
  const [sortCode, SetSortCode] = useState("");
  const [address, SetAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    county: "",
    postcode: "",
    country: "",
  });
  const [dob, SetDOB] = useState("");
  const [phone, SetPhone] = useState("");
  const [email, SetEmail] = useState("");
  const [doc1, SetDoc1] = useState({
    path: null,
    data: null,
  });
  const [doc2, SetDoc2] = useState({
    path: null,
    data: null,
  });
  const [vType, SetVType] = useState("Passport");

  useEffect(() => {
    BankAccount().then();
  }, []);
  // useEffect( ()=>{
  //     console.log(doc1)
  // },[doc1])

  async function BankAccount() {
    const a = await dispatch(CheckCharityBankDetails());
    console.log("if", a);
    if (!a.hasAccount) {
      console.log("if");
      const res = await dispatch(CreateCharityStripeAccount());
      console.log(res);
    }
    return { msg: "ok" };
  }

  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  async function handleFileRead(event) {
    const { name, value } = event.target;
    const file = event.target.files[0];
    const base64 = await convertBase64(file);

    // console.log(name,value)
    const doc = {
      path: value,
      data: base64,
    };
    if (!value || !base64) return { msg: "Image not found" };

    switch (name) {
      case "doc1":
        SetDoc1({ ...doc });
        break;
      case "doc2":
        SetDoc2((ps) => {
          ps.path = value;
          ps.data = base64;
          return { ...ps };
        });
        break;
      default:
        return "Doc not found";
    }
    // console.log(base64)
  }

  return (
    <Page active="payment">
      <div className="row">
        <div className="col">
          <h4 className="projectTitle">
            <i className="fa fa-cog" /> Payment
          </h4>
        </div>
      </div>

      <div className="row">{/*  */}</div>

      <div className="row">
        <div className="col-12">
          <h5 className="projectTitle">Bank Details</h5>
        </div>
        <div className="col-12">
          <form>
            <div className="row">
              <div className="col-sm-6 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>
                    First Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={fName}
                    onChange={(e) => SetFName(e.target.value.trim())}
                    required
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>
                    Last Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={lName}
                    onChange={(e) => SetLName(e.target.value.trim())}
                    // required
                  />
                </div>
              </div>

              <div className="col-sm-6 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>
                    Account Number <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={accountNo}
                    onChange={(e) => SetAccountNo(e.target.value.trim())}
                    // required
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>
                    Sort Code <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={sortCode}
                    onChange={(e) => SetSortCode(e.target.value.trim())}
                    // required
                  />
                </div>
              </div>

              <div className="col-sm-12 col-3">
                <h5 className="projectTitle">Address</h5>
              </div>

              <div className="col-sm-12 col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Address Lookup</label>
                  <Autocomplete
                    apiKey={"AIzaSyDQaXKe4roziIjDQAFFC2wdJQrHM9K1o8M"}
                    onPlaceSelected={(place) => {
                      // console.log(JSON.stringify(place));
                      place.address_components.forEach((addr_details) => {
                        const { short_name, types } = addr_details;

                        if (types.includes("postal_code")) {
                          SetAddress((ps) => {
                            ps.postcode = short_name.trim();
                            return { ...ps };
                          });
                        } else if (types.includes("route")) {
                          SetAddress((ps) => {
                            ps.line2 = short_name.trim();
                            return { ...ps };
                          });
                        } else if (types.includes("postal_town")) {
                          SetAddress((ps) => {
                            ps.city = short_name.trim();
                            return { ...ps };
                          });
                        } else if (
                          types.includes("administrative_area_level_2")
                        ) {
                          SetAddress((ps) => {
                            ps.county = short_name.trim();
                            return { ...ps };
                          });
                        } else if (types.includes("country")) {
                          SetAddress((ps) => {
                            ps.country = short_name.trim();
                            return { ...ps };
                          });
                        }
                      });
                    }}
                    className="form-control"
                    placeholder="Search"
                    options={{
                      types: ["(regions)"],
                    }}
                  />
                </div>
              </div>

              <div className="col-sm-12 col-lg-12 col-md-12">
                <div className="form-group">
                  <label>
                    Line 1<span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={address.line1}
                    onChange={(e) => {
                      const { value } = e.target;
                      SetAddress((ps) => {
                        ps.line1 = value.trim();
                        return { ...ps };
                      });
                    }}
                    // required
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-12 col-md-12">
                <div className="form-group">
                  <label>
                    Line 2 <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={address.line2}
                    onChange={(e) => {
                      const { value } = e.target;
                      SetAddress((ps) => {
                        ps.line2 = value.trim();
                        return { ...ps };
                      });
                    }}
                    // required
                  />
                </div>
              </div>

              <div className="col-sm-6 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>
                    City <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={address.city}
                    onChange={(e) => {
                      const { value } = e.target;
                      SetAddress((ps) => {
                        ps.city = value.trim();
                        return { ...ps };
                      });
                    }}
                    // required
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>
                    County<span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={address.county}
                    onChange={(e) => {
                      const { value } = e.target;
                      SetAddress((ps) => {
                        ps.county = value.trim();
                        return { ...ps };
                      });
                    }}
                    // required
                  />
                </div>
              </div>

              <div className="col-sm-6 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>
                    Postcode <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={address.postcode}
                    onChange={(e) => {
                      const { value } = e.target;
                      SetAddress((ps) => {
                        ps.postcode = value.trim();
                        return { ...ps };
                      });
                    }}
                    // required
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>
                    Country <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={address.country}
                    onChange={(e) => {
                      const { value } = e.target;
                      SetAddress((ps) => {
                        ps.country = value.trim();
                        return { ...ps };
                      });
                    }}
                    // required
                  />
                </div>
              </div>

              <div className="col-sm-12 col-3">
                <h5 className="projectTitle">Personal Info</h5>
              </div>
              <div className="col-sm-12 col-lg-12 col-md-12">
                <div className="form-group">
                  <label>
                    Date of Birth <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    value={dob}
                    onChange={(e) => SetDOB(e.target.value.trim())}
                    // required
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-12 col-md-12">
                <div className="form-group">
                  <label>
                    Phone Number <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="tel"
                    value={phone}
                    onChange={(e) => SetPhone(e.target.value.trim())}
                    // required
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-12 col-md-12">
                <div className="form-group">
                  <label>
                    Email Address <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    value={email}
                    onChange={(e) => SetEmail(e.target.value.trim())}
                    // required
                  />
                </div>
              </div>

              <div className="col-sm-12 col-3">
                <h5 className="projectTitle">Identity</h5>
              </div>
              <div className="col-sm-12 col-lg-12 col-md-12">
                <div className="form-group">
                  <label>
                    Verify your identy with{" "}
                    <span className="text-danger mr-2">*</span>
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="radio1"
                    autoComplete="off"
                    checked={vType === "Passport"}
                    onChange={(e) => {
                      SetVType("Passport");
                    }}
                  />
                  <label className="btn btn-outline-primary" htmlFor="radio1">
                    Passport
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="radio2"
                    autoComplete="off"
                    checked={vType !== "Passport"}
                    onChange={(e) => {
                      SetVType("Licence");
                    }}
                  />
                  <label className="btn btn-outline-primary" htmlFor="radio2">
                    Driving Licence
                  </label>
                </div>
              </div>
              <div className="col-sm-12 col-lg-12 col-md-12">
                <div className="form-group">
                  <label>
                    {vType === "Passport"
                      ? "Passport Photo"
                      : "Font of Licence Photo"}
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    // value={ doc1.path }
                    name={"doc1"}
                    accept="image/jpeg"
                    onChange={handleFileRead}
                    // required
                  />
                </div>
              </div>

              {vType === "Licence" && (
                <div className="col-sm-12 col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Back of Licence Photo{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      // value={ doc2.path }
                      name={"doc2"}
                      accept="image/jpeg"
                      onChange={handleFileRead}
                      // required
                    />
                  </div>
                </div>
              )}

              <div className="col-sm-12 col-lg-12 col-md-12">
                <div className="form-group">
                  <button
                    name="save"
                    className="btn btn-primary"
                    style={{ background: "#009efb", color: "#fff" }}
                    onClick={(e) => {
                      e.preventDefault();
                      onSave().then();
                    }}
                  >
                    {" "}
                    Save{" "}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Page>
  );

  async function onSave() {
    try {
      SetProcessing(true);

      if (!Time.isAbove(new Date(dob), 18)) {
        throw new Error("Age Should be above 18");
      }
      const { dd, mm, yyyy } = Time.DTObj(new Date(dob));

      const bank_p = {
        external_account: {
          acntCountry: "GB",
          acntCurrency: "GBP",
          acntType: "individual",
          name: [fName, lName].join(" "),
          sort_code: sortCode.replace(/\s/g, ""),
          account_number: accountNo.replace(/\s/g, ""),
        },
      };
      const owner_p = {
        individual: {
          first_name: fName,
          last_name: lName,
          dob: { day: dd, month: mm, year: yyyy },
          email,
          phone,
          address: {
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            state: address.county,
            postal_code: address.postcode,
            country: address.country,
          },
        },
      };
      const identity_p = {
        doc1: {
          name: orgId,
          data: doc1.data,
          type: "image/jpeg",
        },
        docType: vType,
      };
      if (vType === "Licence") {
        identity_p["doc2"] = {
          name: orgId,
          data: doc2.data,
          type: "image/jpeg",
        };
      }
      console.log("owner_p : ", owner_p);
      console.log("bank_p : ", bank_p);
      console.log("identity_p : ", identity_p);

      await dispatch(UpdateStripeBankInfo(bank_p));
      await dispatch(UpdateStripeOwnerInfo(owner_p));
      await dispatch(UpdateStripeIdentityInfo(identity_p));
      return {};
    } catch (err) {
      console.error("payment/index : OnSave : err : ", err);

      return { err };
    }
  }
}

export default Payment;
