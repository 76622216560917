/**
 */

import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import Loader from "react-js-loader";
import {Elements} from "@stripe/react-stripe-js";
import CardForm from "../../api/pay/cardForm";
import {PaymentReceived, UpdateRecurringDonation} from "../../rdx/actions";
import {navigate} from "@reach/router";
import {useDispatch, useSelector} from "react-redux";
import {loadStripe} from "@stripe/stripe-js";
import * as Cfg from "../../_cfg";
import PayPal from "./paym_paypal";

/**
 */
const methods = {
    bk:{m:'bank',s:'not-paid',l:'Bank Transfer'},
    crd:{m:'card',s:'not-paid',l:'Card'},
    cs:{m:'cash',s:'not-paid',l:'Cash'},
    cq:{m:'cheque',s:'not-paid',l:'Cheque'},
    ppl:{m:'paypal',s:'not-paid',l:'PayPal'},
}

/**
 */
const PaymentMethod = forwardRef(({Proj,Camp,Pack,Cus,Int,OnPmChange},ref) =>
{
    const dispatch = useDispatch();
    let cardRef = useRef();
    const orgId = useSelector((state) => state.__auth.orgId);
    const [paymentMethod, SetPaymentMethod] = useState('');
    const [stripePromise, SetStripePromise] = useState(null);

    /**
     */
    useEffect(()=>{
        // console.log(Int?.[paymentMethod]?.srvc?.pk);
        console.log( 'PaymentMethod: Int: ', Int )

        // OnPmChange(methods[paymentMethod])

        if (Int && paymentMethod === 'crd')
        {
            const pk = Int?.[paymentMethod]?.srvc?.pk?.length>0?Int?.[paymentMethod]?.srvc?.pk:Cfg.stripe.key[Cfg.stage];

            console.log('PaymentMethod: pk: ', pk)

            SetStripePromise(loadStripe(pk));
        }
    },[Int,paymentMethod]);

    /**
     */
    useImperativeHandle(ref, () => ({
        async PayWithCard(donationId){
            try {
                let donationMsg="Success";
                const paymentData = await cardRef.current.PayWithCard();
                console.log(paymentData);
                // return "test"
                if (!paymentData.payment_success) {
                    throw new Error("Payment Failed");
                }
                const email_param = {
                    to: Cus.email,
                    from: "",
                    sub: "Donation Received",
                    body: "Thanks for your donation.",
                };

                try {
                    await dispatch(
                        PaymentReceived(
                            donationId,
                            paymentData.paymentObj,
                            "received",
                            "card",
                            email_param
                        )
                    );
                }catch (e) {
                    donationMsg = "Donation db update filed."
                }

                if (Pack.p === "rec" && Pack.p_i) {
                    let recurringMsg="Success";
                    const { paymentObj } = paymentData;
                    const rec_param = {
                        si: paymentObj?.s_i,
                        pi: paymentObj?.pi,
                        ci: paymentObj?.ci,
                        prc_i: paymentObj?.prc_i,
                        u: paymentObj?.donor_id,
                        ui: {...Cus,p_no:[Cus.phone.cn,Cus.phone.n].join('')},
                        pr_t: Proj?.p?.t,
                        pr_n: Proj?.tn,
                        cp_t: Camp?.ci?.t,
                        cp_n: Camp.tn,
                        k: Pack,
                        d: donationId,
                    };
                    try {
                        await dispatch(UpdateRecurringDonation(rec_param));
                    }catch (e) {
                        recurringMsg ='Recurring Db update failed.'
                    }
                    return { success: true, donationMsg, recurringMsg};
                }
                // await dispatch(Donation_Notify({ d_id: donation.d, email_to: [email] }));
                return { success: true, donationMsg};
            } catch (e) {
                const email_param = {
                    to: Cus.email,
                    from: "",
                    sub: "Donation Failed",
                    body: "Your payment not received please try again.",
                };
                await dispatch(
                    PaymentReceived(donationId, null, "failed", "card", email_param)
                );
                return Promise.reject(e)
            }
        }
    }));

    /**
     */
    const StripeCardElement = useMemo(()=> {
        // console.log(stripePromise)
        if (stripePromise)
        {
            return (
                <Elements stripe={stripePromise}>
                    <CardForm
                        amount={parseInt((Pack?.a || 0) * 100, 10)}
                        label={orgId}
                        ref={cardRef}
                        isRecurring={Pack?.p === "rec"}
                        package={Pack}
                        project={Proj}
                        campaign={Camp}
                        cus={Cus} // phone, name, addr, email
                        onSuccess={(obj) => {
                            console.log("!!!!!!!!!!!!!!!! : ", obj);
                        }}
                    />
                </Elements>
            )
        }
    },[stripePromise,Proj,Camp,Pack,Cus])

    // console.log(StripeCardElement)

    return(
        <>
            <h3 className="projectTitle display-3">Payment Method</h3>

            <div className="row">
                {
                    Int?.crd?.act &&
                    <Method method={'Card'}
                        selected={paymentMethod === 'crd'}
                        disabled={false}
                        OnPress={_=>SetPaymentMethod('crd')}
                    />
                }
                {
                    Int?.ppl?.act &&
                    <Method method={'PayPal'}
                        selected={paymentMethod === 'ppl'}
                        disabled={ Pack?.p === 'rec'}
                        OnPress={_=>SetPaymentMethod('ppl')}
                    />
                }
                {
                    Int?.bk?.act &&
                    <Method method={'Bank Transfer'}
                        selected={paymentMethod === 'bk'}
                        disabled={ Pack?.p === 'rec'}
                        OnPress={_=>SetPaymentMethod('bk')}
                    />
                }
                {
                    Int?.cs?.act &&
                    <Method method={'Cash'}
                        selected={paymentMethod === 'cs'}
                        disabled={ Pack?.p === 'rec'}
                        OnPress={_=>SetPaymentMethod('cs')}
                    />

                }
                {
                    Int?.cq?.act &&
                    <Method method={'Cheque'}
                        selected={paymentMethod === 'cq'}
                        disabled={ Pack?.p === 'rec'}
                        OnPress={_=>SetPaymentMethod('cq')}
                    />
                }

            </div>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                        {
                            paymentMethod === 'crd' &&
                            StripeCardElement
                        }
                        {
                            paymentMethod === 'ppl' &&
                            <PayPal amount={10} currency={"GBP"}/>
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                    <button
                        // type="submit"
                        className="btn-block btn btn-primary"
                        // disabled={isSaving_Donr || isSaving_Dont}
                        onClick={ () => navigate(-1) }
                    >
                        Back
                    </button>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                    <button
                        // type="submit"
                        className="btn-block btn btn-primary"
                        // disabled={isSaving_Donr || isSaving_Dont}
                        onClick={ () => OnPmChange( methods[paymentMethod] ) }
                    >
                        Proceed
                    </button>
                    </div>
                </div>
            </div>
        </>
    )
})

export default PaymentMethod

const Method = ({disabled,selected,OnPress,method}) => {
  return(
      <div className="col-sm-3">
          <div className="form-group">
              <button
                  className="form-control border-primary"
                  style={{
                      backgroundColor: selected ? '#28a745' : null,
                      color: selected ? '#fff' : null
                  }}
                  onClick={ e => {
                      e.preventDefault();
                      e.stopPropagation();
                      OnPress();
                  } }
                  disabled={disabled}
              >
                  {method}
              </button>
          </div>
      </div>
  )
}
