/**
 *	@copyright	KARD.
 */

/**
 */
export const Cfg_Stage = ( cfg_stage) =>
{
    return {
        type: 'cfg:stage',
        payload: cfg_stage,
    }
}   // Cfg_Stage

/**
 */
export const Cfg_DDB = ( db_name ) =>
{
    return ( _d, gs ) =>
    {
        // return gs().__cfg.db( db_name, gs().__cfg.stage )
        return gs().__cfg.ddb[gs().__cfg.stage][gs().__auth.cn.cn][db_name]
    }
}   // Cfg_DDB

/**
 */
export const Cfg_Lambda = ( lambda_name ) =>
{
    return ( _d, gs ) =>
    {
        // return gs().__cfg.db( db_name, gs().__cfg.stage )
        return gs().__cfg.lambda[gs().__cfg.stage][gs().__auth.cn.cn][lambda_name]
    }
}   // Cfg_Lambda


