/**
 * @copyright KARD
 */

import React from 'react'

import {navigate} from "@reach/router";

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import Page from '../_common/page'

/**
 */
const CampTypeNew = ( props ) =>
{
    const [isSaving, IsSaving] = React.useState( false )

    const [typeName, TypeName] = React.useState( '' )
    const [typeDesc, TypeDesc] = React.useState( '' )

    //
    const OnSubmit = ( e ) =>
    {
        e.preventDefault()
        e.stopPropagation()

        SaveType().catch( err => alert( err.message ? err.message : err ) )
    }   // OnSubmit

    //
    const SaveType = async () =>
    {
        try
        {
            IsSaving(true)

            await props.Campaign_TypeNew( {
                n: typeName,
                d: typeDesc,
            } )

            IsSaving(false)

            alert( 'New Type added' )

            navigate( '/campaign/type' )

            return {}
        }
        catch( err )
        {
            console.warn( 'CampTypeNew: SaveType: err: ', err )

            IsSaving(false)

            return Promise.reject( err )
        }
    }   // SaveType

    //
    return (
        <Page active="campaign">
            <div className="row">
                <div className="col-sm-4 col-3">
                    <h4 className="page-title">Add Campaign</h4>
                </div>
                {/*
                <div className="col-sm-8 col-9 text-right m-b-20">
                    <button onClick={()=>navigate( "/donation/int/new")} className="btn btn btn-primary btn-rounded float-right"><i className="fa fa-plus" /> Add Charity</button>
                </div>
                */}
            </div>

            <div className="row">
                {/*  */}
            </div>

            <div className="row">
                <div className="col-12">
                    <form onSubmit={ OnSubmit }>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Name <span className="text-danger">*</span></label>
                                    <input className="form-control" type="text"
                                        value={ typeName }
                                        onChange={ e => TypeName( e.target.value ) }
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Description</label>
                                    <input className="form-control" type="text"
                                        value={ typeDesc }
                                        onChange={ e => TypeDesc( e.target.value ) }
                                        // required
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="">
                                <button
                                    type="submit" className="btn btn-primary"
                                    style={{margin: 5, padding: 5}}
                                >
                                    Save
                                </button>
                            </div>
                            <div className="">
                                <button
                                    // type="cancel"
                                    className="btn btn-primary"
                                    style={{margin: 5, padding: 5, backgroundColor: 'red',}}
                                    onClick={ e => {
                                        e.preventDefault()

                                        navigate( '/campaign/type' )

                                        return false
                                    } }
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Page>
    )
}   // CampTypeNew

/**
 */
const mapStateToProps = ( state ) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( CampTypeNew )

