/**
 * @copyright KARD
 */

import React from "react";

import AuiModal from "../../aui/modal";

import ListProj from "./list_proj";
import { ListCamp } from "./list_camp";

/**
 */
const FilterView = (props) => {
    const [isModal_Proj, IsModal_Proj] = React.useState(false);
    const [isModal_Camp, IsModal_Camp] = React.useState(false);
  
    const [curProj, CurProj] = React.useState(props?.filter?.curProj);
    const [curCamp, CurCamp] = React.useState(props?.filter?.curCamp);
  
    const [isLoadingProj, IsLoadingProj] = React.useState(false);
    const [isLoadingCamp, IsLoadingCamp] = React.useState(false);
  
    const [dateSt, DateSt] = React.useState(props?.filter?.dateSt);
    const [dateEnd, DateEnd] = React.useState(props?.filter?.dateEnd);
    const [nameF, NameF] = React.useState(props?.filter?.nameF);
    const [nameL, NameL] = React.useState(props?.filter?.nameL);
  
    const [addrCity, AddrCity] = React.useState(props?.filter?.addrCity);
    // const [addrCn, AddrCn] = React.useState( props?.filter?.addrCn )
  
    const [paymMethod, PaymMethod] = React.useState(props?.filter?.paymMethod);
    const [paymStatus, PaymStatus] = React.useState(props?.filter?.paymStatus);
  
    const [amountSt, AmountSt] = React.useState(props?.filter?.amountSt);
    const [amountEnd, AmountEnd] = React.useState(props?.filter?.amountEnd);
  
    React.useEffect(() => {
      LoadProjList().catch((err) => {});
    }, []);
  
    /**
     */
    const LoadProjList = async () => {
      try {
        IsLoadingProj(true);
  
        IsLoadingProj(false);
        return {};
      } catch (err) {
        IsLoadingProj(false);
  
        return Promise.reject(err);
      }
    }; // LoadProjList
  
    /**
     */
    const LoadCampList = async () => {
      try {
        IsLoadingCamp(true);
  
        IsLoadingCamp(false);
        return {};
      } catch (err) {
        IsLoadingCamp(false);
  
        return Promise.reject(err);
      }
    }; // LoadCampList
  
    /**
     */
    const OnClose = (e) => {
      e.preventDefault();
      e.stopPropagation();
  
      props.OnClose();
    }; // OnClose
  
    /**
     */
    const OnClearAll = (e) => {
      e.preventDefault();
      e.stopPropagation();
  
      DateSt(null);
      DateEnd(null);
      NameF(null);
      NameL(null);
      AddrCity(null);
      // AddrCn( null )
      PaymMethod(null);
      PaymStatus(null);
      AmountSt(null);
      AmountEnd(null);
      CurProj(null);
      CurCamp(null);
  
      /// @note need to call save
    }; // OnClearAll
  
    /**
     */
    const OnSave = (e) => {
      e.preventDefault();
      e.stopPropagation();
  
      const name_f = nameF ? nameF.trim() : "";
      const name_l = nameL ? nameL.trim() : "";
      const addr_city = addrCity ? addrCity.trim() : "";
      // const addr_cn = addrCn ? addrCn.trim() : ''
  
      props.OnFilter({
        dateSt,
        dateEnd,
        nameF: name_f.length ? name_f : null,
        nameL: name_l.length ? name_l : null,
        addrCity: addr_city.length ? addr_city : null, // addrCn: addr_cn.length ? addr_cn : null,
        curProj,
        curCamp,
        paymMethod,
        paymStatus,
  
        amountSt,
        amountEnd,
      });
      props.OnClose();
    }; // OnSave
  
    /**
     */
    return (
        <div className="container">
        <h3 className="projectTitle ">Date</h3>
        <div className="row">
            <div className="col-sm-6">
            From
            <input
                className="form-control"
                type="date"
                value={dateSt}
                onChange={(e) => {
                DateSt(e.target.value);
                }}
            />
            </div>
            <div className="col-sm-6">
            To
            <input
                className="form-control"
                type="date"
                value={dateEnd}
                onChange={(e) => {
                DateEnd(e.target.value);
                }}
            />
            </div>
        </div>

        <h3 className="projectTitle ">Name</h3>
        <div className="row">
            <div className="col-sm-6">
            First
            <input
                className="form-control"
                value={nameF}
                onChange={(e) => NameF(e.target.value)}
            />
            </div>
            <div className="col-sm-6">
            Last
            <input
                className="form-control"
                value={nameL}
                onChange={(e) => NameL(e.target.value)}
            />
            </div>
        </div>

        <div className="row">
            <div className="col-sm-6">
            <h3 className="projectTitle ">Project</h3>
            <>
                <button
                className="btn-block btn"
                style={{
                    fontSize: 16,
                    margin: "5px 0",
                    padding: 16,
                    color: curProj ? "#0094FF" : "#4f566a",
                    backgroundColor: curProj ? "#EFF8FF" : null,
                    borderColor: curProj ? "#0094FF" : "#dee2e6",
                }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    IsModal_Proj(true);
                }}
                >
                {isLoadingProj
                    ? "Loading..."
                    : curProj
                    ? curProj.p.t
                    : "Select Project"}
                </button>
            </>
            </div>
            <div className="col-sm-6">
            <h3 className="projectTitle ">Campaign</h3>
            <>
                <button
                className="btn-block btn"
                style={{
                    fontSize: 16,
                    margin: "5px 0",
                    padding: 16,
                    color: curCamp ? "#0094FF" : "#4f566a",
                    backgroundColor: curCamp ? "#EFF8FF" : null,
                    borderColor: curCamp ? "#0094FF" : "#dee2e6",
                }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    IsModal_Camp(true);
                }}
                disabled={!curProj}
                >
                {isLoadingCamp
                    ? "Loading..."
                    : curCamp
                    ? curCamp.ci.t
                    : "Select Campaign"}
                </button>
            </>
            </div>
        </div>

        <div className="row mt-4">
            <div className="col-sm-4">
            <h3 className="projectTitle">City</h3>
            <input
                className="form-control"
                value={addrCity}
                onChange={(e) => AddrCity(e.target.value)}
            />
            </div>

            <div className="col-sm-4">
            <h3 className="projectTitle">Payment Method</h3>
            <select
                className="form-control"
                value={paymMethod}
                onChange={(e) =>
                PaymMethod(e.target.value === "Select" ? null : e.target.value)
                }
            >
                <option>Select</option>
                <option>Card</option>
                <option>Cash</option>
                <option>Bank Transfer</option>
            </select>
            </div>

            <div className="col-sm-4">
            <h3 className="projectTitle">Status</h3>
            <select
                className="form-control"
                value={paymStatus}
                onChange={(e) =>
                    PaymStatus(e.target.value === "Select" ? null : e.target.value)
                }
            >
                <option value=''>Select</option>
                <option value='received'>Received</option>
                <option value='refunded'>Refunded</option>
                <option value='failed'>Failed</option>
                <option value='new'>Commited</option>
                <option value='init'>Processing</option>
            </select>
            </div>
        </div>

        <h3 className="projectTitle ">Amount</h3>
        <div className="row">
            <div className="col-sm-6">
            From
            <input
                className="form-control"
                value={amountSt}
                onChange={(e) => AmountSt(e.target.value)}
            />
            </div>
            <div className="col-sm-6">
            To
            <input
                className="form-control"
                value={amountEnd}
                onChange={(e) => AmountEnd(e.target.value)}
            />
            </div>
        </div>

        <div className="col-sm-12 btn-group mt-4 p-0">
            <button className="btn btn-info rounded mr-3 py-3" onClick={OnClose}>
            Close
            </button>

            <button className="btn btn-info rounded mr-3 py-3" onClick={OnClearAll}>
            Clear All
            </button>

            <button className="btn btn-info rounded py-3" onClick={OnSave}>
            Save
            </button>
        </div>

        <AuiModal
            bOpen={isModal_Proj}
            OnClose={() => IsModal_Proj(false)}
            className="aui-modal"
        >
            <div className="modal-donation">
            <ListProj
                pr={curProj}
                OnSelect={(proj) => {
                CurProj(proj);
                CurCamp(null);
                }}
                OnClose={() => IsModal_Proj(false)}
            />
            </div>
        </AuiModal>

        <AuiModal bOpen={isModal_Camp} OnClose={() => IsModal_Camp(false)}>
            <div className="modal-camp">
            <ListCamp
                pr={curProj}
                cp={curCamp}
                OnSelect={(camp) => {
                console.log("Donations_Internal_New: ListCamp: camp: ", camp);

                CurCamp(camp);
                }}
                OnClose={() => IsModal_Camp(false)}
            />
            </div>
        </AuiModal>
        </div>
    );
}; // FilterView
  
/**
 */
export default FilterView
