/**
 *	@copyright	KARD.
 */

import * as Cfg from '../../_cfg'
import {Core, Lambda} from '../../api/AWS'
export const SavePyamentService = (props) => {
  return async (d, gs)=>{
      try {
          const aws_lambda = new Lambda( {} );

          const aws_core = new Core( {} );
          const cur_usr = await aws_core.currentuser();
          const params = {
              actType: 'charity',
              act: 'charity:save-paym-service',
              c_id: window.localStorage.getItem( 'org_id' ),

              usr: {
                  ...cur_usr.attributes,
                  username: cur_usr.username,
              },

              data: {
                  ...props,
              },
          }

          console.log( 'action/paym_stripe:SaveIndependentStripe: params: ', params );

          const resp_list = await aws_lambda.Invoke( params, Cfg.lambda[Cfg.stage].admin );

          console.log( 'action/paym_stripe:SaveIndependentStripe: ', resp_list );
      }catch (e) {
          console.warn("action/paym_stripe:SaveIndependentStripe: error:",e);
          return Promise.reject(e);
      }
    }
}
