/**
 *	@copyright	KARD.
 */

import * as Cfg from '../../_cfg'
import {Lambda, Core} from '../../api/AWS'
import {ProcessContent, uploadFile} from "./storage";

/**
 */
export const Campaign_New = ( camp_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const {pkg_rec_lst,pkg_lst} = camp_obj.pkg_lst.reduce((acc,cur)=>{
                if (cur.p === 'rec')
                {
                    let ta = acc.pkg_rec_lst
                    ta.push({...cur,a: cur.a ? parseInt( cur.a*100, 10 ) : 0})
                    acc.pkg_rec_lst = [...ta]
                }
                else if (cur.p === 'one')
                {
                    let ta = acc.pkg_lst
                    ta.push({...cur,a: cur.a ? parseInt( cur.a*100, 10 ) : 0})
                    acc.pkg_lst = [...ta]
                }

                return acc;
            },{pkg_rec_lst:[],pkg_lst:[]})

            const p_list = {
                actType: 'camp',
                act: 'camp:new',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    camp_t: camp_obj.camp_t,

                    title: camp_obj.title,
                    title_short: camp_obj.title_short,
                    desc: camp_obj.desc,
                    desc_short: camp_obj.desc_short,

                    dt_s: camp_obj.dt_s,
                    dt_e: camp_obj.dt_e,

                    camp_status: camp_obj.camp_status,

                    pr_n: camp_obj.pr_n,
                    pr_t: camp_obj.pr_t,
                    ct:camp_obj.ct,
                    amount_target: camp_obj.amount_target ? parseInt( camp_obj.amount_target*100, 10) : 0,
                    amount_raised: camp_obj.amount_raised ? parseInt( camp_obj.amount_raised*100, 10) : 0,
                    pkg_lst,
                    pkg_rec_lst,
                },
            }

            console.log( 'actions/campaign: Campaign_New: p_list: ', p_list )

            // return {msg:'test'}

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )
            if (!resp_list.Item)
            {
                throw new Error("Failed to create campaign!")
            }
            console.log( 'actions/campaign: Campaign_New: resp_list: ', resp_list )
            camp_obj['tn'] = resp_list.Item.tn
            camp_obj['i'] = resp_list.Item.i

            const {success,campaign} = await d(UpdateContent(camp_obj))
            if (success)
            {
                return {campaign}
            }

            //
            return {campaign:resp_list}
        }
        catch( err )
        {
            console.warn( 'actions/campaign: Campaign_New: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Campaign_New

/**
 */
export const SaveCampaignImage = (params) =>
{
    return async (d,gs)=> {
        try {
            const aws_lambda = new Lambda({})
            const aws_core = new Core({})
            const cur_usr = await aws_core.currentuser()
            const p_list = {
                actType: 'camp',
                act: 'camp:img-update',
                c_id: gs().__auth.orgId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                   img:{
                       c:{
                           f:params.url,
                           t:""
                       },
                       f:[],
                       g:[]
                   },
                    tn:params.tn
                },
            }

            console.log( 'actions/campaign: SaveCampaignImage: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/campaign: SaveCampaignImage: resp_list: ', resp_list )

            //
            return resp_list

        } catch (e) {
            console.warn("action/campaign: SaveCampaignImage:error", e)
            return Promise.reject(e);
        }
    }
}

/**
 */
export const Campaign_Edit = ( camp_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()
            const {pkg_rec_lst,pkg_lst} = camp_obj.pkg_lst.reduce((acc,cur)=>{
                if (cur.p === 'rec')
                {
                    let ta = acc.pkg_rec_lst
                    ta.push({...cur,a: cur.a ? parseInt( cur.a*100, 10 ) : 0})
                    acc.pkg_rec_lst = [...ta]
                }
                else if (cur.p === 'one')
                {
                    let ta = acc.pkg_lst
                    ta.push({...cur,a: cur.a ? parseInt( cur.a*100, 10 ) : 0})
                    acc.pkg_lst = [...ta]
                }

                return acc;
            },{pkg_rec_lst:[],pkg_lst:[]})
            camp_obj.ct = await d(ProcessContent(camp_obj));
            const p_list = {
                actType: 'camp',
                act: 'camp:edit',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    camp_t: camp_obj.camp_t,

                    title: camp_obj.title,
                    title_short: camp_obj.title_short,
                    desc: camp_obj.desc,
                    desc_short: camp_obj.desc_short,

                    dt_s: camp_obj.dt_s,
                    dt_e: camp_obj.dt_e,
                    tn: camp_obj.cp_n,

                    camp_status: camp_obj.camp_status,

                    pr_n: camp_obj.pr_n,
                    cr_n: camp_obj.cr_n,
                    pr_t: camp_obj.pr_t,
                    ct:camp_obj.ct,
                    amount_target: camp_obj.amount_target ? parseInt( camp_obj.amount_target*100, 10) : 0,
                    amount_raised: camp_obj.amount_raised ? parseInt( camp_obj.amount_raised*100, 10) : 0,
                    pkg_lst,
                    pkg_rec_lst,
                },
            }

            console.log( 'actions/campaign: Campaign_Edit: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/campaign: Campaign_Edit: resp_list: ', resp_list )

            //
            return resp_list
        }
        catch( err )
        {
            console.warn( 'actions/campaign: Campaign_Edit: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Campaign_Edit

/**
 */
export const Campaign_Del = ( camp_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'camp',
                act: 'camp:del',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {...camp_obj},
            }

            // console.log( 'actions/campaign: Campaign_Del: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/campaign: Campaign_Del: resp_list: ', resp_list )

            //
            return resp_list
        }
        catch( err )
        {
            console.warn( 'actions/campaign: Campaign_Del: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Campaign_Del

/**
 */
export const CampaignQuery = ( filterObj, last_key ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'camp',
                act: 'camp:query',
                c_id: gs().__auth.orgId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    last_key,
                    ...filterObj
                },
            }

            console.log( 'actions/campaign: Campaign_List: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/campaign: Campaign_List: resp_list: ', resp_list )

            //
            // return resp_list
            const mapped_data = resp_list.Items.map( x => {
                try
                {
                    return {
                        ...x,
                        a: {...x.a, t: x.a.t / 100.0, r: x.a.r / 100.0 , nr: x.a.nr ? x.a.nr / 100.0 : 0},
                        pk: x.pk.map( y => {
                            return {...y, a: y.a / 100.0}
                        } ),
                    }
                }
                catch( err )
                {
                    return x
                }
            } )

            //
            return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'actions/campaign: Campaign_List: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Campaign_List


/**
 */
export const Campaign_List = ( last_key, n_elem ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'camp',
                act: 'camp:list',
                c_id: gs().__auth.orgId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    last_key,
                    n_elem,
                },
            }

            console.log( 'actions/campaign: Campaign_List: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/campaign: Campaign_List: resp_list: ', resp_list )

            //
            // return resp_list
            const mapped_data = resp_list.Items.map( x => {
                try
                {
                    return {
                        ...x,
                        a: {...x.a, t: x.a.t / 100.0, r: x.a.r / 100.0 , nr: x.a.nr ? x.a.nr / 100.0 : 0},
                        pk: x.pk.map( y => {
                            return {...y, a: y.a / 100.0}
                        } ),
                    }
                }
                catch( err )
                {
                    return x
                }
            } )

            //
            return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'actions/campaign: Campaign_List: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Campaign_List

/**
 */
export const Campaign_List_Project = ( pr_n, last_key ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'camp',
                act: 'camp:list-proj',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {pr_n: pr_n, last_key: last_key},
            }

            // console.log( 'actions/campaign: Campaign_List_Project: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/campaign: Campaign_List_Project: resp_list: ', resp_list )

            //
            // return resp_list
            const mapped_data = resp_list.Items.map( x => {
                try
                {
                    return {
                        ...x,
                        a: {...x.a, t: x.a.t / 100.0, r: x.a.r / 100.0},
                        pk: x.pk.map( y => {
                            return {...y, a: y.a / 100.0}
                        } ),
                    }
                }
                catch( err )
                {
                    return x
                }
            } )

            //
            return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'actions/campaign: Campaign_List_Project: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Campaign_List

/**
 */
export const Campaign_Details = ( tn ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_get = {
                actType: 'camp',
                act: 'camp:details',

                c_id: gs().__auth.orgId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    tn
                },
            }

            // console.log( 'actions/project: Campaign_Details: p_get: ', p_get )

            const resp_get = await aws_lambda.Invoke( p_get, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/project: Campaign_Details: resp_get: ', resp_get )

            //
            // return resp_get
            return {
                ...resp_get,
                Item: {
                    ...resp_get.Item,
                    a: {
                        ...resp_get.Item.a,
                        t: resp_get.Item.a.t / 100.0,
                        r: resp_get.Item.a.r / 100.0,
                        nr: resp_get.Item.a.nr / 100.0,
                    },
                    pk: resp_get.Item.pk.map( y => {
                        return {...y, a: y.a / 100.0}
                    } ),
                },
            }
        }
        catch( err )
        {
            console.warn( 'actions/project: Campaign_Details: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Campaign_Details


const UpdateContent = (params) =>
{
    return async (d, gs)=>{
        try {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()
            if (!cur_usr)
            {
                throw new Error("User not found!")
            }
            params.ct = await d(ProcessContent(params));

            const upd_p = {
                actType: 'camp',
                act: 'camp:content-update',
                c_id: gs().__auth.orgId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data:{
                    tn:params.tn,
                    i:params.i,
                    ct:params.ct,
                }
            }

            console.log( 'actions/campaign: UpdateContent: p_list: ', upd_p )

            const {success, campaign} = await aws_lambda.Invoke( upd_p, Cfg.lambda[Cfg.stage].admin )
            console.log( 'actions/campaign: UpdateContent: resp_new: ', campaign )
            if (!success)
            {
                return success
            }
            return {success,campaign:campaign.data.Attributes}

        }catch (e) {
            console.warn(e);
            return Promise.reject(e);
        }
    }
}

