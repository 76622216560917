
/**
 */
export default ( state = {
    info:{}
}, action ) =>
{
    switch( action.type )
    {


        case 'charity:info':
            return {...state, info: action.payload}
        default:
            return state
    }   // switch ...
}   // export default



