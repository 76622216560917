/**
 * @copyright KARD
 */

import React from 'react'

/**
 */
export default ( props ) =>
{
    return (
        <select
            // className="form-control"
            className={props.className ? props.className : null}
            value={props.value}
            onChange={ e => props.OnChange ? props.OnChange( e.target.value ) : null }
            required={props.bRequired ? props.bRequired : false}
            disabled={props.bDisabled}
        >
            <option value=''>Select one</option>
            <option value='act'>Active</option>
            <option value='dis'>Disabled</option>
        </select>
    )
}




