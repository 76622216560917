/**
 * @copyright KARD
 */

import React from 'react'

import {
	// Button,
} from '@material-ui/core'

// import { navigate } from "@reach/router"

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

// import Hdr from '../_common/hdr'
// import SideBar from '../_common/sidebar'
// import ChatNotif from '../_common/chat_notifications'

/**
 */
class Forgotten extends React.PureComponent
{
    render()
    {
        return (
            <div className="main-wrapper account-wrapper">
                <div className="account-page">
                    <div className="account-center">
                        <div className="account-box">
                            <form className="form-signin" action="#">
                                <div className="account-logo">
                                    <a href="/dashboard"><img src="/assets/img/logo.png" alt="" /></a>
                                </div>
                                <div className="form-group">
                                    <label>Enter Your Email</label>
                                    <input type="text" className="form-control" autofocus />
                                </div>
                                <div className="form-group text-center">
                                    <button className="btn btn-primary account-btn" type="submit">Reset Password</button>
                                </div>
                                <div className="text-center register-link">
                                    <a href="/auth/login">Back to Login</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}   // Forgotten

/**
 */
const mapStateToProps = ( state ) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Forgotten )

