/**
 *  @copyright KARD.
 */

import {core} from "./_core";

export const stage = core.stage
//
export const ddb = {
    live: {
        proj_list: 'ec_projectlist_uk-live',
        camp_list: 'ec_campaignlist_uk-live',
        dont_list: 'ec_donationcrm_uk-live',
        donr_list: 'ec_donorlist_uk-live',
    },
    test: {
        proj_list: 'ec_projectlist_uk-test',
        camp_list: 'ec_campaignlist_uk-test',
        dont_list: 'ec_donationcrm_uk-test',
        donr_list: 'ec_donorlist_uk-test',
    },
}   // db

export const lambda = {
    live: {
        app: 'mi-charityman-live-app-QgL8BHc0CZ4f',
        admin: 'mi-charityman-live-admin-J0SmvTC0onkh',
        comm: 'mi-charityman-test-comm-QJaQW9iMCH10',
        unauth: 'mi-charityman-live-unauth-0hXeumYOlhjX',
        // app: 'mi-charityman-live-app-LrVukkShBs7I',
        // admin: 'mi-charityman-live-admin-ogEvWdmvprvM',
    },
    test: {
        app: 'mi-charityman-test-app-vSdYCVigzJ6U',
        admin: 'mi-charityman-test-admin-aZk3wvMZNFIG',
        comm: 'mi-charityman-test-comm-QJaQW9iMCH10',
        unauth: 'mi-charityman-test-unauth-Ky4gvetIWI6k',
    },
}   // lambda

/**
 */
 export const stripe = {
        key: {
            live: 'pk_live_CsZHj0WJH3LXOllagfsTQVJO',
            test: 'pk_test_g9kLXsq7bwsQjllLFWMpJqEp',
            // live: 'pk_live_51JyfsdKdbv2X8jmA3cJNk0q3uAITvPjBj6ufie4Dh8Ht5hcLOtK7VNFf7xW0ZqLSlnH4TRe5vHchcgnNujgoLNM90028aNBbiw',
            // test: 'pk_test_51JyfsdKdbv2X8jmA4tCsVHzoiEDv0ankfzEyWwXq5oQhfJvosFp3BBf9YiSOMTTtD3ju7LG8QCOJbor8vIbD417t00Gves5QBP',
            // live: 'pk_live_CsZHj0WJH3LXOllagfsTQVJO',
            // test: 'pk_test_g9kLXsq7bwsQjllLFWMpJqEp',
            // live: 'pk_live_51KEd2dHCE5ChRPo3kGTNInsw3od0JjqU6xChrZEmRIenLaP257ZhjAgtu7NH0Pv2Dlr0rSxesyiBZm06YJp2Dz3u00Wx9H2Pk4',
            // test: 'pk_test_51JyfsdKdbv2X8jmA4tCsVHzoiEDv0ankfzEyWwXq5oQhfJvosFp3BBf9YiSOMTTtD3ju7LG8QCOJbor8vIbD417t00Gves5QBP', //old
            // test: 'pk_test_51KEd2dHCE5ChRPo3CNOKIsGKrXCgWQir68aTLDCgw5DWYLVPXJVVbR49LKK5YxHmtwBfxhZ4l7xroLfu2NK4pXuE00NcuAgjHT',
        },
        v: '2019-12-03',
}

export const logo = ()=>
{
    switch (core.o_id) {
        case 'oblulubriggs': return "/assets/img/ob-logo.png";
        case 'magnolia': return "/assets/img/littlehope-logo.png"
        default : return "../../public/assets/img/littlehope-logo.png"
    }
}
export * from './_core'


