/**
 * @copyright KARD
 */

import React from "react";

import { navigate } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";
import Page from "../_common/page";

import "./email.css";

/**
 */
const EmailCompose = (props) => {
  const [isLoading, IsLoading] = React.useState(false);

  const [listProj, ListProj] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);

  //
  React.useEffect(() => {
    Load(null).catch((err) => {});
  }, []);

  //
  const Load = async (b_more) => {
    try {
      IsLoading(true);

      const resp_list = await props.Project_List(b_more ? lastKey : null);

      // console.log( 'EmailCompose: Load: resp_list: ', resp_list )

      ListProj(
        b_more ? [...listProj, ...resp_list.Items] : [...resp_list.Items]
      );
      LastKey(resp_list.LastEvaluatedKey);

      IsLoading(false);

      return {};
    } catch (err) {
      console.warn("EmailCompose: Load: err: ", err);

      IsLoading(false);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // Load

  //
  return (
    <Page active="email">
      <div className="row">
        <div className="col-sm-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Email Sent Items</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Email Compose</a>
              </li>
            </ol>
          </nav>
        </div>
        <div className="col-sm-6 text-right">
          <p>Monday, 10 Augest 2021</p>
        </div>
      </div>

      <div className="row mt-2 mb-3">
        <div className="col">
          <h4 className="projectTitle m-0">
            <i className="fa fa-envelope fa-lg" /> Email Compose
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-9">
          <form>
            <div className="form-group">
              <label for="recipient-name" className="col-form-label">
                Recipients
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                placeholder="arif.hossain@elemelo.com"
              />
            </div>
            <div className="form-group">
              <label for="templateChoose" className="col-form-label">
                Template
              </label>
              <select class="form-control" id="templateChoose">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
            <div className="form-group">
              <label for="email-subject" className="col-form-label">
                Subject
              </label>
              <input
                type="text"
                className="form-control"
                id="email-subject"
                placeholder="Ramadan Greetings"
              />
            </div>
            <div
              class="alert alert-primary alert-dismissible fade show text-primary"
              role="alert"
            >
              <strong>Holy guacamole!</strong> Auto merge possible only when
              selecting camping from address book.
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="form-group">
              <label for="email_body" className="col-form-label">
                Email Body
              </label>
              <textarea
                class="form-control"
                id="email_body"
                rows="4"
              ></textarea>
            </div>

            <button type="button" className="btn btn-primary">
              Send
            </button>
          </form>
        </div>

        <div className="col-sm-3">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title mb-0 ml-3">
                <i className="fa fa-address-card" /> Address Book
              </h4>
            </div>
            <div className="card-body pt-0">
              <ul className="contact-list">
                <li>
                  <div className="contact-cont">
                    <div className="float-left user-img m-r-10">
                      <a href="profile.html" title="Richard Miles">
                        <img
                          src="../assets/img/user.jpg"
                          alt=""
                          className="w-40 rounded-circle"
                        />
                      </a>
                    </div>
                    <div className="contact-info">
                      <span className="contact-date">
                        Abdul Karim / abdulkarim78@gmail.com
                      </span>
                      <span className="contact-name text-ellipsis">
                        +88 0175 444 5870
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="contact-cont">
                    <div className="float-left user-img m-r-10">
                      <a href="profile.html" title="Richard Miles">
                        <img
                          src="../assets/img/user.jpg"
                          alt=""
                          className="w-40 rounded-circle"
                        />
                      </a>
                    </div>
                    <div className="contact-info">
                      <span className="contact-date">
                        Abdul Karim / abdulkarim78@gmail.com
                      </span>
                      <span className="contact-name text-ellipsis">
                        +88 0175 444 5870
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="contact-cont">
                    <div className="float-left user-img m-r-10">
                      <a href="profile.html" title="Richard Miles">
                        <img
                          src="../assets/img/user.jpg"
                          alt=""
                          className="w-40 rounded-circle"
                        />
                      </a>
                    </div>
                    <div className="contact-info">
                      <span className="contact-date">
                        Abdul Karim / abdulkarim78@gmail.com
                      </span>
                      <span className="contact-name text-ellipsis">
                        +88 0175 444 5870
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="contact-cont">
                    <div className="float-left user-img m-r-10">
                      <a href="profile.html" title="Richard Miles">
                        <img
                          src="../assets/img/user.jpg"
                          alt=""
                          className="w-40 rounded-circle"
                        />
                      </a>
                    </div>
                    <div className="contact-info">
                      <span className="contact-date">
                        Abdul Karim / abdulkarim78@gmail.com
                      </span>
                      <span className="contact-name text-ellipsis">
                        +88 0175 444 5870
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}; // EmailCompose

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(EmailCompose);
