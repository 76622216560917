/**
 * @copyright KARD
 */

import React from 'react'

import {
	// Button,
} from '@material-ui/core'

import ReactCodeInput from 'react-verification-code-input'

import { navigate } from "@reach/router"

// import Amplify from 'aws-amplify'

import Loader from "react-js-loader";

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

// import aws_cfg from '../../_cfg/auth'

// import {Loader} from '../../aui/loader'
import {Core} from '../../api/AWS'

import Country from '../../aui/input/country'
import cnList from '../../aconst/cnList'
// import cfg from '../../_cfg/app'

/**
 */
class Register extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )

        this.state = {
            showing: 'form',
            // showing: 'veri',

            cn: '',
            phone: '',
            pass: '',
        }

        this._core = props.Core ? props.Core : new Core({})
    }   // cstr

    /**
     */
    Confirm = async ( code ) =>
    {
        try
        {
            this.setState( {showing: 'confirm'} )

            const phone_no = [this.props.__auth.cn.code, this.state.phone].join('')

            console.log( 'auth:Register: Register: phone_no: ', phone_no )

            const confirm_data = await this._core.SignUp_Confirm( {
                username: phone_no,
                auth_code: code,
            } )

            console.log( 'auth:Register: Register: confirm_data: ', confirm_data )

            // await this.props.Auth_Confirm( {phone, pass} )

            // const usr_data = await this._core.SignIn({username: this.state.phone, password: this.state.pass})

            // console.log( 'auth:Register: Register: usr_data: ', usr_data )

            await this.props.Auth_Login( {phone: this.state.phone, pass: this.state.pass} )

            // this.props.IsLoggedIn( true )
            this.props.Auth_Role( 'owner' )
            // this.props.Auth_Usr( usr_data )

            this.setState( {showing: 'form'} )

            // navigate

            return {}
        }
        catch( err )
        {
            console.log( 'auth:Register: Register: err: ', err )

            this.setState( {showing: 'form'} )

            return Promise.reject( err )
        }
    }   // Confirm

    /**
     */
    Register = async ( {email, phone, pass} ) =>
    {
        try
        {
            // console.log( 'auth:Login: Register: this.state.orgName: ', this.state.orgName )
            // console.log( 'auth:Login: Register: this.state.orgID: ', this.state.orgID )
            // console.log( 'auth:Register: Register: phone: ', phone )
            // console.log( 'auth:Register: Register: pass: ', pass )
            // console.log( 'auth:Register: Register: this.state.tnc: ', this.state.tnc )

            this.setState( {showing: 'signup', email, phone, pass,} )

            // const signup_data = await this._core.SignUp( {username: phone, password: pass} )

            // console.log( 'auth:Register: Register: signup_data: ', signup_data )

            await this.props.Auth_Register( {email, phone, pass} )

            this.setState( {showing: 'veri'} )

            return {}
        }
        catch( err )
        {
            console.log( 'auth:Register: Register: err: ', err )

            this.setState( {showing: 'form'} )

            return Promise.reject( err )
        }
    }   // Register

    /**
     */
    render()
    {
        return (
            <div className="main-wrapper  account-wrapper">
                <div className="account-page">
                    <div className="account-center">
                        <div className="account-box">
                            <div className="account-logo">
                                <a href="#" onClick={()=>navigate('/dashboard')}><img src="/assets/img/logo.png" alt="" /></a>
                            </div>

                            <>
                            {
                                'form' === this.state.showing
                            ?   <RegisterForm
                                    Register={this.Register}
                                    Auth_Init={this.props.Auth_Init}
                                />

                            :   'veri' === this.state.showing
                            ?   <RegisterVeri
                                    OnComplete={ (code) => this.Confirm(code).catch( err => {} ) }
                                />

                            // :   <Loader />
                            :   <div>
                                    <Loader
                                        type="box-rectangular"
                                        bgColor={"#007bff"}
                                        title={"Authenticating ..."}
                                        color={"#007bff"}
                                        size={150}
                                    />
                                    {/* <div
                                        className="modal"
                                        style={{
                                        // margin: '0 auto'
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        background: "#d1cccc80",
                                        }}
                                    >
                                        <Loader
                                        type="box-rectangular"
                                        bgColor={"#007bff"}
                                        title={"Authenticating ..."}
                                        color={"#007bff"}
                                        size={150}
                                        />
                                    </div> */}
                                </div>
                            }
                            </>
                        </div>
                    </div>
                </div>
            </div>
        )
    }   // render

    /**
     */
    componentDidMount()
    {
        // Amplify.configure( {Auth: amplify_config.clinic} )
    }
}   // Register

/**
 */
class RegisterVeri extends React.PureComponent
{
    render()
    {
        return (
            <>
                <ReactCodeInput
                    onComplete={ (code) => this.props.OnComplete( code ) }
                />
            </>
        )
    }   // render
}   // RegisterVeri

/**
 */
class RegisterForm extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )

        this.state = {
            cn: '',
            orgName: '',
            orgID: '',
            phone: '',
            pass: '',
            tnc: false,
            errorMessage:false,
        }
    }   // cstr

    /**
     */
    OnSubmit = ( e ) =>
    {
        e.preventDefault()

        // console.log( 'auth:Register: OnSubmit: e: ', e )

        // console.log( 'auth:RegisterForm: OnSubmit: this.state.cn: ', this.state.cn )
        console.log( 'auth:RegisterForm: OnSubmit: this.state.cn: ', this.state.cn )

        // const phone_no = [this.state.cn.code, this.state.phone].join('')

        // console.log( 'auth:RegisterForm: OnSubmit: phone_no: ', phone_no )

        this.props.Register( {
            phone: this.state.phone,
            email: this.state.email,
            pass: this.state.pass
        } ).catch( () => {} )
    }   // OnSubmit

    /**
     */
    OnCn = ( cn ) =>
    {
        const cn_obj = cnList.find( x => x.val === cn )

        console.log( 'auth:RegisterForm: OnCn: cn_obj: ', cn_obj )

        if( !cn_obj )
            return {}

        this.setState({cn: cn_obj})

        this.props.Auth_Init( {cn: cn_obj, role: 'owner'} )
    }   // OnCn

    /**
     */
    render()
    {
        return (
            <form className="form-signin" onSubmit={this.OnSubmit}>
                {/*
                <div className="form-group">
                    <label>Organisation Name</label>
                    <input type="text" className="form-control"
                        value={this.state.orgName}
                        onChange={(e) => this.setState({orgName: e.target.value})}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Organisation ID</label>
                    <input type="text" className="form-control"
                        value={this.state.orgID}
                        onChange={(e) => this.setState({orgID: e.target.value})}
                        required
                    />
                </div>
                */}
                <div className="form-group">
                    <Country
                        cn={this.state.cn}
                        // OnChange={ (cn) => this.setState({cn}) }
                        OnChange={ this.OnCn }
                    />
                </div>

                <div className="form-group">
                    <label>Email</label>
                    <input type="email" className="form-control"
                        value={this.state.email}
                        onChange={(e) => this.setState({email: e.target.value})}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Phone Number</label>
                    <input type="text" className="form-control"
                        value={this.state.phone}
                        onChange={(e) => this.setState({phone: e.target.value})}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <input type="password" className="form-control"
                        value={this.state.pass}
                        onChange={(e) => this.setState({pass: e.target.value})}
                        required
                    />
                </div>
                {/*
                <div className="form-group">
                    <label>Mobile Number</label>
                    <input type="text" className="form-control" />
                </div>
                */}
                <div className="form-group checkbox">
                    <label>
                        <input type="checkbox"
                            onClick={(e) => this.setState({tnc: e.target.checked})}
                            required
                        />
                        I have read and agree the Terms &amp; Conditions
                    </label>
                </div>
                {
                    this.state.errorMessage &&
                    <div style={{borderRadius: 8, padding: 16, backgroundColor: "#fdeded"}}>
                        <div style={{color: "#5f2120", fontSize: 14, fontWeight: 600}}>
                            {this.state.errorMessage}
                        </div>
                    </div>
                }
                <div className="form-group text-center">
                    <button className="btn btn-primary account-btn" type="submit" value='submit'>Signup</button>
                </div>
                <div className="text-center login-link">
                    Already have an account? <a href="/auth/login">Login</a>
                </div>
            </form>
        )
    }   // render
}   // RegisterForm

/**
 */
const mapStateToProps = ( state ) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Register )

