/**
 *	@copyright	KARD.
 */

import * as Cfg from '../../_cfg'
import {Core, Lambda,} from '../../api/AWS'
import * as AWS from "../../api/AWS";
// import { async } from 'q'

/**
 */
export const Donation_Query = ( {
    index, last_key,
    dt_st, dt_end,
    pr_n, cp_n,
    nm_f, nm_l,
    paym_m, paym_s,
    addr_city, addr_cn,
    a_st, a_end
} ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const p_data = {
                last_key,
                index,
                dt_st,
                dt_end,
                pr_n,
                cp_n,
                nm_f,
                nm_l,
                paym_m,
                paym_s,
                addr_city,
                addr_cn,
                a_st,
                a_end,
            }

            console.log( 'actions/donation: Donation_Query: p_data: ', p_data )

            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'dont',
                act: 'dont:query',
                c_id: gs().__auth.orgId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    ...p_data,
                },
            }

            console.log( 'actions/donation: Donation_Query: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/donation: Donation_Query: resp_list: ', resp_list )

            const mapped_data = resp_list.Items.map( x => {
                try
                {
                    return {
                        ...x,
                        k: {...x.k, a: x.k.a / 100.0},
                    }
                }
                catch( err )
                {
                    return x
                }
            } )

            //
            return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'actions/donation: Donation_Query: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donation_Query

/**
 */
export const Donation_List = ( last_key ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'dont',
                act: 'dont:list',
                c_id: gs().__auth.orgId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {last_key},
            }

            console.log( 'actions/donation: Donation_List: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/donation: Donation_List: resp_list: ', resp_list )

            const mapped_data = resp_list.Items.map( x => {
                try
                {
                    return {
                        ...x,
                        k: {...x.k, a: x.k.a / 100.0},
                    }
                }
                catch( err )
                {
                    return x
                }
            } )

            //
            return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'actions/donation: Donation_List: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donation_List

/**
 */
export const DonationNew = ( dont_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_new = {
                actType: 'dont',
                act: 'dont:new',
                c_id: gs().__auth.orgId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    ...dont_obj,
                    pck: {
                        ...dont_obj.pck,
                        a: dont_obj.pck.a ? parseInt( dont_obj.pck.a * 100, 10 ) : 0,
                    },
                },
            }

            console.log( 'actions/donation: Donation_New: p_new: ', p_new )

            const resp_new = await aws_lambda.Invoke( p_new, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/donation: Donation_New: resp_new: ', resp_new )

            //
            return resp_new
        }
        catch( err )
        {
            console.warn( 'actions/donation: Donation_New: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donation_New
/**
 */
export const Donation_Received = ( dont_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_new = {
                actType: 'dont',
                act: 'dont:received:upd',
                c_id: gs().__auth.orgId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username
                },

                data: {
                    ...dont_obj
                },
            }

            console.log( 'actions/donation: Donation_Received: p_upd: ', p_new )

            const resp_upd = await aws_lambda.Invoke( p_new, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/donation: Donation_Received: resp_upd: ', resp_upd )

            return resp_upd
        }
        catch( err )
        {
            console.warn( 'actions/donation: Donation_Received: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donation_Received



/**
 */
export const PaymentReceived = ( dId, payObj,status, pm ,email_param) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_new = {
                actType: 'dont',
                act: 'dont:payment:upd',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username
                },

                data: {
                    payObj:payObj.paymentObj,
                    d:dId,
                    s:status,
                    m:pm,
                    email_p:email_param
                },
            }

            console.log( 'actions/donation: PaymentReceived: p_upd: ', p_new )

            // return {}
            const resp_upd = await aws_lambda.Invoke( p_new, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/donation: PaymentReceived: resp_upd: ', resp_upd )

            return resp_upd
        }
        catch( err )
        {
            console.warn( 'actions/donation: Donation_Received: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donation_Received

/**
 *
 */
export const UpdateRecurringDonation = (p) => {
  return async (d,gs)=>{
      try
      {
          const aws_lambda = new Lambda( {} )

          const aws_core = new Core( {} )
          const cur_usr = await aws_core.currentuser()

          const p_new = {
              actType: 'dont',
              act: 'dont:recurring',
              c_id: window.localStorage.getItem( 'org_id' ),

              usr: {
                  ...cur_usr.attributes,
                  username: cur_usr.username
              },
              data:p,
          }

          // console.log( 'actions/donation: UpdateRecurringDonation: p_upd: ', p_new )
          // return {}
          const resp_upd = await aws_lambda.Invoke( p_new, Cfg.lambda[Cfg.stage].admin )

          // console.log( 'actions/donation: UpdateRecurringDonation: resp_upd: ', resp_upd )

          return resp_upd
      }
      catch( err )
      {
          console.warn( 'actions/donation: UpdateRecurringDonation: err: ', err )

          return Promise.reject( err )
      }
  }
}

/**
 */
export const Donation_Notify = ( notify_obj ) =>
{
    return async (d, gs) =>
    {
        try
        {
            await Promise.all( [
                d( _Notify_Email( notify_obj ) )
            ] )
        }
        catch( err )
        {
            console.warn( 'actions/donation: Donation_Notify: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donation_Notify

/**
 */
const _Notify_Email = ( notify_obj ) =>
{
    return async (d, gs) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_noti = {
                actType: 'comm',
                act: 'comm:email:trn',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {...notify_obj},
            }

            // console.log( 'actions/donation: _Notify_Email: p_noti: ', p_noti )

            const resp_noti = await aws_lambda.Invoke( p_noti, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/donation: _Notify_Email: resp_noti: ', resp_noti )

            //
            return resp_noti
        }
        catch( err )
        {
            console.warn( 'actions/donation: _Notify_Email: err: ', err )

            // return Promise.reject( err )
            return {err}
        }
    }
}   // _Notify_Email

export const CreateSubscription = (p)=>
{
    return async (d, gs)=>{
        try {
            const aws_lambda = new Lambda( {} )
            const aws_core = new AWS.Core( {} )

            const cur_usr = await aws_core.currentuser()
            const p_list = {
                actType: 'stripe',
                act: "stripe:create-subscription",
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                data:p,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
            }

            console.log( 'actions/charity: CreateSubscription: p_list: ', p_list )
            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )
            console.log( 'actions/charity: CreateSubscription: resp_list: ', resp_list )

            return {msg: "ok",resp:resp_list}
        }catch (e) {
            console.warn("action/donation: CreateSubscription:error",e)
            return Promise.reject(e)
        }
    }
}


export const RDX_UpdateDonationInfo = (p) => {
  return{
      type:'donation:info',
      payload:p
  }
}
