/**
 * @copyright KARD
 */

import React, { useState } from "react";
import { Campaign_List_Project } from "../../rdx/actions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";

/**
 */
const ListCamp = (props) => {
  const dispatch = useDispatch();
  const [isLoading, IsLoading] = React.useState(false);
  const [error, SetError] = useState(null);
  const [listCamp, ListCamp] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);
  const [hasMore, HasMore] = useState(false);

  //
  React.useEffect(() => {
    console.log("ListCamp: const: props: ", props);
    Load()
      .then((p) => {
        if (p.success) {
          ListCamp(p.campaign);
        }
      })
      .catch((err) => {});
  }, []);

  //
  const Load = async () => {
    try {
      IsLoading(true);
      SetError(null);
      let cpList = [];
      do {
        const resp_list = await dispatch(
          Campaign_List_Project(props.pr.tn, hasMore ? lastKey : null)
        );
        cpList = hasMore ? [...listCamp, ...resp_list.Items] : resp_list.Items;

        HasMore(!!resp_list.LastEvaluatedKey);
        if (resp_list.LastEvaluatedKey) {
          LastKey(resp_list.LastEvaluatedKey);
        }
      } while (hasMore);

      IsLoading(false);

      if (!hasMore && !cpList.length) {
        alert("No campaigns found");
        SetError("No campaigns found");
        return props.OnClose ? props.OnClose() : null;
      }

      return { success: true, campaign: cpList };
    } catch (err) {
      console.warn("ProjectList: Load: err: ", err);

      IsLoading(false);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // Load

  //
  return (
    <div style={{ overflow: isLoading ? "hidden" : "auto" }}>
      {isLoading ? (
        <CircularProgress
          color="primary"
          size={200}
          style={{ top: "30%", left: "38%", position: "absolute" }}
        />
      ) : null}
      <div>
        {listCamp.map((x, i) => (
          <div key={i}>
            <div
              className="rounded mx-3 p-3 my-4"
              style={{
                border:
                  props.pr &&
                  props.cp &&
                  props.pr.tn === x.p &&
                  props.cp.tn === x.tn
                    ? "1px solid #fff"
                    : "1px solid #ddd",

                backgroundImage:
                  props.pr &&
                  props.cp &&
                  props.pr.tn === x.p &&
                  props.cp.tn === x.tn
                    ? "linear-gradient( #31A8FF, #008FF6)"
                    : null,
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                props.OnSelect(x);

                props.OnClose();
              }}
            >
              <h3
                // style={{ fontSize: "20px", color: "#060c27" }}
                style={{
                  color:
                    props.pr &&
                    props.cp &&
                    props.pr.tn === x.p &&
                    props.cp.tn === x.tn
                      ? "#fff"
                      : "#060c27",
                  fontSize: 20,
                  fontWeight:
                    props.pr &&
                    props.cp &&
                    props.pr.tn === x.p &&
                    props.cp.tn === x.tn
                      ? "600"
                      : "400",
                }}
              >
                {x.ci.t}
              </h3>
              {/* <hr/> */}
              <h5
                style={{
                  color:
                    props.pr &&
                    props.cp &&
                    props.pr.tn === x.p &&
                    props.cp.tn === x.tn
                      ? "#fff"
                      : "#060c27",
                  fontSize: 16,
                }}
              >
                {x.ci.d}
              </h5>
            </div>
          </div>
        ))}
      </div>

      <div>
        {lastKey && (
          <button
            className="btn btn-primary ml-3"
            style={{
              margin: 5,
              padding: 5,
            }}
            onClick={() => Load(true).catch((err) => {})}
          >
            Show More
          </button>
        )}
      </div>
    </div>
  );
}; // ListCamp
/**
 */
const ListPack = (props) => {
  const [listPack, ListPack] = React.useState(props.pk);

  //
  return (
    <div style={{ overflow: "auto" }}>
      <div>
        {listPack.map((x, i) => (
          <div key={i}>
            <div
              className="border border-primary rounded m-2 p-2"
              style={{
                backgroundColor:
                  props.pk && props.pk.tn === x.pk ? "lightgreen" : null,
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                props.OnSelect(x);

                props.OnClose();
              }}
            >
              <h3>{x.n}</h3>
              {/* <hr/> */}
              <h5>{x.ci.d}</h5>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}; // ListPack

export { ListCamp, ListPack };
