/**
 * @copyright KARD
 */

import React from "react";

// import {navigate} from "@reach/router";

// import {
// 	Grid,
// } from '@material-ui/core'

import { CircularProgress } from "@material-ui/core";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

// import * as Cfg from '../../_cfg'

// import {Time, UI} from "../../api/Utils";

// import {
//     // Core,
//     DDB,
// } from '../../api/AWS'

// import Addr from '../../aui/addr'

/**
 */
const ListProj = (props) => {
  const [isLoading, IsLoading] = React.useState(false);

  const [listProj, ListProj] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);
  const [dt, SetDt] = React.useState("");

  //
  React.useEffect(() => {
    // console.log("ListProj: const: props: ", props);

    Load(null).catch((err) => {});
  }, []);

  //
  const Load = async (b_more) => {
    try {
      IsLoading(true);

      const resp_list = await props.Project_List(b_more ? lastKey : null);

      console.log("ListProj: Load: resp_list: ", resp_list);

      ListProj(
        b_more ? [...listProj, ...resp_list.Items] : [...resp_list.Items]
      );
      SetDt(resp_list.dt_now);
      LastKey(resp_list.LastEvaluatedKey);

      IsLoading(false);

      if (!b_more && !resp_list.Items.length) {
        alert("No projects found");
        return props.OnClose ? props.OnClose() : null;
      }

      return {};
    } catch (err) {
      console.warn("ListProj: Load: err: ", err);

      IsLoading(false);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // Load

  //
  return (
    <div style={{ overflow: isLoading ? "hidden" : "" }}>
      {isLoading ? (
        <CircularProgress
          color="primary"
          size={200}
          style={{ top: "30%", left: "38%", position: "absolute" }}
        />
      ) : null}
      <div>
        {listProj.map((x, i) => (
          <div key={i}>
            <div
              className="rounded mx-3 p-4 my-4"
              style={{
                // border: "1px solid #ddd",
                border:
                  props.pr && props.pr.tn === x.tn
                    ? "1px solid #fff"
                    : "1px solid #ddd",
                backgroundImage:
                  (x.te && dt > x.te) || x.s !== "act"
                    ? "#f8f9fa"
                    : props.pr && props.pr.tn === x.tn
                    ? "linear-gradient( #31A8FF, #008FF6)"
                    : "white",
                opacity:
                  (x.te && dt > x.te) || x.s !== "act"
                    ? "0.6"
                    : props.pr && props.pr.tn === x.tn
                    ? "1"
                    : "",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (((x.te && dt > x.te) || x.s !== "act") && !props.bForceOpen)
                  return {};

                props.OnSelect(x);

                props.OnClose();
              }}
            >
              <h3
                style={{
                  color: props.pr && props.pr.tn === x.tn ? "#fff" : "#060c27",
                  fontSize: 20,
                  fontWeight: props.pr && props.pr.tn === x.tn ? "600" : "400",
                }}
              >
                {x.p.t}
              </h3>

              <h5
                style={{
                  color: props.pr && props.pr.tn === x.tn ? "#fff" : "#51586c",
                  fontSize: 16,
                }}
              >
                {x.p.d}
              </h5>
              {x.te && dt > x.te ? (
                <h6 style={{ color: "#992e36", fontSize: "12" }}>
                  <span className="badge badge-danger">Expired</span>
                </h6>
              ) : (
                ""
              )}
              {x.s !== "act" ? (
                <h6 style={{ color: "#992e36", fontSize: "12" }}>Disabled</h6>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </div>

      <div>
        {lastKey && (
          <button
            className="btn btn-primary ml-3"
            style={{
              margin: 5,
              padding: 5,
            }}
            onClick={() => Load(true).catch((err) => {})}
          >
            Show More
          </button>
        )}
      </div>
    </div>
  );
}; // ListProj

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(ListProj);
