/**
 *	@copyright	KARD.
 */

import * as Cfg from '../../_cfg'
import {Core, Lambda,} from '../../api/AWS'
// import { async } from 'q'

/**
 */
export const CrowdCampaign_Query = ( {index, last_key, dt_st, dt_end, pr_n, cp_n} ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'crowd',
                act: 'crowd:camp:query',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    last_key,
                    index,
                    dt_st,
                    dt_end,
                    pr_n,
                    cp_n,
                },
            }

            console.log( 'actions/donation: CrowdCampaign_Query: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/donation: CrowdCampaign_Query: resp_list: ', resp_list )

            const mapped_data = resp_list.Items.map( x => {
                try
                {
                    return {
                        ...x,
                        k: {...x.k, a: x.k.a / 100.0},
                    }
                }
                catch( err )
                {
                    return x
                }
            } )

            //
            return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'actions/donation: CrowdCampaign_Query: err: ', err )

            return Promise.reject( err )
        }
    }
}   // CrowdCampaign_Query

/**
 */
export const CrowdCampaign_List = ( last_key, n_elem = 10 ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'crowd',
                act: 'crowd:camp:list',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {last_key},
            }

            console.log( 'actions/donation: CrowdCampaign_List: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/donation: CrowdCampaign_List: resp_list: ', resp_list )

            const mapped_data = resp_list.Items.map( x => {
                try
                {
                    return {
                        ...x,
                        // k: {...x.k, a: x.k.a / 100.0},
                    }
                }
                catch( err )
                {
                    return x
                }
            } )

            //
            return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'actions/donation: CrowdCampaign_List: err: ', err )

            return Promise.reject( err )
        }
    }
}   // CrowdCampaign_List

/**
 */
export const CrowdDonation_Query = ( {index, last_key, dt_st, dt_end, pr_n, cp_n} ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'crowd',
                act: 'crowd:dont:query',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    last_key,
                    index,
                    dt_st,
                    dt_end,
                    pr_n,
                    cp_n,
                },
            }

            console.log( 'actions/donation: CrowdDonation_Query: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/donation: CrowdDonation_Query: resp_list: ', resp_list )

            const mapped_data = resp_list.Items.map( x => {
                try
                {
                    return {
                        ...x,
                        k: {...x.k, a: x.k.a / 100.0},
                    }
                }
                catch( err )
                {
                    return x
                }
            } )

            //
            return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'actions/donation: CrowdDonation_Query: err: ', err )

            return Promise.reject( err )
        }
    }
}   // CrowdDonation_Query

/**
 */
export const CrowdDonation_List = ( last_key, n_elem = 10 ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'crowd',
                act: 'crowd:dont:list',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {last_key},
            }

            console.log( 'actions/donation: CrowdDonation_List: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/donation: CrowdDonation_List: resp_list: ', resp_list )

            const mapped_data = resp_list.Items.map( x => {
                try
                {
                    return {
                        ...x,
                        // k: {...x.k, a: x.k.a / 100.0},
                    }
                }
                catch( err )
                {
                    return x
                }
            } )

            //
            return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'actions/donation: CrowdDonation_List: err: ', err )

            return Promise.reject( err )
        }
    }
}   // CrowdDonation_List



