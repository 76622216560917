/**
 *	@copyright	KARD.
 */

/**
 */
export default (
  state = {
    roles: [
      { id: "admin", name: "Admin", desc: "" },
      { id: "editor", name: "Editor", desc: "" },
      //{id: 'manager', name: 'Manager', desc: ''},
      // {id: 'svisor', name: 'Super Visor', desc: ''},
      { id: "analyst", name: "Analyst", desc: "" },
      //{id: 'auditor', name: 'Auditor', desc: ''},
      { id: "marketing", name: "Marketing", desc: "" },
      { id: "support", name: "Support", desc: "" },
      { id: "staff", name: "Staff", desc: "" },

      //{id: 'investor', name: 'Investor', desc: ''},
    ],
    permissions: [],
    actions: [],

    list: [],
    lastKey: null,
  },
  action
) => {
  switch (action.type) {
    case "staffs:list":
      return {
        ...state,
        list: action.payload.list,
        lastKey: action.payload.last_key,
      };

    default:
      return state;
  } // switch ...
}; // export
