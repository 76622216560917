/**
 * @copyright KARD
 */

import React, { useState } from "react";
import {
  LineChart,
  Line,
  card,
  button,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

import { Card, ProgressBar } from "react-bootstrap";

import { navigate } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

import {
  Time,
  // UI,
} from "../../api/Utils";

import AuiStatus from "../../aui/status";

import Page from "../_common/page";

import NewsFeed from "./newsfeed";
import "../donation/donate.css";
const useWindowDimensions = () => {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
};
/**
 */
const Preview = (props) => {
  const parentRef = React.useRef(null);
  // const [width, Width] = React.useState(0);
  const { height, width } = useWindowDimensions();

  const [isLoading_Proj, IsLoading_Proj] = React.useState(false);
  const [isLoading_Camp, IsLoading_Camp] = React.useState(false);

  const [curProj, CurProj] = React.useState(null);
  // const [projTitle, ProjTitle] = React.useState( '' )

  const [campTitle, CampTitle] = React.useState("");
  const [campSubTitle, CampSubTitle] = React.useState("");
  const [campDesc, CampDesc] = React.useState("");
  const [campShortDesc, CampShortDesc] = React.useState("");
  const [campType, CampType] = React.useState("std");
  const [campTarget, CampTarget] = React.useState("");
  const [campRaised, CampRaised] = React.useState("");
  const [campStatus, CampStatus] = React.useState("act");
  const [campDtSt, CampDtSt] = React.useState("N/A");
  const [campDtEnd, CampDtEnd] = React.useState(null);

  const [listPck, ListPck] = React.useState([]);
  const [img, SetImg] = useState(null);

  // const [templateSMS, TemplateSMS] = React.useState( '' )
  // const [templateEmail, TemplateEmail] = React.useState( '' )

  /**
   */
  React.useEffect(() => {
    // console.log( 'ProjectNew: React.useEffect: props: ', props )

    if (props.cp_n) {
      LoadCamp(props.cp_n)
        // .then( data => {
        //     if( !props.pr_n )
        //     {
        //         LoadProj( props.pr_n ).catch( err => {} )
        //     }
        // } )
        .catch((err) => {});
    }

    if (props.pr_n) {
      LoadProj(props.pr_n).catch((err) => {});
    }
  }, []);

  /**
   */
  // React.useEffect(() => {
  //   if (parentRef.current) {
  //     // console.log( 'Home: parentRef.current.offsetWidth: ', parentRef.current.offsetWidth )
  //     Width(parentRef.current.offsetWidth);
  //   }
  // }, [parentRef]);

  /**
   */
  const LoadProj = async (pr_n) => {
    try {
      IsLoading_Proj(true);

      const resp_proj = await props.Project_Details(parseInt(pr_n, 10));

      // console.log( 'CampaignNew: LoadProj: resp_proj: ', resp_proj )

      //
      // CurProj( resp_get.Item )
      CurProj(resp_proj.Item);

      //
      IsLoading_Proj(false);

      //
      return resp_proj;
    } catch (err) {
      console.warn("Donations_Internal_New: LoadProj: err: ", err);

      IsLoading_Proj(false);

      return { err };
    }
  }; // LoadProj

  /**
   */
  const LoadCamp = async (cp_n) => {
    try {
      IsLoading_Camp(true);

      // console.log( 'CampaignNew: LoadCamp: cp_n: ', cp_n )

      const resp_camp = await props.Campaign_Details(parseInt(cp_n, 10));

      // console.log( 'CampaignNew: LoadCamp: resp_camp: ', resp_camp )

      //
      // CurProj( resp_get.Item )
      // CurProj( resp_proj.Item )
      CampType(resp_camp.Item.t);

      if (resp_camp.Item.img) {
        SetImg(resp_camp.Item.img);
      }

      CampTitle(resp_camp.Item.ci.t);
      CampSubTitle(resp_camp.Item.ci.ts);
      CampDesc(resp_camp.Item.ci.d);
      CampShortDesc(resp_camp.Item.ci.ds);

      CampTarget(resp_camp.Item.a.t.toFixed(2));
      CampRaised(resp_camp.Item.a.r.toFixed(2));

      try {
        if (!resp_camp.Item.ts) {
          throw "";
        }

        const dt_obj = new Date(resp_camp.Item.ts * 1000);

        CampDtSt(
          [dt_obj.getDate(), dt_obj.getMonth() + 1, dt_obj.getFullYear()].join(
            "."
          )
        );
      } catch (err) {
        CampDtSt("N/A");
      }

      try {
        if (!resp_camp.Item.te) {
          throw "";
        }

        const dt_obj = new Date(resp_camp.Item.te * 1000);

        CampDtEnd(
          [dt_obj.getDate(), dt_obj.getMonth() + 1, dt_obj.getFullYear()].join(
            "."
          )
        );
      } catch (err) {
        CampDtEnd("N/A");
      }

      ListPck(resp_camp.Item.pk);

      CampStatus(resp_camp.Item.s);

      // pr_n: parseInt( curProj.tn, 10 ),
      // pr_t: curProj.p.t,

      //
      IsLoading_Camp(false);

      //
      return resp_camp;
    } catch (err) {
      console.warn("Donations_Internal_New: LoadCamp: err: ", err);

      IsLoading_Camp(false);

      return { err };
    }
  }; // LoadCamp

  return (
    <Page active="campaign">
      {/* <div className="row">
        <div className="col-sm-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Campaign</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">View</a>
              </li>
            </ol>
          </nav>
        </div>
      </div> */}

      <div className="row mt-2 mb-3">
        <div className="col-sm-8">
          <h4 className="projectTitle m-0">
            <i className="fa fa-cog fa-lg" />
            {` ${campTitle} ( ${
              curProj && curProj.p && curProj.p.t ? curProj.p.t : ""
            } )`}
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-9" ref={parentRef}>
          <div className="card">
            <Last30Days
              camp={props.cp_n}
              width={width}
              Donation_Query={props.Donation_Query}
            />
          </div>

          {/* gallery slider */}
          <div>{/*  */}</div>

          {/* show packages */}
          <div className="packg-list">
            <div>
              <h2 className="projectTitle"> Package List</h2>
            </div>

            {listPck.map((x) => (
              <Card>
                <Card.Img variant="top" />
                <div className="p-3">
                  <Card.Title className="m-0">
                    {x.n}
                    <h2 className="h2 pull-right m-0">
                      {`£${x.a.toFixed(2)} - ${
                        "one" === x.p ? "One Off" : "Recurring"
                      }`}
                    </h2>
                  </Card.Title>
                </div>
              </Card>
            ))}
          </div>

          {/* News Feed */}
          <NewsFeed pr_n={props.pr_n} cp_n={props.cp_n} />
        </div>

        <div className="col-sm-3">
          <div className="card bg-primary row">
            <div className="card-body">
              <div className="bg-white p-2 mb-3 rounded">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="contact-info">
                      <span className="contact-name text-ellipsis text-primary">
                        Target Amount
                      </span>
                      <span className="h3 text-primary font-weight-bold">
                        {/* £5000.00 */}
                        {`£${campTarget}`}
                      </span>
                      <ProgressBar
                        style={{ height: ".25rem", marginTop: "10px" }}
                        now={(campRaised * 100) / campTarget}
                      />
                      <p className="text-primary mt-2 mb-0">
                        {`£${campRaised}`} Raised of {`£${campTarget}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-white mb-4">
                <p className="text-white font-weight-bold mb-2">Details</p>
                {/* There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,
                                or randomised words which don't look even slightly believable. */}
                {campDesc}
              </div>

              <ul className="contact-list project-info">
                {/* <li>
                                    <div className="row">
                                        <div className="mr-2 ml-2">
                                            <i className="fa fa-cog fa-lg text-white" style={{border: '1px solid #fff', borderRadius: '50%',padding: '15px'}} />
                                        </div>
                                        <div className="">
                                            <div className="">
                                                <span className="text-white">Campaign</span>
                                                <span className="contact-name text-ellipsis text-white">Funding</span>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}
                {/* <li>
                                    <div className="row">
                                        <div className="mr-2 ml-2">
                                            <i className="fa fa-file fa-lg text-white" style={{border: '1px solid #fff', borderRadius: '50%',padding: '15px'}} />
                                        </div>
                                        <div className="">
                                            <div className="">
                                                <span className="text-white">Type</span>
                                                <span className="contact-name text-ellipsis text-white">Standard</span>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}
                <li>
                  <div className="row">
                    <div className="mr-2 ml-2">
                      <i
                        className="fa fa-calendar-check-o fa-lg text-white"
                        style={{
                          border: "1px solid #fff",
                          borderRadius: "50%",
                          padding: "15px",
                        }}
                      />
                    </div>
                    <div className="">
                      <div className="">
                        <span className="text-white">Duration</span>
                        <span className="contact-name text-ellipsis text-white">
                          {`${campDtSt} - ${campDtEnd}`}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div className="mr-2 ml-2">
                      <i
                        className="fa fa-power-off fa-lg text-white"
                        style={{
                          border: "1px solid #fff",
                          borderRadius: "50%",
                          padding: "15px",
                        }}
                      />
                    </div>
                    <div className="">
                      <div className="">
                        <span className="text-white">Status</span>
                        <span className="contact-name text-ellipsis text-white">
                          {"act" === campStatus ? "Active" : "Suspended"}
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <hr
                style={{
                  borderTop: "1px solid rgb(255 255 255 / 30%) ",
                }}
              />
              <div className="mt-2 mb-2 text-center">
                {/* <div className="btn btn-info mr-2"><i className="fa fa-plus" /> Add New Campaign</div> */}
                <div
                  className="btn border text-white mr-4"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    // navigate( `` )
                    navigate(`/campaign/list/${props.pr_n}/${props.cp_n}`);
                  }}
                >
                  <i className="fa fa-edit" /> Edit
                </div>
                <div
                  className="btn border text-white"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    // alert and then remove
                  }}
                >
                  <i className="fa fa-remove" /> Delete
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {img && <img src={img.c.f} alt="new" style={{ width: "100%" }} />}
          </div>
        </div>
      </div>
    </Page>
  );
}; // Preview

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Preview);

/**
 */
const Last30Days = (props) => {
  const [isBusy, IsBusy] = React.useState(false);

  // const [data, Data] = React.useState( demo_last30days )
  const [data, Data] = React.useState([]);

  /**
   */
  React.useEffect(() => {
    Load().catch((err) => {});
  }, []);

  /**
   */
  const Load = async () => {
    try {
      IsBusy(true);

      // retrieve data
      let date_st = new Date();
      let date_end = new Date();

      date_st.setDate(date_st.getDate() - 30);
      date_st.setHours(0);
      date_st.setMinutes(0);
      date_st.setSeconds(0);

      date_end.setHours(0);
      date_end.setMinutes(0);
      date_end.setSeconds(0);

      const index = "new";
      const dt_st = Time.Epoch(date_st.getTime());
      const dt_end = Time.Epoch(date_end.getTime());

      let arr_q = [];
      let last_key = null;

      do {
        const resp_q = await props.Donation_Query({
          index,
          last_key,
          dt_st,
          dt_end,
          cp_n: parseInt(props.camp, 10),
        });

        // console.log( 'Last30Days: Load: resp_q: ', resp_q )

        const tmp_arr = resp_q.Count ? resp_q.Items : [];

        arr_q = [...arr_q, ...tmp_arr];

        last_key = resp_q.LastEvaluatedKey ? resp_q.LastEvaluatedKey : null;
      } while (last_key);

      // console.log( 'Last30Days: Load: arr_q: ', arr_q )

      // cluster data
      const v_obj = arr_q.reduce((a, c) => {
        const dt_obj = new Date(c.tn * 1000);
        const dt_year = dt_obj.getFullYear().toString();
        const dt_month = (dt_obj.getMonth() + 1).toString().padStart(2, "0");
        const dt_date = dt_obj.getDate().toString().padStart(2, "0");

        const tmp_k = parseInt([dt_year, dt_month, dt_date].join(""), 10);
        const tmp_n = [dt_date].join("."); //[dt_date, dt_month].join('.')
        const tmp_o = {
          _k: tmp_k,
          _n: tmp_n,
          ...c,
        };

        if (!a[tmp_k]) {
          a[tmp_k] = [];
        }

        a[tmp_k] = [...a[tmp_k], tmp_o];

        return a;
      }, {});

      // console.log( 'Last30Days: Load: v_obj: ', v_obj )

      // const v_obj_2 =
      for (let i = 1; i <= 30; ++i) {
        const dt_obj = new Date();
        dt_obj.setDate(dt_obj.getDate() - i);
        const dt_year = dt_obj.getFullYear().toString();
        const dt_month = (dt_obj.getMonth() + 1).toString().padStart(2, "0");
        const dt_date = dt_obj.getDate().toString().padStart(2, "0");
        const tmp_k = parseInt([dt_year, dt_month, dt_date].join(""), 10);
        const tmp_n = [dt_date].join("."); //[dt_date, dt_month].join('.')

        if (!v_obj[tmp_k]) {
          v_obj[tmp_k] = [
            {
              _k: tmp_k,
              _n: tmp_n,
            },
          ];
        }
      } // for i

      //
      const v_arr = Object.keys(v_obj).reduce((a, c) => {
        try {
          return [
            ...a,
            { _k: parseInt(c, 10), _n: v_obj[c][0]._n, data: v_obj[c] },
          ];
        } catch (err) {
          return a;
        }
      }, []);

      v_arr.sort((a, b) => a._k - b._k);

      // console.log( 'Last30Days: Load: v_arr: ', v_arr )

      const f_arr = v_arr.map((x) => {
        const tmp_tot = x.data.reduce((a, c) => {
          try {
            const tot_a =
              "string" === typeof c.k.a ? parseInt(c.k.a, 10) : c.k.a;

            if ("ref" === c.s) {
              return a - tot_a;
            } else {
              return a + tot_a;
            }
          } catch (err) {
            return a;
          }
        }, 0.0);

        return {
          _k: x._k,
          _n: x._n,
          tot: tmp_tot,
          cnt: x.data.length,
        };
      });

      // console.log( 'Last30Days: Load: f_arr: ', f_arr )

      Data(f_arr);

      //
      IsBusy(false);

      return {};
    } catch (err) {
      console.warn("Last30Days: Load: err: ", err);

      IsBusy(false);

      return Promise.reject(err);
    }
  }; // Load

  /**
   */
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        width={props.width ? props.width - 30 : 650}
        height={300}
        data={data}
      >
        <XAxis dataKey="_n" />
        {/* <YAxis/> */}
        <Tooltip formatter={(value) => [value.toFixed(2), null]} />
        {/* <Legend /> */}
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="tot" stroke="#0094FF" strokeWidth={5} />
        {/* <Line type="monotone" dataKey="pv" stroke="#82ca9d" /> */}
      </LineChart>
    </ResponsiveContainer>
  );
}; // Last30Days
