/**
 *	@copyright	KARD.
 */

/**
 */
export default ( state = {
    types: [],
}, action ) =>
{
    switch( action.type )
    {
    case 'camp:types':
        return {...state, types: action.payload,}

    default:
        return state
    }   // switch ...
}   // export


