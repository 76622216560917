/**
 * @copyright KARD
 */

import React from "react";

import { navigate } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";
import Page from "../_common/page";

import "./sms.css";

/**
 */
const SmsList = (props) => {
  const [isLoading, IsLoading] = React.useState(false);

  const [listProj, ListProj] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);

  //
  return (
    <Page active="sms">
      <div className="row">
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
            </ol>
          </nav>
        </div>
        <div className="col text-right">
          <p>Monday, 10 Augest 2021</p>
        </div>
      </div>

      <div className="row mt-2 mb-3">
        <div className="col">
          <h4 className="projectTitle m-0">
            <i className="fa fa-comment fa-lg" /> SMS List
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="row mt-3 ml-1 mr-1">
              <div className="col">
                <div className="form-group has-search">
                  <span className="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Created At</th>
                    <th>Subject</th>
                    <th>Deadline</th>
                    <th>Status</th>
                    <th>Started At</th>
                    <th>Ended At</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>25-05-2019</td>
                    <td>Last 10 Night</td>
                    <td>25-05-2019</td>
                    <td>Completed</td>
                    <td>25-05-2019 22:48</td>
                    <td>25-05-2019 22:48</td>
                    <td>
                      <a href="" className="btn btn-sm">
                        <i className="fa fa-edit"></i>
                      </a>
                      <a href="" className="btn btn-sm">
                        <i className="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>25-05-2019</td>
                    <td>Last 10 Night</td>
                    <td>25-05-2019</td>
                    <td>Completed</td>
                    <td>25-05-2019 22:48</td>
                    <td>25-05-2019 22:48</td>
                    <td>
                      <a href="" className="btn btn-sm">
                        <i className="fa fa-edit"></i>
                      </a>
                      <a href="" className="btn btn-sm">
                        <i className="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>25-05-2019</td>
                    <td>Last 10 Night</td>
                    <td>25-05-2019</td>
                    <td>Completed</td>
                    <td>25-05-2019 22:48</td>
                    <td>25-05-2019 22:48</td>
                    <td>
                      <a href="" className="btn btn-sm">
                        <i className="fa fa-edit"></i>
                      </a>
                      <a href="" className="btn btn-sm">
                        <i className="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>25-05-2019</td>
                    <td>Last 10 Night</td>
                    <td>25-05-2019</td>
                    <td>Completed</td>
                    <td>25-05-2019 22:48</td>
                    <td>25-05-2019 22:48</td>
                    <td>
                      <a href="" className="btn btn-sm">
                        <i className="fa fa-edit"></i>
                      </a>
                      <a href="" className="btn btn-sm">
                        <i className="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>25-05-2019</td>
                    <td>Last 10 Night</td>
                    <td>25-05-2019</td>
                    <td>Completed</td>
                    <td>25-05-2019 22:48</td>
                    <td>25-05-2019 22:48</td>
                    <td>
                      <a href="" className="btn btn-sm">
                        <i className="fa fa-edit"></i>
                      </a>
                      <a href="" className="btn btn-sm">
                        <i className="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>

                <tfoot></tfoot>
              </table>
            </div>

            <div className="row">
              <div className="col-sm-12 ml-3 mr-3">
                <div className="pull-left">
                  <div className="dataTables_length">
                    <label className="entries">
                      Show
                      <select size="1" className="form-control show-entries">
                        <option value="10" selected="selected">
                          10
                        </option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div>

                <div className="pull-right mr-5">
                  <ul className="pagination">
                    <li className="">
                      <a
                        href="#"
                        tabindex="0"
                        className="paginate_button previous"
                        id="DataTables_Table_0_previous"
                      >
                        Previous
                      </a>
                    </li>
                    <li className="active">
                      <a tabindex="0">1</a>
                    </li>
                    <li>
                      <a tabindex="0">2</a>
                    </li>
                    <li>
                      <a tabindex="0">3</a>
                    </li>
                    <li>
                      <a
                        href="#"
                        tabindex="0"
                        className="paginate_button next"
                        id="DataTables_Table_0_next"
                      >
                        Next
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}; // SmsList

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(SmsList);
