/**
 *	@copyright	KARD.
 */

import * as Cfg from '../../_cfg'
import {Core, Lambda} from '../../api/AWS'

/**
 */
export const Donor_List = ( last_key ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'donr',
                act: 'donr:list',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {last_key},
            }

            // console.log( 'actions/project: Donor_List: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/project: Donor_List: resp_list: ', resp_list )

            //
            return resp_list
        }
        catch( err )
        {
            console.warn( 'actions/project: Donor_List: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donor_List

/**
 */
export const Donor_New = ( donr_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_new = {
                actType: 'donr',
                act: 'donr:new',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {...donr_obj},
            }

            // console.log( 'actions/project: Donor_New: p_new: ', p_new )

            const resp_new = await aws_lambda.Invoke( p_new, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/project: Donor_New: resp_new: ', resp_new )

            //
            return resp_new
        }
        catch( err )
        {
            console.warn( 'actions/project: Donor_New: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donor_New

/**
 */
export const Donor_Get = ( p_no ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_new = {
                actType: 'donr',
                act: 'donr:get',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {p_no},
            }

            // console.log( 'actions/project: Donor_Get: p_new: ', p_new )

            const resp_new = await aws_lambda.Invoke( p_new, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/project: Donor_Get: resp_new: ', resp_new )

            //
            return resp_new
        }
        catch( err )
        {
            console.warn( 'actions/project: Donor_Get: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donor_New

/**
 */
export const Donor_Last = ( p_no ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'donr',
                act: 'donr:last',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {p_no},
            }

            // console.log( 'actions/project: Donor_Last: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/project: Donor_Last: resp_list: ', resp_list )

            //
            return resp_list
        }
        catch( err )
        {
            console.warn( 'actions/project: Donor_Last: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Donor_Last

export const GetDonorProfile = (donorId) =>
{
    return async (d, gs)=>{
        try {
            if (!donorId)
            {
                throw new Error("User id not found!")
            }
            const aws_lambda = new Lambda( {} );
            const aws_core = new Core( {} );
            const cur_usr = await aws_core.currentuser();
            const p_get = {
                actType: 'donr',
                act: 'donor:getProfile',
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    donorId
                },
            }

            // console.log( 'actions/donor: GetDonorProfile: p_get: ', p_get )

            const resp = await aws_lambda.Invoke( p_get, Cfg.lambda[Cfg.stage].admin )
            // console.log( 'actions/donor: GetDonorProfile: resp: ', resp )
            if (!resp)
            {
                throw new Error("Donor not found.")
            }
            return {...resp}
        }catch (e) {
            console.warn('action/donor:GetDonorProfile:err',e);
            return Promise.reject(e);
        }
    }
}
export const GetDonorDonationList = (phoneNo) =>
{
    return async (d, gs)=>{
        try {
            if (!phoneNo)
            {
                throw new Error("Phone no not found!")
            }
            const aws_lambda = new Lambda( {} );
            const aws_core = new Core( {} );
            const cur_usr = await aws_core.currentuser();
            const p_get = {
                actType: 'donr',
                act: 'donor:getDonorDonationList',
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    phone:phoneNo,
                    // n_elem:20,
                },
            }

            // console.log( 'actions/donor: GetDonorDonationList: p_get: ', p_get )

            const resp = await aws_lambda.Invoke( p_get, Cfg.lambda[Cfg.stage].admin )
            // console.log( 'actions/donor: GetDonorDonationList: resp: ', resp )
            if (!resp)
            {
                throw new Error("DonorDonationList not found.")
            }
            return {...resp}
        }catch (e) {
            console.warn('action/donor:GetDonorProfile:err',e);
            return Promise.reject(e);
        }
    }
}
export const GetDonorRecurringDonationList = (phoneNo) =>
{
    return async (d, gs)=>{
        try {
            if (!phoneNo)
            {
                throw new Error("Phone no not found!")
            }
            const aws_lambda = new Lambda( {} );
            const aws_core = new Core( {} );
            const cur_usr = await aws_core.currentuser();
            const p_get = {
                actType: 'donr',
                act: 'donor:getDonorRecurringDonationList',
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    phone:phoneNo,
                },
            }

            // console.log( 'actions/donor: GetDonorRecurringDonationList: p_get: ', p_get )

            const resp = await aws_lambda.Invoke( p_get, Cfg.lambda[Cfg.stage].admin )
            // console.log( 'actions/donor: GetDonorRecurringDonationList: resp: ', resp )
            if (!resp)
            {
                throw new Error("DonorRecurringDonationList not found.")
            }
            return {...resp}
        }catch (e) {
            console.warn('action/donor:GetDonorRecurringDonationList:err',e);
            return Promise.reject(e);
        }
    }
}
export const GetDonorCampaignList = (donorId) =>
{
    return async (d, gs)=>{
        try {
            if (!donorId)
            {
                throw new Error("donorId no not found!")
            }
            const aws_lambda = new Lambda( {} );
            const aws_core = new Core( {} );
            const cur_usr = await aws_core.currentuser();
            const p_get = {
                actType: 'donr',
                act: 'donor:getDonorCampaignList',
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    donorId,
                    // n_elem:20,
                },
            }

            // console.log( 'actions/donor: GetDonorDonationList: p_get: ', p_get )

            const resp = await aws_lambda.Invoke( p_get, Cfg.lambda[Cfg.stage].admin )
            // console.log( 'actions/donor: GetDonorDonationList: resp: ', resp )
            if (!resp)
            {
                throw new Error("DonorDonationList not found.")
            }
            return {...resp}
        }catch (e) {
            console.warn('action/donor:GetDonorProfile:err',e);
            return Promise.reject(e);
        }
    }
}
export const GetDonorSubscriptionList = (email) =>
{
    return async (d, gs)=>{
        try {
            if (!email)
            {
                throw new Error("Email no not found!")
            }
            const aws_lambda = new Lambda( {} );
            const aws_core = new Core( {} );
            const cur_usr = await aws_core.currentuser();
            const p_get = {
                actType: 'donr',
                act: 'donor:getDonorSubsList',
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    email,
                    // n_elem:20,
                },
            }

            console.log( 'actions/donor: GetDonorDonationList: p_get: ', p_get )

            const resp = await aws_lambda.Invoke( p_get, Cfg.lambda[Cfg.stage].admin )
            // console.log( 'actions/donor: GetDonorDonationList: resp: ', resp )
            if (!resp)
            {
                throw new Error("DonorDonationList not found.")
            }
            return {...resp}
        }catch (e) {
            console.warn('action/donor:GetDonorProfile:err',e);
            return Promise.reject(e);
        }
    }
}

