/**
 * @copyright KARD
 */

import React from "react";

import { navigate, Link } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";
import Page from "../_common/page";
import { LinearProgress } from "@material-ui/core";

/**
 */
const DonationView = (props) => {
  const [isLoading, IsLoading] = React.useState(false);

  const [list, List] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);

  //
  React.useEffect(() => {
    Load(null).catch((err) => {});
  }, []);

  //
  const Load = async (b_more) => {
    try {
      IsLoading(true);

      const resp_list = await props.CrowdDonation_List(b_more ? lastKey : null);

      console.log("DonationView: Load: resp_list: ", resp_list);

      List(b_more ? [...list, ...resp_list.Items] : resp_list.Items);
      LastKey(resp_list.LastEvaluatedKey);

      IsLoading(false);

      return {};
    } catch (err) {
      console.warn("ProjectList: Load: err: ", err);

      IsLoading(false);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // Load

  //
  return (
    <Page active="crowd">
      <div className="row">
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Crowd</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Donation</a>
              </li>
            </ol>
          </nav>
        </div>
        <div className="coltext-right">
          <p>Monday, 10 Augest 2021</p>
        </div>
      </div>
      <div className="row mt-2 mb-3">
        <div className="col">
          <h4 className="projectTitle m-0">
            {" "}
            <i className="fa fa-address-card" /> All Donation
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="row mt-3 ml-1 mr-1">
              <div className="col-sm-4 col">
                <div className="form-group has-search">
                  <span className="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="col-sm-8 col text-right">
                <button
                  className="btn btn btn-primary mr-1"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  data-whatever="@getbootstrap"
                >
                  <i className="fa fa-plus" /> Add New Donation
                </button>
                {/* <button className="btn btn btn-primary "> Upload Bulk</button> */}
              </div>
            </div>

            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Donor</th>
                    <th>Project</th>
                    <th>Campaign</th>
                    <th>City</th>
                    <th>Amount</th>
                    <th>Payment Methods</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    <LinearProgress color="primary" />
                    <LinearProgress color="success" />
                    <LinearProgress color="inherit" />
                  </div>
                ) : null}
                <tbody>
                  <tr>
                    <td>11.11.2021</td>
                    <td>Amelia Michigun</td>
                    <td>Clean Water</td>
                    <td>Deep Tubewell</td>
                    <td>Niger</td>
                    <td>500</td>
                    <td>Card</td>
                    <td>
                      <div className="switch_box box_1">
                        <input
                          type="checkbox"
                          className="switch_1"
                          disabled=""
                          checked=""
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="row">
              <div className="col-sm-12 ml-3 mr-3">
                <div className="pull-left">
                  <div className="dataTables_length">
                    <label className="entries">
                      Show
                      <select size="1" className="form-control show-entries">
                        <option value="10" selected="selected">
                          10
                        </option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div>

                <div className="pull-right mr-5">
                  <ul className="pagination">
                    <li className="">
                      <a
                        href="#"
                        tabindex="0"
                        className="paginate_button previous"
                        id="DataTables_Table_0_previous"
                      >
                        Previous
                      </a>
                    </li>
                    <li className="active">
                      <a tabindex="0">1</a>
                    </li>
                    <li>
                      <a tabindex="0">2</a>
                    </li>
                    <li>
                      <a tabindex="0">3</a>
                    </li>
                    <li>
                      <a
                        href="#"
                        tabindex="0"
                        className="paginate_button next"
                        id="DataTables_Table_0_next"
                      >
                        Next
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal start */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <NewPerson />
        </div>
      </div>
      {/* End Modal */}
    </Page>
  );
}; // DonationView

/**
 */

/**
 */
const NewPerson = (props) => {
  // const OnClose = () =>
  // {
  //     //
  // }

  //
  const OnSubmit = () => {
    //
  }; // OnSubmit

  //
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title projectTitle" id="exampleModalLabel">
          Add New Donation
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <form>
          <div className="form-group">
            <label for="recipient-name" className="col-form-label">
              Donor Name *
            </label>
            <input
              type="text"
              className="form-control"
              id="recipient-name"
              placeholder="Test Campaign"
              required
            />
          </div>

          <div className="form-row">
            <div className="col-md-6">
              <label for="p_name" className="col-form-label">
                Project Name *
              </label>
              <select className="form-control" required>
                <option>Select</option>
                <option value="">Clean Water</option>
                <option value="">Education</option>
              </select>
            </div>
            <div className="col-md-6">
              <label for="e_date" className="col-form-label">
                Campaign Name *
              </label>
              <select className="form-control" required>
                <option>Campaign 1</option>
                <option>Campaign 2</option>
              </select>
            </div>
          </div>
          <div className="form-row mt-2 mb-2">
            <div className="col-md-6">
              <label for="city" className="col-form-label">
                City *
              </label>
              <input
                type="text"
                className="form-control"
                id="city"
                placeholder="London"
                required
              />
            </div>
            <div className="col-md-6">
              <label for="amount" className="col-form-label">
                Total Amount *
              </label>
              <input
                type="number"
                className="form-control"
                id="amount"
                placeholder="2000"
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="col-md-6">
              <label className="col-form-label">Payment Method</label>
              <select className="form-control" required>
                <option>Select</option>
                <option value="">Stripe</option>
                <option value="">Credit Card</option>
              </select>
            </div>
            <div className="col-md-6">
              <label className="col-form-label">Status</label>
              <select className="form-control">
                <option>Active</option>
                <option>InActive</option>
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-dark"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={OnSubmit}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}; // NewPerson

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(DonationView);
