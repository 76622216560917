/**
 *	@copyright	KARD.
 */

import {core} from "../../_cfg";

/**
 */
export default ( state = {
    stage: core.stage,

    lambda: {
        live: {
            bd: {
                roi: 'ac-southeast-1',
                dt: 0,
                clinic: 'clinics-live-back',
            },
            uk: {
                roi: 'eu-west-2',
                dt: 0,
                clinic: 'clinics-live-app-40KHGT981WGE'
            },
        },
        test: {
            bd: {
                roi: 'ac-southeast-1',
                dt: 0,
                clinic: 'clinics-dev-back',
            },
            uk: {
                roi: 'eu-west-2',
                dt: 0,
                clinic: 'clinics-dev-app-19NO11HBLGEJ4',
            },
        },
    },
    ddb: {
        live: {
            bd: {
                roi: 'ac-southeast-1',
                dt: 0,
                'hm-docs': 'clinics-live-hm-docs',
                'hm-pats': 'clinics-live-hm-pats',
                'hm-pats-fid': 'clinics-live-hm-pats-fid',
                'clinic-info': 'clinics-live-clinic-info',
                'clinic-docs': 'clinics-live-clinic-docs',
                'clinic-pats': 'clinics-live-clinic-pats',
            },
            uk: {
                roi: 'eu-west-2',
                dt: 0,
            },
        },
        test: {
            bd: {
                roi: 'ac-southeast-1',
                dt: 0,
                'hm-docs': 'clinics-dev-hm-docs',
                'hm-pats': 'clinics-dev-hm-pats',
                'hm-pats-fid': 'clinics-dev-hm-pats-fid',
                'clinic-info': 'clinics-dev-clinic-info',
                'clinic-docs': 'clinics-dev-clinic-docs',
                'clinic-pats': 'clinics-dev-clinic-pats',
            },
            uk: {
                roi: 'eu-west-2',
                dt: 0,
            },
        },
    },
}, action ) =>
{
    switch( action.type )
    {
    case 'cfg:stage':
        return {...state, stage: action.payload}

    default:
        return state
    }   // switch ...
}   // export default



