import React from "react";

const Item = ({ item, isSelected, isExp, isDisable, OnSelect }) => {
  const title = item?.p?.t || item?.ci?.t;
  const details = item?.p?.d || item?.ci?.d;
  return (
    <div key={item.tn}>
      <div
        className="rounded mx-3 p-3 my-4"
        style={{
          border: isSelected ? "1px solid #fff" : "1px solid #ddd",
          backgroundImage:
            isExp || isDisable
              ? "#f8f9fa"
              : isSelected
              ? "linear-gradient( #31A8FF, #008FF6)"
              : "white",
          opacity: isExp || isDisable ? "0.6" : isSelected ? "1" : "",
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          OnSelect(item);
        }}
      >
        <h3
          style={{
            fontSize: 20,
            color: isSelected ? " #fff" : "#060c27",
          }}
        >
          {title}
        </h3>

        <h5 style={{ color: isSelected ? " #fff" : "#060c27", fontSize: "16" }}>
          {details}
        </h5>
        {isExp && (
          <h6 style={{ color: "#992e36", fontSize: "12" }}>
            <span className="badge badge-danger">Expired</span>
          </h6>
        )}
        {isDisable && (
          <h6 style={{ color: "#992e36", fontSize: "12" }}>Disabled</h6>
        )}
      </div>
    </div>
  );
};
const PackItem = ({ item, isSelected, OnSelect }) => {
  return (
    <div key={item.tn}>
      <div
        className="rounded mx-3 p-2 my-4"
        style={{
          border: isSelected ? "1px solid #007bff" : "1px solid #ddd",
          backgroundImage: isSelected
            ? "linear-gradient( #31A8FF, #008FF6)"
            : "white",
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          OnSelect(item);
        }}
      >
        <h3
          style={{ fontSize: "20px", color: isSelected ? "#fff" : "#060c27" }}
        >
          {item.n}
        </h3>
        {item?.a > 0 ? (
          <h5
            style={{ color: isSelected ? "#fff" : "#060c27", fontSize: "16" }}
          >
            {"£"}
            {item.a} - {"rec" === item.p ? "Recurring" : "Once"}
          </h5>
        ) : null}
      </div>
    </div>
  );
};

export { Item, PackItem };
