/**
 * @copyright KARD
 */

import React from 'react'

import {navigate} from "@reach/router";

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import * as Cfg from '../../_cfg'

// import {Time, UI} from "../../api/Utils";

import {
    // Core,
    Lambda,
} from '../../api/AWS'
// import * as AWS from '../../api/aws'

// import Addr from '../../aui/addr'

import Page from '../_common/page'

/**
 */
const UserRoles = ( props ) =>
{
    React.useEffect( () => {
        Init().catch( err => {} )
    }, [] )

    const Init = async () =>
    {
        try
        {
            const aws_lambda = new Lambda({})

            const p_lambda = {
                actType: 'staffs',
                act: 'staffs:cognito',
            }

            console.log( 'UserRoles: Init: p_lambda: ', p_lambda )

            const resp_lambda = await aws_lambda.Invoke( p_lambda, Cfg.lambda[Cfg.stage].admin )

            console.log( 'UserRoles: Init: resp_lambda: ', resp_lambda )

            if( resp_lambda )
            {
                //
            }

            return {}
        }
        catch( err )
        {
            console.warn( 'UserRoles: Init: err: ', err )

            return Promise.reject( err )
        }
    }   // Init

    return (
        <Page active="staff">
            <div className="row">
                <div className="col-sm-4 col-3">
                    <h4 className="projectTitle"><i className="fa fa-tasks" /> List of Roles</h4>
                </div>

                <div className="col-sm-8 col-9 text-right m-b-20">
                    <button onClick={()=>navigate( "/stores/new")} className="btn btn btn-primary btn-rounded float-right"><i className="fa fa-plus" /> Add</button>
                </div>
            </div>

            <div className="row">
                {/*  */}
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <table id="example1" className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>ID</th>
                                        <th>Description</th>
                                        {/* <th>Address</th>
                                        <th>Action</th> */}
                                    </tr>
                                </thead>

                                <tbody>
                                {
                                    props.__staffs.roles.map( x => (
                                        // <Store_ListView store={x} />
                                        <tr>
                                            <td>{ x.name }</td>
                                            <td>{ x.id }</td>
                                            <td>{ x.desc }</td>
                                            {/* <th>{}</th> */}
                                        </tr>
                                    ) )
                                }
                                </tbody>

                                <tfoot>
                                {
                                    props.__staffs.roles.length
                                ?   <tr>
                                        <th>Name</th>
                                        <th>ID</th>
                                        <th>Description</th>
                                        {/* <th>Address</th>
                                        <th>Action</th> */}
                                    </tr>

                                :   null
                                }
                                </tfoot>
                            </table>

                            {/*
                            <>
                            {
                                lastKey
                            ?   <button
                                    onClick={ OnLoadMore }
                                >
                                    Load More
                                </button>

                            :   null
                            }
                            </>
                            */}
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    )
}   // UserRoles

/**
 */
const mapStateToProps = ( state ) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( UserRoles )



