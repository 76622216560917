/**
 *	@copyright	KARD.
 */

import * as Cfg from '../../_cfg'
import {Lambda} from '../../api/AWS'

/**
 */
export const Campaign_TypeNew = ( type_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const p_new = {
                actType: 'camp',
                act: 'camp:type-new',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                data: {...type_obj},
            }

            console.log( 'actions/campaign: Campaign_TypeNew: p_new: ', p_new )

            const resp_new = await aws_lambda.Invoke( p_new, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/campaign: Campaign_TypeNew: resp_new: ', resp_new )

            //
            return resp_new
        }
        catch( err )
        {
            console.warn( 'actions/campaign: Campaign_TypeNew: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Campaign_TypeNew

/**
 */
export const Campaign_TypeEdit = ( type_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const p_edit = {
                actType: 'camp',
                act: 'camp:type-edit',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                data: {...type_obj},
            }

            console.log( 'actions/campaign: Campaign_TypeEdit: p_edit: ', p_edit )

            const resp_edit = await aws_lambda.Invoke( p_edit, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/campaign: Campaign_TypeEdit: resp_edit: ', resp_edit )

            //
            return resp_edit
        }
        catch( err )
        {
            console.warn( 'actions/campaign: Campaign_TypeEdit: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Campaign_TypeEdit

/**
 */
export const Campaign_TypeDel = ( type_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const p_del = {
                actType: 'camp',
                act: 'camp:type-del',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                data: {...type_obj},
            }

            console.log( 'actions/campaign: Campaign_TypeDel: p_del: ', p_del )

            const resp_del = await aws_lambda.Invoke( p_del, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/campaign: Campaign_TypeDel: resp_del: ', resp_del )

            //
            return resp_del
        }
        catch( err )
        {
            console.warn( 'actions/campaign: Campaign_TypeDel: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Campaign_TypeDel

/**
 */
export const Campaign_TypeList = () =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const p_list = {
                actType: 'camp',
                act: 'camp:type-list',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),
            }

            console.log( 'actions/campaign: Campaign_TypeList: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/campaign: Campaign_TypeList: resp_list: ', resp_list )

            d( _Campaign_Type( resp_list ) )

            //
            return {}
        }
        catch( err )
        {
            console.warn( 'actions/campaign: Campaign_TypeList: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Campaign_TypeList

/**
 */
const _Campaign_Type = ( type_list ) =>
{
    // console.log( 'actions/campaign: _Campaign_Type: type_list: ', type_list )

    return {
        type: 'camp:types',
        payload: type_list,
    }
}



