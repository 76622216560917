/**
 * @copyright KARD
 */

 import React from 'react'

 import {navigate} from "@reach/router";
 
 import {connect} from 'react-redux'
 import * as actions from '../../rdx/actions'
 import Page from '../_common/page'
 

 
 /**
  */
 const TasksNew = ( props ) =>
 {
     const [isLoading, IsLoading] = React.useState( false )
 
     const [listProj, ListProj] = React.useState( [] )
     const [lastKey, LastKey] = React.useState( null )
 
     //
     React.useEffect( ()=> {
         Load( null ).catch( err => {} )
     }, [] )
 
     //
     const Load = async ( b_more ) =>
     {
         try
         {
             IsLoading( true )
 
             const resp_list = await props.Project_List( b_more ? lastKey : null )
 
             // console.log( 'TasksNew: Load: resp_list: ', resp_list )
 
             ListProj( b_more ? [...listProj, ...resp_list.Items] : [...resp_list.Items] )
             LastKey( resp_list.LastEvaluatedKey )
 
             IsLoading( false )
 
             return {}
         }
         catch( err )
         {
             console.warn( 'TasksNew: Load: err: ', err )
 
             IsLoading( false )
 
             alert( err.message ? err.message : err )
 
             return Promise.reject( err )
         }
     }   // Load
 
     //
     return (
         <Page>
             
            <div className="row">
                <div className="col-sm-6"> 
                     <nav aria-label="breadcrumb">
                         <ol className="breadcrumb">
                             <li className="breadcrumb-item"><a href="#">Home</a></li>
                             <li className="breadcrumb-item"><a href="#">New Task</a></li>
                         </ol>
                     </nav>
                </div>  
                <div className="col-sm-6 text-right">  
                     <p>Monday, 10 Augest 2021</p>
                </div>
            </div>

            <div className="row mt-2 mb-3">
                <div className="col-sm-6 col-3">
                    <h4 className="projectTitle m-0"><i className="fa fa-tasks fa-lg" />  Create New Task</h4>
                </div>
            </div>
 

 
            <div className="row">
                <div className="col-12">
 
                    <form>
                        <div className="form-row">
                            <div className="col-md-6">
                                <label for="name" className="col-form-label">Name</label>
                                <input type="text" className="form-control" id="name" placeholder="Arif Hossain" />
                            </div>
                            <div class="col-md-6">
                                <label for="s_project" className="col-form-label">Select Project</label>
                                <select className="form-control" id="s_project">
                                        <option>Select</option>
                                        <option>Ramadan</option>
                                        <option>Fidaya</option>
                                        <option>Kafffara</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-group">
                            <label for="description" className="col-form-label">Description</label>
                            <textarea class="form-control" id="description" rows="4"></textarea>
                        </div>
                        <div className="form-group">
                            <label for="type" className="col-form-label">Action</label>
                            <select class="form-control" id="ttype">
                                <option>Charity</option>
                                <option>Fidiya</option>
                            </select>
                        </div>  

                        <div className="form-row mt-2 mb-2">
                            <div className="col-md-6">
                                <label for="s_date" className="col-form-label">Start Date</label>
                                <input type="date" className="form-control" id="s_date" />
                            </div>
                            <div className="col-md-6">
                                <label for="e_date" className="col-form-label">End Date</label>
                                <input type="date" className="form-control" id="e_date" />
                            </div>
                        </div>
                        
                        <div className="form-row mb-2">
                            <div className="col-md-6">
                                <label for="default" className="col-sm-1 col-form-label">Activate</label>
                                <div className="col-sm-2 mt-1">
                                    <input type="checkbox" className="switch_1" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label for="pririty" className="col-form-label"> Priority</label>
                                <select class="form-control" id="ttype">
                                    <option>High</option>
                                    <option>Medium</option>
                                    <option>Low</option>
                                </select>
                            </div>
                        </div>

                        <button type="button" className="btn btn-primary btn-lg">Save</button>
                        
                    </form>
                                          
                </div>
            </div>
         </Page>
     )
 }   // TasksNew
 
 /**
  */
 const mapStateToProps = ( state ) =>
 {
     return state
 }   //
 
 /**
  */
 export default connect(mapStateToProps, actions)( TasksNew )
 
 
 
 
 
 
 
 