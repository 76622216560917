/**
 *  @copyright KARD.
 */

const aws_auth = {
    test: {
        region: 'eu-west-1',
        identityPoolRegion: 'eu-west-1',

        identityPoolId: 'eu-west-1:9437f194-88a6-4ecf-b08f-568dd0148967',
        userPoolId: 'eu-west-1_42Vnt6cZC',
        userPoolWebClientId: '2gc9uiq4rb0v60tstnb2166gk4',
    },
    live: {
        region: 'eu-west-1',
        identityPoolRegion: 'eu-west-1',

        identityPoolId: 'eu-west-1:152368cc-ce3f-4fea-89ed-d241780fdbd7',
        userPoolId: 'eu-west-1_ift849lu1',
        userPoolWebClientId: '6lifqveaugp5qvvrnh9sgff414',
    },
}
// const aws_auth = {
//     test: {
//         region: 'eu-west-1',
//         identityPoolRegion: 'eu-west-1',

//         identityPoolId: 'eu-west-1:3103b742-0af7-4aa6-a1f9-c88adbfaba5c',
//         userPoolId: 'eu-west-1_lsOVPigYO',
//         userPoolWebClientId: '1fadk0rekoj7ec5jjeqesko30i',
//     },
//     live: {
//         region: 'eu-west-1',
//         identityPoolRegion: 'eu-west-1',

//         identityPoolId: 'eu-west-1:e27e754b-51c8-466d-8167-ef9d64e1014a',
//         userPoolId: 'eu-west-1_k5EMsthip',
//         userPoolWebClientId: '69nsnm545qi3si4ntuu3cu4pt9',
//     },
// }

/**
 */
export default aws_auth




