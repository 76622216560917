/**
 * @copyright KARD
 */

import React, { useState } from "react";
import Loader from "react-js-loader";
// import {navigate} from "@reach/router";

import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from "../../rdx/actions";

import Page from "../_common/page";
// import { Time } from "../../api/Utils";
// import { uploadFile } from "../../rdx/actions";

import Settings_Payment from './payment'

/**
 */
const Settings = (props) =>
{
  // const orgId = useSelector((state) => state.__auth.orgId);
  // const dispatch = useDispatch();
  // const [isBusy, IsBusy] = React.useState(false);
  const [isLoading, IsLoading] = React.useState(false);
  const [isSaving, IsSaving] = React.useState(false);
  const [title, Title] = React.useState("");
  const [domain, Domain] = React.useState("");
  const [email, Email] = React.useState({
    h: "",
    n: "",
    i: "",
  });
  const [sett, setSettings] = React.useState({});
  // const [f, sf] = useState(null);

  /**
   */
  React.useEffect(() => {
    Load().catch((err) => {});
  }, []);

  // /**
  //  */
  // const OnChange_Stage = (e) => {
  //   e.preventDefault();

  //   props.Cfg_Stage(e.target.value);
  // }; // OnChange_Stage

  /**
   */
  const OnSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Save().catch((err) => {});
  }; // OnSubmit

  /**
   */
  const Save = async () => {
    try {
      // IsBusy(true);
      IsSaving( true )

      console.log("Title", title);
      const resp_save = await props.Settings_Update({
        c: {
          ...sett.c,
          e: email,
        },
        e: sett.e,
        str: sett.str,
        ve: { te: email.n, v: sett.ve.te.v ? 1 : 0 },
        n: title,
        d: domain,
      });

      console.log("Settings: Load: resp_get: ", resp_save);

      if (resp_save) {
        //
      }

      // IsBusy(false);
      IsSaving( false )

      alert("Settings changes saved");

      return {};
    } catch (err) {
      console.warn("Settings: Save: err: ", err);

      // IsBusy(false);
      IsSaving( false )

      return Promise.reject(err);
    }
  }; // Save

  /**
   */
  const Load = async () => {
    try {
      // IsBusy(true);
      IsLoading( true )

      const { Item } = await props.Settings_Get();

      console.log("Settings: Load: resp_get: ", Item);

      if (Item) {
        //
        Title(Item.n);
        Email({
          h: Item?.c?.e?.h || "",
          n: Item?.c?.e?.n || "",
          i: Item?.c?.e?.i || "",
        });
        setSettings(Item);
        Domain(Item.d);
      }

      // IsBusy(false);
      IsLoading( false )

      return {};
    } catch (err) {
      console.warn("Settings: Load: err: ", err);

      // IsBusy(false);
      IsLoading( false )

      return Promise.reject(err);
    }
  }; // Load

  /**
   */
  return (
    <Page active="settings">
      <div className="row">
        <div className="col">
          <h4 className="projectTitle m-0 mb-4">
            <img
              style={{ height: 20, width: 20, marginRight: 8 }}
              src={require("../../components/assets/icons/sideBar/active/settings.png")}
            />{" "}
            Settings
          </h4>
        </div>
      </div>
      <div className="">
        <form onSubmit={OnSubmit}>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>
                  Title <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control mb-3"
                  type="text"
                  value={title}
                  onChange={(e) => Title(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label className="mt-2">Domain</label>
                <input
                  className="form-control mb-3"
                  type="text"
                  value={domain}
                  onChange={(e) => Domain(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label className="mt-2">
                  No-Reply Email <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control mb-3"
                  type="text"
                  value={email.n}
                  onChange={(e) =>
                    Email({
                      ...email,
                      n: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="mt-2">
                  Info Email <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control mb-3"
                  type="text"
                  value={email.i}
                  onChange={(e) =>
                    Email({
                      ...email,
                      i: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary mt-3 px-5"
            // disabled={!isShowingSave}
          >
            Save
          </button>
        </form>
      </div>

      <div className="row">
        <div className="col-12">
          <Settings_Payment pym={sett.pym} />
        </div>
      </div>

      <>
      {
        (isLoading || isSaving) &&
        <div>
          <div
              className="modal"
              style={{
                // margin: '0 auto'
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#d1cccc80",
              }}
          >
          <Loader
            // type="box-rectangular"
            type="spinner-cub"
            bgColor={"#2680c2"}
            title={isLoading ? 'Loading...' : "Saving ..."}
            color={"#2680c2"}
            size={150}
          />
          </div>
        </div>
      }
      </>
    </Page>
  );
}; // Settings

//ec.comm.uk.eu-west-1-test
/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Settings);

//
