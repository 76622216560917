/**
 * @copyright KARD.
 */

import React from "react";

// import {
// 	Button,
// } from '@material-ui/core'

// import { navigate } from "@reach/router"

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

/**
 */
const Donations = (props) => {
  const [isLoading, IsLoading] = React.useState(false);

  const [listDont, ListDont] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);

  //
  React.useEffect(() => {
    Load(null).catch((err) => {});
  }, []);

  //
  const Load = async (b_more) => {
    try {
      IsLoading(true);

      const resp_list = await props.Donation_List(b_more ? lastKey : null);

      // console.log( 'Donations: Load: resp_list: ', resp_list )

      ListDont(b_more ? [...listDont, ...resp_list.Items] : resp_list.Items);
      LastKey(resp_list.LastEvaluatedKey);

      IsLoading(false);

      return {};
    } catch (err) {
      console.warn("Donations: Load: err: ", err);

      IsLoading(false);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // Load

  return (
    <div className="card">
      <div className="card-header">
        <h2 className="projectTitle py-0 mb-0 pl-2" style={{ fontSize: 24 }}>
          Recent Donations
        </h2>
      </div>
      <div className="card-body pt-0">
        <ul className="contact-list">
          {listDont.map((x) => (
            <ListView key={[x.i, x.d].join(":")} dont={x} />
          ))}
          {/*<li>*/}
          {/*    <div className="contact-info">*/}
          {/*        <span className="contact-date">9 JUNE 2021</span>*/}
          {/*        <span className="contact-name text-ellipsis">Qurbani costs vary by country</span>*/}
          {/*    </div>*/}
          {/*</li>*/}
          {/* <li>
                        <div className="contact-cont">
                            <div className="float-left user-img m-r-10">
                                <a href="profile.html" title="Richard Miles"><img src="assets/img/user.jpg" alt="" className="w-40 rounded-circle" /><span className="status offline" /></a>
                            </div>
                            <div className="contact-info">
                                <span className="contact-date">9 JUNE 2021</span>
                                <span className="contact-name text-ellipsis">Qurbani costs vary by country</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="contact-cont">
                            <div className="float-left user-img m-r-10">
                                <a href="profile.html" title="Richard Miles"><img src="assets/img/user.jpg" alt="" className="w-40 rounded-circle" /><span className="status offline" /></a>
                            </div>
                            <div className="contact-info">
                                <span className="contact-date">9 JUNE 2021</span>
                                <span className="contact-name text-ellipsis">Qurbani costs vary by country</span>
                            </div>
                        </div>
                    </li> */}
        </ul>
      </div>
    </div>
  );
}; // Donations

/**
 */
const ListView = (props) => {
  const [total, Total] = React.useState("0.00");
  const [gift, Gift] = React.useState("0.00");

  const [strDt, StrDt] = React.useState("");
  const [strName, StrName] = React.useState("");

  /**
   */
  React.useEffect(() => {
    try {
      const dt_obj = new Date(props.dont.tn * 1000);

      StrDt(
        [
          dt_obj.getDate().toString().padStart(2, "0"),
          (dt_obj.getMonth() + 1).toString().padStart(2, "0"),
          dt_obj.getFullYear().toString(),
        ].join("-")
      );
    } catch (err) {
      //
    }

    try {
      StrName(
        [props.dont.di.n.t, props.dont.di.n.f, props.dont.di.n.l].join(" ")
      );
    } catch (err) {
      //
    }

    try {
      Total(props.dont.k.a.toFixed(2));
    } catch (err) {
      //
    }

    try {
      if (props.dont.cd.g) {
        // if gift aid
        const total_a = props.dont.k.a;
        const gift_a = total_a * 0.25;
        Gift(gift_a.toFixed(2));
      }
    } catch (err) {
      //
    }
  }, []);

  /**
   */
  return (
    <li>
      <div className="contact-cont">
        {/*
                <a title="Richard Miles">
                    <img src="assets/img/user.jpg" alt="" className="w-40 rounded-circle" />
                    <span className="status offline" />
                </a>
                */}
        <div className="float-left">
          <div style={{ width: 80, height: 50 }}>
            <div>
              <a>{"£" + total}</a>
            </div>
            <div>
              <a>{gift.length ? "£" + gift : "N/A"}</a>
            </div>
          </div>
        </div>
        <div style={{ width: 10 }}></div>
        <div className="contact-info">
          <span className="contact-date">{strDt}</span>
          <span className="contact-name text-ellipsis">{strName}</span>
        </div>
        <div style={{ clear: "both" }}></div>
      </div>
    </li>
  );
}; // ListView

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Donations);
