/**
 *	@copyright	KARD.
 */
import * as Cfg from '../../_cfg'
import {Lambda, Core} from '../../api/AWS'
/**
 */
export const Notify_Email_Send = (  ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const params = {
                actType: 'notify',
                act: 'notify:email',
                c_id: gs().__auth.orgId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                email_from: '',
                email_to: '',
                msg_sub: '',
                msg_body: ''
            }

            console.log( 'actions/notify: Notify_Email_Send: params: ', params )

            const resp = await aws_lambda.Invoke( params, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/notify: Notify_Email_Send: resp: ', resp )

            return resp
        }
        catch( err )
        {
            console.warn( 'actions/notify: Notify_Email_Send: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Notify_Email_Send
