/**
 * @copyright KARD
 */

import React from "react";

import { navigate, Link } from "@reach/router";
import AuiModal from "../../aui/modal";
import { connect, useDispatch } from "react-redux";
import * as actions from "../../rdx/actions";
import Page from "../_common/page";

import "./project.css";
import { LinearProgress } from "@material-ui/core";
import { ProjectQuery } from "../../rdx/actions/project";
import { Time } from "../../api/Utils";

/**
 */
const ProjectList = (props) => {
  const dispatch = useDispatch();
  const [isModal_Filter, IsModal_Filter] = React.useState(false);
  const [isLoading, IsLoading] = React.useState(false);

  const [listProj, ListProj] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);

  const [isFilter, IsFilter] = React.useState(false);
  const [filterObj, FilterObj] = React.useState({
    //
  });
  const [dt, SetDt] = React.useState("");

  //
  React.useEffect(() => {
    const bMore = !!lastKey;

    if (isFilter) {
      IsLoading(true);
      const fo = {
        dtStart: filterObj.dateSt
          ? parseInt(new Date(filterObj.dateSt).setHours(0, 0, 0, 0) / 1000, 10)
          : 0,
        dtEnd: filterObj.dateEnd
          ? parseInt(
              new Date(filterObj.dateEnd).setHours(0, 0, 0, 0) / 1000,
              10
            )
          : 0,
        amountStart: filterObj.amountSt
          ? parseInt(parseFloat(filterObj.amountSt) * 100, 10)
          : 0,
        amountEnd: filterObj.amountEnd
          ? parseInt(parseFloat(filterObj.amountEnd) * 100, 10)
          : 0,
      };
      dispatch(ProjectQuery(fo, bMore ? lastKey : null))
        .then(({ Items, LastEvaluatedKey }) => {
          ListProj(bMore ? [...listProj, ...Items] : [...Items]);
          LastKey(LastEvaluatedKey);
          IsLoading(false);
        })
        .catch((err) => IsLoading(false));
    } else {
      Load(bMore).catch((err) => {});
    }
  }, [filterObj]);

  //
  const Load = async (b_more) => {
    try {
      IsLoading(true);

      const resp_list = await props.Project_List(b_more ? lastKey : null);

      console.log("ProjectList: Load: resp_list: ", resp_list);

      ListProj(
        b_more ? [...listProj, ...resp_list.Items] : [...resp_list.Items]
      );
      LastKey(resp_list.LastEvaluatedKey);
      SetDt(resp_list.dt_now);

      IsLoading(false);

      return {};
    } catch (err) {
      console.warn("ProjectList: Load: err: ", err);

      IsLoading(false);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // Load

  //
  const OnNewProj = (e) => {
    e.preventDefault();
    e.stopPropagation();

    navigate("/project/new");
  }; // OnNewProj

  //
  return (
    <Page active="project">
      {/* <div className="row">
        <div className="col-sm-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Project</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                List
              </li>
            </ol>
          </nav>
        </div>

                <div className="col-sm-6 text-right">
                    <p>Monday, 10 Augest 2021</p>
                </div>

      </div>*/}

      <div className="row mt-2 mb-3">
        <div className="col-sm-6 col">
          <h4 className="projectTitle m-0">
            <img
              style={{ height: 20, width: 20, marginRight: 8 }}
              src={require("../../components/assets/icons/sideBar/active/project.png")}
            />{" "}
            Project List
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="row my-3 ml-1 mr-1">
              <div className="col-sm-4 col">
                {/* <div className="form-group has-search">
                  <span className="fa fa-search form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                </div> */}
                <button
                  className="btn btn btn-outline-primary mr-1 px-4"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    // navigate("/donation/int/new");
                    IsModal_Filter(true);
                  }}
                >
                  <i className="fa fa-filter" />{" "}
                  <a href="" className="btn-filter">
                    {isFilter ? "Filter: In Use" : "Filter: None Selected"}
                  </a>
                </button>
                <>
                  {isModal_Filter && (
                    <AuiModal
                      bOpen={isModal_Filter}
                      OnClose={() => IsModal_Filter(false)}
                    >
                      <FilterView
                        filter={filterObj}
                        OnFilter={(val) => {
                          FilterObj({ ...val });
                          IsFilter(Object.keys(val).length ? true : false);
                          // IsModal_Filter(false)
                        }}
                        OnClose={() => IsModal_Filter(false)}
                      />
                    </AuiModal>
                  )}
                </>
              </div>
              <div className="col-sm-8 col text-right">
                <button
                  className="btn btn btn-primary mr-1 px-4 font-weight-bold"
                  // data-toggle="modal" data-target="#exampleModal" data-whatever="@getbootstrap"
                  onClick={OnNewProj}
                >
                  <i className="fa fa-plus" /> New Project
                </button>
                {/* <button className="btn btn btn-primary "> Edit This Campaign</button> */}
              </div>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Target</th>
                    <th>Raised</th>
                    <th style={{ minWidth: 125 }}>Start</th>
                    <th style={{ minWidth: 125 }}>End</th>
                    <th style={{ minWidth: 120 }}>Action</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {isLoading ? (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "absolute",
                    }}
                  >
                    <LinearProgress color="primary" />
                    <LinearProgress color="success" />
                    <LinearProgress color="inherit" />
                  </div>
                ) : null}
                <tbody>
                  {listProj.map((x, i) => (
                    <ListView key={[x.i, x.tn].join(":")} proj={x} dt={dt} />
                  ))}
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col-sm-12 ml-3 mr-3 mt-3">
                <div className="pull-left">
                  <tfoot>
                    {lastKey ? (
                      <button
                        className="btn btn-info mb-3"
                        onClick={() => {
                          Load(lastKey).catch((err) => {});
                        }}
                        disabled={isLoading}
                      >
                        Show More
                      </button>
                    ) : null}
                  </tfoot>
                </div>

                <div className="pull-right mr-5">
                  {/* <ul className="pagination">
                    <li className="">
                      <a
                        href="#"
                        tabindex="0"
                        className="paginate_button previous"
                        id="DataTables_Table_0_previous"
                      >
                        Previous
                      </a>
                    </li>
                    <li className="active">
                      <a tabindex="0">1</a>
                    </li>
                    <li>
                      <a tabindex="0">2</a>
                    </li>
                    <li>
                      <a tabindex="0">3</a>
                    </li>
                    <li>
                      <a
                        href="#"
                        tabindex="0"
                        className="paginate_button next"
                        id="DataTables_Table_0_next"
                      >
                        Next
                      </a>
                    </li>
                  </ul> */}
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}; // ProjectList

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(ProjectList);

/**
 */
const ListView = (props) => {
  console.log("ListView: props: ", props);
  // console.log( 'ListView: props.proj: ', props.proj )

  const [target, Target] = React.useState("0.00");
  const [raised, Raised] = React.useState("0.00");
  const [st, St] = React.useState(Time.Epoch2Date(props.proj.ts));
  const [te, Te] = React.useState(Time.Epoch2Date(props.proj.te));

  React.useEffect(() => {
    try {
      Target(props.proj.a.t ? props.proj.a.t.toFixed(2) : "0.00");
    } catch (err) {
      //
    }

    try {
      Raised(props.proj.a.r ? props.proj.a.r.toFixed(2) : "0.00");
    } catch (err) {
      //
    }
  }, []);

  /**
   */
  const OnEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(`/project/list/${props.proj.tn}`);
  }; // OnEdit

  /**
   */
  const OnDel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // show alert confirming the action...
  }; // OnDel

  /**
   */
  return (
    <tr>
      {/* ID */}
      <td>
        <Link to={`/project/view/${props.proj.tn}`}>{props.proj.tn}</Link>
      </td>

      {/* title */}
      <td>{props.proj.p.t}</td>

      {/* amount - 54 */}
      {/* <td>{54}</td> */}

      {/* description - Fidiya for whole fast */}
      {/* <td>{props.proj.p.ds}</td> */}

      {/* target - $ 655.05 */}
      <td>{"£" + target}</td>

      {/* raised - $ 255.05*/}
      <td>{"£" + raised}</td>

      {/* start / end date - 12.3.2034*/}
      <td>
        {props.proj.ts && props.dt < props.proj.ts ? (
          <span className="badge badge-primary">{st}</span>
        ) : props.proj.ts && props.dt >= props.proj.ts ? (
          <span className="badge badge-success">{st}</span>
        ) : (
          ""
        )}
      </td>

      {/* start / end date - 12.3.2034*/}
      <td>
        {props.proj.te && props.dt > props.proj.te ? (
          <span className="badge badge-danger">{te}</span>
        ) : props.proj.te ? (
          <span className="badge badge-success">{te}</span>
        ) : (
          ""
        )}
      </td>

      {/* action */}
      <td className="btn-group">
        {/* <a href="" className="btn btn-sm" onClick={ OnEdit }><i className="fa fa-eye"></i></a> */}
        <a href="" className="btn btn-primary btn-sm mr-2" onClick={OnEdit}>
          <i className="fa fa-edit"></i>
        </a>
        <a href="" className="btn btn-danger btn-sm" onClick={OnDel}>
          <i className="fa fa-trash text-white"></i>
        </a>
      </td>

      {/* status */}
      <td>
        <div className="switch_box box_1">
          <input
            type="checkbox"
            className="switch_1"
            checked={"act" === props.proj.s}
            disabled
          />
        </div>
      </td>
    </tr>
  );
}; // ListView

const FilterView = (props) => {
  const [listProj, ListProj] = React.useState([]);
  const [listCamp, ListCamp] = React.useState([]);
  const [isLoadingProj, IsLoadingProj] = React.useState(false);
  const [isLoadingCamp, IsLoadingCamp] = React.useState(false);

  const [dateSt, DateSt] = React.useState(props?.filter?.dateSt);
  const [dateEnd, DateEnd] = React.useState(props?.filter?.dateEnd);
  // const [nameF, NameF] = React.useState(props?.filter?.nameF);
  // const [nameL, NameL] = React.useState(props?.filter?.nameL);

  // const [addrCity, AddrCity] = React.useState(props?.filter?.addrCity);
  // const [addrCn, AddrCn] = React.useState( props?.filter?.addrCn )

  const [projNo, ProjNo] = React.useState(props?.filter?.projNo);
  const [campNo, CampNo] = React.useState(props?.filter?.campNo);

  // const [paymMethod, PaymMethod] = React.useState(props?.filter?.paymMethod);
  // const [paymStatus, PaymStatus] = React.useState(props?.filter?.paymStatus);

  const [amountSt, AmountSt] = React.useState(props?.filter?.amountSt);
  const [amountEnd, AmountEnd] = React.useState(props?.filter?.amountEnd);

  React.useEffect(() => {
    LoadProjList().catch((err) => {});
  }, []);

  /**
   */
  const LoadProjList = async () => {
    try {
      IsLoadingProj(true);

      IsLoadingProj(false);
      return {};
    } catch (err) {
      IsLoadingProj(false);

      return Promise.reject(err);
    }
  }; // LoadProjList

  /**
   */
  const LoadCampList = async () => {
    try {
      IsLoadingCamp(true);

      IsLoadingCamp(false);
      return {};
    } catch (err) {
      IsLoadingCamp(false);

      return Promise.reject(err);
    }
  }; // LoadCampList

  /**
   */
  const OnClose = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.OnClose();
  }; // OnClose

  /**
   */
  const OnClearAll = (e) => {
    e.preventDefault();
    e.stopPropagation();

    DateSt(null);
    DateEnd(null);
    // NameF(null);
    // NameL(null);
    // AddrCity(null);
    // AddrCn( null )
    // ProjNo(null);
    // CampNo(null);
    // PaymMethod(null);
    // PaymStatus(null);
    AmountSt(null);
    AmountEnd(null);

    /// @note need to call save
  }; // OnClearAll

  /**
   */
  const OnSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // const name_f = nameF ? nameF.trim() : "";
    // const name_l = nameL ? nameL.trim() : "";
    // const addr_city = addrCity ? addrCity.trim() : "";
    // const addr_cn = addrCn ? addrCn.trim() : ''

    props.OnFilter({
      dateSt,
      dateEnd,
      // nameF: name_f.length ? name_f : null,
      // nameL: name_l.length ? name_l : null,
      // addrCity: addr_city.length ? addr_city : null,
      // addrCn: addr_cn.length ? addr_cn : null,
      // projNo,
      // campNo,
      // paymMethod,
      // paymStatus,

      amountSt,
      amountEnd,
    });
    props.OnClose();
  }; // OnSave

  /**
   */
  return (
    <div className="container">
      <h3 className="projectTitle ">Date</h3>
      <div className="row">
        <div className="col-sm-6">
          From
          <input
            className="form-control"
            type="date"
            value={dateSt}
            onChange={(e) => {
              DateSt(e.target.value);
            }}
          />
        </div>
        <div className="col-sm-6">
          To
          <input
            className="form-control"
            type="date"
            value={dateEnd}
            onChange={(e) => {
              DateEnd(e.target.value);
            }}
          />
        </div>
      </div>

      {/* <div className="row">
        <div className="col-sm-6">
          <h3 className="projectTitle ">Project</h3>
          <>
            {isLoadingProj ? (
              <a>Loading...</a>
            ) : (
              <select
                className="form-control"
                value={projNo}
                onChange={(e) => ProjNo(e.target.value)}
              >
                <option>Select</option>
                <>
                  {listProj.map((x) => (
                    <option>{x.t}</option>
                  ))}
                </>
              </select>
            )}
          </>
        </div>
        <div className="col-sm-6">
          <h3 className="projectTitle ">Campaign</h3>
          <>
            {isLoadingCamp ? (
              <a>Loading...</a>
            ) : (
              <select
                className="form-control"
                value={campNo}
                onChange={(e) => CampNo(e.target.value)}
                disabled={!ProjNo}
              >
                <option>Select</option>
                <>
                  {listCamp.map((x) => (
                    <option>{x.t}</option>
                  ))}
                </>
              </select>
            )}
          </>
        </div>
      </div> */}

      <h3 className="projectTitle ">Amount</h3>
      <div className="row">
        <div className="col-sm-6">
          From
          <input
            className="form-control"
            value={amountSt}
            onChange={(e) => AmountSt(e.target.value)}
          />
        </div>
        <div className="col-sm-6">
          To
          <input
            className="form-control"
            value={amountEnd}
            onChange={(e) => AmountEnd(e.target.value)}
          />
        </div>
      </div>

      <div className="col-sm-12 btn-group mt-4 p-0">
        <button className="btn btn-info rounded py-2 mr-3" onClick={OnClose}>
          Close
        </button>

        <button className="btn btn-info rounded py-2 mr-3" onClick={OnClearAll}>
          Clear All
        </button>

        <button className="btn btn-info rounded py-2" onClick={OnSave}>
          Save
        </button>
      </div>
    </div>
  );
}; // FilterView
