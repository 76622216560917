/**
 * @copyright KARD
 */

import React, { useEffect, useRef, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { Grid, Switch, LinearProgress } from "@material-ui/core";

import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from "../../rdx/actions";

// import * as Cfg from "../../_cfg";

import AuiModal from "../../aui/modal";

import Page from "../_common/page";
import ListDonationType from "./list_type";

// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import CardForm from "../../api/pay/cardForm";
// import { Overlay } from "../../aui/loader";
import {
  // DonationNew,
  // Donor_New,
  // EmailSubscribe,
  GetCharityInfo,
  // PaymentReceived,
  RDX_UpdateDonationInfo,
  // UpdateRecurringDonation,
} from "../../rdx/actions";

import { navigate } from "@reach/router";
import OptionProject from "../_common/OptionProject";
import OptionCampaign from "../_common/OptionCampaign";
import OptionPack from "../_common/OptionPack";
// import PaymentMethod from "./payment_method";

/**
 */
const Donations_Internal_New = (props) => {
  const dispatch = useDispatch();
  // let pmRef = useRef();
  // let stripePromise = loadStripe(Cfg.stripe.key[Cfg.stage]);
  // const orgId = useSelector((state) => state.__auth.orgId);
  const {info} = useSelector((state) => state.__donation);

  const [isModal_Success, IsModal_Success] = React.useState(false);
  const [isLoading_Dont, IsLoading_Dont] = React.useState(false);
  const [isLoading_Donr, IsLoading_Donr] = React.useState(false);
  const [isSaving_Donr, IsSaving_Donr] = React.useState(false);
  const [isSaving_Dont, IsSaving_Dont] = React.useState(false);

  const [curProj, CurProj] = React.useState(null);
  const [curCamp, CurCamp] = React.useState(null);
  const [curPck, CurPck] = React.useState(null); // {tn: -1, t: 'Other', a: 0, p: 'one',}

  const [curDonType, CurDonType] = React.useState(null);

  const [phone, Phone] = React.useState({ cn: "44", n: "" });
  const [email, Email] = React.useState("");
  const [name, Name] = React.useState({ t: "", f: "", l: "" });
  const [addr, Addr] = React.useState({
    l1: "",
    l2: "",
    c: "",
    p: "",
    cn: "",
    pc: "",
  });

  const [isGiftAid, IsGiftAid] = React.useState(false);
  const [isConsentProj, IsConsentProj] = React.useState(false);
  const [isConsentCharity, IsConsentCharity] = React.useState(false);
  const [int, SetInt] = useState(null);

  /**
   */
  React.useEffect(() => {
    IsLoading_Dont(true);
    dispatch(GetCharityInfo())
      .then(({ charity }) => {
        SetInt(charity?.pym?.int)
        IsLoading_Dont(false);
      })
      .catch((err) => IsLoading_Dont(false));
    // console.log(cardRef);
  }, []);

  useEffect(()=>{
    if (!info)
    {
      return null
    }
    CurProj(info?.curProj||null);
    CurCamp(info?.curCamp||null);
    CurPck(info?.curPck||null);
    CurDonType(info?.curDonType||null);
    Phone(info?.phone||{cn: "44", n: "" });
    Name(info?.name||{ t: "", f: "", l: "" });
    Email(info?.email||"");
    Addr(info?.addr||{
      l1: "",
      l2: "",
      c: "",
      p: "",
      cn: "",
      pc: "",
    });
    IsGiftAid(info?.isGiftAid||false);
    IsConsentProj(info?.isConsentProj||false);
    IsConsentCharity(info?.isConsentCharity||false);
  },[info])

  /**
   */
  const OnSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(RDX_UpdateDonationInfo({
      curProj,
      curCamp,
      curPck,
      curDonType,
      phone,
      name,
      email,
      addr,
      isGiftAid,
      isConsentProj,
      isConsentCharity
    }));

    // IsLoading_Dont(true);
    // ProcessDonation()
    //   .then((eara) => {
    //     IsLoading_Dont(false);
    //     IsModal_Success(true);
    //     alert("Donation Created");
    //     navigate("/donation/list");
    //   })
    //   .catch((err) => {
    //     IsLoading_Dont(false);
    //     //
    //   });

    return navigate("/donation/process");

  }; // OnSubmit

  // /**
  //  */
  // const NewSubs_SMS = async (p_no, topc) => {
  //   try {
  //     return await props.Comm_SmsSubscribe(p_no, topc);
  //   } catch (err) {
  //     console.warn("Donations_Internal_New: NewSubs_SMS: err: ", err);

  //     return { err };
  //   }
  // }; // NewSubs_SMS

  /**
   */
  const RetrieveDonor = async () => {
    try {
      IsLoading_Donr(true);

      const resp_donr = await props.Donor_Get(phone.cn + phone.n);

      // console.log( 'Donations_Internal_New: RetrieveDonor: resp_donr: ', resp_donr )

      if (!resp_donr.Item) {
        IsLoading_Donr(false);

        return {};
      }

      Phone(resp_donr.Item.p);
      Email(resp_donr.Item.e);
      Name(resp_donr.Item.n);
      Addr(resp_donr.Item.a);

      IsLoading_Donr(false);

      return {};
    }
    catch (err) {
      console.warn("Donations_Internal_New: RetrieveDonor: err: ", err);

      IsLoading_Donr(false);

      return Promise.reject(err);
    }
  }; // RetrieveDonor

  const ProjectSelectHandler = (project) => {
    CurProj(project);
    CurCamp(null);
    CurPck(null);
  };

  const CampaignSelectHandler = (campaign) => {
    CurCamp(campaign);
    CurPck(null);
  };

  const PackSelectHandler = (pack) => {
    CurPck(pack);
  };

  /**
   */
  return (
    <Page active="donation">
      {/*{isLoading_Dont ? <Overlay /> : null}*/}
      {isSaving_Donr || isSaving_Dont ? (
        <div
          style={{
            zIndex: 11111,
            height: "100%",
            width: "100%",
            position: "fixed",
            backgroundColor: "red",
            margin: -32,
          }}
        >
          <LinearProgress color="secondary" />
          <LinearProgress color="success" />
          <LinearProgress color="inherit" />
        </div>
      ) : null}

      <div className="row">
        <div className="col mb-3">
          <h4 className="projectTitle mt-0 mb-2">
            <img
              style={{ height: 20, width: 20, marginRight: 16 }}
              src={require("../../components/assets/icons/sideBar/active/donation.png")}
            />
            Add New Donation
          </h4>
        </div>
      </div>
      <div className="row">{/*  */}</div>

      <form onSubmit={OnSubmit}>
        <Grid
          container
          // className={classes.root}
          spacing={2}
        >
          <Grid
            item
            md={12}
            xs={12}
            style={{ backgroundColor: "#f7f7f7", padding: 16 }}
          >
            <div className="row">
              <div className="col-sm-4">
                <OptionProject
                  type={"only-active"}
                  OnSelect={ProjectSelectHandler}
                  currentProject={curProj}
                />
              </div>
              <div className="col-sm-4">
                <OptionCampaign
                  type={"only-active"}
                  OnSelect={CampaignSelectHandler}
                  currentProject={curProj}
                  currentCampaign={curCamp}
                />
              </div>
              <div className="col-sm-4">
                <OptionPack
                  OnSelect={PackSelectHandler}
                  currentCampaign={curCamp}
                  currentPack={curPck}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-5">
                <div>
                  <h3 className="projectTitle">Donation Type</h3>
                  <ListDonationType
                    dt={curDonType}
                    OnSelect={(t_obj) => CurDonType(t_obj)}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div>
                  <h3 className="projectTitle">Summary</h3>

                  {curPck && curPck.n === "Other" ? ( //&& curPck.a <= 0
                    <input
                      className="form-control"
                      value={curPck ? curPck.a : null}
                      onChange={(e) => {
                        e.preventDefault();
                        CurPck({ ...curPck, a: e.target.value });
                      }}
                    />
                  ) : (
                    <div className="form-control">
                      £{curPck?.a}
                      {curPck?.p === "rec" ? "/month" : ""}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-sm-3">
                <h3 className="projectTitle">...</h3>
                <div>
                  {curPck && curPck.p === "rec" ? (
                    <label className="btn btn-primary">Recurring</label>
                  ) : (
                    <label
                      className="btn btn-primary m-0"
                      style={{ padding: "9px 26px" }}
                    >
                      One/Off
                    </label>
                  )}
                </div>
              </div>
            </div>

            {/*
                        <div className="row">
                            <div className="col-sm-3">
                                <div className="form-group">
                                </div>
                            </div>
                        </div>
                        */}

            <AuiModal
              bOpen={isModal_Success}
              OnClose={() => IsModal_Success(false)}
            >
              <img
                style={{
                  height: 300,
                  width: 300,
                  display: "flex",
                  margin: "auto",
                }}
                src={require("../assets/successDonation.png")}
              />
              <a href="/donation/list" className="logo">
                <span>Go to donation list </span>
              </a>
              {/* </div> */}
            </AuiModal>
          </Grid>
          <Grid item md={12} xs={12}>
            <h3 className="projectTitle">Phone Number</h3>

            <div className="row">
              <div className="col-sm-4">
                <div className="form-group">
                  <label>
                    Country <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    value={phone.cn}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      Phone({ ...phone, cn: e.target.value });
                    }}
                    required
                  >
                    <option value="">Select</option>
                    <option value="44">UK (+44)</option>
                    <option value="880">Bangladesh (+880)</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Number <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="79 0000 0000"
                    value={phone.n}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      Phone({ ...phone, n: e.target.value });
                    }}
                    required
                  />
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label>...</label>
                  <button
                    className="btn btn-primary btn-block mt-2"
                    style={{ padding: 10 }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      RetrieveDonor().catch((err) => {});
                    }}
                  >
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
            </div>

            <h3 className="projectTitle display-3">Name</h3>

            <div className="row">
              <div className="col-sm-4">
                <div className="form-group">
                  <label>Title</label>
                  <select
                    className="form-control"
                    value={name.t}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      Name({ ...name, t: e.target.value });
                    }}
                  >
                    <option value="">Select</option>
                    <option value="mr">Mr</option>
                    <option value="ms">Ms</option>
                    <option value="mrs">Mrs</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label>
                    First <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={name.f}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      Name({ ...name, f: e.target.value });
                    }}
                    required
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label>
                    Last <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={name.l}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      Name({ ...name, l: e.target.value });
                    }}
                    required
                  />
                </div>
              </div>
            </div>

            <h3 className="projectTitle">Email</h3>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    className="form-control"
                    type="email"
                    value={email}
                    placeholder="hello@yourcomapny.com"
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      Email(e.target.value);
                    }}
                    // required
                  />
                </div>
              </div>
            </div>

            <h3 className="projectTitle">Address</h3>

            <div className="row">
              <div className="col-sm-8">
                <div className="form-group">
                  <label>Address Lookup</label>
                  <Autocomplete
                    apiKey={"AIzaSyDQaXKe4roziIjDQAFFC2wdJQrHM9K1o8M"}
                    onPlaceSelected={(place) => {
                      console.log(place);
                      let addr = {
                        l1: "",
                        l2: "",
                        c: "",
                        p: "",
                        cn: "",
                        pc: "",
                      };

                      place.address_components.forEach((addr_details) => {
                        const { short_name, types } = addr_details;

                        if (types.includes("postal_code")) {
                          Addr((ps) => {
                            ps.pc = short_name.trim();
                            return { ...ps };
                          });
                        } else if (types.includes("route")) {
                          Addr((ps) => {
                            ps.l2 = short_name.trim();
                            return { ...ps };
                          });
                        } else if (types.includes("postal_town")) {
                          Addr((ps) => {
                            ps.c = short_name.trim();
                            return { ...ps };
                          });
                        } else if (
                          types.includes("administrative_area_level_2")
                        ) {
                          Addr((ps) => {
                            ps.p = short_name.trim();
                            return { ...ps };
                          });
                        } else if (types.includes("country")) {
                          Addr((ps) => {
                            ps.cn = short_name.trim();
                            return { ...ps };
                          });
                        }
                      });
                    }}
                    className="form-control"
                    placeholder="Search"
                    options={{
                      types: ["(regions)"],
                    }}
                  />
                </div>
              </div>
              {/* <div className="col-sm-4">
                                <label>...</label>
                                <div className="btn btn-info btn-block"><i className="fa fa-map-marker" /> Find Your Address</div>
                            </div>*/}
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>
                    Address Line 1 <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Address Line 1 (*)"
                    value={addr.l1}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      Addr({ ...addr, l1: e.target.value });
                    }}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Address Line 2</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Address Line 2"
                    value={addr.l2}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      Addr({ ...addr, l2: e.target.value });
                    }}
                    // required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    City <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="City (*)"
                    value={addr.c}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      Addr({ ...addr, c: e.target.value });
                    }}
                    required
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Country / Province</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="County / Province"
                    value={addr.p}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      Addr({ ...addr, p: e.target.value });
                    }}
                    // required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Postcode <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Postcode (*)"
                    value={addr.pc}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      Addr({ ...addr, pc: e.target.value });
                    }}
                    required
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Country</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Country"
                    value={addr.cn}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      Addr({ ...addr, cn: e.target.value });
                    }}
                    // required
                  />
                </div>
              </div>
            </div>
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <PaymentMethod Proj={curProj} Camp={curCamp} Pack={curPck} Cus={{phone, name, addr, email}} Int={int} OnPmChange={val=>Payment(val)} ref={pmRef}/>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <h3 className="projectTitle">Consent</h3>

            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label className="font-weight-bold">GiftAid</label>
                  <Switch
                    checked={isGiftAid}
                    onChange={() => IsGiftAid(!isGiftAid)}
                  />
                </div>
                <div className="form-group">
                  <label>{text_legal.gift_aid}</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label className="font-weight-bold">Consent (Project)</label>
                  <Switch
                    checked={isConsentProj}
                    onChange={() => IsConsentProj(!isConsentProj)}
                  />
                </div>
                <div className="form-group">
                  <label>{text_legal.consent_proj}</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label className="font-weight-bold">Consent (Charity)</label>
                  <Switch
                    checked={isConsentCharity}
                    onChange={() => IsConsentCharity(!isConsentCharity)}
                  />
                </div>
                <div className="form-group">
                  <label>{text_legal.consent_charity}</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn-block btn btn-primary"
                    disabled={isSaving_Donr || isSaving_Dont}
                  >
                    Process Donation
                  </button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </Page>
  );
}; // Donations_Internal_New

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Donations_Internal_New);

/**
 */
const text_legal = {
  gift_aid:
    "I am a UK taxpayer and I wish (Global One) to reclaim tax back on all donations I have made within the last 6 years and all donations that I make hereafter. *",
  consent_proj:
    "Donor give consent for personal data to be used for future communication, but not to share with third party",
  consent_charity:
    "Donor give consent for personal data to be used for future communication, but not to share with third party",
};
