import React from "react";
import {Time, UI} from './Utils'

export const DropDown = (props) =>
{
    // console.log("Options",props.options)


    return(
        <div className= {props.divClass? props.divClass: "form-group"} >
            <label>{props.label?props.label:"Select"}</label>
            <select
                className= {props.selClass? props.selClass: "form-control"}
                onChange={(data)=> {
                    console.log("select value",data.target.value)
                    const obj = data.target.value === "select" ? {[props.valAttribute]:0} : props.options[data.target.value]
                    console.log(obj)

                    props.onChange(obj)
                }}
                >
                <option value={"select"}>Select</option>
                {
                    props.options && props.options.map( (data,idx) =>{

                        const txt = typeof data === 'string' ? data : props.txtAttribute? data[props.txtAttribute]: (data.name?NameObj(data.name):data.text?data.text:data.option)
                        const id = typeof data === 'string' ? data : (props.valAttribute?data[props.valAttribute]:data.id)
                        const isSelected = props.value && props.value === id
                        return  isSelected ? <option value={idx} selected={true} key={idx} >{txt}</option> : <option key={idx} value={idx} >{txt}</option>
                    })
                }

            </select>
        </div>
    )

}

class CustomAutoComplete extends React.PureComponent {
    constructor(p) {
        super(p)
        this.state = {
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: "",
            bSearch: this.props.bSearch ? this.props.bSearch :false
        }
        this.onSelect = this.onSelect.bind(this)
    }

    onChange = (event) => {
        const val = event.target.value;
        if (val.trim().length >= 2) {
            const fs = this.props.suggestions.filter(data => data[this.props.valAttribute].toLowerCase().trim().indexOf(['+88',val].join("").toLowerCase().trim()) > -1);
            console.log(fs)
            this.setState({filteredSuggestions: fs, showSuggestions: true, userInput:val})

        } else
            this.setState({showSuggestions: false, userInput:val})
    }
    onSelect = (event) => {
        const idx = event.currentTarget.dataset.id;
        const val = this.state.filteredSuggestions[idx];
        // console.log(val)

        this.props.onSelect(val)
        this.setState({showSuggestions: false, userInput:val[this.props.valAttribute],filteredSuggestions:[]})

    }

    /**
     */
    OnSubmit = ( e ) =>
    {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault()

        console.log( 'auth:Register: OnSubmit: e: ', e )

        // this.Register().catch( () => {} )
    }   // OnSubmit

    render() {
        return (
            <div className={this.props.divClass ? this.props.divClass : "form-group"}>

                <label>{this.props.label ? this.props.label : "Select"}</label>
                <input
                    className="form-control"
                    type="text"
                    onChange={this.onChange}
                    value={this.state.userInput}
                />
                <br/>
                {
                    this.state.showSuggestions && this.state.filteredSuggestions.length && this.state.filteredSuggestions.length>0 ?
                    <ul>
                        {
                            this.state.filteredSuggestions.map((data,idx) => <li data-id={idx} key={idx} onClick={this.onSelect} >{data[this.props.valAttribute]}</li>)
                        }
                    </ul>
                        :null
                }
                {
                    this.state.bSearch &&
                        <a className="btn btn-sm btn-primary"  onClick={()=>{this.props.onSearch(this.state.userInput)}}> Search </a>
                }
            </div>
        )
    }
}

export const RadioButton = (props) => {
    // console.log(props)
    return (
        <div className={props.divClass ? props.divClass : "col-md-12"} onChange={props.onChangeValue}>
            {
                props.label &&
                <label className="display-block">{props.label}</label>

            }
            {
                props.options && props.options.length && props.options.length > 0 ?
                    props.options.map((val,idx) => {
                        const value = val.value ? val.value : '';
                        const name = val.name ? val.name : '';
                        return <label className="form-check-label" key={idx}><input className="radio-input" type="radio" value={value} checked={props.selectedOption === value}/>{name}</label>
                    })
                    :
                    null

            }
        </div>
    )
}

export const CheckBox = (props)=>
{
    return(
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label>{props.label?props.label:"CheckBox"}</label><br/>
                    {
                        props.days.map((value,idx) => {
                            // value.checked = !!(this.props.bEdit && this.props.scheduleEdit.day === idx)
                            // console.log("checked",value)

                            return(
                                <div key={idx} className="form-check form-check-inline">
                                    <input className="form-check-input"
                                           type="checkbox"
                                           disabled={value.disable}
                                           value={idx}
                                           checked={value.checked}
                                           onChange={props.onChange}/>
                                    <label className="form-check-label"
                                           htmlFor="inlineCheckbox5">{Time.dayFull[idx]}</label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

const NameObj =(name)=>
{
    if (typeof name === 'string')
        return name
    else
        return [name.title , name.first ,name.last].join(' ').trim()
}
export const AutoComplete = CustomAutoComplete



