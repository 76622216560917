/**
 * @copyright KA-Tech Ltd.
 */

import React from 'react'

import {
    Box,
    CircularProgress,
    Modal,
} from '@material-ui/core'

/**
 */
const Loader = ( props ) =>
{
    return (
        <div>
            <CircularProgress
                color="#fff"
                size={props.size ? props.size : 200}
            />
            <h1 style={{color:"#fff"}}>{props.txt ? props.txt : 'Loading...'}</h1>
        </div>
    )
}   // Loader

const Overlay = ( props ) => {
    return (
        <div style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 121212,
            cursor: "pointer",
        }}>
            <div style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                fontSize: 50,
                color: "white",
                transform: "translate(-50%,-50%)",
            }}>
                <CircularProgress
                    color="#fff"
                    size={props.size ? props.size : 200}
                />
                <h1 style={{color:"#fff"}}>{props.txt ? props.txt : 'Processing...'}</h1>
            </div>
        </div>
    )
}

const MLoader = ( props ) =>
{
    return (
        <Modal
            open={props.bModal}
            onClose={ props.OnClose }
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Box
                height='100vh'
                display='flex'
                // flex={1}
                flexDirection='column'
                justifyContent="center"
                alignItems="center"
            >
                <Loader {...props} />
            </Box>
        </Modal>
    )
}   // Loader

/**
 */
export {MLoader, Loader, Overlay}









