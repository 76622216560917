import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { Donation_Query } from "../../rdx/actions";
import { useDispatch, useSelector } from "react-redux";
import AuiModal from "../../aui/modal";
import ListProj from "../donation/list_proj";
import { ListCamp } from "../donation/list_camp";
import OptionProject from "../_common/OptionProject";
import OptionCampaign from "../_common/OptionCampaign";

const useWindowDimensions = () => {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
};
function MonthlyDonation(props) {
  const { height, width } = useWindowDimensions();
  const orgId = useSelector((state) => state.__auth.orgId);
  const Rdx = useDispatch();
  const [monthlyDonation, SetMonthlyDonation] = React.useState([]);
  const [selectedMonth, SelectMonth] = React.useState(new Date());

  const [curProj, CurProj] = React.useState(null);
  const [curCamp, CurCamp] = React.useState(null);
  const [isModal_Proj, IsModal_Proj] = React.useState(false);
  const [isModal_Camp, IsModal_Camp] = React.useState(false);

  const LoadData = async (params) => {
    try {
      let hasMore = false;
      let dataList = [];
      do {
        const data = await Rdx(Donation_Query(params));
        // console.log(data);
        hasMore = !!data.LastEvaluatedKey;
        params["last_key"] = data?.LastEvaluatedKey;
        dataList.push(...data.Items);
      } while (hasMore);

      return dataList;
    } catch (e) {
      console.warn("Filed to load specific donation data!", e);
      return Promise.reject(e);
    }
  };
  const LoadMonthlyDonation = async () => {
    try {
      console.log(selectedMonth.toLocaleDateString());
      const dt_st = parseInt(
        new Date(
          new Date().setFullYear(
            selectedMonth.getFullYear(),
            selectedMonth.getMonth(),
            1
          )
        ).setHours(0, 0, 0) / 1000,
        10
      );
      const dt_end = parseInt(
        new Date(
          new Date().setFullYear(
            selectedMonth.getFullYear(),
            selectedMonth.getMonth() + 1,
            1
          )
        ).setHours(0, 0, 0) / 1000,
        10
      );
      const params = {
        last_key: null,
        dt_st,
        dt_end,
        nm_f: null,
        nm_l: null,
        pr_n: curProj?.tn,
        cp_n: curCamp?.tn,
        paym_m: null,
        paym_s: null,
        a_st: 0,
        a_end: 0,
      };
      const data = await LoadData(params);
      SetMonthlyDonation(data);
      // console.log(data)
    } catch (e) {
      console.warn("Filed to load specific campaign data!", e);
      return Promise.reject(e);
    }
  };


  const ProjectSelectHandler = (project) => {
    CurProj(project);
    CurCamp(null);
  }

  const CampaignSelectHandler = (campaign) => {
    CurCamp(campaign);
  }
  
  return (
    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <h2 className="projectTitle">Monthly Donation Data</h2>
      <form className="mb-3" onSubmit={LoadMonthlyDonation}>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <OptionProject type={'all'} OnSelect={ProjectSelectHandler} currentProject={curProj}/>
          </div>
          <div className="col-lg-3 col-md-6">
            <OptionCampaign type={'only-active'} OnSelect={CampaignSelectHandler} currentProject={curProj} currentCampaign={curCamp}/>
          </div>

          <div className="col-lg-2 col-md-6">
            <select
              className="form-control"
              style={{
                fontSize: 20,
                borderColor: "#0094FF",
                borderRadius: 4,
                minHeight: 63,
                margin: "5px 0px",
              }}
              onChange={(e) => {
                if (parseInt(e.target.value, 10) >= 0) {
                  SelectMonth(
                    new Date(
                      selectedMonth.setMonth(parseInt(e.target.value, 10))
                    )
                  );
                }
              }}
            >
              <option value={-1}>Select Month</option>
              <option value={0}>January</option>
              <option value={1}>February</option>
              <option value={2}>March</option>
              <option value={3}>April</option>
              <option value={4}>May</option>
              <option value={5}>June</option>
              <option value={6}>July</option>
              <option value={7}>August</option>
              <option value={8}>September</option>
              <option value={9}>October</option>
              <option value={10}>November</option>
              <option value={11}>December</option>
            </select>
          </div>
          <div className="col-lg-2 col-md-6">
            <select
              className="form-control"
              style={{
                fontSize: 20,
                borderColor: "#0094FF",
                borderRadius: 4,
                minHeight: 63,
                margin: "5px 0px",
              }}
              onChange={(e) => {
                if (parseInt(e.target.value, 10) >= 0) {
                  SelectMonth(
                    new Date(
                      selectedMonth.setFullYear(parseInt(e.target.value, 10))
                    )
                  );
                }
              }}
            >
              <option value="0">Select Year</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>s
              <option value="2028">2028</option>
              <option value="2029">2029</option>
              <option value="2030">2030</option>
            </select>
          </div>
          <div className="col-lg-2 col-md-6">
            <input
              type="button"
              className="btn btn-primary btn-block"
              style={{ minHeight: 61, margin: "4px 0" }}
              value="Show"
              onClick={LoadMonthlyDonation}
            />
          </div>
        </div>
      </form>
      <Last30Days width={width} donation={monthlyDonation} />
      <AuiModal
        bOpen={isModal_Proj}
        OnClose={() => IsModal_Proj(false)}
        className="aui-modal"
      >
        <div className="modal-donation">
          <ListProj
            pr={curProj}
            OnSelect={(proj) => {
              CurProj(proj);
              CurCamp(null);
            }}
            OnClose={() => IsModal_Proj(false)}
          />
        </div>
      </AuiModal>

      <AuiModal bOpen={isModal_Camp} OnClose={() => IsModal_Camp(false)}>
        <div className="modal-camp">
          <ListCamp
            pr={curProj}
            cp={curCamp}
            OnSelect={(camp) => {
              console.log("Donations_Internal_New: ListCamp: camp: ", camp);

              CurCamp(camp);
            }}
            OnClose={() => IsModal_Camp(false)}
          />
        </div>
      </AuiModal>
    </div>
  );
}

export default MonthlyDonation;

/**
 */
const Last30Days = (props) => {
  const [isBusy, IsBusy] = React.useState(false);

  const [data, Data] = React.useState([]);

  /**
   */
  React.useEffect(() => {
    if (props.donation) {
      PrepData(props.donation)
        .then((data) => Data(data))
        .catch();
    }
  }, [props.donation]);

  /**
   */
  const PrepData = async (data) => {
    try {
      // cluster data
      const v_obj = data.reduce((a, c) => {
        const dt_obj = new Date(c.tn * 1000);
        const dt_year = dt_obj.getFullYear().toString();
        const dt_month = (dt_obj.getMonth() + 1).toString().padStart(2, "0");
        const dt_date = dt_obj.getDate().toString().padStart(2, "0");

        const tmp_k = parseInt([dt_year, dt_month, dt_date].join(""), 10);
        const tmp_n = [dt_date].join("."); //[dt_date, dt_month].join('.')
        const tmp_o = {
          _k: tmp_k,
          _n: tmp_n,
          ...c,
        };

        if (!a[tmp_k]) {
          a[tmp_k] = [];
        }

        a[tmp_k] = [...a[tmp_k], tmp_o];

        return a;
      }, {});

      // console.log( 'Last30Days: Load: v_obj: ', v_obj )

      // const v_obj_2 =
      for (let i = 1; i <= 30; ++i) {
        const dt_obj = new Date();
        dt_obj.setDate(dt_obj.getDate() - i);
        const dt_year = dt_obj.getFullYear().toString();
        const dt_month = (dt_obj.getMonth() + 1).toString().padStart(2, "0");
        const dt_date = dt_obj.getDate().toString().padStart(2, "0");
        const tmp_k = parseInt([dt_year, dt_month, dt_date].join(""), 10);
        const tmp_n = [dt_date].join("."); //[dt_date, dt_month].join('.')

        if (!v_obj[tmp_k]) {
          v_obj[tmp_k] = [
            {
              _k: tmp_k,
              _n: tmp_n,
            },
          ];
        }
      } // for i

      //
      const v_arr = Object.keys(v_obj).reduce((a, c) => {
        try {
          return [
            ...a,
            { _k: parseInt(c, 10), _n: v_obj[c][0]._n, data: v_obj[c] },
          ];
        } catch (err) {
          return a;
        }
      }, []);

      v_arr.sort((a, b) => a._k - b._k);

      // console.log( 'Last30Days: Load: v_arr: ', v_arr )

      const f_arr = v_arr.map((x) => {
        const tmp_tot = x.data.reduce((a, c) => {
          try {
            const tot_a =
              "string" === typeof c.k.a ? parseInt(c.k.a, 10) : c.k.a;

            if ("ref" === c.s) {
              return a - tot_a;
            } else {
              return a + tot_a;
            }
          } catch (err) {
            return a;
          }
        }, 0.0);

        return {
          _k: x._k,
          _n: x._n,
          tot: tmp_tot,
          cnt: x.data.length,
        };
      });

      // console.log( 'Last30Days: Load: f_arr: ', f_arr )

      return f_arr;
    } catch (err) {
      console.warn("Last30Days: Load: err: ", err);

      IsBusy(false);

      return Promise.reject(err);
    }
  }; // Load

  // console.log("props.width", props.width);

  /**
   */
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        width={
          props.width && props.width > 800
            ? 800
            : props.width
            ? props.width - 30
            : 800
        }
        height={300}
        data={data}
      >
        <XAxis dataKey="_n" />
        {/* <YAxis/> */}
        <Tooltip formatter={(value) => [value.toFixed(2), null]} />
        {/* <Legend /> */}
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="tot" stroke="#8884d8" strokeWidth={5} />
        {/* <Line type="monotone" dataKey="pv" stroke="#82ca9d" /> */}
      </LineChart>
    </ResponsiveContainer>
  );
}; // Last30Days
