/**
 * @copyright KARD
 */

import React from "react";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";
import "./campaign.css";
import { ImageInput } from "../_common/img_input";
import Image from "../_common/image";
import PHI from "../../assets/img/placeholder.png";

import { News_SaveImage } from "../../rdx/actions";
import { uploadFile } from "../../rdx/actions";
import { News_Edit } from "../../rdx/actions";
import { useAlert } from "react-alert";

/**
 */
const NewsFeed = (props) => {

  const Alert = useAlert();

  const [isLoading, IsLoading] = React.useState(false);
  const [isPosting, IsPosting] = React.useState(false);

  const [list, List] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);

  // const [title, Title] = React.useState("");
  const [message, Message] = React.useState("");
  const [img, SetImg] = React.useState({});
  const [selPost, SetSelPost] = React.useState(false);
  /**
   */
  React.useEffect(() => {
    Load(false).catch((err) => {});
  }, []);

  /**
   */
  const OnPost = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (selPost.ni) {
      EditNewsFeed()
        .then(() => {
          SetSelPost({});
        })
        .catch((err) => {});
    } else {
      SaveProcess()
        .then((data) => {
          if (data.success) {
            alert("News feed saved");

            SetSelPost({});
            Message( '' )
            SetImg( {} )

            List([...list, data.news]);
          }
          else{
            alert("News feed did not save, try again.");
          }
        })
        .catch((err) => {
          alert(err.message ? err.message : err);
        });
    }
  }; // OnSave
  /**
   */
  async function SaveProcess() {
    try {
      const { Item } = await SaveNew();

      if (!Item) {
        throw new Error("Failed to create News Feed!");
      }
      if (img.file) {
        const { success, url } = await saveImage(Item);

        if (success) {
          const resp = await props.NewsFeed_SaveImage({ url, ni: Item.ni });
          if (resp.success) {
            return { success: true, news: resp.news.data.Attributes };
          }
        }
      } else {
        return { success: true, news: Item };
      }
    } catch (e) {
      console.warn("campaign/newsfeed: SaveProcess:error", e);

      return Promise.reject(e);
    }
  } // Save Process
  /**
   */
  async function saveImage(p) {
    try {
      const file = img.file;
      const ft = file.type.split("/")[1];
      const dtNow = Date.now();
      const iid = [dtNow, p.i, p.ni, "profile", file.name.split(".")[0]].join(
        "_"
      );
      const name = iid + "." + ft;
      const params = {
        name: name,
        key: name,
        contentType: file.type,
        file: file,
        g: "profile",
        tp: "profile",
        iid,
        ft,
      };
      // console.log(params,file);
      const { s3 } = await props.uploadFile(params);
      console.log(s3);
      if (s3.success) {
        return { ...s3 };
      } else {
        throw new Error("Filed to save file!");
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }
  /**
   */
  const SaveNew = async () => {
    try {
      IsPosting(true);

      const message_p = message.trim().split("\n");

      const resp_post = await props.NewsFeed_New({
        t: "",
        m: message_p,
        p: props.pr_n,
        c: props.cp_n,
        url: "",
        udt: "",
      });

      console.log("campaign/newsfeed: SaveNew: resp_post: ", resp_post);

      if (resp_post) {
        //
        const params = {
          p: props.pr_n,
          c: props.cp_n,
          email_from: "",
          msg_sub: "Campaign Information",
          msg_body: message_p.join("\n"),
        };
        props.NewsFeed_SendEmail(params).catch();
      }

      return resp_post;
    } catch (err) {
      console.warn("campaign/newsfeed: OnPost: err: ", err);

      IsPosting(false);

      return { err };
    }
  }; // SaveNew
  /**
   */
  const EditNewsFeed = async () => {
    try {
      let newUrl = img.url;
      if (img.file) {
        const p = {
          i: selPost.i,
          ni: selPost.ni,
        };
        const { success, url } = await saveImage(p);
        if (success) {
          newUrl = url;
        }
      }

      const message_p = message.trim().split("\n");

      const news_obj = {
        t: "",
        m: message_p,
        p: props.pr_n,
        c: props.cp_n,
        ni: selPost.ni,
        url: newUrl,
        udt: "",
      };

      console.log("campaign/newsfeed: EditNews: news_obj: ", news_obj);

      const resp_save = await props.NewsFeed_Edit(news_obj);

      console.log("campaign/newsfeed: EditNews: resp_save: ", resp_save);

      if (resp_save.msg === "OK") {
        let _list = [...list];
        let index = _list.findIndex((x) => x.ni == selPost.ni);
        _list[index] = resp_save.data.Attributes;
        List(_list);

        SetSelPost({});
        Message("");
        SetImg({});
      }

      // ListProj( b_more ? [...listProj, ...resp_list.Items] : [...resp_list.Items] )
      // LastKey( resp_list.LastEvaluatedKey )

      alert("Newsfeed changes saved");

      return {};
    } catch (err) {
      console.warn("campaign/newsfeed: Edit: err: ", err);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // EditNews
  /**
   */
  const DeleteNewsFeed = async (post) => {
    try {
      const del_obj = {
        ni: post.ni,
      };
      console.log("del_obj", del_obj);

      const del_resp = await props.NewsFeed_Delete(del_obj);
      console.log("campaign/newsfeed: DeleteNewsFeed: resp_save: ", del_resp);
      if (del_resp) {
        //
      }

      alert("News feed deleted ");

      const _list = list.filter((x) => x.ni !== post.ni);
      List(_list);

      return {};
    } catch (err) {
      console.warn("campaign/newsfeed: Edit: err: ", err);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // EditNews
  /**
   */
  const Load = async (b_more) => {
    try {
      IsLoading(true);

      const resp_list = await props.NewsFeed_List({
        last_key: b_more ? lastKey : null,
        // pr_n: props.pr_n,
        cp_n: props.cp_n,
      });

      console.log("campaign/newsfeed: Load: resp_list: ", resp_list);

      List(b_more ? [...list, ...resp_list.Items] : [...resp_list.Items]);
      LastKey(resp_list.LastEvaluatedKey);

      IsLoading(false);

      return {};
    } catch (err) {
      console.warn("campaign/newsfeed: Load: err: ", err);

      IsLoading(false);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // Load

  /**
   */
  return (
    <>
      <div className="mt-3 mr-1">
        <div className="col-sm-4 col p-0">
          <h2 className="projectTitle">News Feed</h2>
        </div>
        {/* <div className="col-sm-8 col text-right mb-3">
          <button
              onClick={() => {
                SetAddNew(!addNew)
                Message('')
                SetImg({})
                SetSelPost({})
              }}
              className={addNew ? "btn btn btn-danger float-right" : "btn btn btn-primary float-right" }
          >
            <i className={addNew ? "fa fa-minus" : "fa fa-plus" } /> {addNew ? 'Close' : 'Add'}
          </button>
        </div>*/}
      </div>

      {
        /*addNew &&*/
        <form onSubmit={OnPost}>
          <div className="form-group">
            <label htmlFor="post_body" className="col-form-label">
              Message
            </label>
            <textarea
              className="form-control"
              id="post_body"
              rows="4"
              value={message}
              onChange={(e) => Message(e.target.value)}
            />
            <div className="form-group">
              <label htmlFor="s_article" className="col-form-label">
                Image
              </label>
              <ImageInput
                onChange={(img) => {
                  SetImg(img);
                }}
                value={img}
                label={"Select an Image"}
              />
              <div className="form-group"></div>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={isPosting}
          >
            {selPost.ni ? "Edit Post" : "Post"}
          </button>
        </form>
      }

      {
        /*!addNew &&*/
        <ul className="feed">
          {list.map((x) => (
            <FeedView
              key={x.ni}
              feed={x}
              onEdit={(post) => {
                Message(post.m.join(""));
                if (post.url) {
                  SetImg({ url: post.url });
                }
                SetSelPost(post);
              }}
              onDel={(post)=>{
                Alert.show("Do you want to delete this feed ?", {
                  title: "Alert!!",
                  actions: [
                    {
                      copy: "Yes",
                      onClick: () => DeleteNewsFeed(post),
                    },
                  ],
                  closeCopy: "No",
                });
              }}
            />
          ))}
        </ul>
      }
    </>
  );
}; // NewsFeed

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(NewsFeed);

/**
 */
const FeedView = (props) => {
  return (
      <li>
        <hr />
        {/* <div className="feed-title mb-3"> */}
        <div style={{display: 'flex',}}>
          {/*<span className="contact-name text-ellipsis feed-name">
            Arif Hossain | 2 hr
          </span>*/}
          {/* <div className="feed-content"> */}
          <div style={{flex: 1,}}>
            <>
            {props.feed.url && (
                <div className="form-group">
                  <Image
                      src={props.feed.url}
                      alt={PHI}
                      altTxt="image"
                      cn="border"
                      style={{
                        width: 400,
                        maxHeight: 300,
                        minHeight: 200,
                        objectFit: "cover",
                      }}
                  />
                </div>
            )}
            </>
            <>
            {props.feed.m.map((x, i) => (
                <p key={i}>
                  <a>{x}</a>
                </p>
            ))}
            </>
          </div>
          <div style={{display: 'flex', 'flex-direction': 'column'}}>
            {/* <button
                className="btn btn-primary btn-lg mr-1"
                style={{width: '80px'}}
                onClick={() => {
                  props.onEdit(props.feed);
                }}
                disabled
            >
              Edit
            </button> */}
            <button
                className="btn btn-danger btn-lg"
                style={{width: '80px'}}
                onClick={() => {
                  props.onDel(props.feed);
                }}
            >
              Delete
            </button>
          </div>
        </div>
      </li>
  );
}; // FeedView
