/**
 * @copyright KARD.
 */

import React from 'react'

/**
 */
const cnList = [
    {val: 'United Kingdom', cn: 'UK', code: '+44'},
    {val: 'Bangladesh', cn: 'bd', code: '+880'},
]   // cnList

/**
 */
export default cnList



