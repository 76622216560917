/**
 * @copyright KARD.
 */

import React from 'react'

// import {
// 	Button,
// } from '@material-ui/core'

// import { navigate } from "@reach/router"

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

/**
 */
class Notifications extends React.PureComponent
{
    render()
    {
        return (
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title mb-0">Notifications</h4>
                </div>
                <div className="card-body">
                    <ul className="contact-list">
                    <li>
                        <div className="contact-info">
                            <span className="contact-date">9 JUNE 2021</span>
                            <span className="contact-name text-ellipsis">Qurbani costs vary by country</span>
                        </div>
                    </li>
                    <li>
                        <div className="contact-cont">
                        {/*<div className="float-left user-img m-r-10">*/}
                        {/*    <a href="profile.html" title="Richard Miles"><img src="assets/img/user.jpg" alt="" className="w-40 rounded-circle" /><span className="status offline" /></a>*/}
                        {/*</div>*/}
                            <div className="contact-info">
                                <span className="contact-date">9 JUNE 2021</span>
                                <span className="contact-name text-ellipsis">Qurbani costs vary by country</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="contact-cont">
                        {/*<div className="float-left user-img m-r-10">*/}
                        {/*    <a href="profile.html" title="John Doe"><img src="assets/img/user.jpg" alt="" className="w-40 rounded-circle" /><span className="status away" /></a>*/}
                        {/*</div>*/}
                            <div className="contact-info">
                                <span className="contact-date">9 JUNE 2021</span>
                                <span className="contact-name text-ellipsis">Qurbani costs vary by country</span>
                            </div>
                        </div>
                    </li>
                    </ul>
                </div>
                {/*<div className="card-footer text-center bg-white">*/}
                {/*    <a href="doctors.html" className="text-muted">View all Doctors</a>*/}
                {/*</div>*/}
            </div>
        )
    }   // render
}   // Notifications

/**
 */
const mapStateToProps = ( state ) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Notifications )





