import React, { useEffect, useRef, useState } from "react";
import AuiModal from "../../aui/modal";
import { useDispatch } from "react-redux";
import { Project_List } from "../../rdx/actions";
import { CircularProgress } from "@material-ui/core";
import { Time } from "../../api/Utils";
import { Item } from "./OptionItem";

const OptionProject = ({ type, OnSelect, currentProject }) => {
  const dispatch = useDispatch();

  const isMounted = useRef(true);
  const [selectedProject, SelectProject] = useState(null);
  const [isShowingProjectList, ShowProjectList] = useState(false);
  const [isLoading, SetLoading] = useState(false);
  const [projectList, UpdateProjectList] = useState([]);
  const [lastKey, SetLastKey] = useState(null);
  const dtNow = Time.Epoch(Date.now());

  const LoadProject = async () => {
    try {
      SetLoading(true);
      const { Items, LastEvaluatedKey } = await dispatch(Project_List(lastKey));
      if (!isMounted.current) return null;
      UpdateProjectList(lastKey ? [...projectList, ...Items] : Items);
      if (LastEvaluatedKey) {
        SetLastKey(LastEvaluatedKey);
      }
      SetLoading(false);

      return { msg: "ok" };
    } catch (e) {
      console.warn("ProjectList: LoadProject: Error:", e);
      return Promise.reject(e);
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      LoadProject().then().catch();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (currentProject) {
      SelectProject(currentProject);
    }
  }, [currentProject]);

  const SelectProjectHandler = (project) => {
    OnSelect(project);
    SelectProject(project);
    ShowProjectList(false);
  };

  return (
    <>
      <button
        className="btn-block btn"
        style={{
          fontSize: 20,
          fontWeight: selectedProject ? 900 : null,
          margin: 5,
          padding: 16,
          color: selectedProject ? "#0094FF" : "#4f566a",
          backgroundColor: selectedProject ? "#EFF8FF" : null,
          borderColor: selectedProject ? "#0094FF" : "#009efb",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        onClick={(e) => {
          e.preventDefault();
          if (isLoading) return "Loading";
          ShowProjectList(true);
        }}
      >
        {isLoading ? (
          <>
            Loading...
            <CircularProgress
              color="primary"
              size={10}
              style={{ top: 10, right: 20, position: "absolute" }}
            />
          </>
        ) : (
          <>{selectedProject?.p?.t || "Select Project"}</>
        )}
      </button>
      <AuiModal
        bOpen={isShowingProjectList}
        OnClose={() => ShowProjectList(false)}
        className="aui-modal"
      >
        <div className="modal-donation">
          <div style={{ overflow: isLoading ? "hidden" : "" }}>
            <div className={"d-flex justify-content-end rounded mx-3 my-4"}>
              <button
                className="btn-outline-danger btn"
                style={{
                  fontWeight: selectedProject ? 900 : null,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  SelectProjectHandler(null);
                }}
              >
                <i className="fa fa-remove" style={{ fontSize: 18 }}></i> Clear
                Selection
              </button>
            </div>

            {isLoading ? (
              <CircularProgress
                color="primary"
                size={200}
                style={{ top: "30%", left: "38%", position: "absolute" }}
              />
            ) : (
              projectList.map((value) => {
                const isExp = !!(value.te && value.te > 0 && value.te < dtNow);
                const isDisable = !(!value.s || value.s === "act");
                const isSelected = value?.tn === selectedProject?.tn;
                if (type === "only-active" && (isExp || isDisable)) return null;

                return (
                  <Item
                    key={value.tn}
                    item={value}
                    isExp={isExp}
                    isDisable={isDisable}
                    isSelected={isSelected}
                    OnSelect={SelectProjectHandler}
                  />
                );
              })
            )}
            <div>
              {lastKey && (
                <button
                  className="btn btn-primary ml-3"
                  style={{
                    margin: 5,
                    padding: 5,
                  }}
                  onClick={() => LoadProject().catch((err) => {})}
                >
                  Show More
                </button>
              )}
            </div>
          </div>
        </div>
      </AuiModal>
    </>
  );
};
export default OptionProject;
