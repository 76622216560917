/**
 * @copyright KARD
 */

import React, { useState } from "react";

import {
  ResponsiveContainer,
  LineChart,
  Line,
  // BarChart, Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import { Card, ProgressBar } from "react-bootstrap";
import { navigate, Link } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "../../rdx/actions";

import {
  Time,
  // UI,
} from "../../api/Utils";

import AuiStatus from "../../aui/status";

import Page from "../_common/page";

import NewsFeed from "./newsfeed";
import "../donation/donate.css";
// import './project.css'

const useWindowDimensions = () => {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
};
/**
 */
const Preview = (props) => {
  const parentRef = React.useRef(null);
  // const [width, Width] = React.useState(0);
  const { height, width } = useWindowDimensions();

  const [isLoading, IsLoading] = React.useState(false);

  const [projTitle, ProjTitle] = React.useState("");
  const [projSubTitle, ProjSubTitle] = React.useState("");
  const [projDesc, ProjDesc] = React.useState("");
  const [projShortDesc, ProjShortDesc] = React.useState("");
  const [projStatus, ProjStatus] = React.useState("act");
  const [projDtSt, ProjDtSt] = React.useState("N/A");
  const [projDtEnd, ProjDtEnd] = React.useState("N/A");

  const [projTarget, ProjTarget] = React.useState(0);
  const [projRaised, ProjRaised] = React.useState(0);

  const [listCamp, ListCamp] = React.useState([]);
  const [lastKey, LastKey] = React.useState(null);
  const [img, SetImg] = useState(null);

  /**
   */
  React.useEffect(() => {
    if (props.pr_n) {
      // IsShowingSave( false )

      Load().catch((err) => {});
    } else {
      // IsShowingSave( true )
    }
  }, []);

  /**
   */
  // React.useEffect(() => {
  //   if (parentRef.current) {
  //     // console.log( 'Home: parentRef.current.offsetWidth: ', parentRef.current.offsetWidth )
  //     Width(parentRef.current.offsetWidth);
  //   }
  // }, [parentRef]);

  /**
   */
  const Load = async () => {
    try {
      IsLoading(true);

      const pr_arr = [LoadProj(), LoadCampList(false)];

      const pr_resp = await Promise.all(pr_arr);

      if (pr_resp) {
        //
      }

      //
      IsLoading(false);

      return {};
    } catch (err) {
      console.warn("ProjectNew: Load: err: ", err);

      IsLoading(false);

      return Promise.reject(err);
    }
  }; // Load

  /**
   */
  const LoadCampList = async (b_more) => {
    try {
      const list_camp = await props.Campaign_List_Project(
        parseInt(props.pr_n, 10),
        b_more ? lastKey : null
      );

      console.log("ProjectNew: LoadCampList: list_camp: ", list_camp);

      ListCamp(list_camp.Items);
      // OrigListCamp( list_camp )
      LastKey(list_camp.LastEvaluatedKey);

      //
      return {};
    } catch (err) {
      console.warn("ProjectNew: LoadCampList: err: ", err);

      return { err };
    }
  }; // LoadCampList

  /**
   */
  const LoadProj = async () => {
    try {
      const resp_proj = await props.Project_Details(parseInt(props.pr_n, 10));
      console.log(resp_proj);

      try {
        ProjTitle(resp_proj.Item.p.t ? resp_proj.Item.p.t : "");
      } catch (err) {}

      try {
        ProjSubTitle(resp_proj.Item.p.ts ? resp_proj.Item.p.ts : "");
      } catch (err) {}

      try {
        ProjDesc(resp_proj.Item.p.d ? resp_proj.Item.p.d : "");
      } catch (err) {}

      try {
        ProjShortDesc(resp_proj.Item.p.ds ? resp_proj.Item.p.ds : "");
      } catch (err) {}

      try {
        ProjStatus(resp_proj.Item.s ? resp_proj.Item.s : "");
      } catch (err) {}

      try {
        ProjTarget(resp_proj.Item.a.t.toFixed(2));
      } catch (err) {}
      try {
        ProjRaised(resp_proj.Item.a.r.toFixed(2));
      } catch (err) {}
      try {
        SetImg(resp_proj?.Item?.img);
      } catch (err) {}

      try {
        if (!resp_proj.Item.ts) {
          throw "";
        }

        const dt_obj = new Date(resp_proj.Item.ts * 1000);

        ProjDtSt(
          [dt_obj.getDate(), dt_obj.getMonth() + 1, dt_obj.getFullYear()].join(
            "."
          )
        );
      } catch (err) {
        ProjDtSt("N/A");
      }

      try {
        if (!resp_proj.Item.te) {
          throw "";
        }

        const dt_obj = new Date(resp_proj.Item.te * 1000);

        ProjDtEnd(
          [dt_obj.getDate(), dt_obj.getMonth() + 1, dt_obj.getFullYear()].join(
            "."
          )
        );
      } catch (err) {
        ProjDtEnd("N/A");
      }

      return {};
    } catch (err) {
      console.warn("ProjectNew: Load: err: ", err);

      return { err };
    }
  }; // LoadProj

  /**
   */
  return (
    <Page>
      {/* <div className="row">
        <div className="col-sm-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Project</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">View</a>
              </li>
            </ol>
          </nav>
        </div>
      </div> */}

      <div className="row mt-2 mb-3">
        <div className="col-sm-12">
          <h4 className="projectTitle m-0">
            <i className="fa fa-cog fa-lg mr-1" />
            {projTitle}
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-9" ref={parentRef}>
          <div className="card">
            <Last30Days
              proj={props.pr_n}
              width={width}
              Donation_Query={props.Donation_Query}
            />
          </div>

          {/* gallery slider */}
          <div>{/*  */}</div>

          {/* show campaigns */}
          <div className="camp-list">
            <div>
              <h2 className="projectTitle">Campaign List</h2>
            </div>
            <div className="row">
              {listCamp.map((x) => (
                <div className="col-sm-6 col-md-4">
                  <Card className="projectCard">
                    {x.img ? (
                      <Card.Img
                        variant="top"
                        src={x.img.c.f}
                        style={{ maxHeight: 300, minHeight: 300 }}
                      />
                    ) : (
                      <Card.Img
                        variant="top"
                        src={require("./fund.jpeg")}
                        style={{ maxHeight: 300, minHeight: 300 }}
                      />
                    )}
                    <Card.Body>
                      <Card.Title>
                        <Link to={`/campaign/view/${props.pr_n}/${x.tn}`}>
                          {x.ci.t}
                        </Link>
                        {/*<p className="pull-right">10 November 2021</p>*/}
                      </Card.Title>
                      <Card.Text className="text-muted">
                        {`£${(x.a.r / 100).toFixed(2)}`} Collected of{" "}
                        {`£${(x.a.t / 100).toFixed(2)}`} goal
                      </Card.Text>
                      <ProgressBar
                        now={(x.a.r * 100) / x.a.t}
                        style={{ height: ".50rem", marginTop: "10px" }}
                      />
                      <Card.Text className="mt-3">
                        <a>{x.ci.d}</a>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </div>

          {/* News Feed */}
          <NewsFeed pr_n={props.pr_n} />
        </div>

        <div className="col-sm-3">
          <div className="card bg-primary">
            <div className="card-body">
              <div className="bg-white p-2 mb-3 rounded">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="contact-info">
                      <span className="contact-name text-ellipsis text-primary">
                        Target Amount
                      </span>
                      <span className="h3 text-primary font-weight-bold">
                        {/* $ 5000.00 */}
                        {`£${projTarget}`}
                      </span>
                      <ProgressBar
                        style={{ height: ".25rem", marginTop: "10px" }}
                        now={(projRaised * 100) / projTarget}
                      />
                      <p className="text-primary mt-2 mb-0">
                        {`£${projRaised}`} Raised of {`£${projTarget}`}
                      </p>
                    </div>
                  </div>
                  {/* <div className="col-sm-2 justify-content-center" >
                                        <i className="fa fa-dollar fa-lg text-primary" style={{margin: '15px 5px'}} />
                                    </div> */}
                </div>
              </div>
              <div className="text-white mb-4">
                <p className="text-white font-weight-bold mb-2">Details</p>
                {projDesc}
              </div>

              <ul className="contact-list project-info">
                {/* <li>
                                    <div className="row">
                                        <div className="mr-2 ml-2">
                                            <i className="fa fa-cog fa-lg text-white" style={{border: '1px solid #fff', borderRadius: '50%',padding: '15px'}} />
                                        </div>
                                        <div className="">
                                            <div className="">
                                                <span className="text-white">Project</span>
                                                <span className="contact-name text-ellipsis text-white">Funding</span>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}
                {/* <li>
                                    <div className="row">
                                        <div className="mr-2 ml-2">
                                            <i className="fa fa-file fa-lg text-white" style={{border: '1px solid #fff', borderRadius: '50%',padding: '15px'}} />
                                        </div>
                                        <div className="">
                                            <div className="">
                                                <span className="text-white">Type</span>
                                                <span className="contact-name text-ellipsis text-white">Standard</span>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}
                <li>
                  <div className="row">
                    <div className="mr-2 ml-2">
                      <i
                        className="fa fa-calendar-check-o fa-lg text-white"
                        style={{
                          border: "1px solid #fff",
                          borderRadius: "50%",
                          padding: "15px",
                        }}
                      />
                    </div>
                    <div className="">
                      <span className="text-white">Duration</span>
                      <span className="contact-name text-ellipsis text-white">{`${projDtSt} - ${projDtEnd}`}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div className="mr-2 ml-2">
                      <i
                        className="fa fa-power-off fa-lg text-white"
                        style={{
                          border: "1px solid #fff",
                          borderRadius: "50%",
                          padding: "15px",
                        }}
                      />
                    </div>
                    <div className="">
                      <span className="text-white">Status</span>
                      <span className="contact-name text-ellipsis text-white">
                        {"act" === projStatus ? "Active" : "Suspended"}
                      </span>
                    </div>
                  </div>
                </li>

                <hr
                  style={{
                    borderTop: "1px solid rgb(255 255 255 / 30%) ",
                  }}
                />
                <div className="mt-2 mb-2 text-center">
                  {/* <div className="btn btn-info mr-2"><i className="fa fa-plus" /> Add New Campaign</div> */}
                  <div
                    className="btn border text-white mr-4"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      // navigate( `` )
                      navigate(`/project/list/${props.pr_n}`);
                    }}
                  >
                    <i className="fa fa-edit" /> Edit
                  </div>
                  <div
                    className="btn border text-white"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      // alert and then remove
                    }}
                  >
                    <i className="fa fa-remove" /> Delete
                  </div>
                </div>
              </ul>
            </div>
          </div>

          {/* Gallery  */}

          {/* <h2 className="projectTitle">Gallery </h2>
          <div class="row">
            <div class="col-lg-4 col-md-12 mb-2 mb-lg-0">
              <img
                src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(73).jpg"
                class="w-100 shadow-1-strong rounded mb-2"
                alt=""
              />

              <img
                src="https://mdbootstrap.com/img/Photos/Vertical/mountain1.jpg"
                class="w-100 shadow-1-strong rounded mb-2"
                alt=""
              />
            </div>

            <div class="col-lg-4 mb-2 mb-lg-0">
              <img
                src="https://mdbootstrap.com/img/Photos/Vertical/mountain2.jpg"
                class="w-100 shadow-1-strong rounded mb-2"
                alt=""
              />

              <img
                src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(73).jpg"
                class="w-100 shadow-1-strong rounded mb-2"
                alt=""
              />
            </div>

            <div class="col-lg-4 mb-2 mb-lg-0">
              <img
                src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(18).jpg"
                class="w-100 shadow-1-strong rounded mb-2"
                alt=""
              />

              <img
                src="https://mdbootstrap.com/img/Photos/Vertical/mountain3.jpg"
                class="w-100 shadow-1-strong rounded mb-2"
                alt=""
              />
            </div>
          </div> */}
          {/* Gallery  */}
        </div>
      </div>
    </Page>
  );
}; // Preview

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(Preview);

/**
 */
const Last30Days = (props) => {
  const [isBusy, IsBusy] = React.useState(false);

  // const [data, Data] = React.useState( demo_last30days )
  const [data, Data] = React.useState([]);

  /**
   */
  React.useEffect(() => {
    Load().catch((err) => {});
  }, []);

  /**
   */
  const Load = async () => {
    try {
      IsBusy(true);

      // retrieve data
      let date_st = new Date();
      let date_end = new Date();

      date_st.setDate(date_st.getDate() - 30);
      date_st.setHours(0);
      date_st.setMinutes(0);
      date_st.setSeconds(0);

      date_end.setHours(0);
      date_end.setMinutes(0);
      date_end.setSeconds(0);

      const index = "new";
      const dt_st = Time.Epoch(date_st.getTime());
      const dt_end = Time.Epoch(date_end.getTime());

      let arr_q = [];
      let last_key = null;

      do {
        const resp_q = await props.Donation_Query({
          index,
          last_key,
          dt_st,
          dt_end,
          pr_n: parseInt(props.proj, 10),
        });

        // console.log( 'Last30Days: Load: resp_q: ', resp_q )

        const tmp_arr = resp_q.Count ? resp_q.Items : [];

        arr_q = [...arr_q, ...tmp_arr];

        last_key = resp_q.LastEvaluatedKey ? resp_q.LastEvaluatedKey : null;
      } while (last_key);

      // console.log( 'Last30Days: Load: arr_q: ', arr_q )

      // cluster data
      const v_obj = arr_q.reduce((a, c) => {
        const dt_obj = new Date(c.tn * 1000);
        const dt_year = dt_obj.getFullYear().toString();
        const dt_month = (dt_obj.getMonth() + 1).toString().padStart(2, "0");
        const dt_date = dt_obj.getDate().toString().padStart(2, "0");

        const tmp_k = parseInt([dt_year, dt_month, dt_date].join(""), 10);
        const tmp_n = [dt_date].join("."); //[dt_date, dt_month].join('.')
        const tmp_o = {
          _k: tmp_k,
          _n: tmp_n,
          ...c,
        };

        if (!a[tmp_k]) {
          a[tmp_k] = [];
        }

        a[tmp_k] = [...a[tmp_k], tmp_o];

        return a;
      }, {});

      // console.log( 'Last30Days: Load: v_obj: ', v_obj )

      // const v_obj_2 =
      for (let i = 1; i <= 30; ++i) {
        const dt_obj = new Date();
        dt_obj.setDate(dt_obj.getDate() - i);
        const dt_year = dt_obj.getFullYear().toString();
        const dt_month = (dt_obj.getMonth() + 1).toString().padStart(2, "0");
        const dt_date = dt_obj.getDate().toString().padStart(2, "0");
        const tmp_k = parseInt([dt_year, dt_month, dt_date].join(""), 10);
        const tmp_n = [dt_date].join("."); //[dt_date, dt_month].join('.')

        if (!v_obj[tmp_k]) {
          v_obj[tmp_k] = [
            {
              _k: tmp_k,
              _n: tmp_n,
            },
          ];
        }
      } // for i

      //
      const v_arr = Object.keys(v_obj).reduce((a, c) => {
        try {
          return [
            ...a,
            { _k: parseInt(c, 10), _n: v_obj[c][0]._n, data: v_obj[c] },
          ];
        } catch (err) {
          return a;
        }
      }, []);

      v_arr.sort((a, b) => a._k - b._k);

      // console.log( 'Last30Days: Load: v_arr: ', v_arr )

      const f_arr = v_arr.map((x) => {
        const tmp_tot = x.data.reduce((a, c) => {
          try {
            const tot_a =
              "string" === typeof c.k.a ? parseInt(c.k.a, 10) : c.k.a;

            if ("ref" === c.s) {
              return a - tot_a;
            } else {
              return a + tot_a;
            }
          } catch (err) {
            return a;
          }
        }, 0.0);

        return {
          _k: x._k,
          _n: x._n,
          tot: tmp_tot,
          cnt: x.data.length,
        };
      });

      // console.log( 'Last30Days: Load: f_arr: ', f_arr )

      Data(f_arr);

      //
      IsBusy(false);

      return {};
    } catch (err) {
      console.warn("Last30Days: Load: err: ", err);

      IsBusy(false);

      return Promise.reject(err);
    }
  }; // Load

  /**
   */
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        width={props.width ? props.width - 30 : 650}
        height={300}
        data={data}
      >
        <XAxis dataKey="_n" />
        {/* <YAxis/> */}
        <Tooltip formatter={(value) => [value.toFixed(2), null]} />
        {/* <Legend /> */}
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="tot" stroke="#0094FF" strokeWidth={5} />
        {/* <Line type="monotone" dataKey="pv" stroke="#82ca9d" /> */}
      </LineChart>
    </ResponsiveContainer>
  );
}; // Last30Days
