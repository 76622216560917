import React, { useEffect, useRef } from "react";
// import { Button } from "@material-ui/core";
// import { Image } from "@material-ui/icons";
import { ImageInput } from "./img_input";
/**
 */
const DescComp = (props) => {
  const [listDesc, ListDesc] = React.useState([]);

  useEffect(() => {
    // console.log("listDesc", listDesc);
    console.log("listDesc: listDesc", listDesc);

    // if()
    // {
    //   //
    // }
    props.onUpdate(listDesc);
  }, [listDesc]);

  useEffect(() => {
    console.log("props.listDesc: listDesc", props.listDesc);

    if (props.listDesc && props.listDesc.length) {
      ListDesc(props.listDesc);
    } else {
      ListDesc([{ t: "txt", d: "", tn: Date.now() }]);
    }
  }, [props.listDesc]);

  return (
    <div>
      <div className="pl-0">
        <h2 className="projectTitle mt-5 mb-0 pl-0">Add Content Description</h2>
      </div>
      <>
        {listDesc.map((x, i, a) => (
          <Description
            key={x.tn}
            {...x}
            OnChange={(t, d) => {
              a[i].t = t;
              a[i].d = d;
              ListDesc([...a]);
            }}
            OnRem={() => {
              a.splice(i, 1);
              ListDesc([...a]);
            }}
            OnAdd={() => {
              ListDesc([...a, { t: "txt", d: "", tn: Date.now() }]);
            }}
          />
        ))}
      </>
      {/* <div className="row">
        <div className="col-sm-12 col-lg-12 col-md-12">
          <div className="form-group">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                ListDesc([...listDesc, { t: "txt", d: "", tn: Date.now() }]);
              }}
            >
              Add a New Section
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
}; // DescComp

export default DescComp;

function Description(props) {
  const [type, Type] = React.useState(props.t); // txt / img / vidUrl
  const [text, Text] = React.useState("txt" === props.t ? props?.d : "");
  const OnChangeTxt = (e) => {
    // e.preventDefault()
    Text(e.target.value);
    props.OnChange("txt", e.target.value);
  };

  return (
    <>
      <>
        <div className="mt-3">
          {"txt" === type ? (
            <textarea
              // key={i}
              className="form-control"
              style={{
                // maxWidth: "90%",
                border: "1px solid #e3e3e3",
                borderRadius: 4,
              }}
              rows="5"
              id={["social", props.key].join("-")}
              placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, "
              value={text}
              onChange={OnChangeTxt}
              // onBlur={OnBlur}
            />
          ) : "img" === type ? (
            <div>
              <ImageInput
                onChange={(d) => {
                  props.OnChange("img", d);
                }}
                label={"Select an image"}
                value={props.d ? props.d : {}}
              />
            </div>
          ) : "vid" === type ? (
            <div>{/*  */}</div>
          ) : null}
        </div>
      </>

      {/* <div className="mt-3 col-sm-11 btn-group p-0 mb-3">
        <button
          className="btn btn-primary mr-1"
          variant={type === "txt" ? "contained" : "outlined"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            Type("txt");
          }}
        >
          <i className="fa fa-file text-white" />
        </button>
        <button
          className="btn btn-primary mr-1"
          variant={type === "img" ? "contained" : "outlined"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            Type("img");
          }}
        >
          <i className="fa fa-picture-o text-white" />
        </button>
        <button
          className="btn btn-primary mr-1"
          variant={type === "vid" ? "contained" : "outlined"}
          disabled={true}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            Type("vid");
          }}
        >
          <i className="fa fa-file-video-o text-white" />
        </button>
        <button
          className="btn btn-danger mr-1"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.OnRem();
          }}
        >
          <i className="fa fa-trash text-white" />
        </button>
        <button
          className="btn btn-success"
          // onClick={(e) => {
          //   e.preventDefault();
          //   e.stopPropagation();
          //   props.OnRem();
          // }}
        >
          <i className="fa fa-plus text-white" />
        </button>
      </div> */}

      <div
        className="col-sm-12 p-0"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <div
          className="mt-3 mb-3 mr-3 rounded"
          style={{
            border: "1px solid #dee2e6",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <button
            className="btn mr-1"
            variant={type === "txt" ? "contained" : "outlined"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              Type("txt");
            }}
          >
            <i className="text-primary fa fa-file" />
          </button>
          <button
            className="btn mr-1"
            variant={type === "img" ? "contained" : "outlined"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              Type("img");
            }}
          >
            <i className="text-primary fa fa-picture-o" />
          </button>
          <button
            className="btn mr-1"
            variant={type === "vid" ? "contained" : "outlined"}
            disabled={true}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              Type("vid");
            }}
          >
            <i className="text-primary fa fa-file-video-o" />
          </button>
        </div>
        <div className="mt-3 mb-3">
          <button
            className="btn btn-danger mr-1"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.OnRem();
            }}
          >
            <i className="fa fa-trash text-white" />
          </button>
          <button
            className="btn btn-success"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              // props.OnRem();
              props.OnAdd();
            }}
          >
            <i className="fa fa-plus text-white" />
          </button>
        </div>
      </div>
    </>
  );
}
