/**
 *	@copyright	KARD.
 */

import * as Cfg from '../../_cfg'
import {Core, Lambda,} from '../../api/AWS'

/**
 */
export const TempEmail_List = ( last_key ) =>
{
    return async (d, gs) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'comm',
                act: 'comm:email:temp:list',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {last_key},
            }

            // console.log( 'actions/TempEmail_List: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/TempEmail_List: resp_list: ', resp_list )

            //
            return resp_list
        }
        catch( err )
        {
            console.warn( 'actions/TempEmail_List: err: ', err )

            return Promise.reject( err )
        }
    }
}   // TempEmail_List

/**
 */
export const TempEmail_Details = ( temp_obj ) =>
{
    return async (d, gs) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'comm',
                act: 'comm:email:temp:get',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {...temp_obj},
            }

            // console.log( 'actions/TempEmail_Details: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/TempEmail_Details: resp_list: ', resp_list )

            //
            return resp_list
        }
        catch( err )
        {
            console.warn( 'actions/TempEmail_Details: err: ', err )

            return Promise.reject( err )
        }
    }
}   // TempEmail_Details

/**
 */
export const TempEmail_Create = ( temp_obj ) =>
{
    return async (d, gs) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'comm',
                act: 'comm:email:temp:new',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {...temp_obj},
            }

            // console.log( 'actions/TempEmail_Create: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/TempEmail_Create: resp_list: ', resp_list )

            //
            return resp_list
        }
        catch( err )
        {
            console.warn( 'actions/TempEmail_Create: err: ', err )

            return Promise.reject( err )
        }
    }
}   // TempEmail_Create

/**
 */
export const TempEmail_Edit = ( temp_obj ) =>
{
    return async (d, gs) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'comm',
                act: 'comm:email:temp:edit',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {...temp_obj},
            }

            // console.log( 'actions/TempEmail_Edit: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/TempEmail_Edit: resp_list: ', resp_list )

            //
            return resp_list
        }
        catch( err )
        {
            console.warn( 'actions/TempEmail_Edit: err: ', err )

            return Promise.reject( err )
        }
    }
}   // TempEmail_Edit

/**
 */
export const TempEmail_Del = (temp_obj) =>
{
    return async (d, gs) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'comm',
                act: 'comm:email:temp:del',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {...temp_obj},
            }

            // console.log( 'actions/TempEmail_Del: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/TempEmail_Del: resp_list: ', resp_list )

            //
            return resp_list
        }
        catch( err )
        {
            console.warn( 'actions/TempEmail_Del: err: ', err )

            return Promise.reject( err )
        }
    }
}   // TempEmail_Del

