/**
 * @copyright KARD
 */

import React from 'react'

/**
 */
const PayPattern = ( props ) =>
{
    return (
        <select
            className="form-control"
            value={props.value}
            onChange={ e => {
                e.preventDefault()
                if( props.OnChange )
                {
                    props.OnChange( e.target.value )
                }
            } }
            disabled={props.bDisabled}
            required={props.bRequired}
        >
            <option value=''>Select One</option>
            <option value='one'>One off</option>
            <option value='rec'>Recurring</option>
        </select>
    )
}   // PayPattern

/**
 */
export default PayPattern

