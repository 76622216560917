/**
 * @copyright DARD
 */

import React from "react";

import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
  } from "@paypal/react-paypal-js";

/**
 */
const PayPal = ( {currency, amount} ) =>
{
  // sandbox account: sb-idu7p14486211@business.example.com
  // client_id: AcxO13EBSdh4pBLAGu5s1po73dBiq_MH7eJcC4cT354PKQbs4Sl8jAr87li3z9mQLFLuCJrYkblR3e0T
  // secret_key: ELVtr6_EQdLP8BHSPuNw5KGEuny6jYuRFqMKNqfJav8AP6MlNhmrBBPCIm2tlRqvnyuqcLvRomP0XsyR
  // const currency

  return (
    <div style={{ maxWidth: "750px", minHeight: "200px" }}>
        <PayPalScriptProvider
            options={{
                "client-id": "AcxO13EBSdh4pBLAGu5s1po73dBiq_MH7eJcC4cT354PKQbs4Sl8jAr87li3z9mQLFLuCJrYkblR3e0T",
                components: "buttons",
                currency: currency,// ? props.currency : "GBP",
            }}
        >
			<PayPalWrapper
                currency={currency}
                amount={amount}
                showSpinner={false}
            />
		</PayPalScriptProvider>
	</div>
  )
} // PayPal

/**
 */
export default PayPal

/**
 * Paypay integration
 */
const PayPalWrapper = (  props) =>
{
  const style = {"layout":"vertical"};
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  React.useEffect(() => {
    // console.log( 'PayPalWrapper: options: ', options )

    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: props.currency,
      },
    });
  }, [props.currency, props.showSpinner]);

  return (
    <>
      { (props.showSpinner && isPending) && <div className="spinner" /> }
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[props.amount, props.currency, style]}
        fundingSource={undefined}
        createOrder={(data, actions) => {
          // console.log( 'PayPalWrapper: PayPalButtons: : createOrder: data: ', data )
          // console.log( 'PayPalWrapper: PayPalButtons: : createOrder: actions: ', actions )

          return actions.order
            .create({
              purchase_units: [
                  {
                      amount: {
                          currency_code: props.currency,
                          value: props.amount,
                      },
                  },
              ],
            })
            .then((orderId) => {
              console.log( 'PayPalWrapper: PayPalButtons: : createOrder: then: order=>: ', 'complete' )

              // Your code here after create the order
              // console.log( 'PayPalWrapper: PayPalButtons: : createOrder: then: orderId: ', orderId )

              //
              return orderId;
            })
            .catch( err => {
              console.warn( 'PayPalWrapper: PayPalButtons: : createOrder: catch: err: ', err )
            } )
            ;
        }}
        onApprove={function (data, actions) {
          return actions.order.capture().then(function () {
              // Your code here after capture the order
          });
        }}
      />
    </>
  )
} // PayPayPalWrapperPal


