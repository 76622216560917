/**
 * @copyright KARD
 */

import React, { useEffect, useReducer, useState } from "react";
import Loader from "react-js-loader";
import { navigate } from "@reach/router";

import { connect, useDispatch, useSelector } from "react-redux";
// import * as actions from '../../rdx/actions'

import { Time } from "../../api/Utils";

import AuiModal from "../../aui/modal";
// import AuiStatus from '../../aui/status'

import Page from "../_common/page";
import PayPattern from "../_common/pay_pat";
import ListProj from "../donation/list_proj";

import CampType_V from "./type_v";
import {
  Campaign_Details,
  Campaign_Edit,
  Campaign_New,
  Project_Details,
  SaveCampaignImage,
  uploadFile,
} from "../../rdx/actions";
import DescComp from "../_common/desc_comp";
import { ImageInput } from "../_common/img_input";

const ctInit = {
  listDesc: [],
  socTwit: "",
  profVid: "",
  socInsta: "",
  socYT: "",
  socFb: "",
};

const INIT_STATE = {
  title: "",
  selectedProject: {},
  description: "",
  type: "std",
  tAmount: "",
  packageList: [],
  sDate: 0,
  eDate: 0,
  smsTemplate: "",
  emailTemplate: "",
  status: "act",
  profileImg: {},
  ct: ctInit,
  projectList: [],
  descList: [],
  isBusy: false,
  showProjectList: false,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "project-list":
      return { ...state, projectList: action.payload };
    case "campaign":
      const { payload } = action;
      return {
        ...state,
        title: payload.ci.t,
        description: payload.ci.ds,
        type: payload.t,
        tAmount: payload.a.t,
        packageList: payload.pk,
        sDate: payload.ts ? Time.Epoch2DateYMD(payload.ts) : 0,
        eDate: payload.te ? Time.Epoch2DateYMD(payload.te) : 0,
        smsTemplate: "",
        emailTemplate: "",
        status: payload.s,
        profileImg: payload.img ? { url: payload.img?.c?.f } : {},
        ct: payload.ct ? payload.ct : ctInit,
      };
    case "busy":
      return { ...state, isBusy: action.payload };
    case "select-project":
      return { ...state, selectedProject: action.payload };
    default:
      return { ...state, [action.type]: action.payload };
  }
};
/**
 */
const CampaignNew = (props) => {
  const orgId = useSelector((state) => state.__auth.orgId);
  const [curProj, CurProj] = React.useState(null);
  const [state, dispatch] = useReducer(reducer, INIT_STATE);
  const rdx = useDispatch();

  const [isLoading, IsLoading] = React.useState(false);
  const [isSaving, IsSaving] = React.useState(false);

  useEffect(() => {
    console.log("State", state);
    // console.log("State",state)
  }, [state]);

  async function Init() {
    try {
      // console.log("INIT", props);
      dispatch({ type: "busy", payload: true });

      if (props.pr_n) {
        // console.log("INIT",props.pr_n)

        const { Item } = await rdx(Project_Details(parseInt(props.pr_n, 10)));
        // console.log(Item)
        dispatch({ type: "select-project", payload: Item });
      }

      if (props.cp_n) {
        const { Item } = await rdx(Campaign_Details(parseInt(props.cp_n, 10)));
        // console.log(Item);

        dispatch({ type: "campaign", payload: Item });
      }

      dispatch({ type: "busy", payload: false });
    }
    catch (e) {
      console.warn(e);
      dispatch({ type: "busy", payload: false });
      return e;
    }
  }

  /**
   */
  React.useEffect(() => {
    IsLoading( true )
    Init()
      .then( () => IsLoading( false ) )
      .catch( () => IsLoading( false ) );
  }, []);

  /**
   */
  const OnSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    IsSaving( true )

    dispatch({ type: "busy", payload: true });

    if (props.cp_n) {
      EditCamp()
        // .then(async () => {
        //   await Init();
        //   dispatch({ type: "busy", payload: false });
        //   // alert('Campaign changes saved')
        // })
        .then(() => {
          alert('Campaign changes saved')

          Init()
            .then( () => {
              IsSaving( false )
            } )
            .catch( () => {
              IsSaving( false )
            } );

          // dispatch({ type: "busy", payload: false });
        })
        .catch((err) => {
          IsSaving( false )
          dispatch({ type: "busy", payload: false });
          alert(err.message ? err.message : err);
        });
    } else {
      SaveProcess()
        .then((data) => {
          IsSaving( false )

          dispatch({ type: "busy", payload: false });

          if (data.success) {
            alert("Campaign saved");
            navigate("/campaign/list");
          }
        })
        .catch((err) => {
          IsSaving( false )
          
          dispatch({ type: "busy", payload: false });

          alert(err.message ? err.message : err);
        });
    }
  }; // OnSave

  async function SaveProcess() {
    try {
      const { campaign } = await SaveCamp();
      if (!campaign) {
        throw new Error("Failed to create Campaign!");
      }
      if (!state.profileImg.file) {
        return { success: false, campaign };
      }
      const { success, url } = await saveImage(campaign);
      if (success) {
        const resp = await rdx(SaveCampaignImage({ url, tn: campaign.tn }));
        if (resp.success) {
          return { success: true, campaign: resp.campaign.data.Attributes };
        }
      }
      return { success: false };
    } catch (e) {
      console.warn("campaign/new: SaveProcess:error", e);
      return Promise.reject(e);
    }
  }

  /*
   */
  async function saveImage(p) {
    try {
      const file = state.profileImg.file;
      const ft = file.type.split("/")[1];
      const dtNow = Date.now();
      const iid = [dtNow, p.i, p.tn, "profile", file.name.split(".")[0]].join(
        "_"
      );
      const name = iid + "." + ft;
      const params = {
        name: name,
        key: name,
        contentType: file.type,
        file: file,
        g: "profile",
        tp: "campaign",
        iid,
        ft,
      };
      // console.log(params,file);
      const { s3 } = await rdx(uploadFile(params));
      console.log(s3);
      if (s3.success) {
        return { ...s3 };
      } else {
        throw new Error("Filed to save file!");
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   */
  const SaveCamp = async () => {
    try {
      const camp_obj = {
        camp_t: state.type,

        title: state.title,
        desc: state.description,

        amount_target: state.tAmount,

        dt_s: state.sDate ? Time.Epoch(state.sDate) : 0,
        dt_e: state.eDate ? Time.Epoch(state.eDate) : 0,
        pkg_lst: state.packageList,

        camp_status: state.status,

        pr_n: parseInt(state.selectedProject.tn, 10),
        pr_t: state.selectedProject.p.t,
        ct: state.ct,
        listDesc: state.descList,
      };

      console.log("CampaignNew: SaveCamp: camp_obj: ", camp_obj);

      const { campaign } = await rdx(Campaign_New(camp_obj));

      console.log("CampaignNew: SaveCamp: resp_save: ", campaign);

      return { campaign };
    } catch (err) {
      console.warn("CampaignNew: Load: err: ", err);
      return Promise.reject(err);
    }
  }; // SaveCamp

  /**
   */
  const EditCamp = async () => {
    try {
      const camp_obj = {
        dt_n: parseInt(props.cp_n, 10),

        camp_t: state.type,

        title: state.title,
        desc: state.description,

        amount_target: state.tAmount,

        dt_s: state.sDate ? Time.Epoch(state.sDate) : 0,
        dt_e: state.eDate ? Time.Epoch(state.eDate) : 0,
        pkg_lst: state.packageList,

        camp_status: state.status,

        pr_n: parseInt(state.selectedProject.tn, 10),
        pr_t: state.selectedProject.p.t,
        ct: state.ct,
        listDesc: state.descList,
        cp_n: parseInt(props.cp_n, 10),
      };

      console.log("CampaignNew: EditCamp: camp_obj: ", camp_obj);

      const { data } = await rdx(Campaign_Edit(camp_obj));

      console.log("CampaignNew: EditCamp: resp_save: ", data);

      if (state.profileImg.file) {
        const p = {
          i: data?.Attributes?.i || orgId,
          tn: data?.Attributes?.tn || props.cp_n,
        };
        const { success, url } = await saveImage(p);
        if (success) {
          const resp = await rdx(
            SaveCampaignImage({ url, tn: data?.Attributes?.tn })
          );
          if (resp.success) {
            return { success: true, campaign: resp.campaign.data.Attributes };
          }
        }
      }

      // ListProj( b_more ? [...listProj, ...resp_list.Items] : [...resp_list.Items] )
      // LastKey( resp_list.LastEvaluatedKey )

      alert("Campaign changes saved");

      return {};
    } catch (err) {
      console.warn("CampaignNew: Load: err: ", err);

      alert(err.message ? err.message : err);

      return Promise.reject(err);
    }
  }; // EditCamp

  /**
   */
  return (
    <Page active="campaign">
      {/* <div className="row">
        <div className="col-sm-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Campaign</a>
              </li>
            </ol>
          </nav>
        </div>
      </div> */}

      <div className="row">
        <div className="col mb-3">
          <h4 className="projectTitle">
            <img
              style={{ height: 20, width: 20, marginRight: 16 }}
              src={require("../../components/assets/icons/sideBar/active/campaign.png")}
              alt=''
            />
            {props.cp_n ? "Edit" : "Add New"} Campaign
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <form onSubmit={OnSave}>
            <div className="form-row">
              <div className="col-md-6">
                <label for="name" className="col-form-label">
                  Campaign Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Campaign Title"
                  value={state.title}
                  onChange={(e) =>
                    dispatch({ type: "title", payload: e.target.value })
                  }
                />
              </div>
              <div class="col-md-6">
                <label for="s_project" className="col-form-label">
                  Select Project
                </label>

                <div
                  className="btn-block btn "
                  style={{
                    fontSize: 20,
                    fontWeight: curProj ? 900 : null,
                    margin: "10px 0px",
                    padding: 8,
                    color: "#0094FF",
                    backgroundColor: curProj ? "#EFF8FF" : null,
                    borderColor: curProj ? "#0094FF" : "#009efb",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                  id="s_project"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    dispatch({ type: "showProjectList", payload: true });
                  }}
                >
                  <a>{state.selectedProject?.p?.t || "Select a project"}</a>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label for="description" className="col-form-label">
                Campaign Description
              </label>
              <textarea
                class="form-control"
                id="description"
                rows="4"
                value={state.description}
                placeholder={"Campaign Description"}
                onChange={(e) =>
                  dispatch({ type: "description", payload: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label for="type" className="col-form-label">
                Type
              </label>

              <CampType_V
                value={state.type}
                onChange={(val) => dispatch({ type: "type", payload: val })}
                // bRequired={true}
              />
            </div>

            <div className="form-group">
              <label for="t_amount" className="col-form-label">
                Target Amount
              </label>
              <input
                type="text"
                className="form-control"
                id="t_amount"
                value={state.tAmount}
                onChange={(e) =>
                  dispatch({ type: "tAmount", payload: e.target.value })
                }
              />
            </div>
            <div className="row">
              <div className="col-12">
                <label>Package List</label>
                <div className="table-responsive border rounded">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Amount</th>
                        <th>Pattern</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.packageList.map((x, i, a) => (
                        <CampPkg
                          key={x.id ? x.id : i}
                          pkg={x}
                          OnSave={(obj) => (a[i] = obj)}
                          OnRemove={(_) =>
                            dispatch({
                              type: "packageList",
                              payload: a.filter((item, idx) => idx !== i),
                            })
                          }
                        />
                      ))}
                    </tbody>

                    <tfoot>
                      <CampPkg
                        OnAdd={(obj) =>
                          dispatch({
                            type: "packageList",
                            payload: [...state.packageList, obj],
                          })
                        }
                      />
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>

            <div className="form-row mt-2 mb-2">
              <div className="col-md-6">
                <label for="s_date" className="col-form-label">
                  Start Date
                </label>
                <input
                  className="form-control"
                  type="date"
                  id="s_date"
                  value={state.sDate}
                  onChange={(e) =>
                    dispatch({ type: "sDate", payload: e.target.value })
                  }
                  // required
                />
              </div>
              <div className="col-md-6">
                <label for="e_date" className="col-form-label">
                  End Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="e_date"
                  value={state.eDate}
                  onChange={(e) =>
                    dispatch({ type: "eDate", payload: e.target.value })
                  }
                />
              </div>
            </div>

            {/*<div className="form-row mb-2">
                            <div className="col-md-6">
                                <label for="s_template" className="col-form-label">SMS Template</label>
                                <select className="form-control" id="s_template">
                                    <option>Select</option>
                                    <option>Template 1</option>
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label for="e_template" className="col-form-label">E-mail Template</label>
                                <select className="form-control" id="e_template">
                                    <option>Select</option>
                                    <option>Templat 1</option>
                                </select>
                            </div>
                        </div>*/}

            <div className="form-group row mt-3">
              <label for="default" className="col-sm-1 col-form-label m-0">
                Activate
              </label>
              <div className="col-sm-2 mt-1">
                <input
                  type="checkbox"
                  className="switch_1"
                  checked={"act" === state.status}
                  // onChange={ e => {
                  //     console.log( 'campStatus: e.target.checked: ', e.target.checked )
                  // } }
                  onChange={(e) =>
                    dispatch({
                      type: "status",
                      payload: e.target.checked ? "act" : "dis",
                    })
                  }
                />
              </div>
              {/* <AuiStatus
                                className="form-control"
                                value={campStatus}
                                OnChange={ val => CampStatus( val ) }
                            /> */}
            </div>
            <div className="col-sm-12 col-lg-12 col-md-12 p-0">
              <div className="form-group">
                <ImageInput
                  onChange={(d) => {
                    console.log(d);
                    dispatch({ type: "profileImg", payload: d });
                  }}
                  label={"Select an image"}
                  value={state.profileImg}
                />
              </div>
            </div>
            <DescComp
              listDesc={state.ct.listDesc}
              onUpdate={(l) => dispatch({ type: "descList", payload: [...l] })}
            />

            <button type="submit" className="btn btn-primary btn-lg px-5">
              Save
            </button>
          </form>

          <AuiModal
            bOpen={state.showProjectList}
            OnClose={() =>
              dispatch({ type: "showProjectList", payload: false })
            }
          >
            {/* <div style={{maxHeight: '90%'}}> */}
            <ListProj
              pr={state.selectedProject}
              OnSelect={(proj) => {
                dispatch({ type: "selectedProject", payload: proj });
              }}
              OnClose={() =>
                dispatch({ type: "showProjectList", payload: false })
              }
            />
            {/* </div> */}
          </AuiModal>
        </div>
      </div>

      <>
      {
        (isLoading || isSaving) &&
        <div>
          <div
              className="modal"
              style={{
                // margin: '0 auto'
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#d1cccc80",
              }}
          >
          <Loader
            // type="box-rectangular"
            type="spinner-cub"
            bgColor={"#2680c2"}
            title={isLoading ? 'Loading...' : "Saving ..."}
            color={"#2680c2"}
            size={150}
          />
          </div>
        </div>
      }
      </>
    </Page>
  );
}; // CampaignNew

/**
 */
const CampPkg = (props) => {
  const [pkgName, PkgName] = React.useState("");
  const [pkgDesc, PkgDesc] = React.useState("");
  const [pkgTarget, PkgTarget] = React.useState("");
  const [pkgAmount, PkgAmount] = React.useState("");
  const [pkgPattern, PkgPattern] = React.useState("one");
  const [pkgStatus, PkgStatus] = React.useState("act");

  const [isSave, IsSave] = React.useState(false);

  /**
   */
  React.useEffect(() => {
    if (props.pkg) {
      PkgName(props.pkg.n);
      PkgDesc(props.pkg.d);
      PkgTarget(props.pkg.t);
      PkgAmount(props.pkg.a);
      PkgPattern(props.pkg.p);
      PkgStatus(props.pkg.st);
    }
  }, [props.pkg]);

  /**
   */
  React.useEffect(() => {
    if (props.pkg) {
      const dt_now = Time.Epoch(Date.now());

      if (props.OnChange) {
        props.OnChange({ ...ObjT(), tm: dt_now });
      }

      IsSave(
        pkgName !== props.pkg.n ||
          pkgDesc !== props.pkg.d ||
          pkgTarget !== props.pkg.t ||
          pkgAmount !== props.pkg.a ||
          pkgPattern !== props.pkg.p ||
          pkgStatus !== props.pkg.st
      );
    } // props.pkg
  }, [pkgName, pkgDesc, pkgTarget, pkgAmount, pkgPattern, pkgStatus]);

  /**
   */
  const OnAdd = (e) => {
    e.preventDefault();

    const dt_now = Time.Epoch(Date.now());

    if (pkgAmount<=0)
    {
      alert("Package amount should bd greater then 0")

      return false
    }
    if (pkgName?.length===0)
    {
      alert("Please Add a package name")

      return false
    }

    props.OnAdd({ ...ObjT(), tn: dt_now });

    Reset();

    return false;
  }; // OnAdd

  /**
   * Opens up a modal and show edit window / view
   */
  const OnEdit = (e) => {
    e.preventDefault();

    props.OnEdit();

    return false;
  }; // OnEdit

  /**
   */
  const OnSave = (e) => {
    e.preventDefault();

    const dt_now = Time.Epoch(Date.now());
    if (pkgAmount<=0)
    {
      alert("Package amount should bd greater then 0")

      return false
    }
    if (pkgName?.length===0)
    {
      alert("Please Add a package name")

      return false
    }
    props.OnSave({ ...ObjT(), tm: dt_now });

    return false;
  }; // OnSave

  /**
   */
  const OnRemove = (e) => {
    e.preventDefault();

    props.OnRemove();

    return false;
  }; // OnRem

  /**
   */
  const ObjT = () => {
    return {
      n: pkgName,
      d: pkgDesc,
      at: pkgTarget,
      a: pkgAmount,
      p: pkgPattern,
      st: pkgStatus,
    };
  }; // ObjT

  /**
   */
  const Reset = () => {
    PkgName("");
    PkgDesc("");
    PkgTarget("");
    PkgAmount("");
    PkgPattern("one");
    PkgStatus("act");
  }; // Reset

  /**
   */
  return (
    <tr active="campaign">
      <td>
        <input
          type="text"
          className="form-control"
          placeholder="Name"
          value={pkgName}
          onChange={(e) => PkgName(e.target.value)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          placeholder="£450"
          value={pkgAmount}
          pattern="[0-9]*"
          onChange={(e) => {
            const onlyNums = e.target.value.replace(/[^0-9]/g, "");
            PkgAmount(onlyNums)
          }}
        />
      </td>

      <td>
        <PayPattern
          value={pkgPattern}
          OnChange={(val) => PkgPattern(val)}
          bRequired={true}
        />
      </td>
      <td>
        {/* <AuiStatus
                    value={pkgStatus}
                    OnChange={ val => PkgStatus(val) }
                /> */}
      </td>
      <td>
        <>
          {props.OnAdd && (
            <button className="btn btn-primary" onClick={OnAdd}>
              Add
            </button>
          )}
        </>
        <>
          {props.OnEdit && (
            // <button className="btn btn-primary" onClick={OnEdit}>Edit</button>
            <a className="btn btn-sm" onClick={OnEdit}>
              <i className="fa fa-edit"></i>
            </a>
          )}
        </>
        <>
          {props.OnSave && (
            // <button className="btn btn-primary" onClick={OnSave} disabled={!isSave}>Save</button>
            <a className="btn btn-sm" onClick={OnSave} disabled={!isSave}>
              <i className="fa fa-save"></i>
            </a>
          )}
        </>
        <>
          {props.OnRemove && (
            // <button className="btn btn-primary" onClick={OnRemove}>Remove</button>
            <a className="btn btn-sm" onClick={OnRemove}>
              <i className="fa fa-trash"></i>
            </a>
          )}
        </>
      </td>
    </tr>
  );
}; // CampPkg

/**
 */
export default CampaignNew;
