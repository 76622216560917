/**
 * @copyright KARD
 */

 import React from 'react'

 import {navigate} from "@reach/router";
 
 import {connect} from 'react-redux'
 import * as actions from '../../rdx/actions'
 
 
 import Page from '../_common/page'
 
 /**
  */
 const TaskList = ( props ) =>
 {
     const [isLoading, IsLoading] = React.useState( false )
 
     const [listCamp, ListCamp] = React.useState( [] )
     const [lastKey, LastKey] = React.useState( null )

 
     //
     return (
         <Page>
             <div className="row">
                 <div className="col-sm-6"> 
                     <nav aria-label="breadcrumb">
                         <ol className="breadcrumb">
                             <li className="breadcrumb-item"><a href="#">Home</a></li>
                             <li className="breadcrumb-item"><a href="#">Campaign</a></li>
                         </ol>
                     </nav>
                 </div>  
                 <div className="col-sm-6 text-right">  
                     <p>Monday, 10 Augest 2021</p>
                 </div>
             </div>
 
             <div className="row mt-2 mb-3">
                 <div className="col-sm-6 col-3">
                     <h4 className="projectTitle m-0"><i className="fa fa-tasks fa-lg" /> List of My Pending Task </h4>
                 </div>
             </div>
 
             <div className="row">
                 <div className="col-12">
                     <div className="card">   
 
                         <div className="row mt-3 ml-1 mr-1">
                             <div className="col-sm-4 col-4">
                                 <div className="form-group has-search">
                                     <span className="fa fa-search form-control-feedback"></span>
                                     <input type="text" className="form-control" placeholder="Search" />
                                 </div>
                             </div>
                             <div className="col-sm-8 col-9 text-right">
                                 <button className="btn btn btn-primary mr-1"><i className="fa fa-plus" /> <a href="../todo/new" style={{color: '#fff'}}>Create New Task</a></button>
                                 <button className="btn btn btn-outline-info mr-1"> Filter</button>
                                 
                             </div>
                         </div> 
 
                         <table className="table">
                         <thead>
                             <tr>
                                 <th>SN</th>
                                 <th>Task</th>
                                 <th>Priority</th>
                                 <th>Deadline</th>
                                 <th>Created at</th>
                                 <th>Action</th>
                                 <th>Edit</th>
                                 <th>Status</th>
                             </tr>
                         </thead>
 
                         <tbody>
                            <tr>
                                 <td>101</td>
                                 <td>Check Marketing Report</td>
                                 <td>High</td>
                                 <td>24 May 20</td>
                                 <td>5 May 21</td>
                                 <td>NA</td>
                                 <td>
                                     <a href="" className="btn btn-sm" ><i className="fa fa-edit"></i></a>
                                     <a href="" className="btn btn-sm"><i className="fa fa-trash"></i></a>
                                 </td>
                                 <td>		
                                     <div className="switch_box box_1">
                                         <input type="checkbox" className="switch_1" />
                                     </div>
                                 </td>
                            </tr>
                            <tr>
                                 <td>101</td>
                                 <td>Check Marketing Report</td>
                                 <td>High</td>
                                 <td>24 May 20</td>
                                 <td>5 May 21</td>
                                 <td>NA</td>
                                 <td>
                                     <a href="" className="btn btn-sm" ><i className="fa fa-edit"></i></a>
                                     <a href="" className="btn btn-sm"><i className="fa fa-trash"></i></a>
                                 </td>
                                 <td>		
                                     <div className="switch_box box_1">
                                         <input type="checkbox" className="switch_1" />
                                     </div>
                                 </td>
                            </tr>
                            <tr>
                                 <td>101</td>
                                 <td>Check Marketing Report</td>
                                 <td>High</td>
                                 <td>24 May 20</td>
                                 <td>5 May 21</td>
                                 <td>NA</td>
                                 <td>
                                     <a href="" className="btn btn-sm" ><i className="fa fa-edit"></i></a>
                                     <a href="" className="btn btn-sm"><i className="fa fa-trash"></i></a>
                                 </td>
                                 <td>		
                                     <div className="switch_box box_1">
                                         <input type="checkbox" className="switch_1" />
                                     </div>
                                 </td>
                            </tr>
                            <tr>
                                 <td>101</td>
                                 <td>Check Marketing Report</td>
                                 <td>High</td>
                                 <td>24 May 20</td>
                                 <td>5 May 21</td>
                                 <td>NA</td>
                                 <td>
                                     <a href="" className="btn btn-sm" ><i className="fa fa-edit"></i></a>
                                     <a href="" className="btn btn-sm"><i className="fa fa-trash"></i></a>
                                 </td>
                                 <td>		
                                     <div className="switch_box box_1">
                                         <input type="checkbox" className="switch_1" />
                                     </div>
                                 </td>
                            </tr>
                            <tr>
                                 <td>101</td>
                                 <td>Check Marketing Report</td>
                                 <td>High</td>
                                 <td>24 May 20</td>
                                 <td>5 May 21</td>
                                 <td>NA</td>
                                 <td>
                                     <a href="" className="btn btn-sm" ><i className="fa fa-edit"></i></a>
                                     <a href="" className="btn btn-sm"><i className="fa fa-trash"></i></a>
                                 </td>
                                 <td>		
                                     <div className="switch_box box_1">
                                         <input type="checkbox" className="switch_1" />
                                     </div>
                                 </td>
                            </tr>
                            <tr>
                                 <td>101</td>
                                 <td>Check Marketing Report</td>
                                 <td>High</td>
                                 <td>24 May 20</td>
                                 <td>5 May 21</td>
                                 <td>NA</td>
                                 <td>
                                     <a href="" className="btn btn-sm" ><i className="fa fa-edit"></i></a>
                                     <a href="" className="btn btn-sm"><i className="fa fa-trash"></i></a>
                                 </td>
                                 <td>		
                                     <div className="switch_box box_1">
                                         <input type="checkbox" className="switch_1" />
                                     </div>
                                 </td>
                            </tr>
                         </tbody>    

                     </table>

                    <div className="row">
                        <div className="col-sm-12 ml-3 mr-3">

                            <div className="pull-left">
                                <div className="dataTables_length">
                                    <label className="entries">
                                        Show 
                                        <select size="1" className="form-control show-entries">
                                            <option value="10" selected="selected">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>

                            <div className="pull-right mr-5">
                                <ul className="pagination">
                                    <li className=""><a href="#" tabindex="0" className="paginate_button previous" id="DataTables_Table_0_previous">Previous</a></li>
                                    <li className="active"><a tabindex="0">1</a></li>
                                    <li><a tabindex="0">2</a></li>
                                    <li><a tabindex="0">3</a></li>
                                    <li><a href="#" tabindex="0" className="paginate_button next" id="DataTables_Table_0_next">Next</a></li>
                                </ul>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Page>
     )
 }   // TaskList
 
 /**
  */
 const mapStateToProps = ( state ) =>
 {
     return state
 }   //
 
 /**
  */
 export default connect(mapStateToProps, actions)( TaskList )
 
 
 
 