/**
 * @copyright KARD
 */

import React from 'react'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'


const Donations_Ext_New = ( props ) =>
{
    const OnSubmit = ( e ) =>
    {
        e.preventDefault()
        e.stopPropagation()

        //
    }   // OnSubmit

    return (
        <div className="main-wrapper">
            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-6">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#">Donation</a></li>
                                    <li className="breadcrumb-item"><a href="#">External</a></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row mt-2 mb-3">
                        <div className="col-sm-12">
                            <h4 className="projectTitle m-0"><i className="fa fa-rocket fa-lg" /> Donate to this Campaign </h4>
                        </div>
                    </div>

                    <div className="row">
                        {/*  */}
                    </div>

                    <form onSubmit={ OnSubmit }>
                        //
                    </form>
                </div>
            </div>
        </div>
    )
}   // Donations_Ext_New

/**
 */
const mapStateToProps = ( state ) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Donations_Ext_New )

/**
 */
const text_legal = {
    gift_aid: 'I am a UK taxpayer and I wish (Global One) to reclaim tax back on all donations I have made within the last 6 years and all donations that I make hereafter. *',
    consent_proj: 'Donor give consent for personal data to be used for future communication, but not to share with third party',
    consent_charity: 'Donor give consent for personal data to be used for future communication, but not to share with third party',
}

