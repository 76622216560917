/**
 *	@copyright	KARD.
 */

import * as Cfg from '../../_cfg'
import {Core, Lambda} from '../../api/AWS'
import {ProcessContent, uploadFile} from "./storage";

/**
 */
export const ProjectQuery = ( filetrObj,last_key,) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'proj',
                act: 'proj:query',
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    ...filetrObj,
                    last_key,
                },
            }

            console.log( 'actions/project: Project_List: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/project: Project_List: resp_list: ', resp_list )

            //
            // return resp_list
            const mapped_data = resp_list.Items.map( x => {
                try
                {
                    return {
                        ...x,
                        a: {...x.a, t: x.a.t / 100.0, r: x.a.r / 100.0},
                    }
                }
                catch( err )
                {
                    return x
                }
            } )

            //
            return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'actions/project: Project_List: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Project_List
/**
 */
export const Project_List = ( last_key, n_elem ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'proj',
                act: 'proj:list',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    last_key,
                    n_elem,
                },
            }

            // console.log( 'actions/project: Project_List: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            // console.log( 'actions/project: Project_List: resp_list: ', resp_list )

            //
            // return resp_list
            const mapped_data = resp_list.Items.map( x => {
                try
                {
                    return {
                        ...x,
                        a: {...x.a, t: x.a.t / 100.0, r: x.a.r / 100.0},
                    }
                }
                catch( err )
                {
                    return x
                }
            } )

            //
            return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'actions/project: Project_List: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Project_List

/**
 */
export const Project_Details = ( tn ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_get = {
                actType: 'proj',
                act: 'proj:details',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    tn
                },
            }

            console.log( 'actions/project: Project_Details: p_get: ', p_get )

            const resp_get = await aws_lambda.Invoke( p_get, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/project: Project_Details: resp_get: ', resp_get )

            //
            // return resp_get
            return {
                ...resp_get,
                Item: {
                    ...resp_get.Item,
                    a: {
                        ...resp_get.Item.a,
                        t: resp_get.Item.a.t / 100.0,
                        r: resp_get.Item.a.r / 100.0,
                    },
                },
            }
        }
        catch( err )
        {
            console.warn( 'actions/project: Project_Details: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Project_Details

/**
 */
export const Project_New = ( proj_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()


            const p_new = {
                actType: 'proj',
                act: 'proj:new',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    p: proj_obj.p,
                    v: proj_obj.v,
                    a: proj_obj.a,
                    ts: 0,
                    te: 0,
                    s: proj_obj.s,
                    ct:proj_obj.ct,
                },
            }

            // console.log( 'actions/project: Project_New: p_new: ', p_new )

            const resp_new = await aws_lambda.Invoke( p_new, Cfg.lambda[Cfg.stage].admin )

            if (!resp_new.Item)
            {
                throw new Error("Failed to create project!")
            }

            console.log( 'actions/project: Project_New: resp_new: ', resp_new )

            proj_obj['tn'] = resp_new.Item.tn
            proj_obj['i'] = resp_new.Item.i

            const {success,project} = await d(UpdateContent(proj_obj))
            if (success)
            {
                return {project}
            }
            //
            return {project:resp_new.Item}
        }
        catch( err )
        {
            console.warn( 'actions/project: Project_New: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Project_New

/**
 */
export const Project_Edit = ( proj_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new Lambda( {} )

            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()
            proj_obj.ct = await d(ProcessContent(proj_obj));

            const p_edit = {
                actType: 'proj',
                act: 'proj:edit',
                // c_id: gs().__auth.orgId,
                c_id: window.localStorage.getItem( 'org_id' ),

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    tn: proj_obj.tn,
                    p: proj_obj.p,
                    v: proj_obj.v,
                    a: proj_obj.a,
                    ts: proj_obj.ts,
                    te: proj_obj.te,
                    s: proj_obj.s,
                    ct:proj_obj.ct,
                    amount_target: proj_obj.a ? parseInt( proj_obj.a*100, 10) : 0,
                },
            }

            console.log( 'actions/project: Project_Edit: p_edit: ', p_edit )

            const resp_edit = await aws_lambda.Invoke( p_edit, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/project: Project_Edit: resp_edit: ', resp_edit )

            //
            return resp_edit
        }
        catch( err )
        {
            console.warn( 'actions/project: Project_Edit: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Project_Edit

/**
 */
export const SaveProjectImage = (params) =>
{
    return async (d,gs)=> {
        try {
            const aws_lambda = new Lambda({})
            const aws_core = new Core({})
            const cur_usr = await aws_core.currentuser()
            const p_list = {
                actType: 'proj',
                act: 'proj:img-update',
                c_id: gs().__auth.orgId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    img:{
                        p:{
                            f:params.url,
                            t:""
                        },
                        f:[],
                        g:[]
                    },
                    tn:params.tn
                },
            }

            console.log( 'actions/project: SaveProjectImage: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )

            console.log( 'actions/project: SaveProjectImage: resp_list: ', resp_list )

            //
            return resp_list

        } catch (e) {
            console.warn("action/project: SaveProjectImage:error", e)
            return Promise.reject(e);
        }
    }
}

const UpdateContent = (params) =>
{
    return async (d, gs)=>{
        try {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()
            if (!cur_usr)
            {
                throw new Error("User not found!")
            }
            params.ct = await d(ProcessContent(params));

            const upd_p = {
                actType: 'proj',
                act: 'proj:content-update',
                c_id: gs().__auth.orgId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data:{
                    tn:params.tn,
                    i:params.i,
                    ct:params.ct,
                }
            }

            console.log( 'actions/project: UpdateContent: p_list: ', upd_p )

            const {success, project} = await aws_lambda.Invoke( upd_p, Cfg.lambda[Cfg.stage].admin )
            console.log( 'actions/project: UpdateContent: resp_new: ', project )
            if (!success)
            {
                return success
            }
            return {success,project:project.data.Attributes}

        }catch (e) {
            console.warn(e);
            return Promise.reject(e);
        }
    }
}

