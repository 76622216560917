import {Core, Lambda} from "../../api/AWS";
import * as Cfg from "../../_cfg";


function getSignedUrl(params) {
    return async (d, gs)=>{
        try {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'storage',
                act: 'storage:get-signed-url',
                c_id: gs().__auth.orgId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data: {
                    bucket:params.bucket,
                    key:params.key,
                    contentType:params.contentType
                },
            }

            // console.log( 'actions/storage: getSignedUrl: p_list: ', p_list )
            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )
            // console.log( 'actions/storage: getSignedUrl: ', resp_list )
            return {success:true,...resp_list}
        }catch (e) {
            console.warn("actions/storage: getSignedUrl:error",e)
            return Promise.reject(e);
        }
    }
}

export const uploadFile = (params)=> {
    return async (d, gs)=> {
        try {
            const aws_core = new Core( {} );
            const auth_cred =  await aws_core.Credentials_Current();
            const file_p = {
                name: params.name,
                key: params.key,
                contentType: params.contentType,
                bucket: params.bucket,
                region:auth_cred._clientConfig.region,
                file:params.file
            }
            // console.log('actions/storage: UploadFile: p_list: ', file_p)
            const {success, url} = await d(getSignedUrl(file_p))
            // console.log('actions/storage: UploadFile: success,url', success,url)
            if (success) {
                file_p['url'] = url
                const resp = await Save2S3(file_p);
                // console.log('actions/storage: UploadFile: resp', resp.success,params)

                if (resp.success)
                {
                    const gall_p = {
                        fn: params.name,
                        k: params.key,
                        ct: params.contentType,
                        ft: params?.ft || params.contentType,
                        tg: params?.tg || [],
                        tp: params?.tp || 'gallery',
                        g: params?.g || 'gallery',
                        t: params?.title || params.name,
                        d: params?.desc || params.name,
                        st:'ok',
                        url:resp.url
                    }
                    if (params.iid)
                    {
                        gall_p['ii'] = params.iid
                    }
                    // console.log('actions/storage: UploadFile: gall_p', gall_p)
                    const gal = await d(updateGallery(gall_p))
                    return {s3:resp,...gal}
                }

                // console.log('actions/storage: UploadFile: ', resp)
            }

            return {}

        } catch (e) {
            console.warn('action/storage: UploadFile:error', e);
            return Promise.reject(e);
        }
    }
}


export const ProcessContent = params =>
{
    return async (d, gs)=>{
        try {
            const {listDesc} = params
            params.ct['listDesc'] = await listDesc.reduce(async (acc, cur, index) => {
                const accum = await acc;
                if (cur.d.file) {
                    const file = cur.d.file
                    const ft = file.type.split("/")[1];
                    const dtNow = Date.now()
                    const iid = [dtNow,params.i, params.tn,'gallery_'+index,file.name.split('.')[0]].join("_")
                    const name =  iid+ "." + ft;
                    const params_upd = {
                        name: name,
                        key: name,
                        contentType: file.type,
                        file: file,
                        g:'content',
                        tp:'gallery',
                        iid,
                        ft,
                    };

                    // console.log("Upload params", params_upd);

                    // const { success, url } = await dispatch(uploadFile(params));
                    const {s3} = await d(uploadFile(params_upd));

                    // console.log(s3);

                    if (s3.success) {
                        cur.d = {url: s3.url}
                        accum.push(cur)
                    }
                } else {
                    accum.push(cur)
                }
                return accum
            }, Promise.resolve([]))

            return params.ct
        }catch (e) {
            console.warn(e)
            return Promise.reject(e)
        }
    }
}

function Save2S3(p) {
    return new Promise(async (resolve, reject) => {
        try {
            const resp = await fetch(p.url, {
                method: "PUT",
                headers: {
                    "Content-Type": p.contentType
                },
                body: p.file
            });

            if (resp.status === 200)
            {
                const url = resp.url.split('?')[0]
                return resolve({success:true,url})
            }
            else {
                return reject({success:false,error:resp.statusText})
            }
        }catch (e) {
            console.warn('action/storage: Save2S3:error',e)
            return reject(e)
        }
    })
}
function updateGallery(p) {
    return async (d, gs)=>{
        try {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            const p_list = {
                actType: 'storage',
                act: 'storage:update-gallery',
                c_id: gs().__auth.orgId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data: p,
            }

            // console.log( 'actions/storage: updateGallery: p_list: ', p_list )
            const resp_list = await aws_lambda.Invoke( p_list, Cfg.lambda[Cfg.stage].admin )
            // console.log( 'actions/storage: updateGallery: ', resp_list )
            return {success:true,gal:resp_list}
        }catch (e) {
            console.warn("actions/storage: updateGallery:error",e)
            return Promise.reject(e);
        }
    }
}

