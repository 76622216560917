/**
 * @copyright KARD
 */

import React from 'react'

//  import {
//      Button,
//  } from '@material-ui/core'

import {navigate} from "@reach/router";

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

//  import {Time} from '../../api/Utils' 

import Page from '../_common/page'
// import Hdr from '../_common/hdr'
// import SideBar from '../_common/sidebar'
// import ChatNotif from '../_common/chat_notifications'

// import {ViewApntModal} from './appntModal'


/**
 */
class ProfileEdit extends React.PureComponent
{
constructor(props) {
    super(props)

    this.state = {
        //
    }
}

    render()
    {
        return (
            <Page>
                <div className="row mb-5">
                    <div className="col-sm-4 col-3">
                        <h4 className="projectTitle"><i className="fa fa-user" /> Profile Edit</h4>
                    </div>
                </div>

            <div className="row mt-3 card">
                <div className="col-sm-12">
                    <form>
                        <div className="form-row mt-2 mb-2">
                            <div className="col-md-4">
                                <label for="titel" className="col-form-label">Title</label>
                                <input type="text" className="form-control" id="titel" placeholder="Miss/Mr/Mrs" />
                            </div>
                            <div className="col-md-4">
                                <label for="f_name" className="col-form-label">First Name</label>
                                <input type="text" className="form-control" id="f_name" placeholder="Giovani" />
                            </div>
                            <div className="col-md-4">
                                <label for="l_name" className="col-form-label">Last Name</label>
                                <input type="text" className="form-control" id="l_name" placeholder="Alesandro" />
                            </div>
                        </div> 
                            <div className="form-group">
                                <label for="address_line" className="col-form-label">Address Line 1:</label>
                                <input type="text" className="form-control" id="address_line" placeholder="221 Beker Street" />
                            </div>
                        <div className="form-row mt-2 mb-2">
                            <div className="col-md-6">
                                <label for="contact" className="col-form-label">Contact No</label>
                                <input type="text" className="form-control" id="contact" placeholder="01578988745" />
                            </div>
                            <div className="col-md-6">
                                <label for="post" className="col-form-label">Post Code</label>
                                <input type="text" className="form-control" id="post" placeholder="0X44 GP" />
                            </div>
                        </div> 
                            <div className="form-group">
                                <label for="email" className="col-form-label">Email Address</label>
                                <input type="text" className="form-control" id="email" placeholder="adam@example.com" />
                            </div>                       
                            <div className="form-group">
                                <label for="source" className="col-form-label">Source</label>
                                <input type="text" className="form-control" id="source" placeholder="Your Source" />
                            </div>                       
                        </form>
                </div>    
            </div>    
            </Page>
        )
    }   // render

    // componentDidMount()
    // {
    //     //
    // }
}   // ProfileEdit

/**
 */
const mapStateToProps = ( state ) =>
{
    return state
}   //

export default connect(mapStateToProps, actions)( ProfileEdit )





